import {fetch2} from "../../utils.js"
export default class TingbogsFetcher {
  /**
   * @param {Object} options
   * @param {string} [options.token] Bearer token til tingbogs-api
   * @param {string} [options.endpoint=https://tinglysning-api.septima.dk]
   **/
  constructor(options = {}) {
    if (options.test) {
      this.token = "4kxsUaaDjfWh8Hf8EPXfCw"
      if (options.token)
        this.token = options.token

      this.endpoint = "https://tinglysning-api.test.septima.dk"
      if (options.endpoint)
        this.endpoint = options.endpoint
      
    } else {
      this.token = "2axdUaadjfeh5Hf6EPXfca"
      if (options.token)
        this.token = options.token

      this.endpoint = "https://tinglysning-api.septima.dk"
      if (options.endpoint)
        this.endpoint = options.endpoint
    }
  }
  
  async getEjendomSummarisk(bfeNo) {
    let reply = await this.get("tinglysningapi", {BFEs: bfeNo})
    return reply[0].Items[0]
  }
  
  getRequestOptions(params) {
    let fetchOptions = {}
    if (params)
      fetchOptions.data = params
    if (this.token)
      fetchOptions.Authorization =  {Bearer: {token: this.token}}
    return fetchOptions
  }

  async get(target, params) {
    let url = `${this.endpoint}/${target}`
    let fetchOptions = this.getRequestOptions(params)
    return await fetch2(url, fetchOptions)
  }
}