import kodeMap from './bbrkoder/bbr_kode-lookup.js'
/*
{
  "AdresseRolle": {
    "0": {
      "title": "Fastsat til denne",
      "presentText": "Fastsat til denne",
      "presentShortText": "Fastsat til denne",
      "description": " "
    },
    "1": {
      "title": "Kun vejledende",
      "presentText": "Kun vejledende",
      "presentShortText": "Kun vejledende",
      "description": " "
    }
  },
  {...},
  ...
}  
 */
export default class BbrKodeliste {
  
  find(codelist, key) {
    if (key) {
      let codelistMap = kodeMap[codelist]
      if (codelistMap) {
        let keyMap = codelistMap[key]
        if (keyMap && keyMap.presentShortText)
          return keyMap.presentShortText
        else if (keyMap && keyMap.presentText)
          return keyMap.presentText
      }
      return codelist + "." + key
    } else {
      return "Ikke angivet"
    }
  }

}