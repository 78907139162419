/**
 * @module
 */
import {getWKTParser} from "../../util/getWKTParser.js"
import {fetch2} from "../../utils.js"
/**
 * BETA BEATA Represents a geoserver wfs BETA BETA
 *
 * @example <caption>YAML Declaration</caption>
mysearchers:
  daiservice:
    _type: Septima.Search.Geoserver.Service
    _options:
      endpoint: https://b0902-udv-dist-app.azurewebsites.net/geoserver/wfs
      source: dai
      defaultIdField: {name: "Objekt_id", type: "string"}
      defaultFields: {geometry: "Shape"} 
  dai:bes_vandloeb:
    _type: Septima.Search.Geoserver.Searcher
    _options:
      service:
        _ref: $.mysearchers.daiservice
      featureType: {name: "dai:bes_vandloeb", singular: "Beskyttet vandløb", plural: "Beskyttede vandløb"}
 * @api
 */
export default class Service {

  /**
   * @param {Object} options Service expects these options:<br/>
   * @param {string} options.endpoint service-url eg: "https://b0902-udv-dist-app.azurewebsites.net/geoserver/wfs"
   * @param {string} options.source Results will set to this source
   * @param {Object} [options.defaultIdField] id information
   * @param {string} [options.defaultIdField.name] the name of the field containing a feature's id
   * @param {string} [options.defaultIdField.type] the type of the field containing a feature's id
   * @param {Object} [options.defaultFields] information on other important field names
   * @param {string} options.defaultFields.geometry the name of the field containing the geometry
   * @param {string} [options.defaultFields.title] the name of the field to be used as title
   * @param {string} [options.defaultFields.description] the name of the field to be used as description
   */
  constructor(options={}) {
    if (!options.endpoint || !options.source)
      throw new Error("geoserver.Service expects options.endpoint and options.source")
    this._endpoint = options.endpoint
    this._source = options.source

    this._defaultIdField
    if (options.defaultIdField)
      this._defaultIdField = options.defaultIdField
    
    this._defaultFields
    if (options.defaultFields)
      this._defaultFields = options.defaultFields
  }
  
  get endpoint() {
    return this._endpoint
  }
  
  get source() {
    return this._source
  }
  
  get defaultFields() {
    return this._defaultFields
  }
  
  get defaultIdField() {
    return this._defaultIdField
  }

  async fetchFeatureById(featureType, id, idFieldToUse) {
    let idField = Object.assign({}, this._defaultIdField, idFieldToUse)
    try {
      let value = id
      if (idField.type == "string")
        value = "'" + value + "'"
      return await this.fetchFeatures(featureType, `${idField.name}=${value}`)
    } catch {
      return []
    }
  }

  async fetchFeatureByGeometry_get(featureType, geometry, geometryFieldToUse, options= {}) {
    let geometryField = geometryFieldToUse ? geometryFieldToUse : this._defaultFields.geometry
    let wktParser = getWKTParser()
    const wkt = wktParser.convert(geometry)
    try {
      return await this.fetchFeatures(featureType, `intersects(${geometryField},${wkt})`, options)
    } catch {
      return []
    }
  }

  async fetchFeatureByGeometry(featureType, geometry, geometryFieldToUse, options= {}) {
    let geometryField = geometryFieldToUse ? geometryFieldToUse : this._defaultFields.geometry
    let wktParser = getWKTParser()
    const wkt = wktParser.convert(geometry)
    try {
      options.method = "post"
      return await this.fetchFeatures(featureType, `intersects(${geometryField},${wkt})`, options)
    } catch {
      return []
    }
  }
  
  async fetchFeatures(featureType, cqlFilter, options= {}) {
    let method = "get"
    if (options.method == "post")
      method = "post"
    let maxFeatures = 200
    if (options.maxFeatures)
      maxFeatures = options.maxFeatures
    try {
      let result
      if (method === 'get') {
        result = await fetch2(`${this.endpoint}?request=getFeature&srs=EPSG:25832&typename=${featureType}&cql_filter=${cqlFilter}&outputFormat=json&count=${maxFeatures}`)
      } else {
        let params = {
          servicename: 'WFS',
          request: 'GetFeature',
          srs: "EPSG:25832",
          typename: featureType,
          CQL_FILTER: cqlFilter,
          outputformat: 'json',
          count: maxFeatures
        }
        result = await fetch2(this.endpoint, { data: params, method: 'post' })
      }
      return result.features
    } catch (e) {
      return []
    }
  }


}