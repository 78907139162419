/**
 * @module
 **/
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
/**
 * Viser info for en politikreds fra GeoSearch
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.GeoSearch
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.InfoForPolitidistriktProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 *geoSearcher.addDetailHandlerDef(new Septima.Search.InfoForPolitidistriktProvider())
 *
 * @example <caption>ES6:</caption>
 * import InfoForPolitidistriktProvider from './searchers/detailhandlers/InfoForPolitidistriktProvider.js'
 * geoSearcher.addDetailHandlerDef(new InfoForPolitidistriktProvider())
 * @api
 **/
export default class InfoForPolitidistriktProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields=["presentationString","id", "name"] Felter, der skal medtages i info
   **/
  constructor(options= {}) {
    let defaultOptions = {
      buttonText: "Politidistrikt",
      more: true
    }
    super(Object.assign(defaultOptions, options))

    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.handler
    //Default fields
    this.fields = ["presentationString","id", "name"]
    //logger.debug('InfoForMatrikelProvider.isapplicable')
    if (typeof options !== 'undefined' && typeof options.fields !== 'undefined')
      this.fields = options.fields
  }

  isApplicable(result) {
    //logger.debug('result: ',result)
    return (result.source === "Kortforsyningen" && (result.type.id === "politikredse"))
  }
  async handler(result) {
    //logger.debug('InfoForMatrikelProvider handler called')

    let items = []
    //logger.debug(this.fields)
    for (let field of this.fields) {
      let item = this.createItem(result, field)
      if (typeof item !== 'undefined')
        items.push(item)
    }
    //logger.debug(items)  logger.debug('InfoForMatrikelProvider.isapplicable')
    return items

  }
  
  createItem(result, field) {
  
    let props = result.data
    //logger.debug(props)
    if (field === 'presentationString') 
      return {
        type: 'labelvalue',
        label: 'Beskrivelse',
        value: props.presentationString
      }
    else if(field === 'id') 
      return {
        type: 'labelvalue',
        label: 'id',
        value: props.id
      }
    
    else if(field === 'name') 
      return {
        type: 'labelvalue',
        label: 'Navn',
        value: props.name
      }
    
   
    return 
  }


}