/**
 * @module
 */

/**
 *
 * A single result from a searcher. May represent either a real result or a new query. Only Searchers will create instances of Result
 * @api
 */
export default class Result {
  /**
   * @property {string} type This result's type
   * @api
   */
  typeId = null
  /**
  * @property {string} source This result's source
  * @api
  */
  source = null
  /**
 * @property {string} title This result's title
 * @api
 */
  title = null
  /**
 * @property {string} description This result's description
 * @api
 */
  description = null
  /**
 * @property {string} geometry This result's geometry
 * @api
 */
  geometry = null
  /**
 * @property {string} data This result's data (As returned from underlying service or data)
 * @api
 */
  data = null
  /**
 * @property {string} searcher This result's searcher
 * @api
 */
  searcher = null
  /**
 * @property {string} image This result's image url
 * @api
 */
  image = null
  /**
 * @property {string} newquery This newquery suggestion
 * @api
 */
  newquery = null
  /**
  /**
 * @property {string} isComplete Whether the result is complete
 * @api
 */
  isComplete = true

  get type() {
    const type = this.searcher.getType(this.source, this.typeId)
    if (!type)
      throw new Error(`Cannot resolve type for source ${this.source} and typeId ${this.typeId}`)
    return type
  }

  constructor(options) {
    Object.assign(this, options)
    this._hasdetailHandlerDefs = null
  }

  summary() {
    return {
      title: this.title,
      typeId: this.typeId
    }
  }

  isNewQuery() {
    return typeof this.newquery !== 'undefined' && this.newquery !== null
  }

  /**
 * @async
 * @returns {module:js/Result} Completed result
 * @throws {Error}
 * @api
 */
  async complete() {
    if (this.isComplete || this.searcher === null)
      return this
    else
      return this.searcher.completeResult(this)
  }

  hasdetailHandlerDefs() {
    if (this._hasdetailHandlerDefs === null)
      if (this.searcher !== null)
        this._hasdetailHandlerDefs = this.searcher.hasdetailHandlerDefs(this)
      else
        this._hasdetailHandlerDefs = false
    return this._hasdetailHandlerDefs
  }

  async getdetailHandlerDefs() {
    return this.searcher.getdetailHandlerDefs(this)
  }

  hasDescription() {
    return (this.description && this.description !== null && this.description !== "")
  }

  /**
 * @async
 * @returns {Object}
 * @throws {Error}
 * @api
 */
  async getRelations() {
    if (this.searcher === null)
      return { children: [], parents: [], siblings: [] }
    else
      return this.searcher.getRelations(this)
  }
}