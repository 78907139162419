import ClassRegistry from "../../ClassRegistry.js"
import TingbogsOplysningerForBfe from "./TingbogsOplysningerForBfe.js"
import TingbogsFetcher from "./TingbogsFetcher.js"
import TingbogsSearcher from "./TingbogsSearcher.js"

const tingbogTypes = new ClassRegistry("Septima.Search.Tingbog.")

const typesToAdd = {
  TingbogsOplysningerForBfe,
  TingbogsFetcher,
  TingbogsSearcher
}

for (let [key, value] of Object.entries(typesToAdd))
  tingbogTypes.addClass(value, key)

export default tingbogTypes