export default class Cache {

  constructor(options= {}) {
    this.ttl = options.ttl || 60 // Seconds
    this.maxEntries = options.maxEntries || 40
    this.data = {}
    this.entries = []
  }


  now() {
    return new Date().getTime() / 1000
  }

  /**
   * Checks if a key is currently set in the cache.
   * @param key the key to look for
   * @returns {boolean} true if set, false otherwise
   */
  _has(key) {
    return this.data.hasOwnProperty(key)
  }

  /**
   * Clears all cache entries.
   */
  clear() {
    for(let key in this.data)
      if (this.data.hasOwnProperty(key))
        this.remove(key)
  }

  /**
   * Gets the cache entry for the given key.
   * @param key the cache key
   * @returns {*} the cache entry if set, or undefined otherwise
   */
  get(key) {
    if (this.ttl == 0)
      return
    if (this._has(key)) {
      let entry = this.data[key]
      if (entry.expired(this.now())) {
        let indexOfKey = this.entries.indexOf(key)
        this.entries.splice(indexOfKey, 1)
        this.remove(key)
      } else {
        return entry.value
      }
    }
  }

  /**
   * Sets a cache entry with the provided key and value.
   * @param key the key to set
   * @param value the value to set
   */
  set(key, value) {
    if (this.ttl == 0)
      return
    let entry = new CacheEntry(this.now() + this.ttl, value)
    if (this.entries.indexOf(key) == -1) {
      if (this.entries.length > this.maxEntries) {
        let outgoingKey = this.entries.shift()
        this.remove(outgoingKey)
      }
      this.entries.push(key)
    }
    this.data[key] = entry      
  }
  
  setResult(result) {
    if (this.ttl == 0)
      return
    if (result.isComplete && result.id)
      this.set(result.id, result)
  }

  /**
   * Removes the cache entry for the given key.
   * @param key the key to remove
   */
  remove(key) {
    delete this.data[key]
  }

}

/**
 * Object for holding a value and an expiration time
 * @param expires the expiry time as a UNIX timestamp
 * @param value the value of the cache entry
 * @constructor ¯\(°_o)/¯
 */
class CacheEntry  {
  constructor(expires, value) {
    this.expires = expires
    this.value = value
  }
  
  expired(now) {
    return this.expires < now
  }
}

