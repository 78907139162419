import GeoSearch from "./GeoSearch.js"
import GSearchFetcher from "./GSearchFetcher.js"

export default class GSearch extends GeoSearch {

  constructor(options) {
    super(options)
    this.kommunefilter = null
    this.fetcher = new GSearchFetcher(options.kommunekode)
  }

  async asyncFetchData(query) {
    switch (query.type) {
      case 'collapse': {
        return this.createQueryResultFromResources()
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let data = await this.fetchFromGSearch(query)
        data.query = query
        return this.parseResult(data.data, query)
      }
    }
  }



}