/**
 * @module
 */

import Cache from "./util/Cache.js"

export default class ResultType {
  /**
   * @param {Object} options
   * @param {string} options.id
   * @param {string} [options.singular=id]
   * @param {string} [options.plural=options.singular]
   * @param {string} [options.description=""]
   * @param {string} [options.queryBehaviour="search"] 'search', 'match', 'none'
   * @param {string} [options.geometrySupport="hasGeometry"] sq, hasGeometry, none
   * @param {string} [options.iconURI=icons.result.defaultIcon]
   * @param {function} [options.itemFunction] Function that converts an item to a reulst added to a queryresult (item, queryResult)=>
   */
  constructor(options) {
    if (typeof options === 'undefined' || typeof options.id === 'undefined')
      throw ("ResultType needs id as an option")
    let defaultValues = {
      description: '',
      queryBehaviour: 'search', //'search', 'match', 'none'
      geometrySupport: 'hasGeometry' // sq, hasGeometry, none
    }
    this.values = Object.assign(defaultValues, options)
    this.values.singular = this.values.singular ? this.values.singular : this.values.id
    this.values.plural = this.values.plural ? this.values.plural : this.values.singular
    
    let cacheOptions = {}
    if (this.values.ttl)
      cacheOptions.ttl = this.values.ttl
    if (this.values.maxEntries)
      cacheOptions.maxEntries = this.values.maxEntries
    
    this._cache = new Cache(cacheOptions)
  }
  
  get cache() {
    return this._cache
  }
  
  set cache(c) {}
  
  get id() {
    return this.values.id
  }

  get singular() {
    return this.values.singular
  }

  set singular(singular) {
    this.values.singular = singular
  }
  
  get plural() {
    return this.values.plural
  }

  set plural(plural) {
    this.values.plural = plural
  }

  get description() {
    return this.values.description
  }
  
  get queryBehaviour() {
    return this.values.queryBehaviour
  }

  set queryBehaviour(queryBehaviour) {
    this.values.queryBehaviour = queryBehaviour
  }

  get hasGeometry() {
    return this.values.geometrySupport == 'sq' || this.values.geometrySupport == 'hasGeometry' 
  }
  
  get iconURI() {
    return this.values.iconURI
  }

  get itemFunction() {
    return this.values.itemFunction
  }
  
  set iconURI(uri) {
    this.values.iconURI = uri
  }

  toJSON() {
    return this.values
  }
}
