/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import DawaSearcher from "../DawaSearcher.js"
import {fetch2} from "../../utils.js"

/**
 * Viser info om en bygning (daf.bbr_bygning)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: datafordeler.BygningsInfoProvider
 *   _options:
 *     fetcher:
 *       _type: datafordeler.Fetcher
 *       _options:
 *         ...
 * @api
 **/
export default class BygningsInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText=Bygningen] Titel
   * @param {Object} [options.sections=[]] Kan være [bbrkommune]. Så medtages links til kommune.bbr.dk for bygning og grund
   * @param {Object} options.fetcher datafordeler.Fetcher
   * @param {Object} options.fetcher datafordeler.EjendomsSearcher
   **/
  constructor(options = {}) {
    if (!options.fetcher)
      throw new Error("BygningsInfoProvider called without options.fetcher")

    let defaultOptions = {
      buttonText: "Bygningen",
    }

    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.source === "DAF" && result.typeId === "bbr_bygning"))
    }

    //Read sections from options
    this.sections = []
    if (typeof options.sections !== 'undefined')
      this.sections = options.sections

    this.dawaSearcher = new DawaSearcher({})

    this.fetcher = options.fetcher

    if (typeof options.ejendomsSearcher !== 'undefined')
      this.ejendomsSearcher = options.ejendomsSearcher
  }

  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "DAF" && typeId === "bbr_bygning")
      return await this.getBygningsDetails(result)
  }

  async getBygningsDetails(bygningsResult) {
    let items = []
    let bbrBygningResponse = bygningsResult.data

    let beskrivelsesSectionsPromise = this.getBeskrivelsesSections(bbrBygningResponse)
    //let getEtageListeSectionsPromise = this.getEtageListeSections(bbrBygningResponse)
    let forsyningsSectionPromise = this.getForsyningsSection(bbrBygningResponse)
    let energiMaerkeSectionPromise = this.getEnergiMaerkeSection(bbrBygningResponse)
    let arealerSectionPromise = this.getArealerSection(bbrBygningResponse)
    let ejendomsSectionPromise = this.getEjendomsSection(bbrBygningResponse)
    let boligerVirksomhederSectionPromise = this.getBoligerVirksomhederSection(bbrBygningResponse)
    let bbrKommuneLinksPromise = this.getBbrKommuneLinks(bbrBygningResponse)


    items = [
      ...await beskrivelsesSectionsPromise,
      ...await arealerSectionPromise,
      ...await forsyningsSectionPromise,
      ...await energiMaerkeSectionPromise,
      ...await ejendomsSectionPromise,
      ...await boligerVirksomhederSectionPromise,
      ...await bbrKommuneLinksPromise]
    return items
  }

  async getBeskrivelsesSections(bbrBygningResponse) {
    //Skal returnere to resultlists
    let items = []

    //Første resultlist
    //https://github.com/Septima/septima-search/issues/366#issuecomment-642627030
    let detailItemsList = new DetailItemsList({
      header: "Bygningen",
      name: "bygning_adresse_anvendelse"
    })

    let adgangsadressebetegnelse = ""
    try {
      let husnummerId = bbrBygningResponse.husnummer
      let dawaResult = await this.dawaSearcher.get(husnummerId, "adresse")
      adgangsadressebetegnelse = " - " + dawaResult.title
      // eslint-disable-next-line no-empty
    } catch (error) {
    }

    detailItemsList.append({
      type: "labelvalue",
      value: `BYG: ${bbrBygningResponse.byg007Bygningsnummer ? bbrBygningResponse.byg007Bygningsnummer : '(Intet nummer)'}  ${adgangsadressebetegnelse}`
    })

    detailItemsList.append({
      type: "labelvalue",
      value: "(" + bbrBygningResponse.byg021BygningensAnvendelse + ") " + this.fetcher.findBbrKode("BygAnvendelse", bbrBygningResponse.byg021BygningensAnvendelse)
    })


    items.push(detailItemsList.asItem())

    //Anden resultlist
    //https://github.com/Septima/septima-search/issues/366#issuecomment-644699466
    detailItemsList = new DetailItemsList({
      header: "Beskrivelse",
      name: "bygning_info"
    })

    detailItemsList.append({
      type: "labelvalue",
      value: this.fetcher.findBbrKode("BygAnvendelse", bbrBygningResponse.byg021BygningensAnvendelse) + " - " + bbrBygningResponse.byg041BebyggetAreal + "m2"
    })
    let etager = bbrBygningResponse.byg054AntalEtager
    if (etager) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Etager",
        value: etager
      })


    }
    detailItemsList.append({
      type: "labelvalue",
      label: "Opførelsesår",
      value: bbrBygningResponse.byg026Opførelsesår
    })

    let tilbygningsaar = bbrBygningResponse.byg027OmTilbygningsår
    if (tilbygningsaar) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Til/ombygningsår",
        value: tilbygningsaar
      })
    }

    let tagdaekningsMaterialeKode = bbrBygningResponse.byg033Tagdækningsmateriale
    let tagdaekningsMaterialeTekst = this.fetcher.findBbrKode("Tagdaekningsmateriale", tagdaekningsMaterialeKode)
    if (tagdaekningsMaterialeTekst) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Tag",
        value: tagdaekningsMaterialeTekst
      })
    }

    let ydervaegsMaterialeKode = bbrBygningResponse.byg032YdervæggensMateriale
    let ydervaegsMaterialeTekst = this.fetcher.findBbrKode("YdervaeggenesMateriale", ydervaegsMaterialeKode)
    if (ydervaegsMaterialeTekst) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Ydervægge",
        value: ydervaegsMaterialeTekst
      })
    }
    items.push(detailItemsList.asItem())

    return items
  }
  async getEtageListeSections(bbrBygningResponse) {

    let items = []




    let detailItemsList = new DetailItemsList({
      header: "Etager",
      name: "bygning_etager"
    })

    bbrBygningResponse.etageList.forEach(function (e) {
      if (e.etage.status === "6") {
        let text = ""
        if (e.etage.eta025Etagetype === "0") { //0 - Regulær etage
          text = "Regulær etage"
        } else if (e.etage.eta025Etagetype === "1") { //1 - Tagetage
          text = "Tagetage"
          if (e.etage.eta020SamletArealAfEtage)
            text = text + " - samlet areal: " + e.etage.eta020SamletArealAfEtage + "m2"
          if (e.etage.eta021ArealAfUdnyttetDelAfTagetage)
            text = text + " ,udnyttet areal: " + e.etage.eta021ArealAfUdnyttetDelAfTagetage + "m2"
        } else if (e.etage.eta025Etagetype === "2") { //2 - Kælder
          text = "Kælder"
          if (e.etage.eta020SamletArealAfEtage)
            text = text + " - samlet areal: " + e.etage.eta020SamletArealAfEtage + "m2"
          if (e.etage.eta022Kælderareal)
            text = text + " ,heraf kælderareal: " + e.etage.eta022Kælderareal + "m2"
        }
        detailItemsList.append({
          type: "labelvalue",
          label: e.etage.eta006BygningensEtagebetegnelse,
          value: text
        })
      }
    })
    items.push(detailItemsList.asItem())

    return items
  }

  async getForsyningsSection(bbrBygningResponse) {
    let forsyningsItems = []

    let detailItemsList = new DetailItemsList({
      header: "Forsyning",
      name: "bygning_forsyning"
    })

    let byg056Varmeinstallation = bbrBygningResponse.byg056Varmeinstallation
    let varmeinstallationTekst = this.fetcher.findBbrKode("BygVarmeinstallation", byg056Varmeinstallation)
    detailItemsList.append({
      type: "labelvalue",
      label: "Varme",
      value: varmeinstallationTekst
    })

    let grundeResponse = await this.fetcher.read("bbr", "bbr", "grund", { id: bbrBygningResponse.grund }, this.getLogger())

    if (bbrBygningResponse.byg030Vandforsyning) {
      let byg030Vandforsyning = bbrBygningResponse.byg030Vandforsyning // Eks: 1
      let vandforsyningsTekst = this.fetcher.findBbrKode("BygVandforsyning", byg030Vandforsyning)
      detailItemsList.append({
        type: "labelvalue",
        label: "Vand",
        value: vandforsyningsTekst
      })
    } else if (grundeResponse) {
      let gru009Vandforsyning = grundeResponse[0].gru009Vandforsyning // Eks: 1
      let vandforsyningsTekst = this.fetcher.findBbrKode("GruVandforsyning", gru009Vandforsyning)
      detailItemsList.append({
        type: "labelvalue",
        label: "Vand",
        value: vandforsyningsTekst
      })
    }

    if (bbrBygningResponse.byg031Afløbsforhold) {
      let byg031Afløbsforhold = bbrBygningResponse.byg031Afløbsforhold // Eks: 10
      let afloebsTekst = this.fetcher.findBbrKode("Afloebsforhold", byg031Afløbsforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Afløb",
        value: afloebsTekst
      })
    } else if (grundeResponse) {
      let gru010Afløbsforhold = grundeResponse[0].gru010Afløbsforhold // Eks: 10
      let afloebsTekst = this.fetcher.findBbrKode("Afloebsforhold", gru010Afløbsforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Afløb",
        value: afloebsTekst
      })
    }

    forsyningsItems.push(detailItemsList.asItem())

    return forsyningsItems
  }

  async getEnergiMaerkeSection(bbrBygningResponse) {
    let detailItemsList = new DetailItemsList({
      header: "Energimærke",
      name: "bygning_energi"
    })
    try {
      let grundeResponse = await this.fetcher.read("bbr", "bbr", "grund", { id: bbrBygningResponse.grund }, this.getLogger())
      if (grundeResponse && grundeResponse.length > 0) {
        let komnr = grundeResponse[0].bestemtFastEjendom.kommunekode.substring(1)
        let esrnr = grundeResponse[0].bestemtFastEjendom.ejendomsnummer
        let bygnr = bbrBygningResponse.byg007Bygningsnummer
        let url = "https://emoweb.dk/EMOData/EMOData.svc/SearchEnergyLabelBBR/" + komnr + "/" + esrnr + "/" + bygnr
        let result = await fetch2(url, { Authorization: { Basic: { username: "peter@septima.dk", password: "12345678" } } })
        if (result && result.ResponseStatus.Status === "RESULT_OK" && result.SearchResults && result.SearchResults.length > 0) {
          let id = result.SearchResults[0].EntityIdentifier
          let classification = result.SearchResults[0].EnergyLabelClassification
          detailItemsList.append({
            type: "labelvalue",
            label: "Klassifikation",
            value: classification
          })
          detailItemsList.append({
            type: 'link',
            link: `https://emoweb.dk/EMODigital/EMODigital.svc/PdfDocument/${id}`,
            linkTitle: "Rapport"
          })
          return [detailItemsList.asItem()]
        }
      }
      detailItemsList.append({
        type: "labelvalue",
        value: "Ikke energimærket"
      })
    } catch (e) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Der skete en fejl ved hentning af energimærke"
      })
    }
    return [detailItemsList.asItem()]
  }

  async getArealerSection(bbrBygningResponse) {
    let detailItemsList = new DetailItemsList({
      header: "Arealer (m2)",
      name: "bygning_arealer"
    })

    detailItemsList.append({
      type: "labelvalue",
      label: "Bebygget areal",
      value: bbrBygningResponse.byg041BebyggetAreal,
      valueformat: "int"
    })
    let etageareal = bbrBygningResponse.byg038SamletBygningsareal
    if (etageareal && etageareal != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Etageareal",
        value: bbrBygningResponse.byg038SamletBygningsareal,
        valueformat: "int"
      })
    }
    let erhvervsareal = bbrBygningResponse.byg040BygningensSamledeErhvervsAreal
    if (erhvervsareal && erhvervsareal != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Erhverv",
        value: erhvervsareal,
        valueformat: "int"
      })
    }

    let samletBoligareal = bbrBygningResponse.byg039BygningensSamledeBoligAreal
    if (samletBoligareal && samletBoligareal != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Heraf bolig",
        value: samletBoligareal,
        valueformat: "int"
      })
    }
    return [detailItemsList.asItem()]
  }

  async getEjendomsSection(bbrBygningResponse) {
    let items = []
    if (bbrBygningResponse.bygningPåFremmedGrundList && bbrBygningResponse.bygningPåFremmedGrundList.length > 0) {
      if (bbrBygningResponse.bygningPåFremmedGrundList[0].bygningPåFremmedGrund == 7) {
        let bpgResult = await this.ejendomsSearcher.get(bbrBygningResponse.bygningPåFremmedGrundList[0].bygningPåFremmedGrund.bfeNummer, "bfg")
        if (bpgResult) {
          let detailItemsList = new DetailItemsList({
            header: "Indgår i bygning på fremmed grund",
            name: "bygning_bfg"
          })
          detailItemsList.append({
            type: "result",
            result: bpgResult
          })
          items.push(detailItemsList.asItem())
        }
      }
    }

    //grund
    let grundResponse = await this.fetcher.read("bbr", "bbr", "grund", { id: bbrBygningResponse.grund }, this.getLogger())
    let sfeBfeNummer = grundResponse[0].bestemtFastEjendom.samletFastEjendom
    let sfeResult = await this.ejendomsSearcher.get(sfeBfeNummer, "sfe")
    if (sfeResult) {
      let detailItemsList = new DetailItemsList({
        header: "Ligger på fast ejendom",
        name: "bygning_sfe"
      })
      detailItemsList.append({
        type: "result",
        result: sfeResult
      })
      items.push(detailItemsList.asItem())
    }
    return items
  }

  // eslint-disable-next-line no-unused-vars
  async getBoligerVirksomhederSection(bbrBygningResponse) {
    let items = []
    let detailItemsList = new DetailItemsList({
      header: "Boliger og virksomheder",
      name: "bygning_boliger_virksomheder"
    })

    detailItemsList.append({
      type: "labelvalue",
      label: "TBD",
      value: "TBD"
    })

    items.push(detailItemsList.asItem())
    return items
  }
  async getBbrKommuneLinks(bbrBygningResponse) {
    let items = []
    let detailItemsList = new DetailItemsList({
      header: "BBR kommune",
      name: "bygning_bbr_links"
    })

    if (this.sections.includes("bbrkommune")) {
      detailItemsList.append({
        type: "link",
        link: `https://kommune.bbr.dk/#!/bob/BYG/${bbrBygningResponse.id_lokalId}`,
        linkTitle: "Se bygning"
      })
      detailItemsList.append({
        type: "link",
        link: `https://kommune.bbr.dk/#!/bob/BRUG/${bbrBygningResponse.grund}`,
        linkTitle: "Se grund"
      })

      items.push(detailItemsList.asItem())

    }
    return items
  }
}