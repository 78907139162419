import ClassRegistry from "../../ClassRegistry.js"
import Service from "./Service.js"
import WfsSearcher from "./WfsSearcher.js"

const geoserverTypes = new ClassRegistry("Septima.Search.Geoserver.")

const typesToAdd = {
  Service,
  WfsSearcher
}

for (let [key, value] of Object.entries(typesToAdd))
  geoserverTypes.addClass(value, key)

export default geoserverTypes
