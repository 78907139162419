import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
export default class CvrRegistreringerForHusnummer extends DetailsHandlerDef {
  constructor(options= {}) {
    if (!options.cvrSearcher)
      throw "CvrRegistreringerForHusnummer needs a CvrSearcher"
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      try{
        // eslint-disable-next-line no-console
        console.warn("CVR_enhedSearcher is deprecated - Use Septima.Search.DataApi")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }
    
    let defaultOptions = {
      buttonText: "CVR-registreringer på husnummeret",
      buttonImage: options.cvrSearcher.iconURI,
      targets: [{source: "Dawa", typeId: "adresse"}]
    }
    super(Object.assign(defaultOptions, options))
    
    this.searcher = options.cvrSearcher

    this.handlerFunction = this.myHandler
  }

  async myHandler(result) {
    let items = []
    let registreringer = await this.searcher.getRegistreringerForHusnummer(result.id) 
    
    if (registreringer.virksomheder) {
      let resultType = this.searcher.getType("cvr", "virksomhed")
      let antal = registreringer.virksomheder.length
      let listOptions = {
        itemType: antal > 0 ? "result" : "labelvalue",
        name: "Cvrnr_liste",
        header: resultType.plural,
        image: resultType.iconURI
      }
      let detailItemsList = new DetailItemsList(listOptions)
      if (antal > 0) {
        for (let virksomhed of registreringer.virksomheder) {
          detailItemsList.append({
            type: "result",
            result: virksomhed
          })
        }
      } else {
        items.push({
          type: "labelvalue",
          value: "Ingen " + resultType.plural + " registreret"
        })
      }
      items.push(detailItemsList.asItem())
    }

    if (registreringer.produktionsenheder) {
      let resultType = this.searcher.getType("cvr", "produktionsenhed")
      let antal = registreringer.produktionsenheder.length
      let listOptions = {
        itemType: antal > 0 ? "result" : "labelvalue",
        name: "Pnr_liste",
        header: resultType.plural,
        image: resultType.iconURI
      }
      let detailItemsList = new DetailItemsList(listOptions)
      if (antal > 0) {
        for (let produktionsenhed of registreringer.produktionsenheder) {
          detailItemsList.append({
            type: "result",
            result: produktionsenhed
          })
        }
      } else {
        items.push({
          type: "labelvalue",
          value: "Ingen " + resultType.plural + " registreret"
        })
      }
      items.push(detailItemsList.asItem())
    }
    return items
  }

}