import {fetch2} from "../../utils.js"

export default class Fetcher {
  /**
   * @param {Object} options
   **/
  constructor() {
    
    this.endpoint = "https://fkg.mapcentia.com/api/v2/sql/fkg?"

  }

  async search(q) {
    let data =  {
      srs : "25832",
      q: q
    }
    
    let url = `${this.endpoint}`
    return await fetch2(url,  {data: data})
  }

  async get(target, params) {
    let url = `${this.endpoint}`
    return await fetch2(url, {data: params})
  }

}
