import {fetch2} from "../../utils.js"

export default class GSearchFetcher {
  constructor(options={}) {
    this.serviceUrl = 'https://api.dataforsyningen.dk/rest/gsearch/v1.0'
    this.kommunefilter = null
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let kommuner = options.kommunekode.split(' ')
      for (let i = 0; i < kommuner.length; i++)
        kommuner[i] = "kommunekode like '%" + kommuner[i] + "%'"
      this.kommunefilter = kommuner.join(' or ')
    }
    this.authParamsDataforsyningen = options.authParamsDataforsyningen || options.authParams || {
      token: '22e4c7f57b6172b780abf7a447ebe7bd'
    }
    this.convert2Geo = false
    if (options.convert2Geo)
      this.convert2Geo = true
    
    this.defaultTypes = options.defaultTypes

    this.myTypes = [
      "matrikel",
      "kommune",
      "opstillingskreds",
      "politikreds",
      "postnummer",
      "region",
      "retskreds",
      "sogn",
      "stednavn",
      "matrikel_udgaaet"
    ]
    this.geoTypes = {
      matrikel: "matrikelnummer",
      kommune: "kommune",
      opstillingskreds: "opstillingskreds",
      politikreds: "politikreds",
      postnummer: "postdistrikt",
      region: "region",
      retskreds: "retskreds",
      sogn: "sogn",
      stednavn: "stednavn",
      matrikel_udgaaet: "matrikelnummer_udgaaet"
    }    
  }
  //queryString
  //limit
  //[type]
  async fetchFromGSearch(querystring, limit, type) {
    const searchParams = {
      q: querystring,
      limit: limit
    }
    if (this.kommunefilter)
      searchParams.filter = this.kommunefilter

    let data = []
    if (type === null) {
      let fetchPromises = []
      for (let type of this.defaultTypes) {
        fetchPromises.push(this.fetchType(type, searchParams))
      }
      for (let fetchPromise of await Promise.allSettled(fetchPromises)) {
        if (fetchPromise.status == "fulfilled") {
          data = [...data, ...fetchPromise.value ? fetchPromise.value : []]
        }
      }
    } else {
      data = (await this.fetchType(type, searchParams))
    }
    return {data}
  }

  async fetchType(type, searchParams) {
    searchParams.limit = Math.min(100, searchParams.limit)
    if (searchParams.q == '') {
      let qs = ["a", "b", "c", "d"]
      switch (type) {
        case "politikreds":
          qs.push("politi")
          break
        case "region":
          qs.push("region")
          break
        case "retskreds":
          qs.push("ret")
          break
        case "matrikel":
        case "matrikel_udgaaet":
        case "postnummer":
          qs = ["1", "2", "3", "4"]
          break

        default:
          break
      }
      let geoSearchResponse = []
      for (let q of qs) {
        searchParams.q = q
        geoSearchResponse = [...geoSearchResponse, ... (await this.fetchWithParams(type, searchParams))]
        if (geoSearchResponse.length >= searchParams.limit)
          return geoSearchResponse
      }
      return geoSearchResponse
    } else {
      return await this.fetchWithParams(type, searchParams)
    }
  }

  async fetchWithParams(type, searchParams) {
    let callParams = Object.assign({}, searchParams, this.authParamsDataforsyningen)
    let gSearchResponse = await fetch2(this.serviceUrl + '/' + type, {data: callParams})

    if (this.convert2Geo)
      gSearchResponse = this.convert2GeosearchResponse(type, gSearchResponse)
    else
      gSearchResponse.forEach((gHit) => gHit.type = type)
    return gSearchResponse
  }

  convert2GeosearchResponse(type, gSearchResponse) {
    switch (type) {
      case 'matrikel':
      case "matrikel_udgaaet":
        /*{
        "ejerlavskode": "1380151",
        "ejerlavsnavn": "Aaker",
        "centroid_x": "880688.477",
        "matrikelnummer": "12a",
        "centroid_y": "6119201.713",
        "geometri": {....},
        "visningstekst": "12a, Aaker"
    }=>{
            "type": "matrikelnummer",
            "elavsnavn": "Abkær, Vedsted",
            "elavskode": "1431051",
            "matrnr": "1",
            "presentationString": "Abkær, Vedsted - 1"
        }
            */

        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.ejerlavskode + '-' + gHit.matrikelnummer,
            elavsnavn: gHit.ejerlavsnavn,
            elavskode: gHit.ejerlavskode,
            matrnr: gHit.matrikelnummer,
            presentationString: gHit.visningstekst,
            centroid_x: gHit.centroid_x,
            centroid_y: gHit.centroid_y,
            geometry: gHit.geometri
          })
        })
      case 'kommune':
        /*
 {
        "kommunenavn": "Albertslund",
        "geometri": {
        "kommunekode": "0165",
        "visningstekst": "Albertslund Kommune"
    } =>
 {
            "type": "kommune",
            "id": "0165",
            "name": "Albertslund",
            "presentationString": "Albertslund kommune (0165)"
        }    
 */
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.kommunekode,
            name: gHit.kommunenavn,
            presentationString: gHit.visningstekst + " (" + gHit.kommunekode + ")",
            geometry: gHit.geometri
          })
        })
      case 'opstillingskreds':
        /*
{
        "opstillingskredsnavn": "Vesterbro",
        "opstillingskredsnummer": "9",
        "valgkredsnummer": "9",
        "geometri": ...,
        "visningstekst": "Vesterbrokredsen",
        "storkredsnummer": "1",
        "storkredsnavn": "København"
    } =>
{
            "type": "opstillingskreds",
            "id": "44",
            "name": "Assens",
            "presentationString": "Assenskredsen",
            "valgkredsNr": "4",
            "storkredsNr": "",
            "storkredsNavn": "",
            "landsdelsNr": "",
            "landsdelsNavn": ""
        } */
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.opstillingskredsnummer,
            name: gHit.opstillingskredsnavn,
            presentationString: gHit.visningstekst,
            valgkredsNr: gHit.valgkredsnummer,
            storkredsNr: "",
            storkredsNavn: "",
            landsdelsNr: "",
            landsdelsNavn: "",
            geometry: gHit.geometri
          })
        })
      case 'politikreds':
        /*
{
         "navn": "Københavns Politi",
        "politikredsnummer": "11",
        "myndighedskode": "1470",
        "visningstekst": "Københavns Politikreds"
    } =>
{
            "type": "politikreds",
            "id": "11",
            "name": "Københavns Politi",
            "presentationString": "Københavns Politikreds",
             "myndighedsKode": "1470"
        } */
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.politikredsnummer,
            name: gHit.navn,
            presentationString: gHit.visningstekst,
            myndighedsKode: gHit.myndighedskode,
            geometry: gHit.geometri
          })
        })
      case 'postnummer':
        /*
{
        "postnummer": "9550",
        "postnummernavn": "Mariager",
        "visningstekst": "9550 Mariager",
        "gadepostnummer": "f"
    } =>
{
            "type": "postdistrikt",
            "id": "5320",
            "name": "Agedrup",
            "presentationString": "Agedrup, 5320"
        } */
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.postnummer,
            name: gHit.postnummernavn,
            presentationString: gHit.visningstekst,
            geometry: gHit.geometri
          })
        })
      case 'region':
        /*
 {
        "regionskode": "1082",
        "regionsnavn": "Region Midtjylland",
        "visningstekst": "Region Midtjylland"
    } =>
{
            "type": "region",
            "id": "1082",
            "name": "Region Midtjylland",
            "presentationString": "Region Midtjylland"} */
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.regionskode,
            name: gHit.regionsnavn,
            presentationString: gHit.visningstekst,
            geometry: gHit.geometri
          })
        })
      case 'retskreds':
        /*
  {
        "retskredsnummer": "2",
        "retkredsnavn": "Retten i Aalborg",
        "myndighedskode": "1178",
        "visningstekst": "Retten i Aalborg"
    } =>
{
            "type": "retskreds",
            "id": "2",
            "name": "Retten i Aalborg",
            "presentationString": "Retten i Aalborg",,
            "myndighedsKode": "1178" */
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.retskredsnummer,
            name: gHit.retkredsnavn,
            presentationString: gHit.visningstekst,
            myndighedsKode: gHit.myndighedskode,
            geometry: gHit.geometri
          })
        })
      case 'sogn':
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            type: this.geoTypes[type],
            id: gHit.sognekode,
            name: gHit.sognenavn,
            presentationString: gHit.visningstekst,
            geometry: gHit.geometri
          })
        })
      case 'stednavn':
        return gSearchResponse.map((gHit)=>{
          return Object.assign({}, gHit,{
            geometry: gHit.geometri
          })
        })
    }
    return gSearchResponse
  }

}