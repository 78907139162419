/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import * as reproject from "../../util/reproject.js"
import {getWKTParser} from "../../util/getWKTParser.js"
import DataApiFetcher from "../data-api/Fetcher.js"

/**
 * Viser link til relevante offentlige hjemmesider for en geometri (for et punkt på flade/linie)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 *   allowDetails: true
 * detailhandlers:
 *   - _type: Septima.Search.OffentligeLinksProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.OffentligeLinksForAdresseMatrikel({more: true}))
 *
 * @example <caption>ES6:</caption>
 * import OffentligeLinksForAdresseMatrikel from './searchers/detailhandlers/OffentligeLinksForAdresseMatrikel.js'
 * dawaSearcher.addDetailHandlerDef(new OffentligeLinksForAdresseMatrikel({more: false}))
 * @api
 **/
export default class OffentligeLinksProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.links=["skraafoto_dataforsyningen", "sdfekort", "plankort"]] Hvilke links skal vises.
   * Muligheder: "skraafoto_dataforsyningen", "sdfekort", "plankort"
   * @param {Object} [options.visEsrNummer=true]
   **/

  constructor(options= {}) {
    if (!options.buttonText)
      options.buttonText = "Offentlige links"

    super(options)
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler

    this.visEsrNummer = true
    if (typeof options.visEsrNummer != "undefined" && options.visEsrNummer == false)
      this.visEsrNummer = false
      
    //Set links to default
    this.links = ["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "skraafoto_dataforsyningen"]
    if (typeof options.links !== 'undefined') {
      if (options.links.length > 0 && options.links[0] === "*")
        //set links to practically all 
        this.links = ["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto_dataforsyningen", "sdfekort", "plankort"]
      else
        //Set to options
        this.links = options.links
    }

    this.wktParser = getWKTParser()
    
    this.dataApiNameFor = {
      "boligejer.dk": "boligejer.dk",
      "vurdering":  "vurdering.skat.dk",
      "bbrmeddelelse": "bbrmeddelelse",
      "bbrejendom": "bbrejendom",
      "bbkort": "kort.bbr.dk",
      "retbbr": "ret.bbr.dk",
      "ois": "nytois.dk",
      "skraafoto": "skraafoto.kortforsyningen.dk",
      "skraafoto_dataforsyningen": "skraafoto_dataforsyningen",
      "sdfekort": "sdfekort.dk",
      "plankort": "kort.plandata.dk",
      "jfa": "jordrapport.miljoeportal.dk",
      "arealinformation": "danmarksarealinformation.miljoeportal.dk"
    }
  }

  isApplicable(result) {
    return (typeof result.geometry !== 'undefined')
  }

  async myHandler(result) {
    let pointGeom = this.wktParser.getInteriorPoint(result.geometry)
    

    let items = await this.createLinks(result, null, null, pointGeom, null, null, null)
    return items
  }

  async createLinks(result, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr, jordstykkeid) {
    let items = []
    let dataApiFetcher = new DataApiFetcher({test: true})
    let options = {}
    if (pointGeom)
      options.labelpointgeom = pointGeom
    if (result) {
      if (result.geometry) {
        options.geometry = result.geometry
        if (result.geometry.type.toLowerCase() == "point") {
          if (!pointGeom)
            options.labelpointgeom = result.geometry
        } else {
          if (!pointGeom)
            options.labelpointgeom = this.wktParser.getInteriorPoint(result.geometry)
        }
      }
      if (options.labelpointgeom)
        options.label = result.title + (result.description ? ("-" + result.description) : "")
    }
    if (komnr)
      options.komnr = komnr
    if (esrnr)
      options.esrnr = esrnr
    if (elavskode && matrnr) {
      options.elavskode = elavskode
      options.matrnr = matrnr
    }
    if (bfenr)
      options.bfenr = bfenr

    if (jordstykkeid)
      options.jordstykkeid = jordstykkeid

    let linksArray = await dataApiFetcher.rpc("createlinkitems", options)
    let linksObject = {}
    linksArray.forEach(link=>{
      linksObject[link.name] = link 
    })
    
    for (let link of this.links) {
      let dataApiName = this.dataApiNameFor[link]
      if (dataApiName) {
        let linkObject = linksObject[dataApiName]
        if (linkObject) {
          items.push({type: 'link',
            name: linkObject.name,
            icon: icons.details.link,
            link: linkObject.link,
            linkTitle: linkObject.linktitle})
        }
      }
    }
    return items
  }
  
  createLinks_old(result, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr) {
    let items = []
    for (var link of this.links) {
      let linkItems = this.createLinkItems(result, link, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr)
      if (linkItems)
        items = [...items, ...linkItems]
        //items.push(linkItems)
    }
    return items
  }

  createLinkItems(result, link, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr) {

    if (link === 'bbrmeddelelse' && komnr && esrnr) {
      let buttonImage= icons.pdf
      return [{type: 'link',
        name: "bbrmeddelelse",
        icon: this.more ? buttonImage : icons.details.link,
        link: `https://nyt.ois.dk/bbrmeddelelse/get?bfe=${bfenr}`,
        linkTitle: "BBR-meddelelse"}]
    } else if (link === 'bbkort' && komnr && esrnr) {
      return [{type: 'link',
        name: "kort.bbr.dk",
        icon: icons.globe,
        link: `https://kort.bbr.dk/link/esr/${komnr}/${esrnr}`,
        linkTitle: 'BBRkort ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}]
    } else if (link === 'bbrejendom' && bfenr) {
      return [{type: 'link',
        name: "bbrejendom",
        icon: icons.exlink,
        link: `https://bbr.dk/se-bbr-oplysninger/0/1/0/bfe:${bfenr}`,
        linkTitle: 'Ejendommens BBR-oplysninger'}]
    } else if (link==='boligejer.dk' && komnr && esrnr) {
      return [{type: 'link',
        name: "boligejer.dk",
        icon: icons.exlink,
        link: `http://boligejer.dk/ejendomsdata?kommuneId=${komnr}&ejendomId=${esrnr}`,
        linkTitle: 'Boligejer.dk ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}]
    } else if (link==='vurdering' && komnr && esrnr) {
      return [{type: 'link',
        name: "vurdering.skat.dk",
        icon: icons.exlink,
        link: `http://www.vurdering.skat.dk/borger/ejendomsvurdering/Vis.do?KMNR=${komnr.substr(1, 3)}&sideNavn=vstarte&EJDNR=${esrnr}&POSTNR=&VEJKODE=&VEJNAVN`,
        linkTitle: 'Ejendomsvurdering på skat.dk ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}]
    } else if (link==='jfa' && elavskode && matrnr) {
      let url = "http://jordrapport.miljoeportal.dk/?elav=" +elavskode + "&matrnr="+ matrnr
      let buttonImage= icons.pdf
      return [{ type: 'link',
        name: "jordrapport.miljoeportal.dk",
        icon: this.more ? buttonImage : icons.details.link,
        link: url,
        linkTitle: "Jordforureningsattest fra Miljøportalen"}]
      
    } else if (link==='ois' && komnr && esrnr) {
      let oisLinks = []
      if (komnr && esrnr) {
        oisLinks = [{ type: 'link',
          name: "ois.dk",
          icon: icons.exlink,
          link: "http://www.ois.dk/ui/linkpartner/linkpartner.aspx?CBKORT=TRUE&komnr=" + komnr + "&ejdnr=" + esrnr,
          linkTitle: "OIS.dk"}]
      }
      if (bfenr) {
        oisLinks.push({ type: 'link',
          name: "nytois.dk",
          icon: icons.exlink,
          link: "https://nyt.ois.dk/search/" + bfenr,
          linkTitle: "Nyt OIS.dk"})
      }
      return oisLinks
    } else if (link === 'retbbr' && komnr && esrnr) {
      return [{type: 'link',
        name: "ret.bbr.dk",
        icon: icons.bbrhouse,
        link: `https://ret2.bbr.dk/oversigt/0/7/0/${komnr}|${esrnr}`,
        linkTitle: 'RetBBR ' + (this.visEsrNummer ? `(ESR ${esrnr})` : "" )}]
    } else if ((link === 'skraafoto' || link === 'skraafoto_js') && pointGeom) {
      let latLon = this.convert2582GeometryTowgs84LatLon(pointGeom)
      let endpoint = "https://skraafoto.kortforsyningen.dk/oblivisionjsoff"
      if (link === 'skraafoto_js')
        endpoint = "https://skraafoto.kortforsyningen.dk/oblivisionjs"
      let url = endpoint + "/index.aspx?project=Denmark&lon=" + latLon.lon + "&lat=" + latLon.lat
      return [{ type: 'link',
        name: "skraafoto.kortforsyningen.dk",
        icon: icons.exlink,
        link: url,
        linkTitle: "Skråfoto fra SDFI"}]
    } else if ((link === 'skraafoto_dataforsyningen') && pointGeom) {
      let url = "https://skraafoto.dataforsyningen.dk/viewer.html?center=" + pointGeom.coordinates[0] + "%2C"  + pointGeom.coordinates[1]
      return [{ type: 'link',
        name: "skraafoto.dataforsyningen.dk",
        icon: icons.exlink,
        link: url,
        linkTitle: "Skråfoto fra Dataforsyningen"}]
    } else if(link === 'sdfekort' && result.geometry) {
      let wktParser = getWKTParser()
      let extent = wktParser.getExtent(result.geometry)
      let wkt  = wktParser.convert(extent)
      let url
      if (pointGeom) {
        let pointWKT = wktParser.convert(pointGeom)
        let label = result.title + (result.description ? ("-" + result.description) : "")
        url = `http://sdfekort.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens+matrikelkort+adresser+dhm&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+theme_forvaltning2_husnummer+userpoint&label=${label}&labelPos=${pointWKT}`
      } else {
        url = `http://sdfekort.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens+matrikelkort+adresser+dhm&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+theme_forvaltning2_husnummer+userpoint`
      }
      return [{ type: 'link',
        name: "sdfekort.dk",
        icon: icons.exlink,
        link: url,
        linkTitle: "SDFI Kortviser"}]
    }else if(link === 'plankort' && result.geometry) {
      let wktParser = getWKTParser()
      let extent = wktParser.getExtent(result.geometry)
      let wkt  = wktParser.convert(extent)
      let url
      if (pointGeom) {
        let pointWKT = wktParser.convert(pointGeom)
        let label = result.title + (result.description ? ("-" + result.description) : "")
        
        url = `http://kort.plandata.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+userpoint&label=${label}&labelPos=${pointWKT}`
      } else {
        url = `http://kort.plandata.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens&layers=theme-dtk_skaermkort_daempet_daf+theme-daf_matrikelkort_roed+userpoint`
      }
      return [{ type: 'link',
        name: "kort.plandata.dk",
        icon: icons.exlink,
        link: url,
        linkTitle: "Kort.plandata.dk"}]
    }
  }

  convert2582GeometryTowgs84LatLon(geometry) {
    let geoJsonWgs84 = reproject.reproject(geometry, "EPSG:25832", "EPSG:4326")
    return {lon: geoJsonWgs84.coordinates[0], lat: geoJsonWgs84.coordinates[1]}
  }

}
