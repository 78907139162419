import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"

import KomKodeliste from "./KommuneKodeliste.js"
export default class JordstykkeInfoProvider extends DetailsHandlerDef {
  constructor(options={}) {
    if (!options.fetcher)
      throw new Error("JordstykkeInfoProvider called without options (Needs options.fetcher)")

    let defaultOptions = {
      buttonText: "Om jordstykket"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.source === "Kortforsyningen" && result.typeId === "matrikelnumre"))
    }

    this.dafFetcher = options.fetcher
    this.komKodeliste = new KomKodeliste()

  }

  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "Kortforsyningen" && typeId === "matrikelnumre") {
      let ejerlavskode = result.data.elavskode
      let matrikelnr = result.data.matrnr
      let dafJordstykke = await this.dafFetcher.read("matrikel", "matrikel", "jordstykke", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
      if (dafJordstykke)
        return await this.getJordstykkeDetails(dafJordstykke)
    }
  }
  findKommune(code) {
    return this.komKodeliste.findKommune(code)
  }
  async getJordstykkeDetails(fokusJordStykke) {
    // eslint-disable-next-line no-unused-vars
    let exampleSfeResponse = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {
            "BFEnummer": 3140901,
            "arbejderbolig": false,
            "bygningPaaFremmedGrund": [],
            "ejerlejlighed": [],
            "erFaelleslod": false,
            "forretningshaendelse": "Afledt ændring",
            "sekundaerForretningshaendelse": [],
            "forretningsomraade": "52.20.05",
            "forretningsproces": "Anden sagskategori",
            "hovedejendomOpdeltIEjerlejligheder": false,
            "id_lokalId": "3140901",
            "id_namespace": "http://data.gov.dk/Matriklen/SamletFastEjendom",
            "jordstykke": [
              {
                "type": "Jordstykke",
                "properties": {
                  "arealberegningsmetode": "Areal beregnet efter opmåling - o",
                  "arealbetegnelse": null,
                  "arealtype": null,
                  "brugsretsareal": false,
                  "centroide": [
                    {
                      "geometri": "POINT(687507.577 6071959.899)",
                      "senesteSagLokalId": "7764108",
                      "status": "Gældende"
                    }
                  ],
                  "delnummer": null,
                  "ejerlavskode": 521455,
                  "ejerlavsnavn": "Sdr. Vedby By, Nykøbing F.",
                  "faelleslod": false,
                  "forretningshaendelse": "Afledt ændring",
                  "sekundaerForretningshaendelse": [],
                  "forretningsomraade": "52.20.05",
                  "forretningsproces": "Anden sagskategori",
                  "fredskov": {
                    "fredskovsareal": null,
                    "omfang": null
                  },
                  "id_lokalId": "1279873",
                  "id_namespace": "http://data.gov.dk/Matriklen/Jordstykke",
                  "jordrente": {
                    "omfang": null
                  },
                  "klitfredning": {
                    "klitfredningsareal": null,
                    "omfang": null
                  },
                  "kommuneLokalId": "0376",
                  "majoratskov": {
                    "majoratskovsnummer": null,
                    "omfang": null
                  },
                  "matrikelnummer": "1ab",
                  "paataenktHandling": null,
                  "regionLokalId": "1085",
                  "registreretAreal": 1252,
                  "registreringFra": "2018-06-03T07:18:13.526534+02:00",
                  "registreringTil": null,
                  "registreringsaktoer": "Matriklen",
                  "samletFastEjendomBFEnummer": 3140901,
                  "senesteSagLokalId": "7764108",
                  "skelforretningssagsLokalId": null,
                  "sognLokalId": "7578",
                  "stammerFraJordstykkeLokalId": null,
                  "status": "Gældende",
                  "stormfald": [],
                  "strandbeskyttelse": {
                    "omfang": null,
                    "strandbeskyttelsesareal": null
                  },
                  "supplerendeMaalingSagLokalId": null,
                  "vandarealinkludering": "ukendt",
                  "vejareal": "0",
                  "vejarealberegningsstatus": "vejareal beregnet",
                  "virkningFra": "1977-03-22T00:00:00.000000+01:00",
                  "virkningTil": null,
                  "virkningsaktoer": "Geodatastyrelsen"
                }
              }
            ],
            "landbrugsnotering": null,
            "paataenktHandling": null,
            "registreringFra": "2018-06-03T05:56:19.410888+02:00",
            "registreringTil": null,
            "registreringsaktoer": "Matriklen",
            "senesteSagLokalId": "7764108",
            "status": "Gældende",
            "udskiltVej": false,
            "virkningFra": "1977-03-22T00:00:00.000000+01:00",
            "virkningTil": null,
            "virkningsaktoer": "Geodatastyrelsen"
          }
        }
      ]
    }
    let listHeader = "Matrikel nr. " +
      fokusJordStykke.properties.matrikelnummer + ", " + 
      fokusJordStykke.properties.ejerlavsnavn
    let detailItemsList = new DetailItemsList({
      header: listHeader,
      name: "jordstykke_info"
    })
    let kommuneCode = fokusJordStykke.properties.kommuneLokalId
    let kommuneText = this.findKommune(fokusJordStykke.properties.kommuneLokalId)

   
    detailItemsList.append({
      type: "labelvalue",
      label: "Kommune",
      value: `${kommuneCode} (${kommuneText})`
    })
    let statusText = fokusJordStykke.properties.status
    detailItemsList.append({
      type: "labelvalue",
      label: "Status",
      value: statusText
    })
    let idText = fokusJordStykke.properties.id_lokalId
    detailItemsList.append({
      type: "labelvalue",
      label: "id",
      value: idText
    })
    /*
    let antalJordstykker = sfeResponse.features[0].properties.jordstykke.length
    let bfeNr = sfeResponse.features[0].properties.BFEnummer
    let antalJordstykkerText = "I alt " + antalJordstykker + (antalJordstykker === 1? " jordstykke indgår" : " jordstykker indgår") + " i ejendommen BFE " + bfeNr
    detailItemsList.append({
      type: "textarea",
      text: antalJordstykkerText
    })
    let erResponse = await this.dafFetcher.read("bbr", "bbr", "ejendomsrelation", {BFENummer: bfeNr}, this.getLogger())
    let esrNummer = erResponse[0].ejendomsnummer
    let erKommuneKode = erResponse[0].kommunekode
    detailItemsList.append({
      type: "labelvalue",
      label: "Esr. nr",
      value: erKommuneKode + "-" + esrNummer
    })
     */
    return [detailItemsList.asItem()]
  }
  
}