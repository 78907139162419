import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"

export default class SagsoplysningerForJordstykkeProvider extends DetailsHandlerDef {
  constructor(options={}) {
    if (!options.fetcher)
      throw new Error("JordstykkeInfoProvider called without options.fetcher")

    let defaultOptions = {
      buttonText: "Seneste sag"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.source === "Kortforsyningen" && result.typeId === "matrikelnumre"))
    }

    this.dafFetcher = options.fetcher
  }
  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "Kortforsyningen" && typeId === "matrikelnumre") {
      let ejerlavskode = result.data.elavskode
      let matrikelnr = result.data.matrnr
      let dafJordstykke = await this.dafFetcher.read("matrikel", "matrikel", "jordstykke", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
      if (dafJordstykke) {
        //Upper case on purpose
        let SagsId = dafJordstykke.properties.senesteSagLokalId
        if (SagsId) {
          let matSagResponse = await this.dafFetcher.read("matrikel", "matrikel", "matrikulaersag", {SagsId}, this.getLogger())
          if (matSagResponse.features.length > 0)
            return await this.getSagDetails(matSagResponse.features[0])
        }
      }
    }
    let detailItemsList = new DetailItemsList({
      itemType: "labelvalue",
      header: "Seneste sag",
      name: "jordstykke_sag_ingen"
    })
    detailItemsList.append({
      type: "labelvalue",
      value: "Ingen sag fundet"
    })
    return [detailItemsList.asItem()]
  }

  async getSagDetails(matSagResponse) {
    // eslint-disable-next-line no-unused-vars
    let eksempelmatSagResponse = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {
            "ejerlavLokalId": null,
            "id_namespace": "http://data.gov.dk/Matriklen/MatrikulaerSag",
            "id_lokalId": "16014736",
            "forretningshaendelse": "Diverse",
            "forretningsomraade": "52.20.05",
            "forretningsproces": "Matrikulær sag",
            "journaliseringsdato": "1985-10-31T00:00:00.000000+01:00",
            "kommune": "<ingen data>",
            "matrikelmyndighedensJournalnummer": "U1985/56615",
            "registreringFra": "2018-06-03T06:10:54.169314+02:00",
            "registreringsaktoer": "Matriklen",
            "registreringTil": null,
            "rekvirentRef": "203.85",
            "sagstitel": "<ingen data>",
            "status": "Afsluttet",
            "virkningFra": "1986-02-04T00:00:00.000000+01:00",
            "virkningsaktoer": "Geodatastyrelsen",
            "virkningTil": null,
            "jordstykkeLokalId": null,
            "bestemtFastEjendom": [
              {
                "BFEnummer": 3168360,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168361,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168362,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168363,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168364,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168365,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168366,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168367,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168368,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168369,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168370,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168375,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168376,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168377,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168378,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168379,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168380,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168381,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168382,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168383,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168390,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168391,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168351,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168352,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168353,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168354,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168355,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168356,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168357,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168358,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168359,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168371,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168372,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168373,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168374,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168384,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168385,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168386,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168387,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168388,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168389,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168392,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168393,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168394,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168395,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168349,
                "status": "Gældende"
              },
              {
                "BFEnummer": 3168350,
                "status": "Gældende"
              }
            ]
          }
        }
      ]
    }

    let detailItemsList = new DetailItemsList({
      header: "Seneste sag",
      name: "jordstykke_sag_seneste"
    })
    let gstJournal = matSagResponse.properties.matrikelmyndighedensJournalnummer ? matSagResponse.properties.matrikelmyndighedensJournalnummer : ""
    let journaliseringsDatoTid = matSagResponse.properties.journaliseringsdato ? matSagResponse.properties.journaliseringsdato : ""
    let journaliseringsDato = ""
    if (journaliseringsDatoTid.indexOf("T"))
      journaliseringsDato = journaliseringsDatoTid.split("T")[0]
    detailItemsList.append({
      type: "labelvalue",
      label: "GST journal nr. og -dato",
      value: gstJournal + " - " + journaliseringsDato
    })

    let sagsTitel = matSagResponse.properties.sagstitel ? matSagResponse.properties.sagstitel : null
    let sagsId = matSagResponse.properties.id_lokalId ? matSagResponse.properties.id_lokalId : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Sagstitel og -id",
      value: sagsTitel + " - " + sagsId
    })

    let rekvirent = matSagResponse.properties.rekvirentRef ? matSagResponse.properties.rekvirentRef : null
    detailItemsList.append({
      type: "labelvalue",
      label: "Rekvirent",
      value: rekvirent
    })

    let status = matSagResponse.properties.status ? matSagResponse.properties.status : null
    detailItemsList.append({
      type: "labelvalue",
      label: "Status",
      value: status
    })

    return [detailItemsList.asItem()]
  }
}