import ResultStore from "./ResultStore.js"

export default class MemoryResultStore extends ResultStore {
  constructor(options) {
    super(options)
    this.data = []
  }
  
  //Interface methods
  //Context not used in Memory implementation of ResultStore
  // eslint-disable-next-line no-unused-vars
  async put(resultDef, context) {
    let id = resultDef.source + "_" + resultDef.typeId + "_" + resultDef.resultid
    let existingResultDef = await this._find(id)
    
    if (existingResultDef)
      existingResultDef.timestamp = resultDef.timestamp
    else {
      resultDef.id = id
      this.data.push(resultDef)
      this.data = this.adjustData(this.data)
    }
  }

  //Context not used in Memory implementation of ResultStore
  // eslint-disable-next-line no-unused-vars
  async getAll(context) {
    return this.data
  }

  // eslint-disable-next-line no-unused-vars
  async remove(id) {
    // noop - remove necessary in Memory implementation
  }

}