/**
 * @module
 */
import DetailsHandlerDef from "./DetailsHandlerDef.js"
import jsonpath from "../util/JsonParser.js"
import DetailItemsList from "./DetailItemsList.js"
/**
 * Generic details handler - formats properties of result.data to detailitems
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration</caption>
  dawa:
    _type: Septima.Search.DawaSearcher
    _options:
      resultTypes:
        adresse:
          singular: Adresse
          plural: Adresser
    detailhandlers:
      - _type: Septima.Search.Details
        _options:
          buttonText: Info vha. Septima.Search.Details
          details:
            - type: labelvalue
              label: id
              value: $.properties.id
            - type: labelvalue
              value: ligger i postnr {$.properties.postnummer.nr} {$.properties.postnummer.navn}
            - type: link
              linkTitle: JSON fra DAWA
              link: https://api.dataforsyningen.dk/adgangsadresser/{$.properties.id}
            - type: "result"
              label: "Administrator"
              result:
                searcher:
                  _ref: $.searchers.dataapi.cvr
                type: virksomhed
                id: $.properties.CVR_kode
 * @api
 **/
export default class Details extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} options.details detail items. Fields containing "$." are replaced with result.data property values
   * @param {string} [options.asList=true]
   * @param {string} [options.more=true]
   **/
  constructor(options = {}) {
    if (!options.details)
      throw new Error("FeatureDetails needs options.details")
    let defaultOptions = {
      more: true
    }
    super(Object.assign({}, defaultOptions, options))
    this.details = options.details
    this.handlerFunction = this.myHandler
    this.buttonImageHasBeenSpecified = false
    if (options.buttonImage)
      this.buttonImageHasBeenSpecified = true
    this.asList = true
    if (typeof options.asList != 'undefined')
      this.asList = options.asList 
  }

  getbuttonText(result) {
    if (super.getbuttonText() == super.getId())
      //buttonText hasn't been supplied
      return result.title
    else
      return super.getbuttonText()
  }
  
  getbuttonImage(result) {
    if (this.buttonImageHasBeenSpecified)
      //buttonImage hasn't been supplied
      return super.getbuttonImage()
    else
      return result.image
  }

  async myHandler(result) {
    let items = []
    if (result.data) {
      for (let detail of this.details) {
        
        if (detail.type == "result" && detail.result) {
          let searcher = detail.result.searcher
          let type = detail.result.type
          let id = detail.result.id
          if (id.indexOf("$.") == 0)
            id = jsonpath.value(result.data, id)
          if (searcher && id) {
            let itemResult = await searcher.get(id, type)
            if (itemResult) {
              let item = {
                type: "result",
                result: itemResult
              }
              if (detail.label)
                item.label = detail.label
              items.push(item)
            }
          }
        } else {
          let item = Object.assign({}, detail)
          for (let property in item) {
            if (property != "type") {
              if (item[property].indexOf("$.") == 0) {
                item[property] = jsonpath.value(result.data, item[property])
              } else {
                item[property] = this.replaceSelectors(item[property], result.data)
              }
            }
          }
          items.push(item)
        }
      }
      if (this.asList && items.length > 0) {
        let detailItemsListOptions = {}
        if (this.more) {
          detailItemsListOptions.header = this.getbuttonText(result)
          detailItemsListOptions.buttonImage = this.getbuttonImage(result)
        }
        let itemTypes = this.getDistinctItemTypes(items)
        if (itemTypes.length == 1)
          detailItemsListOptions.itemType = itemTypes[0]
        let detailItemsList = new DetailItemsList(detailItemsListOptions)
        items.forEach(item => detailItemsList.append(item))
        return [detailItemsList.asItem()]
      }
    }
    return items
  }
  
  replaceSelectors(value, data) {
    //https://bobbyhadz.com/blog/javascript-split-string-only-on-first-instance-of-character#:~:text=Report%20Ad-,To%20split%20a%20JavaScript%20string%20only%20on%20the%20first%20occurrence,first%20occurrence%20of%20the%20character
    let selectorStartPos = value.indexOf("{$.")
    if (selectorStartPos == -1) {
      return value
    } else {
      let beforeSelector = value.slice(0, selectorStartPos)
      let afterSelectorStart = value.slice(selectorStartPos + 1)
      let selectorEndPos = afterSelectorStart.indexOf("}")
      let selector = afterSelectorStart.slice(0, selectorEndPos)
      let afterSelector = afterSelectorStart.slice(selectorEndPos + 1)
      return beforeSelector + jsonpath.value(data, selector) + this.replaceSelectors(afterSelector, data)
    }
  }

  getDistinctItemTypes(items) {
    let types = items.map(item=>item.type)
    let set = new Set(types)
    return [...set ]
  }
}