import icons from '../resources/icons.js'
import DetailsHandlerDef from '../details/DetailsHandlerDef.js'
import ComposedDetailsHandler from '../details/ComposedDetailsHandler.js'
import domutil from '../../../lib/domutil.js'

export default class {
  constructor(options) {
    //this.detailsView = options.detailsView;
    this.showDescriptionInMore = true
    this.gui = null
    this.result = null
    this.panels = []
    this.activePanel = null
    this.headerPanel = null
    this.lastActivePanelId = ""
    this.detailItemsRenderer = null
    if (options.detailItemsRenderer)
      this.detailItemsRenderer = options.detailItemsRenderer
  }

  setResultActionListeners(listeners) {
    this.resultActionListeners = listeners
    if (this.detailItemsRenderer.setResultActionListeners)
      this.detailItemsRenderer.setResultActionListeners(listeners)
  }

  setDetailActionListeners(listeners) {
    this.detailActionListeners = listeners
    if (this.detailItemsRenderer.setDetailActionListeners)
      this.detailItemsRenderer.setDetailActionListeners(listeners)
  }

  async getUI(result) {
    this.gui = null
    this.result = result
    if (typeof this.result.lastActivePanelId !== 'undefined')
      this.lastActivePanelId = this.result.lastActivePanelId
    else
      this.lastActivePanelId = ""

    this.panels = []
    this.activePanel = null
    this.headerPanel = null
    await this.buildUI()
    return this.gui
  }

  //	forgetState(){
  //		//this.lastActivePanelId = "";
  //	}

  async buildUI() {

    let detailHandlerDefs = await this.result.searcher.getdetailHandlerDefs(this.result)
    //let hasMore = (this.getMoreDetailHandlerDef(detailHandlerDefs) !== null)
    let hasCustom = this.result.searcher.getCustomButtonDefs(this.result).length > 0
    //Create a "more" def if one doesn't exist and result has custom buttons
    if (!this.hasMore(detailHandlerDefs) && hasCustom) {
      let detailHandlerDef = new DetailsHandlerDef(
        {"buttonText": "",
          "buttonImage": null,
          "handler": () => {
            return [{type: 'dom-object', object: domutil.createElement('div')}]
          },
          more: true}
      )
      detailHandlerDefs.push(detailHandlerDef)
    }
    this.gui = domutil.createElement('div', 'ssAccordionDetails')
    this.gui.id = 'ssAccordionDetails'
    await this.createPanels(detailHandlerDefs)

  }

  getCustomButtons() {
    const customButtonDefs = this.result.searcher.getCustomButtonDefs(this.result)
    let items = []
    for (let buttonDef of  customButtonDefs) {

      //Create icon and text
      let icon = domutil.createElement('span')

      let buttonText = buttonDef.buttonText
      if (typeof buttonDef.getbuttonText !== 'undefined')
        buttonText = buttonDef.getbuttonText(this.result)


      if (buttonDef.buttonImage !== undefined)
        icon = domutil.createImageElement(buttonDef.buttonImage, buttonText)

      //let title = domutil.createElement('span', '', buttonText)
      let title = domutil.createElement('div', 'ssValue', buttonText)

      //Create click function
      let clickFunction
      if (buttonDef.callBack)
        clickFunction = async() => {
          let newButtonDef = await buttonDef.callBack(this.result)
          if (typeof newButtonDef !== 'undefined' && newButtonDef !== null) {
            domutil.empty(title)
            title.append(newButtonDef.buttonText)
            if (buttonDef.buttonImage !== undefined) {
              icon.setAttribute("src", newButtonDef.buttonImage)
              icon.setAttribute("title", newButtonDef.buttonText)
            }
          }
          return false
        }


      //Attach click function to icon and text
      /*
      if (buttonDef.callBack) {
        title.addEventListener('click', clickFunction)
        domutil.css(title, "cursor", "pointer")
        //title.css("cursor", "pointer")
        if (buttonDef.buttonImage !== undefined) {
          icon.addEventListener('click', clickFunction)
          domutil.css(icon, "cursor", "pointer")
          //icon.style.cursor = "pointer"
        }
      }
      
       */

      items.push({type: "button", icon: icon, title: title, onClick: clickFunction})
        
    }
    return this.detailItemsRenderer.formatItems(items)
  }

  destroyUI() {
    for (let panel of this.panels) {
      if (panel.hasContent)
        domutil.empty(panel.contentHolder)
      panel = null
    }
    domutil.empty(this.gui)
    this.gui = null
    if (this.result !== null)
      this.result.lastActivePanelId = this.lastActivePanelId

    this.result = null
    this.panels = null
  }

  async createPanels(detailHandlerDefs) {
    let panelToActivate = null
    const moreDetailHandlerDef = this.getMoreDetailHandlerDef(detailHandlerDefs)
    //Create headerPanel first (more: true)
    if (moreDetailHandlerDef !== null) {
      let morePanel = this.createPanel(moreDetailHandlerDef)
      this.headerPanel = morePanel
      this.panels.push(morePanel)
      panelToActivate = morePanel
      let domObjects = this.getCustomButtons()
      for (let domObject of domObjects)
        morePanel.contentHolder.append(domObject)
    }
    const otherDetailHandlerDefs = this.getOtherDetailHandlerDefs(detailHandlerDefs)
    for (let detailHandlerDef of otherDetailHandlerDefs) {
      let panel = this.createPanel(detailHandlerDef)
      if (panelToActivate === null || panel.id === this.lastActivePanelId)
        panelToActivate = panel

      this.panels.push(panel)
    }
    this.addPanelsToGui()
    this.activatePanel(panelToActivate)
  }

  hasMore(detailHandlerDefs) {
    for (let detailHandlerDef of detailHandlerDefs)
      if (detailHandlerDef.more)
        return true
    return false
  }

  getMoreDetailHandlerDef(detailHandlerDefs) {
    let moreHandlers = []
    for (let detailHandlerDef of detailHandlerDefs)
      if (detailHandlerDef.more)
        moreHandlers.push(detailHandlerDef)

    if (moreHandlers.length > 1) {
      let composedMoreDef = new ComposedDetailsHandler(
        { "buttonText": "",
          "buttonImage": null,
          more: true,
          detailshandlers: moreHandlers}
      )
      return composedMoreDef
    } else if (moreHandlers.length === 1) {
      return moreHandlers[0]
    } else {
      return null
    }
  }

  getOtherDetailHandlerDefs(detailHandlerDefs) {
    const otherDetailHandlerDefs = []
    for (let detailHandlerDef of detailHandlerDefs)
      if (!detailHandlerDef.more)
        otherDetailHandlerDefs.push(detailHandlerDef)
    return otherDetailHandlerDefs
  }

  createPanel(detailHandlerDef) {
    const panel = {}
    panel.id = detailHandlerDef.buttonText
    panel.gui = domutil.createElement('div', 'ssDetailPanel')
    if (detailHandlerDef.more) {
      panel.title = domutil.createElement('div', 'ssDetailTitle')
      domutil.addClass(panel.title, 'ssHeader')
      let imgContainer = domutil.createElement('div', 'ssDetailTitleImage')
      imgContainer.append(domutil.createImageElement(this.result.image, this.result.title))
      panel.title.append(imgContainer)
      let textElement = domutil.createElement('div', 'ssDetailTitleText','...')
      panel.title.append(textElement)
      panel.clickArea = textElement
    } else {
      panel.title = domutil.createElement('div', 'ssDetailTitle')
      let panelTopTitle = detailHandlerDef.buttonText
      if (typeof detailHandlerDef.getbuttonText !== 'undefined')
        panelTopTitle = detailHandlerDef.getbuttonText(this.result)

      let panelTopImage = detailHandlerDef.buttonImage
      if (typeof detailHandlerDef.getbuttonImage !== 'undefined')
        panelTopImage = detailHandlerDef.getbuttonImage(this.result)
      let imgContainer = domutil.createElement('div', 'ssDetailTitleImage')
      imgContainer.append(domutil.createImageElement(panelTopImage, panelTopTitle))
      panel.title.append(imgContainer)
      let textElement = domutil.createElement('div', 'ssDetailTitleText', panelTopTitle)
      panel.title.append(textElement)
      panel.clickArea = textElement
    }
    panel.gui.append(panel.title)
    panel.contentHolder = domutil.createElement('div', 'ssDetailContent')
    const spinner = domutil.createImageElement(icons.spinner)
    spinner.classList.add('spinner')
    panel.contentHolder.append(spinner)
    panel.gui.append(panel.contentHolder)
    panel.detailHandlerDef = detailHandlerDef
    panel.hasContent = false
    return panel
  }

  addPanelsToGui() {

    for (let panel of this.panels) {
      panel.clickArea.addEventListener('click', () => {
        this.resultFocus(this.result)
        if (domutil.hasClass(panel.gui, 'ssActive'))
          this.deActivatePanel(panel)
        else
          this.activatePanel(panel)
      })
      this.gui.append(panel.gui)
    }
  }

  async activatePanel(panel) {
    //Hide the active panel
    this.hidePanel(this.activePanel)
    //Set to active and show
    this.activePanel = panel
    this.lastActivePanelId = panel.id
    this.showPanel(panel)
    if (panel !== null) {
      //Ensure it has content
      if (!panel.hasContent) {
        panel.hasContent = true
        let items
        try {
          items = await panel.detailHandlerDef.handler(this.result)
        } catch (e) {
          items = [{
            "type": "error",
            "icon": icons.infoRed,
            "label": panel.detailHandlerDef.more ? panel.detailHandlerDef.getbuttonText() : "",
            "value": "Der skete en fejl",
            "message": e.message
          }]  
        }
        
        let domObjects = this.detailItemsRenderer.formatItems(items)
        domutil.findChildren(panel.contentHolder, ".spinner").forEach(c=> domutil.hide(c))
        for (let domObject of domObjects)
          panel.contentHolder.append(domObject)
        if (this.showDescriptionInMore && domutil.hasClass(panel.title, 'ssHeader') && this.result.description && this.result.description !== "")
          domutil.prepend(panel.contentHolder, this.detailItemsRenderer.formatArea({
            value: this.result.description,
            icon: icons.info
          }))
        let flattenedItems = this.flattenItems(items)
        let extraHeader = this.onDetailHeader(flattenedItems, items)
        //extrahHeader = { icon, text, onClick, onHover}
        if (extraHeader && extraHeader.icon) {
          let iconContainer = domutil.createElement('div', 'ssDetailActionIcon')
          let title = extraHeader.text ? extraHeader.text : ""
          iconContainer.append(domutil.createImageElement(extraHeader.icon, title))
          panel.title.append(iconContainer)
          if (extraHeader.onClick) {
            iconContainer.addEventListener('click', () => {
              extraHeader.onClick(this.result, flattenedItems)
              return true
            })
          }
          if (extraHeader.onHover) {
            iconContainer.addEventListener("mouseenter", () => {
              extraHeader.onHover(this.result, flattenedItems)
            })
            iconContainer.addEventListener("mouseleave", () => {
              extraHeader.onHover(null)
            })
          }
        }
      }
    }
  }
  
  flattenItems(detailItems) {
    let returnItems = []
    for (let detailItem of detailItems) {
      if (detailItem.type !== 'list') {
        returnItems.push(detailItem)
      } else {
        for (let listItem of detailItem.items) {
          returnItems.push(listItem)
          if (listItem.infoItems) {
            for (let infoItem of listItem.infoItems) {
              returnItems.push(infoItem)
            }
          }
        }
      }
    }
    return returnItems
  }

  deActivatePanel(panel) {
    if (panel !== null) {
      //Remove active and hide
      this.activePanel = null
      this.hidePanel(panel)
      //If this is not the header panel -> activate the header panel
      if (!domutil.hasClass(panel.title, "ssHeader") && this.headerPanel)
        this.activatePanel(this.headerPanel)
    }
  }

  showPanel(panel) {
    if (panel !== null)
      domutil.addClass(panel.gui, "ssActive")
  }

  hidePanel(panel) {
    if (panel !== null)
      domutil.removeClass(panel.gui, "ssActive")
  }

  resultFocus(result) {
    if (this.resultActionListeners) {
      if (this.resultActionListeners.hover)
        this.resultActionListeners.hover(null)

      if (this.resultActionListeners.focus)
        this.resultActionListeners.focus(result)
    }
  }

  onDetailHeader(flattenedItems, items) {
    if (this.detailActionListeners.onDetailHeader) {
      return this.detailActionListeners.onDetailHeader(this.result, flattenedItems, items)
    } else {
      return null
    } 
  }
}
