import { fetch2 } from '../utils.js'

export default class RouteCalculator {
	
  constructor(options) {
		
    if (options.apikey) 
      this.apikey = options.apikey
    else
      throw 'Septima.Search.RouteCalculator: option.apikey is missing'
		
    this.profile = 'car'
    if (options.profile) 
      //    		'car'
      //    		'bus'
      //    		'bicycle'
      //    		'foot'
      this.profile = options.profile
		
    if (options.fromGeometry) 
      this.fromGeometry = options.fromGeometry
    else
      throw 'Septima.Search.RouteCalculator: options.fromGeometry is missing'
		
    if (options.toFeatureCollection) {
      this.toFeatureCollection = options.toFeatureCollection
    } else if (options.toGeometry) {
      this.toGeometry = options.toGeometry
    } else {
      throw 'Septima.Search.RouteCalculator: option.toFeatureCollection/toGeometry is missing'
    }
  }
	
  async calculate() {
    if (this.toFeatureCollection)
      return await this._calculatePointToFeatureCollection(this.fromGeometry, this.toFeatureCollection)
    else
      return await this._calculatePointToPoint(this.fromGeometry, this.toGeometry)
  }

  async _calculatePointToPoint(fromPointGeometry, toPointGeometry) {
    const fromPoint = this.getPointFromGeometry(fromPointGeometry)
    const toPoint = this.getPointFromGeometry(toPointGeometry)
    let latlonstr = "&lat=" + toPoint[1].toFixed(6) + "&lon=" + toPoint[0].toFixed(6)
    const url = 'https://new-routing.septima.dk/' + this.apikey + '/one2many?engine=' + this.profile +
      '&returntype=json&fromlat=' + fromPoint[1].toFixed(6) + '&fromlon=' + fromPoint[0].toFixed(6) + latlonstr

    let data = await fetch2(url, {})
    // Old version uses 1e-5 for precision
    let precision = 1e-6
    if( data.version == 0.3)
      precision = 1e-5

    let thisData = data[0]
    return {
      time: Math.floor(thisData.route_summary.total_time),
      distance: Math.floor(thisData.route_summary.total_distance),
      geometry: {
        "type" : "LineString",
        "coordinates" : this.decode(thisData.route_geometry, precision)
      }
    } 
  }
  
  async _calculatePointToFeatureCollection(fromPointGeometry, toFeatureCollection) {
    const fromPoint = this.getPointFromGeometry(fromPointGeometry)
    const toLatLonCoordinates = this.getCoordinatesFromFeaturecollection(toFeatureCollection)
    let latlonstr = ""
    
    for(let i=0; i<toLatLonCoordinates.length; i++) 
      latlonstr += "&lat=" + toLatLonCoordinates[i][1].toFixed(6) + "&lon=" + toLatLonCoordinates[i][0].toFixed(6)
    
    const url = 'https://new-routing.septima.dk/' + this.apikey + '/one2many?engine=' + this.profile +
              '&returntype=json&fromlat=' + fromPoint[1].toFixed(6) + '&fromlon=' + fromPoint[0].toFixed(6) + latlonstr

    let data = await fetch2(url, {})
    // Old version uses 1e-5 for precision
    let precision = 1e-6
    if( data.version == 0.3) 
      precision = 1e-5

    for(let i=0; i<this.toFeatureCollection.features.length; i++) {
      let thisData = data[i]
      let feature = this.toFeatureCollection.features[i]
      if (thisData.status === 0 && !(feature.geometry.type !== 'Point'))
        feature.route = {
          time: thisData.route_summary.total_time,
          dist: thisData.route_summary.total_distance,
          geometry:{
            "type" : "LineString",
            "coordinates" : this.decode(thisData.route_geometry, precision)
          }
        }
      
    }
    return this.toFeatureCollection
  }
	
  getPointFromGeometry(geometry) {
    if (geometry.type == 'Point') 
      return geometry.coordinates
    else if (geometry.type == 'MultiPoint' || geometry.type == 'LineString')
      return geometry.coordinates[0]
    else
      return geometry.coordinates[0][0]
  }
	
  getCoordinatesFromFeaturecollection(featureCollection) {
    const features = featureCollection.features
    const coordinates = []
    for(let i=0; i<features.length; i++) {
      let thisFeature = features[i] 
      let coordinate
      if (thisFeature.geometry.type == 'Point') 
        coordinate = thisFeature.geometry.coordinates
      else if (thisFeature.geometry.type == 'MultiPoint' || thisFeature.geometry.type == 'LineString') 
        coordinate = thisFeature.geometry.coordinates[0]
      else 
        coordinate = thisFeature.geometry.coordinates[0][0]
      
      coordinates.push(coordinate)
    }
    return coordinates
  }

  decode(encoded, precision) {
    const len = encoded.length
    let index = 0
    const array = []
    let lat = 0
    let lng = 0

    while (index < len) {
      let b
      let shift = 0
      let result = 0
      do {
        b = encoded.charCodeAt(index++) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      //noinspection UnnecessaryLocalVariableJS,JSBitwiseOperatorUsage
      const dlat = ((result & 1) ? ~(result >> 1) : (result >> 1))
      lat += dlat

      shift = 0
      result = 0
      do {
        b = encoded.charCodeAt(index++) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      //noinspection JSBitwiseOperatorUsage,UnnecessaryLocalVariableJS
      const dlng = ((result & 1) ? ~(result >> 1) : (result >> 1))
      lng += dlng

      array.push([lng * precision, lat * precision])
    }

    return array
  }
}
