/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"

/**
 * Viser vurderingsinfo for ejendom
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: datafordeler.VurderingsHandler
 *   _options:
 *     showVurderingForSamvurdering: true
 *     showSamvurderedeEjendomme: true
 * @api
 **/
export default class VurderingsHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.showVurderingForSamvurdering=false] Vis vurdering selvom ejendommen er samvurderet
   * @param {Object} [options.showSamvurderedeEjendomme=false] Vis de samvurderede ejendomme
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "Ejendomsvurdering"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler

    this.showVurderingForSamvurdering = false
    if (options.showVurderingForSamvurdering)
      this.showVurderingForSamvurdering = true

    this.showSamvurderedeEjendomme = false
    if (options.showSamvurderedeEjendomme)
      this.showSamvurderedeEjendomme = true

    this.isApplicableFunction = (result) => {
      return (result.source === "DAF")
    }
  }
  
  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "DAF") {
      if (typeId === "samvurdering")
        return await this.getSamvurderingResultItems(result)
      else
        return await this.getBfeVurderingItems(result)
    }
  }

  async getSamvurderingResultItems(samvurderingResult) {
    let bfeNummer = samvurderingResult.data.vurderingsResponse.BFEnummerList[0]
    let vurderingsResponse = await samvurderingResult.searcher.fetcher.read("ejendomsvurdering", "ejendomsvurdering", "HentEjendomsvurderingerForBFE", {"BFEnummer": bfeNummer})
    vurderingsResponse = this.sortVurderingsResponse(vurderingsResponse)
    return await this.createItemsFromVurderingsResponse(vurderingsResponse)
  }
  
  async getBfeVurderingItems(bfe_result) {
    let items = []

    let vurderingsResponse = await bfe_result.searcher.fetcher.read("ejendomsvurdering", "ejendomsvurdering", "HentEjendomsvurderingerForBFE", {"BFEnummer": bfe_result.id})
    vurderingsResponse = this.sortVurderingsResponse(vurderingsResponse)
    if (vurderingsResponse.length > 0) {
      let bfeCount = vurderingsResponse[0].BFEnummerList.length
      if (bfeCount === 1) {
        return this.createItemsFromVurderingsResponse(vurderingsResponse)
      }else {
        let samvurderingsResult = await bfe_result.searcher.createSamvurderingFromVurderingsResponse(vurderingsResponse[0])
        if (samvurderingsResult) {
          items.push({
            type: "labelvalue",
            label: "Samvurderes",
            value: "Vurderes sammen med " + (bfeCount - 1) + " andre ejendomme"
          })
          if (this.showSamvurderedeEjendomme) {
            let bfeResults = await bfe_result.searcher.gets(vurderingsResponse[0].BFEnummerList, "bfe")
            let detailItemsListOptions = {
              itemType: "result",
              header: "Samvurderede ejendomme",
              name: "samvurderings_list"
            }
            let samvurderingsList = new DetailItemsList(detailItemsListOptions)
            for (let bfeResult of bfeResults) {
              samvurderingsList.append({
                type: "result",
                result: bfeResult
              })
            }
            items.push(samvurderingsList.asItem())
          }
          items.push({
            type: "result",
            label: "Samvurdering",
            result: samvurderingsResult
          })
          if (this.showVurderingForSamvurdering)
            items = [...items, ...await this.createItemsFromVurderingsResponse(vurderingsResponse)]
        }
      }
    } else {
      items.push({
        type: "labelvalue",
        label: "Vurdering",
        value: 'Ingen vurdering fundet'
      })
    }

    return items
  }

  createEjendomsVærdiItem(fullVurderingsResponse) {
    if (fullVurderingsResponse.benyttelseKode != "20")
      return {type: "labelvalue", value: fullVurderingsResponse.ejendomværdiBeløb, valueformat: "currencyamount", valueunit: "DKK"}
    else
      return {type: "labelvalue", value: "-"}
  }

  createGrundVærdiItem(fullVurderingsResponse) {
    if (fullVurderingsResponse.benyttelseKode != "20") {
      return {type: "labelvalue", value: fullVurderingsResponse.grundværdiBeløb, valueformat: "currencyamount", valueunit: "DKK"}
    } else {
      if (fullVurderingsResponse.Grundværdispecifikation) {
        for (let spec of fullVurderingsResponse.Grundværdispecifikation) {
          if (spec.prisKode.trim() == 'E')
            return {type: "labelvalue", value: spec.beløb, valueformat: "currencyamount", valueunit: "DKK"}
        }
      }
      return {type: "labelvalue", value: "-"}
    }
  }
  
  async createItemsFromVurderingsResponse(fullVurderingsResponse) {
    //løb baglæns og se om året er en gentagelse -> hvis det er så skriv ændringsdato
    fullVurderingsResponse.reverse()
    let items = []
    
    if (fullVurderingsResponse.length > 0) {
      let forrigeAar = fullVurderingsResponse[0].år
      let antalMedvurderedeLejligheder = fullVurderingsResponse[0].antalMedvurderedeLejligheder
      antalMedvurderedeLejligheder = antalMedvurderedeLejligheder === 0 ? "-" : antalMedvurderedeLejligheder
      let ejendomsVærdiItem = this.createEjendomsVærdiItem(fullVurderingsResponse[0])
      let grundVærdiItem = this.createGrundVærdiItem(fullVurderingsResponse[0])
      let item = {
        type: "labelvalue",
        value: "" + fullVurderingsResponse[0].år,
        infoItems: [
          {type: "labelvalue", value: ""},
          ejendomsVærdiItem,
          grundVærdiItem,
          {type: "labelvalue", value: antalMedvurderedeLejligheder, valueformat: "int"},
          {type: "labelvalue", value: fullVurderingsResponse[0].vurderetAreal, valueformat: "int", valueunit: "m²"}]
      }
      if (fullVurderingsResponse.length == 1) {
        item.value = item.value + " (Seneste)"
        items.push(item)
      } else {
        for (let i=1; i<fullVurderingsResponse.length; i++) {
          let detteAar = fullVurderingsResponse[i].år
          let ændretInfoItem = {type: "labelvalue", value: ""}
          if (forrigeAar == detteAar)
            ændretInfoItem =  {type: "labelvalue", value: fullVurderingsResponse[i].ændringDato, valueformat: "iso-date"}
          let ejendomsVærdiItem = this.createEjendomsVærdiItem(fullVurderingsResponse[i])
          let grundVærdiItem = this.createGrundVærdiItem(fullVurderingsResponse[i])
          let antalMedvurderedeLejligheder = fullVurderingsResponse[i].antalMedvurderedeLejligheder
          antalMedvurderedeLejligheder = antalMedvurderedeLejligheder === 0 ? "-" : antalMedvurderedeLejligheder
          forrigeAar = detteAar
          let item = {
            type: "labelvalue",
            value: "" + detteAar,
            infoItems: [
              ændretInfoItem,
              ejendomsVærdiItem,
              grundVærdiItem,
              {type: "labelvalue", value: antalMedvurderedeLejligheder, valueformat: "int"},
              {type: "labelvalue", value: fullVurderingsResponse[i].vurderetAreal, valueformat: "int", valueunit: "m²"}]
          }
          if (i == fullVurderingsResponse.length - 1)
            item.value = item.value + " (Seneste)"
          items.push(item)
        }
      }
      
      items.reverse()
      let detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Vurdering",
        name: "vurdering_liste",
        infoItemsHeaders: [
          {type: "labelvalue", label: "Ændret"},
          {type: "labelvalue", label: "Ejendomsværdi"},
          {type: "labelvalue", label: "Grundværdi"},
          {type: "labelvalue", label: "Lejligheder"},
          {type: "labelvalue", label: "Vurderet areal (m2)"}]
      })
      for (let item of items)
        detailItemsList.append(item)
      return [detailItemsList.asItem()]
    } else {
      return []
    }
      
  }

  sortVurderingsResponse(vurderingsResponse) {
    const sorter = function(h1, h2) {
      try {
        if (h1.år === h2.år) {
          if (h2.ændringDato > h1.ændringDato)
            return 1
          else
            return -1
        } else {
          return (h2.år - h1.år)
        }
      } catch (error) {
        return 0
      }
    }
    vurderingsResponse.sort(sorter)
    return vurderingsResponse
  }
  
  //Næste metoder kaldes udefra (EjendomsInfoProvider for at lave en summering)
  async getLatestSamvurderingsItems(samvurderingsResult) {
    let items = []
    let vurderingsResponse = samvurderingsResult.data.vurderingsResponse
    if (vurderingsResponse) {
      items.push(this.getLatestVurderingItem(vurderingsResponse))

      let detailItemsList = new DetailItemsList({
        name: "samvurdering_oversigt"
      })
      detailItemsList.append({
        type: "labelvalue",
        label: "Vurderet areal (m2)",
        value: vurderingsResponse.vurderetAreal,
        valueformat: "int",
        valueunit: "m²"

      })
      if (vurderingsResponse.antalMedvurderedeLejligheder && vurderingsResponse.antalMedvurderedeLejligheder > 0) {
        detailItemsList.append({
          type: "labelvalue",
          label: "antal medvurderede lejligheder",
          value: vurderingsResponse.antalMedvurderedeLejligheder,
          valueformat: "int"
        })
      }
      items.push(detailItemsList.asItem())
    }

    return items
  }

  async getLatestBfeVurderingItems(bfe_result) {
    let items = []

    let vurderingsResponse = await bfe_result.searcher.fetcher.read("ejendomsvurdering", "ejendomsvurdering", "HentEjendomsvurderingerForBFE", {"BFEnummer": bfe_result.id})
    if (vurderingsResponse.length > 0) {
      vurderingsResponse = this.sortVurderingsResponse(vurderingsResponse)
      items.push(this.getLatestVurderingItem(vurderingsResponse[0]))

      let samvurderingsResult = await bfe_result.searcher.createSamvurderingFromVurderingsResponse(vurderingsResponse[0])
      if (samvurderingsResult) {
        let detailItemsList = new DetailItemsList({
          itemType: "result",
          header: "Denne ejendom er del af en samvurdering",
          name: "vurdering_samvurdering"
        })
        
        detailItemsList.append({
          type: "result",
          result: samvurderingsResult
        })
        items.push(detailItemsList.asItem())
      }
    }
    return items
  }
  
  getLatestVurderingItem(vurderingsResponse) {
    //https://github.com/Septima/septima-search/issues/466

    let infoItemsHeaders = []
    let infoItems = []
    let benyttelseKode = parseInt(vurderingsResponse.benyttelseKode)

    if (benyttelseKode !== 20) {
      infoItemsHeaders.push({type: "labelvalue", label: "Ejendomsværdi"})
      let ejendomvaerdiBeloeb = vurderingsResponse.ejendomværdiBeløb
      infoItems.push({
        type: "labelvalue",
        value: ejendomvaerdiBeloeb,
        valueformat: "currencyamount",
        valueunit: "DKK"
      })
    }

    if (benyttelseKode <= 40 || benyttelseKode >= 50) {
      infoItemsHeaders.push({type: "labelvalue", label: "Grundværdi"})
      let grundvaerdiBeloeb = 0
      if (benyttelseKode === 20) {
        if (vurderingsResponse.Grundværdispecifikation && vurderingsResponse.Grundværdispecifikation.length > 0) {
          vurderingsResponse.Grundværdispecifikation.forEach((spec) => {
            grundvaerdiBeloeb += (spec.prisKode.trim() === "E" ? spec.beløb : 0)
          })
        }
      } else {
        grundvaerdiBeloeb = vurderingsResponse.grundværdiBeløb
      }
      infoItems.push({
        type: "labelvalue",
        value: grundvaerdiBeloeb,
        valueformat: "currencyamount",
        valueunit: "DKK"
      })
    }

    if (vurderingsResponse.Fritagelse && vurderingsResponse.Fritagelse.length > 0) {
      infoItemsHeaders.push({type: "labelvalue", label: "Fritagelse"})
      let fritagelsesBeloeb = 0
      vurderingsResponse.Fritagelse.forEach((fritagelse)=>{
        fritagelsesBeloeb += fritagelse.beløb
      })
      infoItems.push({
        type: "labelvalue",
        value: fritagelsesBeloeb,
        valueformat: "currencyamount",
        valueunit: "DKK"
      })
    }

    let detailItemsList = new DetailItemsList({
      itemType: "labelvalue",
      header: "Vurdering",
      infoItemsHeaders: infoItemsHeaders,
      name: "vurdering_seneste"
    })

    detailItemsList.append({
      type: "labelvalue",
      value: "" + vurderingsResponse.år + " (Seneste)",
      infoItems: infoItems
    })
    return detailItemsList.asItem()
  }

}