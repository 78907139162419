export const da_DK = {
  "search": "Søg",
  "matches": "matcher",
  "close": "Luk",
  "doDetails": "Se detaljer",
  "at_site": "på",
  "noResults": "Ingen resultater" 
}

export const sv_SE = {
  "search": "Sök",
  "matches": "matchar",
  "close": "Stäng",
  "doDetails": "Detaljer",
  "at_site": "på",
  "noResults": "Inget resultat"
}
	
export const en_GB = {
  "search": "Search",
  "matches": "match",
  "close": "Close",
  "doDetails": "Details",
  "at_site": "at",
  "noResults": "No matching results"
}

const runtimeLocales = {
  da_DK,
  sv_SE,
  en_GB
}

export let defaultLocale = 'da_DK'
let currentLocale = da_DK

export function setLocaleByIdentifier(localeIdentifier) {
  if (runtimeLocales[localeIdentifier]) 
    currentLocale = runtimeLocales[localeIdentifier]
  
}

export function setLocale(locale) {
  currentLocale = locale
}

export function getString(name, fallback) {
  if (currentLocale[name])
    return currentLocale[name]
  else if (fallback)
    return fallback
  else
    return "[" + name + "]"
}
