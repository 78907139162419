import Fetcher from "./Fetcher.js"
import CvrSearcher from "./CvrSearcher.js"
import CvrInfoProvider from "./CvrInfoProvider.js"
import ClassRegistry from "../../ClassRegistry.js"
import CvrRegistreringerForHusnummer from "./CvrRegistreringerForHusnummer.js"
const dataApiTypes = new ClassRegistry("Septima.Search.DataApi.")

const typesToAdd = {
  Fetcher,
  CvrSearcher,
  CvrInfoProvider,
  CvrRegistreringerForHusnummer
}

for (let [key, value] of Object.entries(typesToAdd))
  dataApiTypes.addClass(value, key)

export default dataApiTypes