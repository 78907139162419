export default {
  "AdresseRolle": {
    "0": {
      "title": "Fastsat til denne",
      "presentText": "Fastsat til denne",
      "presentShortText": "Fastsat til denne",
      "description": " "
    },
    "1": {
      "title": "Kun vejledende",
      "presentText": "Kun vejledende",
      "presentShortText": "Kun vejledende",
      "description": " "
    }
  },
  "Adressestatus": {
    "0": {
      "title": "Har husnummer (adresse)",
      "presentText": "Har husnummer (adresse)",
      "presentShortText": "Har husnummer (adresse)",
      "description": " "
    },
    "1": {
      "title": "Markeret til at få husnummer (adresse)",
      "presentText": "Markeret til at få husnummer (adresse)",
      "presentShortText": "Markeret til at få husnummer (adresse)",
      "description": " "
    },
    "2": {
      "title": "Uden husnummer",
      "presentText": "Uden husnummer",
      "presentShortText": "Uden husnummer",
      "description": " "
    }
  },
  "Afloebsforhold": {
    "1": {
      "title": "Fælleskloakeret: spildevand + tag- og overfladevand",
      "presentText": "Fælleskloakeret: Spildevand, tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Spildevand, tag- og overfladevand",
      "description": " "
    },
    "2": {
      "title": "Fælleskloakeret: spildevand + delvis tag- og overfladevand",
      "presentText": "Fælleskloakeret: Spildev. med delvis tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Spildev. med delvis tag- og overfladevand",
      "description": " "
    },
    "3": {
      "title": "Fælleskloakeret: spildevand",
      "presentText": "Fælleskloakeret: Spildevand",
      "presentShortText": "Fælleskloakeret: Spildevand",
      "description": " "
    },
    "4": {
      "title": "Fælleskloakeret: tag- og overfladevand",
      "presentText": "Fælleskloakeret: Tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Tag- og overfladevand",
      "description": " "
    },
    "5": {
      "title": "Separatkloakeret: spildevand + tag- og overfladevand",
      "presentText": "Separatkloakeret: Spildevand hhv. tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Spildevand hhv. tag- og overfladevand",
      "description": " "
    },
    "6": {
      "title": "Separatkloakeret: spildevand + delvis tag- og overfladevand",
      "presentText": "Separatkloakeret: Spildevand hhv. delvis tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Spildevand hhv. delvis tag- og overfladevand",
      "description": " "
    },
    "7": {
      "title": "Separatkloakeret: spildevand",
      "presentText": "Separatkloakeret: Spildevand",
      "presentShortText": "Separatkloakeret: Spildevand",
      "description": " "
    },
    "8": {
      "title": "Separatkloakeret: tag- og overfladevand",
      "presentText": "Separatkloakeret: Tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Tag- og overfladevand",
      "description": " "
    },
    "9": {
      "title": "Spildevandskloakeret: Spildevand",
      "presentText": "Spildevandskloakeret: Spildevand",
      "presentShortText": "Spildevandskloakeret: Spildevand",
      "description": " "
    },
    "10": {
      "title": "Afløb til spildevandsforsyningens renseanlæg",
      "presentText": "Afløb til spildevandsforsyningens renseanlæg",
      "presentShortText": "Afløb til spildevandsforsyningens renseanlæg",
      "description": " "
    },
    "11": {
      "title": "Afløb til fællesprivat spildevandsanlæg",
      "presentText": "Afløb til fællesprivat spildevandsanlæg",
      "presentShortText": "Afløb til fællesprivat spildevandsanlæg",
      "description": " "
    },
    "12": {
      "title": "Afløb til fællesprivat kloakledning med tilslutning til spv. renseanlæg",
      "presentText": "Afløb til fællesprivat kloakledning m. tilslut. t. spv. renseanlæg",
      "presentShortText": "Afløb til fællesprivat kloakledning m. tilslut. t. spv. renseanlæg",
      "description": " "
    },
    "20": {
      "title": "Afløb til samletank",
      "presentText": "Afløb til samletank",
      "presentShortText": "Afløb til samletank",
      "description": " "
    },
    "21": {
      "title": "Afløb til samletank for toiletvand og mek. rensning af øvr. spildevand",
      "presentText": "Afløb til samletank for toiletv. og mek. rensning af øvrigt spildevand",
      "presentShortText": "Afløb til samletank for toiletv. og mek. rensning af øvrigt spildevand",
      "description": " "
    },
    "29": {
      "title": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "presentText": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "presentShortText": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "description": " "
    },
    "30": {
      "title": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "presentText": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "presentShortText": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "description": " "
    },
    "31": {
      "title": "Mekanisk rensning med privat udledn. dir. til vandløb, sø eller hav",
      "presentText": "Mekanisk rensning m. privat udledning til vandløb, sø eller hav",
      "presentShortText": "Mekanisk rensning m. privat udledning til vandløb, sø eller hav",
      "description": " "
    },
    "32": {
      "title": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "presentText": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "presentShortText": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "description": " "
    },
    "70": {
      "title": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "presentText": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "presentShortText": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "description": " "
    },
    "75": {
      "title": "Blandet afløbsforhold på ejendommen (er specificeret på bygningen)",
      "presentText": "Blandet afløbsforhold på ejendommen (angivet på bygningen)",
      "presentShortText": "Blandet afløbsforhold på ejendommen (angivet på bygningen)",
      "description": " "
    },
    "80": {
      "title": "Anden type afløb",
      "presentText": "Anden type afløb",
      "presentShortText": "Anden type afløb",
      "description": " "
    },
    "90": {
      "title": "Ingen udledning",
      "presentText": "Ingen udledning",
      "presentShortText": "Ingen udledning",
      "description": " "
    },
    "101": {
      "title": "SOP: Minirenseanlæg med direkte udledning",
      "presentText": "SOP: Minirenseanlæg med direkte udledning",
      "presentShortText": "SOP: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "102": {
      "title": "SOP: Minirenseanlæg med udledning til markdræn",
      "presentText": "SOP: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "SOP: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "103": {
      "title": "SOP: Minirenseanlæg med nedsivning i faskine",
      "presentText": "SOP: Minirenseanlæg med nedsivning i faskine",
      "presentShortText": "SOP: Minirenseanlæg med nedsivning i faskine",
      "description": " "
    },
    "104": {
      "title": "SOP: Nedsivning til sivedræn",
      "presentText": "SOP: Nedsivning til sivedræn",
      "presentShortText": "SOP: Nedsivning til sivedræn",
      "description": " "
    },
    "105": {
      "title": "SOP: Samletank",
      "presentText": "SOP: Samletank",
      "presentShortText": "SOP: Samletank",
      "description": " "
    },
    "106": {
      "title": "SOP: Pileanlæg med nedsivning (uden membran)",
      "presentText": "SOP: Pileanlæg med nedsivning (uden membran)",
      "presentShortText": "SOP: Pileanlæg med nedsivning (uden membran)",
      "description": " "
    },
    "107": {
      "title": "SOP: Pileanlæg uden udledning (med membran)",
      "presentText": "SOP: Pileanlæg uden udledning (med membran)",
      "presentShortText": "SOP: Pileanlæg uden udledning (med membran)",
      "description": " "
    },
    "108": {
      "title": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "presentText": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "presentShortText": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "description": " "
    },
    "109": {
      "title": "SOP: Sandfiltre med P-fældning i bundfældningstanken og direkte udledning",
      "presentText": "SOP: Sandfiltre m. P-fældning i tank og direkte udledning",
      "presentShortText": "SOP: Sandfiltre m. P-fældning i tank og direkte udledning",
      "description": " "
    },
    "110": {
      "title": "SOP: Sandfiltre med P-fældning i bundfældningstanken og udledning til markdræn",
      "presentText": "SOP: Sandfiltre m. P-fældning i tank og udledning til dræn",
      "presentShortText": "SOP: Sandfiltre m. P-fældning i tank og udledning til dræn",
      "description": " "
    },
    "190": {
      "title": "SOP: Andet",
      "presentText": "SOP: Andet",
      "presentShortText": "SOP: Andet",
      "description": " "
    },
    "201": {
      "title": "SO: Biologisk sandfilter med direkte udledning",
      "presentText": "SO: Biologisk sandfilter med direkte udledning",
      "presentShortText": "SO: Biologisk sandfilter med direkte udledning",
      "description": " "
    },
    "202": {
      "title": "SO: Biologisk sandfilter med udledning til markdræn",
      "presentText": "SO: Biologisk sandfilter med udledning til markdræn",
      "presentShortText": "SO: Biologisk sandfilter med udledning til markdræn",
      "description": " "
    },
    "203": {
      "title": "SO: Minirensanlæg med direkte udledning",
      "presentText": "SO: Minirensanlæg med direkte udledning",
      "presentShortText": "SO: Minirensanlæg med direkte udledning",
      "description": " "
    },
    "204": {
      "title": "SO: Minirenseanlæg med udledning til markdræn",
      "presentText": "SO: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "SO: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "205": {
      "title": "SO: Beplantede filteranlæg med direkte udledning",
      "presentText": "SO: Beplantede filteranlæg med direkte udledning",
      "presentShortText": "SO: Beplantede filteranlæg med direkte udledning",
      "description": " "
    },
    "206": {
      "title": "SO: Beplantede filteranlæg med udledning til markdræn",
      "presentText": "SO: Beplantede filteranlæg med udledning til markdræn",
      "presentShortText": "SO: Beplantede filteranlæg med udledning til markdræn",
      "description": " "
    },
    "290": {
      "title": "SO: Andet",
      "presentText": "SO: Andet",
      "presentShortText": "SO: Andet",
      "description": " "
    },
    "301": {
      "title": "OP: Minirenseanlæg med direkte udledning",
      "presentText": "OP: Minirenseanlæg med direkte udledning",
      "presentShortText": "OP: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "302": {
      "title": "OP: Minirenseanlæg med udledning til markdræn",
      "presentText": "OP: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "OP: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "390": {
      "title": "OP: Andet",
      "presentText": "OP: Andet",
      "presentShortText": "OP: Andet",
      "description": " "
    },
    "401": {
      "title": "O: Rodzoneanlæg med direkte udledning",
      "presentText": "O: Rodzoneanlæg med direkte udledning",
      "presentShortText": "O: Rodzoneanlæg med direkte udledning",
      "description": " "
    },
    "402": {
      "title": "O: Rodzoneanlæg med udledning til markdræn",
      "presentText": "O: Rodzoneanlæg med udledning til markdræn",
      "presentShortText": "O: Rodzoneanlæg med udledning til markdræn",
      "description": " "
    },
    "403": {
      "title": "O: Minirenseanlæg med direkte udledning",
      "presentText": "O: Minirenseanlæg med direkte udledning",
      "presentShortText": "O: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "404": {
      "title": "O: Minirenseanlæg med udledning til markdræn",
      "presentText": "O: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "O: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "490": {
      "title": "O: Andet",
      "presentText": "O: Andet",
      "presentShortText": "O: Andet",
      "description": " "
    },
    "501": {
      "title": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "presentText": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "presentShortText": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "description": " "
    },
    "502": {
      "title": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "presentText": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "presentShortText": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "description": " "
    },
    "503": {
      "title": "Øvrige renseløsninger: Ældre nedsivningsanlæg med nedsivning til sivebrønd",
      "presentText": "Øvrige renseløsninger: Ældre nedsivningsanlæg med sivebrønd",
      "presentShortText": "Øvrige renseløsninger: Ældre nedsivningsanlæg med sivebrønd",
      "description": " "
    },
    "504": {
      "title": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "presentText": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "presentShortText": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "description": " "
    },
    "505": {
      "title": "Øvrige renseløsninger: Urenset",
      "presentText": "Øvrige renseløsninger: Urenset",
      "presentShortText": "Øvrige renseløsninger: Urenset",
      "description": " "
    },
    "590": {
      "title": "Øvrige renseløsninger: Andet",
      "presentText": "Øvrige renseløsninger: Andet",
      "presentShortText": "Øvrige renseløsninger: Andet",
      "description": " "
    },
    "601": {
      "title": "Anden type afløb (større end 30 PE med egen udledning)",
      "presentText": "Anden type afløb: Større end 30 PE med egen udledning",
      "presentShortText": "Anden type afløb: Større end 30 PE med egen udledning",
      "description": " "
    },
    "701": {
      "title": "Intet afløb",
      "presentText": "Intet afløb",
      "presentShortText": "Intet afløb",
      "description": " "
    }
  },
  "AfvigendeEtager": {
    "0": {
      "title": "Bygningen har ikke afvigende etager",
      "presentText": "Bygningen har ikke afvigende etager",
      "presentShortText": "Bygningen har ikke afvigende etager",
      "description": " "
    },
    "10": {
      "title": "Bygningen har afvigende etager",
      "presentText": "Bygningen har afvigende etager",
      "presentShortText": "Bygningen har afvigende etager",
      "description": " "
    },
    "11": {
      "title": "Bygningen indeholder hems",
      "presentText": "Bygningen indeholder hems",
      "presentShortText": "Bygningen indeholder hems",
      "description": " "
    },
    "12": {
      "title": "Bygningen indeholder dobbelt højt rum",
      "presentText": "Bygningen indeholder dobbelt højt rum",
      "presentShortText": "Bygningen indeholder dobbelt højt rum",
      "description": " "
    },
    "13": {
      "title": "Bygningen indeholder indskudt etage",
      "presentText": "Bygningen indeholder indskudt etage",
      "presentShortText": "Bygningen indeholder indskudt etage",
      "description": " "
    }
  },
  "Arealtype": {
    "1": {
      "title": "Type 1",
      "presentText": "Type 1",
      "presentShortText": "Type 1",
      "description": " "
    },
    "2": {
      "title": "Type 2",
      "presentText": "Type 2",
      "presentShortText": "Type 2",
      "description": " "
    },
    "3": {
      "title": "Type 3",
      "presentText": "Type 3",
      "presentShortText": "Type 3",
      "description": " "
    }
  },
  "Artskode": {
    "0": {
      "title": "Vigtigste matrikelnummer på ejendommen (normalt med evt. bygning)",
      "presentText": "Vigtigste matrikelnummer på ejend. (normalt med evt. bygning)",
      "presentShortText": "Vigtigste matrikelnummer på ejend. (normalt med evt. bygning)",
      "description": " "
    },
    "1": {
      "title": "Andre matrikelnumre på ejendommen",
      "presentText": "Andre matrikelnumre på ejendommen",
      "presentShortText": "Andre matrikelnumre på ejendommen",
      "description": " "
    },
    "2": {
      "title": "Kode for ejerlejlighed",
      "presentText": "Ejerlejlighed",
      "presentShortText": "Ejerlejlighed",
      "description": " "
    },
    "3": {
      "title": "Bygning på matrikelnummer (på lejet grund)",
      "presentText": "Bygning på matrikelnummer (på lejet grund)",
      "presentShortText": "Bygning på matrikelnummer (på lejet grund)",
      "description": " "
    },
    "4": {
      "title": "Del af matrikelnummer (parcel) – [kan være fælleslod]",
      "presentText": "Del af matrikelnummer (parcel), kan være fælleslod",
      "presentShortText": "Del af matrikelnummer (parcel), kan være fælleslod",
      "description": " "
    },
    "5": {
      "title": "Umatrikuleret areal",
      "presentText": "Umatrikuleret areal",
      "presentShortText": "Umatrikuleret areal",
      "description": " "
    },
    "6": {
      "title": "Umatrikuleret havneareal",
      "presentText": "Umatrikuleret havneareal",
      "presentShortText": "Umatrikuleret havneareal",
      "description": " "
    },
    "7": {
      "title": "Umatrikuleret jernbaneareal",
      "presentText": "Umatrikuleret jernbaneareal",
      "presentShortText": "Umatrikuleret jernbaneareal",
      "description": " "
    },
    "8": {
      "title": "Bygning på umatrikuleret areal",
      "presentText": "Bygning på umatrikuleret areal",
      "presentShortText": "Bygning på umatrikuleret areal",
      "description": " "
    },
    "9": {
      "title": "Bygning på umatrikuleret havneareal",
      "presentText": "Bygning på umatrikuleret havneareal",
      "presentShortText": "Bygning på umatrikuleret havneareal",
      "description": " "
    },
    "10": {
      "title": "Bygning på umatrikuleret jernbaneareal",
      "presentText": "Bygning på umatrikuleret jernbaneareal",
      "presentShortText": "Bygning på umatrikuleret jernbaneareal",
      "description": " "
    },
    "20": {
      "title": "Andet afgivet areal, f. eks. lejet grund",
      "presentText": "Andet afgivet areal, fx lejet grund",
      "presentShortText": "Andet afgivet areal, fx lejet grund",
      "description": " "
    },
    "21": {
      "title": "Tilskyllet",
      "presentText": "Tilskyllet",
      "presentShortText": "Tilskyllet",
      "description": " "
    },
    "22": {
      "title": "Bortskyllet",
      "presentText": "Bortskyllet",
      "presentShortText": "Bortskyllet",
      "description": " "
    },
    "23": {
      "title": "Eksproprieret til",
      "presentText": "Eksproprieret til",
      "presentShortText": "Eksproprieret til",
      "description": " "
    },
    "24": {
      "title": "Eksproprieret fra",
      "presentText": "Eksproprieret fra",
      "presentShortText": "Eksproprieret fra",
      "description": " "
    },
    "25": {
      "title": "Dokumenteret arealafvigelse tillagt",
      "presentText": "Dokumenteret arealafvigelse tillagt",
      "presentShortText": "Dokumenteret arealafvigelse tillagt",
      "description": " "
    },
    "26": {
      "title": "Dokumenteret arealafvigelse afgivet",
      "presentText": "Dokumenteret arealafvigelse afgivet",
      "presentShortText": "Dokumenteret arealafvigelse afgivet",
      "description": " "
    },
    "27": {
      "title": "Tillagt ved jordfordeling",
      "presentText": "Tillagt ved jordfordeling",
      "presentShortText": "Tillagt ved jordfordeling",
      "description": " "
    },
    "28": {
      "title": "Afgivet ved jordfordeling",
      "presentText": "Afgivet ved jordfordeling",
      "presentShortText": "Afgivet ved jordfordeling",
      "description": " "
    },
    "30": {
      "title": "(Foreløbig) Vigtigste matrikelnummer på ejendommen (normalt med evt. bygning)",
      "presentText": "(Forel.) Vigtigste matrikelnr. på ejend. (normalt m.evt. bygning)",
      "presentShortText": "(Forel.) Vigtigste matrikelnr. på ejend. (normalt m.evt. bygning)",
      "description": " "
    },
    "31": {
      "title": "(Foreløbig) Andre matrikelnumre på ejendommen",
      "presentText": "(Forel.) Andre matrikelnumre på ejendommen",
      "presentShortText": "(Forel.) Andre matrikelnumre på ejendommen",
      "description": " "
    },
    "32": {
      "title": "(Foreløbig) Kode for ejerlejlighed",
      "presentText": "(Forel.) Kode for ejerlejlighed",
      "presentShortText": "(Forel.) Kode for ejerlejlighed",
      "description": " "
    },
    "33": {
      "title": "(Foreløbig) Bygning på matrikelnummer (på lejet grund)",
      "presentText": "(Forel.) Bygning på matrikelnummer (på lejet grund)",
      "presentShortText": "(Forel.) Bygning på matrikelnummer (på lejet grund)",
      "description": " "
    },
    "34": {
      "title": "(Foreløbig) Del af matrikelnummer (parcel)",
      "presentText": "(Forel.) Del af matrikelnummer (parcel)",
      "presentShortText": "(Forel.) Del af matrikelnummer (parcel)",
      "description": " "
    },
    "35": {
      "title": "(Foreløbig) Umatrikuleret areal",
      "presentText": "(Forel.) Umatrikuleret areal",
      "presentShortText": "(Forel.) Umatrikuleret areal",
      "description": " "
    },
    "36": {
      "title": "(Foreløbig) Umatrikuleret havneareal",
      "presentText": "(Forel.) Umatrikuleret havneareal",
      "presentShortText": "(Forel.) Umatrikuleret havneareal",
      "description": " "
    },
    "37": {
      "title": "(Foreløbig) Umatrikuleret jernbaneareal",
      "presentText": "(Forel.) Umatrikuleret jernbaneareal",
      "presentShortText": "(Forel.) Umatrikuleret jernbaneareal",
      "description": " "
    },
    "38": {
      "title": "(Foreløbig) Bygning på umatrikuleret havneareal",
      "presentText": "(Forel.) Bygning på umatrikuleret havneareal",
      "presentShortText": "(Forel.) Bygning på umatrikuleret havneareal",
      "description": " "
    },
    "39": {
      "title": "(Foreløbig) Bygning på umatrikuleret havneareal",
      "presentText": "(Forel.) Bygning på umatrikuleret havneareal",
      "presentShortText": "(Forel.) Bygning på umatrikuleret havneareal",
      "description": " "
    },
    "40": {
      "title": "(Foreløbig) Bygning på umatrikuleret jernbaneareal",
      "presentText": "(Forel.) Bygning på umatrikuleret jernbaneareal",
      "presentShortText": "(Forel.) Bygning på umatrikuleret jernbaneareal",
      "description": " "
    }
  },
  "AsbestholdigtMateriale": {
    "1": {
      "title": "Asbestholdigt ydervægsmateriale",
      "presentText": "Asbestholdigt ydervægsmateriale",
      "presentShortText": "Asbestholdigt ydervægsmateriale",
      "description": " "
    },
    "2": {
      "title": "Asbestholdigt tagdækningsmateriale",
      "presentText": "Asbestholdigt tagdækningsmateriale",
      "presentShortText": "Asbestholdigt tagdækningsmateriale",
      "description": " "
    },
    "3": {
      "title": "Asbestholdigt ydervægs- og tagdækningsmateriale",
      "presentText": "Asbestholdigt ydervægs- og tagdækningsmateriale",
      "presentShortText": "Asbestholdigt ydervægs- og tagdækningsmateriale",
      "description": " "
    }
  },
  "Badeforhold": {
    "C": {
      "title": "Adgang til badeværelse",
      "presentText": "Adgang til badeværelse",
      "presentShortText": "Adgang til badeværelse",
      "description": " "
    },
    "D": {
      "title": "Hverken badeværelse eller adgang til badeværelse",
      "presentText": "Hverken badeværelse eller adgang til badeværelse",
      "presentShortText": "Hverken badeværelse eller adgang til badeværelse",
      "description": " "
    },
    "V": {
      "title": "Badeværelser i enheden",
      "presentText": "Badeværelser i enheden",
      "presentShortText": "Badeværelser i enheden",
      "description": " "
    }
  },
  "BBRMessageNiveau": {
    "0": {
      "title": "Ejendom",
      "presentText": "Ejendom",
      "presentShortText": "Ejendom",
      "description": " "
    },
    "1": {
      "title": "Grund",
      "presentText": "Grund",
      "presentShortText": "Grund",
      "description": " "
    },
    "2": {
      "title": "Bygning",
      "presentText": "Bygning",
      "presentShortText": "Bygning",
      "description": " "
    },
    "3": {
      "title": "Enhed",
      "presentText": "Enhed",
      "presentShortText": "Enhed",
      "description": " "
    },
    "4": {
      "title": "Teknisk Anlæg",
      "presentText": "Teknisk Anlæg",
      "presentShortText": "Teknisk Anlæg",
      "description": " "
    }
  },
  "BBRMessageType": {
    "0": {
      "title": "BBR-Meddelelse",
      "presentText": "BBR-Meddelelse",
      "presentShortText": "BBR-Meddelelse",
      "description": " "
    },
    "1": {
      "title": "Registerudskrift",
      "presentText": "Registerudskrift",
      "presentShortText": "Registerudskrift",
      "description": " "
    },
    "2": {
      "title": "Andelsboligudskrift",
      "presentText": "Andelsboligudskrift",
      "presentShortText": "Andelsboligudskrift",
      "description": " "
    }
  },
  "BBRMessageAarsagskode": {
    "20": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af ejerskifte.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af ejerskifte.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af ejerskifte.",
      "description": " "
    },
    "30": {
      "title": "Denne BBR-Meddelelse er udskrevet efter rekvisition.",
      "presentText": "Denne BBR-Meddelelse er udskrevet efter rekvisition.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet efter rekvisition.",
      "description": " "
    },
    "31": {
      "title": "Denne BBR-Andelsboligudskrift er udskrevet på grund af rekvisition.",
      "presentText": "Denne BBR-Andelsboligudskrift er udskrevet på grund af rekvisition.",
      "presentShortText": "Denne BBR-Andelsboligudskrift er udskrevet på grund af rekvisition.",
      "description": " "
    },
    "40": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af afsluttet byggesag.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af afsluttet byggesag.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af afsluttet byggesag.",
      "description": " "
    },
    "41": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af indflytning.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af indflytning.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af indflytning.",
      "description": " "
    },
    "45": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af ændring uden byggesag.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af ændring uden byggesag.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af ændring uden byggesag.",
      "description": " "
    },
    "46": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af opdeling af lejligheder.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af opdeling af lejligheder.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af opdeling af lejligheder.",
      "description": " "
    },
    "47": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af sammenlægning af lejligheder.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af sammenlægning af lejligheder.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af sammenlægning af lejligheder.",
      "description": " "
    },
    "48": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af opdeling/ændret opdeling i ejerlejligheder.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af opdeling/ændret opdeling i ejerlejligheder.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af opdeling/ændret opdeling i ejerlejligheder.",
      "description": " "
    },
    "50": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af, at kommunen har foretaget rettelser af registreringen i BBR.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af, at kommunen har foretaget rettelser af registreringen i BBR.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af, at kommunen har foretaget rettelser af registreringen i BBR.",
      "description": " "
    },
    "51": {
      "title": "De har til kommunen anmeldt et byggeri som ikke er færdigmeldt. Denne BBR-Meddelelse udskrives…",
      "presentText": "De har til kommunen anmeldt et byggeri som ikke er færdigmeldt. Denne BBR-Meddelelse udskrives…",
      "presentShortText": "De har til kommunen anmeldt et byggeri som ikke er færdigmeldt. Denne BBR-Meddelelse udskrives…",
      "description": " "
    },
    "70": {
      "title": "Denne BBR-Meddelelse er udskrevet på grund af matrikulære ændringer.",
      "presentText": "Denne BBR-Meddelelse er udskrevet på grund af matrikulære ændringer.",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet på grund af matrikulære ændringer.",
      "description": " "
    },
    "80": {
      "title": "Denne BBR-Meddelelse er udskrevet fordi ejer selv eller andre har rettet, slettet eller tilføjet…",
      "presentText": "Denne BBR-Meddelelse er udskrevet fordi ejer selv eller andre har rettet, slettet eller tilføjet…",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet fordi ejer selv eller andre har rettet, slettet eller tilføjet…",
      "description": " "
    },
    "81": {
      "title": "Denne BBR-Meddelelse er udskrevet fordi skatteforvaltningen har rettet, slettet eller tilføjet…",
      "presentText": "Denne BBR-Meddelelse er udskrevet fordi skatteforvaltningen har rettet, slettet eller tilføjet…",
      "presentShortText": "Denne BBR-Meddelelse er udskrevet fordi skatteforvaltningen har rettet, slettet eller tilføjet…",
      "description": " "
    }
  },
  "BeregningsprincipForArealAfCarport": {
    "1": {
      "title": "Carportareal er målt efter tagflade",
      "presentText": "Carportareal målt efter tagflade",
      "presentShortText": "Carportareal målt efter tagflade",
      "description": " "
    },
    "2": {
      "title": "Carportarealet er målt ½ meter inde på åbne sider",
      "presentText": "Carportareal målt 0,5 m inde på åbne sider",
      "presentShortText": "Carportareal målt 0,5 m inde på åbne sider",
      "description": " "
    }
  },
  "Boligtype": {
    "1": {
      "title": "Egentlig beboelseslejlighed (boligenhed med eget køkken)",
      "presentText": "Egentlig beboelseslejlighed med eget køkken",
      "presentShortText": "Egentlig beboelseslejlighed med eget køkken",
      "description": " "
    },
    "2": {
      "title": "Blandet erhverv og bolig med eget køkken",
      "presentText": "Blandet erhverv og bolig med eget køkken",
      "presentShortText": "Blandet erhverv og bolig med eget køkken",
      "description": " "
    },
    "3": {
      "title": "Enkeltværelse (boligenhed med fast kogeinstallation, fælles køkken eller intet køkken).",
      "presentText": "Enkeltværelse m kogeinstal., fælles el. intet køkken",
      "presentShortText": "Enkeltværelse m kogeinstal., fælles el. intet køkken",
      "description": " "
    },
    "4": {
      "title": "Fællesbolig eller fælleshusholdning",
      "presentText": "Fællesbolig eller fælleshusholdning",
      "presentShortText": "Fællesbolig eller fælleshusholdning",
      "description": " "
    },
    "5": {
      "title": "Sommer-/fritidsbolig",
      "presentText": "Sommer- eller fritidsbolig",
      "presentShortText": "Sommer- eller fritidsbolig",
      "description": " "
    },
    "E": {
      "title": "Andet  (bl.a. institutioner og erhverv)",
      "presentText": "Andet, bl.a. institutioner og erhverv",
      "presentShortText": "Andet, bl.a. institutioner og erhverv",
      "description": " "
    }
  },
  "BygAfloebsforhold": {
    "1": {
      "title": "Fælleskloakeret: spildevand + tag- og overfladevand",
      "presentText": "Fælleskloakeret: Spildevand, tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Spildevand, tag- og overfladevand",
      "description": " "
    },
    "2": {
      "title": "Fælleskloakeret: spildevand + delvis tag- og overfladevand",
      "presentText": "Fælleskloakeret: Spildevand med delvis tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Spildevand med delvis tag- og overfladevand",
      "description": " "
    },
    "3": {
      "title": "Fælleskloakeret: spildevand",
      "presentText": "Fælleskloakeret: Spildevand",
      "presentShortText": "Fælleskloakeret: Spildevand",
      "description": " "
    },
    "4": {
      "title": "Fælleskloakeret: tag- og overfladevand",
      "presentText": "Fælleskloakeret: Tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Tag- og overfladevand",
      "description": " "
    },
    "5": {
      "title": "Separatkloakeret: spildevand + tag- og overfladevand",
      "presentText": "Separatkloakeret: Spildevand hhv. tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Spildevand hhv. tag- og overfladevand",
      "description": " "
    },
    "6": {
      "title": "Separatkloakeret: spildevand + delvis tag- og overfladevand",
      "presentText": "Separatkloakeret: Spildevand hhv. delvis tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Spildevand hhv. delvis tag- og overfladevand",
      "description": " "
    },
    "7": {
      "title": "Separatkloakeret: spildevand",
      "presentText": "Separatkloakeret: Spildevand",
      "presentShortText": "Separatkloakeret: Spildevand",
      "description": " "
    },
    "8": {
      "title": "Separatkloakeret: tag- og overfladevand",
      "presentText": "Separatkloakeret: Tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Tag- og overfladevand",
      "description": " "
    },
    "9": {
      "title": "Spildevandskloakeret: Spildevand",
      "presentText": "Spildevandskloakeret: Spildevand",
      "presentShortText": "Spildevandskloakeret: Spildevand",
      "description": " "
    },
    "10": {
      "title": "Afløb til spildevandsforsyningens renseanlæg",
      "presentText": "Afløb til spildevandsforsyningens renseanlæg",
      "presentShortText": "Afløb til spildevandsforsyningens renseanlæg",
      "description": " "
    },
    "11": {
      "title": "Afløb til fællesprivat spildevandsanlæg",
      "presentText": "Afløb til fællesprivat spildevandsanlæg",
      "presentShortText": "Afløb til fællesprivat spildevandsanlæg",
      "description": " "
    },
    "12": {
      "title": "Afløb til fællesprivat kloakledning med tilslutning til spv. renseanlæg",
      "presentText": "Afløb til fællesprivat kloakledning m. tilslut. t. spv. renseanlæg",
      "presentShortText": "Afløb til fællesprivat kloakledning m. tilslut. t. spv. renseanlæg",
      "description": " "
    },
    "20": {
      "title": "Afløb til samletank",
      "presentText": "Afløb til samletank",
      "presentShortText": "Afløb til samletank",
      "description": " "
    },
    "21": {
      "title": "Afløb til samletank for toiletvand og mek. rensning af øvr. spildevand",
      "presentText": "Afløb til samletank for toiletv. og mek. rensning af øvrigt spildevand",
      "presentShortText": "Afløb til samletank for toiletv. og mek. rensning af øvrigt spildevand",
      "description": " "
    },
    "29": {
      "title": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "presentText": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "presentShortText": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "description": " "
    },
    "30": {
      "title": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "presentText": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "presentShortText": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "description": " "
    },
    "31": {
      "title": "Mekanisk rensning med privat udledn. dir. til vandløb, sø eller hav",
      "presentText": "Mekanisk rensning m. privat udledning til vandløb, sø el. hav",
      "presentShortText": "Mekanisk rensning m. privat udledning til vandløb, sø el. hav",
      "description": " "
    },
    "32": {
      "title": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "presentText": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "presentShortText": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "description": " "
    },
    "70": {
      "title": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "presentText": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "presentShortText": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "description": " "
    },
    "75": {
      "title": "Blandet afløbsforhold på ejendommen (er specificeret på bygningen)",
      "presentText": "Blandet afløbsforhold på ejendommen (angivet på bygningen)",
      "presentShortText": "Blandet afløbsforhold på ejendommen (angivet på bygningen)",
      "description": " "
    },
    "80": {
      "title": "Anden type afløb",
      "presentText": "Anden type afløb",
      "presentShortText": "Anden type afløb",
      "description": " "
    },
    "90": {
      "title": "Ingen udledning",
      "presentText": "Ingen udledning",
      "presentShortText": "Ingen udledning",
      "description": " "
    },
    "101": {
      "title": "SOP: Minirenseanlæg med direkte udledning",
      "presentText": "SOP: Minirenseanlæg med direkte udledning",
      "presentShortText": "SOP: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "102": {
      "title": "SOP: Minirenseanlæg med udledning til markdræn",
      "presentText": "SOP: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "SOP: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "103": {
      "title": "SOP: Minirenseanlæg med nedsivning i faskine",
      "presentText": "SOP: Minirenseanlæg med nedsivning i faskine",
      "presentShortText": "SOP: Minirenseanlæg med nedsivning i faskine",
      "description": " "
    },
    "104": {
      "title": "SOP: Nedsivning til sivedræn",
      "presentText": "SOP: Nedsivning til sivedræn",
      "presentShortText": "SOP: Nedsivning til sivedræn",
      "description": " "
    },
    "105": {
      "title": "SOP: Samletank",
      "presentText": "SOP: Samletank",
      "presentShortText": "SOP: Samletank",
      "description": " "
    },
    "106": {
      "title": "SOP: Pileanlæg med nedsivning (uden membran)",
      "presentText": "SOP: Pileanlæg med nedsivning (uden membran)",
      "presentShortText": "SOP: Pileanlæg med nedsivning (uden membran)",
      "description": " "
    },
    "107": {
      "title": "SOP: Pileanlæg uden udledning (med membran)",
      "presentText": "SOP: Pileanlæg uden udledning (med membran)",
      "presentShortText": "SOP: Pileanlæg uden udledning (med membran)",
      "description": " "
    },
    "108": {
      "title": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "presentText": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "presentShortText": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "description": " "
    },
    "109": {
      "title": "SOP: Sandfiltre med P-fældning i bundfældningstanken og direkte udledning",
      "presentText": "SOP: Sandfiltre med P-fældning i tank og direkte udledning",
      "presentShortText": "SOP: Sandfiltre med P-fældning i tank og direkte udledning",
      "description": " "
    },
    "110": {
      "title": "SOP: Sandfiltre med P-fældning i bundfældningstanken og udledning til markdræn",
      "presentText": "SOP: Sandfiltre med P-fældning i tank og udledning til markdræn",
      "presentShortText": "SOP: Sandfiltre med P-fældning i tank og udledning til markdræn",
      "description": " "
    },
    "190": {
      "title": "SOP: Andet",
      "presentText": "SOP: Andet",
      "presentShortText": "SOP: Andet",
      "description": " "
    },
    "201": {
      "title": "SO: Biologisk sandfilter med direkte udledning",
      "presentText": "SO: Biologisk sandfilter med direkte udledning",
      "presentShortText": "SO: Biologisk sandfilter med direkte udledning",
      "description": " "
    },
    "202": {
      "title": "SO: Biologisk sandfilter med udledning til markdræn",
      "presentText": "SO: Biologisk sandfilter med udledning til markdræn",
      "presentShortText": "SO: Biologisk sandfilter med udledning til markdræn",
      "description": " "
    },
    "203": {
      "title": "SO: Minirensanlæg med direkte udledning",
      "presentText": "SO: Minirensanlæg med direkte udledning",
      "presentShortText": "SO: Minirensanlæg med direkte udledning",
      "description": " "
    },
    "204": {
      "title": "SO: Minirenseanlæg med udledning til markdræn",
      "presentText": "SO: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "SO: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "205": {
      "title": "SO: Beplantede filteranlæg med direkte udledning",
      "presentText": "SO: Beplantede filteranlæg med direkte udledning",
      "presentShortText": "SO: Beplantede filteranlæg med direkte udledning",
      "description": " "
    },
    "206": {
      "title": "SO: Beplantede filteranlæg med udledning til markdræn",
      "presentText": "SO: Beplantede filteranlæg med udledning til markdræn",
      "presentShortText": "SO: Beplantede filteranlæg med udledning til markdræn",
      "description": " "
    },
    "290": {
      "title": "SO: Andet",
      "presentText": "SO: Andet",
      "presentShortText": "SO: Andet",
      "description": " "
    },
    "301": {
      "title": "OP: Minirenseanlæg med direkte udledning",
      "presentText": "OP: Minirenseanlæg med direkte udledning",
      "presentShortText": "OP: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "302": {
      "title": "OP: Minirenseanlæg med udledning til markdræn",
      "presentText": "OP: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "OP: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "390": {
      "title": "OP: Andet",
      "presentText": "OP: Andet",
      "presentShortText": "OP: Andet",
      "description": " "
    },
    "401": {
      "title": "O: Rodzoneanlæg med direkte udledning",
      "presentText": "O: Rodzoneanlæg med direkte udledning",
      "presentShortText": "O: Rodzoneanlæg med direkte udledning",
      "description": " "
    },
    "402": {
      "title": "O: Rodzoneanlæg med udledning til markdræn",
      "presentText": "O: Rodzoneanlæg med udledning til markdræn",
      "presentShortText": "O: Rodzoneanlæg med udledning til markdræn",
      "description": " "
    },
    "403": {
      "title": "O: Minirenseanlæg med direkte udledning",
      "presentText": "O: Minirenseanlæg med direkte udledning",
      "presentShortText": "O: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "404": {
      "title": "O: Minirenseanlæg med udledning til markdræn",
      "presentText": "O: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "O: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "490": {
      "title": "O: Andet",
      "presentText": "O: Andet",
      "presentShortText": "O: Andet",
      "description": " "
    },
    "501": {
      "title": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "presentText": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "presentShortText": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "description": " "
    },
    "502": {
      "title": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "presentText": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "presentShortText": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "description": " "
    },
    "503": {
      "title": "Øvrige renseløsninger: Ældre nedsivningsanlæg med nedsivning til sivebrønd",
      "presentText": "Øvrige renseløsninger: Ældre nedsivningsanlæg med sivebrønd",
      "presentShortText": "Øvrige renseløsninger: Ældre nedsivningsanlæg med sivebrønd",
      "description": " "
    },
    "504": {
      "title": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "presentText": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "presentShortText": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "description": " "
    },
    "505": {
      "title": "Øvrige renseløsninger: Urenset",
      "presentText": "Øvrige renseløsninger: Urenset",
      "presentShortText": "Øvrige renseløsninger: Urenset",
      "description": " "
    },
    "590": {
      "title": "Øvrige renseløsninger: Andet",
      "presentText": "Øvrige renseløsninger: Andet",
      "presentShortText": "Øvrige renseløsninger: Andet",
      "description": " "
    },
    "601": {
      "title": "Anden type afløb (større end 30 PE med egen udledning)",
      "presentText": "Anden type afløb: Større end 30 PE med egen udledning",
      "presentShortText": "Anden type afløb: Større end 30 PE med egen udledning",
      "description": " "
    },
    "701": {
      "title": "Intet afløb",
      "presentText": "Intet afløb",
      "presentShortText": "Intet afløb",
      "description": " "
    }
  },
  "BygAnvendelse": {
    "110": {
      "title": "Stuehus til landbrugsejendom",
      "presentText": "Stuehus til landbrug",
      "presentShortText": "Stuehus til landbrug",
      "description": " "
    },
    "120": {
      "title": "Fritliggende enfamilieshus (parcelhus)",
      "presentText": "Fritliggende enfamiliehus",
      "presentShortText": "Fritliggende enfamiliehus",
      "description": " "
    },
    "121": {
      "title": "Sammenbygget enfamiliehus",
      "presentText": "Sammenbygget enfamiliehus",
      "presentShortText": "Sammenbygget enfamiliehus",
      "description": ""
    },
    "130": {
      "title": "(UDFASES) Række-, kæde-, eller dobbelthus (lodret adskillelse mellem enhederne).",
      "presentText": "Række-, kæde- el. dobbelthus",
      "presentShortText": "Række-, kæde- el. dobbelthus",
      "description": " "
    },
    "131": {
      "title": "Række- og kædehus",
      "presentText": "Række- eller kædehus",
      "presentShortText": "Række- eller kædehus",
      "description": " "
    },
    "132": {
      "title": "Dobbelthus",
      "presentText": "Dobbelthus",
      "presentShortText": "Dobbelthus",
      "description": " "
    },
    "140": {
      "title": "Etagebolig-bygning, flerfamiliehus eller to-familiehus",
      "presentText": "Etagebygning el. fler-/to-familiehus",
      "presentShortText": "Etagebygning, tofamiliehus e.l.",
      "description": " "
    },
    "150": {
      "title": "Kollegium",
      "presentText": "Kollegium",
      "presentShortText": "Kollegium",
      "description": " "
    },
    "160": {
      "title": "Boligbygning til døgninstitution",
      "presentText": "Boligbygning til institution",
      "presentShortText": "Døgninstitution",
      "description": " "
    },
    "185": {
      "title": "Anneks i tilknytning til helårsbolig.",
      "presentText": "Anneks til helårsbolig",
      "presentShortText": "Anneks til helårsbolig",
      "description": " "
    },
    "190": {
      "title": "Anden bygning til helårsbeboelse",
      "presentText": "Anden helårsbeboelse",
      "presentShortText": "Anden helårsbeboelse",
      "description": " "
    },
    "210": {
      "title": "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, råstofudvinding o. lign",
      "presentText": "Bygning t. landbrug, gartneri mv.",
      "presentShortText": "Landbrug, gartneri, råstof. mv.",
      "description": " "
    },
    "211": {
      "title": "Stald til svin",
      "presentText": "Stald til svin",
      "presentShortText": "Stald til svin",
      "description": " "
    },
    "212": {
      "title": "Stald til kvæg, får mv.",
      "presentText": "Stald til kvæg, får mv.",
      "presentShortText": "Stald til kvæg, får mv.",
      "description": " "
    },
    "213": {
      "title": "Stald til fjerkræ",
      "presentText": "Stald til fjerkræ",
      "presentShortText": "Stald til fjerkræ",
      "description": " "
    },
    "214": {
      "title": "Minkhal",
      "presentText": "Minkhal",
      "presentShortText": "Minkhal",
      "description": " "
    },
    "215": {
      "title": "Væksthus",
      "presentText": "Væksthus",
      "presentShortText": "Væksthus",
      "description": " "
    },
    "216": {
      "title": "Lade til foder, afgrøder mv.",
      "presentText": "Lade til foder, afgrøder mv.",
      "presentShortText": "Lade til foder, afgrøder mv.",
      "description": " "
    },
    "217": {
      "title": "Maskinhus,  garage mv.",
      "presentText": "Maskinhus, garage mv.",
      "presentShortText": "Maskinhus, garage mv.",
      "description": " "
    },
    "218": {
      "title": "Lade til halm, hø mv.",
      "presentText": "Lade til halm, hø mv.",
      "presentShortText": "Lade til halm, hø mv.",
      "description": " "
    },
    "219": {
      "title": "Anden bygning til landbrug mv.",
      "presentText": "Anden bygning til landbrug mv.",
      "presentShortText": "Anden bygning til landbrug mv.",
      "description": " "
    },
    "220": {
      "title": "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende industri, håndværk m.v. (fabrik, værksted o.lign.)",
      "presentText": "Bygning til fabrik, værksted e.l.",
      "presentShortText": "Fabrik, værksted e.l.",
      "description": " "
    },
    "221": {
      "title": "Bygning til industri med integreret produktionsapparat",
      "presentText": "Industribygning m. produktion",
      "presentShortText": "Industri m. produktionsapparat",
      "description": " "
    },
    "222": {
      "title": "Bygning til industri uden integreret produktionsapparat",
      "presentText": "Industribygning u. produktion",
      "presentShortText": "Industri u. produktionsapparat",
      "description": " "
    },
    "223": {
      "title": "Værksted",
      "presentText": "Værksted",
      "presentShortText": "Værksted",
      "description": " "
    },
    "229": {
      "title": "Anden bygning til produktion",
      "presentText": "Anden bygning til produktion",
      "presentShortText": "Anden bygning til produktion",
      "description": " "
    },
    "230": {
      "title": "(UDFASES) El-, gas-, vand- eller varmeværk, forbrændingsanstalt m.v.",
      "presentText": "Bygning t. forsyningsvirksomh. mv.",
      "presentShortText": "Forsyningsvirksomhed e.l.",
      "description": " "
    },
    "231": {
      "title": "Bygning til energiproduktion",
      "presentText": "Bygning til energiproduktion",
      "presentShortText": "Energiproduktion",
      "description": " "
    },
    "232": {
      "title": "Bygning til forsyning- og energidistribution",
      "presentText": "Bygning til forsyning- og energi",
      "presentShortText": "Forsyning- og energi",
      "description": " "
    },
    "233": {
      "title": "Bygning til vandforsyning",
      "presentText": "Bygning til vandforsyning",
      "presentShortText": "Vandforsyning",
      "description": " "
    },
    "234": {
      "title": "Bygning til håndtering af affald og spildevand",
      "presentText": "Bygning til affald el. spildevand",
      "presentShortText": "Affald- el. spildevandshåndtering",
      "description": " "
    },
    "239": {
      "title": "Anden bygning til energiproduktion og -distribution",
      "presentText": "Anden bygning til forsyningsvirk",
      "presentShortText": "Anden forsyningsvirksomhed",
      "description": " "
    },
    "290": {
      "title": "(UDFASES) Anden bygning til landbrug, industri etc.",
      "presentText": "Anden bygn. t. landbrug, industri mv.",
      "presentShortText": "Anden byg. t. landbrug, industri",
      "description": " "
    },
    "310": {
      "title": "(UDFASES) Transport- og garageanlæg (fragtmandshal, lufthavnsbygning, banegårdsbygning, parkeringshus). Garage med plads til et eller to køretøjer registreres med anvendelseskode 910",
      "presentText": "Transport- el. garageanlæg",
      "presentShortText": "Transport- el. garageanlæg",
      "description": " "
    },
    "311": {
      "title": "Bygning til jernbane- og busdrift",
      "presentText": "Bygning til jernbane- el. busdrift",
      "presentShortText": "Jernbane- el. busdrift",
      "description": " "
    },
    "312": {
      "title": "Bygning til luftfart",
      "presentText": "Bygning til luftfart",
      "presentShortText": "Luftfartsvirksomhed",
      "description": " "
    },
    "313": {
      "title": "Bygning til parkering- og transportanlæg",
      "presentText": "Bygning til parkering- el. transport",
      "presentShortText": "Parkering- el. transportvirks.",
      "description": " "
    },
    "314": {
      "title": "Bygning til parkering af flere end to køretøjer i tilknytning til boliger",
      "presentText": "Garage til boliger, t. mere end 2 biler",
      "presentShortText": "Garage til boliger,  > 2 biler",
      "description": " "
    },
    "315": {
      "title": "Havneanlæg",
      "presentText": "Havneanlæg",
      "presentShortText": "Havneanlæg",
      "description": " "
    },
    "319": {
      "title": "Andet transportanlæg",
      "presentText": "Andet transportanlæg",
      "presentShortText": "Andet transportanlæg",
      "description": " "
    },
    "320": {
      "title": "(UDFASES) Bygning til kontor, handel, lager, herunder offentlig administration",
      "presentText": "Bygning til kontor, handel, lager mv. ",
      "presentShortText": "Kontor, handel, lager mv. ",
      "description": " "
    },
    "321": {
      "title": "Bygning til kontor",
      "presentText": "Bygning til kontor",
      "presentShortText": "Kontorvirksomhed",
      "description": " "
    },
    "322": {
      "title": "Bygning til detailhandel",
      "presentText": "Bygning til detailhandel",
      "presentShortText": "Detailhandel",
      "description": " "
    },
    "323": {
      "title": "Bygning til lager",
      "presentText": "Bygning til lager",
      "presentShortText": "Lager",
      "description": " "
    },
    "324": {
      "title": "Butikscenter",
      "presentText": "Butikscenter",
      "presentShortText": "Butikscenter",
      "description": " "
    },
    "325": {
      "title": "Tankstation",
      "presentText": "Tankstation",
      "presentShortText": "Tankstation",
      "description": " "
    },
    "329": {
      "title": "Anden bygning til kontor, handel og lager",
      "presentText": "Anden bygning til kontor, handel mv. ",
      "presentShortText": "Anden bygning til kontor, handel ",
      "description": " "
    },
    "330": {
      "title": "(UDFASES) Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
      "presentText": "Bygning til hotel, restaurant, service",
      "presentShortText": "Hotel, restaurant, service",
      "description": " "
    },
    "331": {
      "title": "Hotel, kro eller konferencecenter med overnatning",
      "presentText": "Hotel, konference- el. med overnatning",
      "presentShortText": "Hotel ol. med overnatning",
      "description": " "
    },
    "332": {
      "title": "Bed & breakfast mv.",
      "presentText": "Bed & breakfast mv.",
      "presentShortText": "Bed & breakfast mv.",
      "description": " "
    },
    "333": {
      "title": "Restaurant, café og konferencecenter uden overnatning",
      "presentText": "Restaurant, konference- u. overnatning ",
      "presentShortText": "Restaurant e.l.  u. overnatning ",
      "description": " "
    },
    "334": {
      "title": "Privat servicevirksomhed som frisør, vaskeri, netcafé mv.",
      "presentText": "Privat service fx frisør, vaskeri, netcafé",
      "presentShortText": "Privat servicevirksomhed",
      "description": " "
    },
    "339": {
      "title": "Anden bygning til serviceerhverv",
      "presentText": "Anden bygning til serviceerhverv",
      "presentShortText": "Anden bygning til serviceerhverv",
      "description": " "
    },
    "390": {
      "title": "(UDFASES) Anden bygning til transport, handel etc",
      "presentText": "Anden bygning til transport, handel etc",
      "presentShortText": "Anden byg. til transport, handel e.l.",
      "description": " "
    },
    "410": {
      "title": "(UDFASES) Bygning til biograf, teater, erhvervsmæssig udstilling, bibliotek, museum, kirke o. lign.",
      "presentText": "Biograf, udstilling, bibliotek, kirke e.l.",
      "presentShortText": "Biograf, udstilling, bibliotek, kirke e.l.",
      "description": " "
    },
    "411": {
      "title": "Biograf, teater, koncertsted mv.",
      "presentText": "Biograf, teater, koncertsted mv.",
      "presentShortText": "Biograf, teater, koncertsted mv.",
      "description": " "
    },
    "412": {
      "title": "Museum",
      "presentText": "Museum",
      "presentShortText": "Museum",
      "description": " "
    },
    "413": {
      "title": "Bibliotek",
      "presentText": "Bibliotek",
      "presentShortText": "Bibliotek",
      "description": " "
    },
    "414": {
      "title": "Kirke eller anden bygning til trosudøvelse for statsanerkendte trossamfund",
      "presentText": "Kirke el. anden bygning til trosudøvelse",
      "presentShortText": "Kirke el. anden trosudøvelse",
      "description": " "
    },
    "415": {
      "title": "Forsamlingshus",
      "presentText": "Forsamlingshus",
      "presentShortText": "Forsamlingshus",
      "description": " "
    },
    "416": {
      "title": "Forlystelsespark",
      "presentText": "Forlystelsespark",
      "presentShortText": "Forlystelsespark",
      "description": " "
    },
    "419": {
      "title": "Anden bygning til kulturelle formål",
      "presentText": "Anden bygning til kulturelle formål",
      "presentShortText": "Anden byg. til kulturelle formål",
      "description": " "
    },
    "420": {
      "title": "(UDFASES) Bygning til undervisning og forskning (skole, gymnasium, forskningslabratorium o.lign.).",
      "presentText": "Bygning til undervisning el. forskning",
      "presentShortText": "Undervisning eller forskning",
      "description": " "
    },
    "421": {
      "title": "Grundskole",
      "presentText": "Grundskole",
      "presentShortText": "Grundskole",
      "description": " "
    },
    "422": {
      "title": "Universitet",
      "presentText": "Universitet",
      "presentShortText": "Universitet",
      "description": " "
    },
    "429": {
      "title": "Anden bygning til undervisning og forskning",
      "presentText": "Anden bygning til undervisning el. forskning",
      "presentShortText": "Anden byg. til underv. el. forskning",
      "description": " "
    },
    "430": {
      "title": "(UDFASES) Bygning til hospital, sygehjem, fødeklinik o. lign.",
      "presentText": "Bygning til hospital, fødeklinik e.l.",
      "presentShortText": "Hospital, sygehjem, fødeklinik e.l.",
      "description": " "
    },
    "431": {
      "title": "Hospital og sygehus",
      "presentText": "Hospital eller sygehus",
      "presentShortText": "Hospital eller sygehus",
      "description": " "
    },
    "432": {
      "title": "Hospice, behandlingshjem mv.",
      "presentText": "Hospice, behandlingshjem mv.",
      "presentShortText": "Hospice, behandlingshjem mv.",
      "description": " "
    },
    "433": {
      "title": "Sundhedscenter, lægehus, fødeklinik mv.",
      "presentText": "Sundhedscenter, lægehus, fødeklinik e.l.",
      "presentShortText": "Sundhedscenter, lægehus e.l.",
      "description": " "
    },
    "439": {
      "title": "Anden bygning til sundhedsformål",
      "presentText": "Anden bygning til sundhedsformål",
      "presentShortText": "Anden byg. til sundhedsformål",
      "description": " "
    },
    "440": {
      "title": "(UDFASES) Bygning til daginstitution",
      "presentText": "Bygning til daginstitution",
      "presentShortText": "Bygning til daginstitution",
      "description": " "
    },
    "441": {
      "title": "Daginstitution",
      "presentText": "Daginstitution",
      "presentShortText": "Daginstitution",
      "description": " "
    },
    "442": {
      "title": "Servicefunktion på døgninstitution",
      "presentText": "Servicefunktion på døgninstitution",
      "presentShortText": "Servicefunktion på døgninstitution",
      "description": " "
    },
    "443": {
      "title": "Kaserne",
      "presentText": "Kaserne",
      "presentShortText": "Kaserne",
      "description": " "
    },
    "444": {
      "title": "Fængsel, arresthus mv.",
      "presentText": "Fængsel, arresthus mv.",
      "presentShortText": "Fængsel, arresthus mv.",
      "description": " "
    },
    "449": {
      "title": "Anden bygning til institutionsformål",
      "presentText": "Anden bygning til institutionsformål",
      "presentShortText": "Anden byg. til institutionsformål",
      "description": " "
    },
    "490": {
      "title": "(UDFASES) Bygning til anden institution, herunder kaserne, fængsel o. lign.",
      "presentText": "Bygning til anden institution",
      "presentShortText": "Bygning til anden institution",
      "description": " "
    },
    "510": {
      "title": "Sommerhus",
      "presentText": "Sommerhus",
      "presentShortText": "Sommerhus",
      "description": " "
    },
    "520": {
      "title": "(UDFASES) Bygning til feriekoloni, vandrehjem o.lign. bortset fra sommerhus",
      "presentText": "Feriekoloni, vandrehjem e.l. ",
      "presentShortText": "Feriekoloni, vandrehjem e.l. ",
      "description": " "
    },
    "521": {
      "title": "Feriecenter, center til campingplads mv.",
      "presentText": "Feriecenter, center til campingplads el.",
      "presentShortText": "Feriecenter, campingplads e.l.",
      "description": " "
    },
    "522": {
      "title": "Bygning med ferielejligheder til erhvervsmæssig udlejning",
      "presentText": "Bygning med ferielejligheder t. udlejning",
      "presentShortText": "Ferielejligheder t. udlejning",
      "description": " "
    },
    "523": {
      "title": "Bygning med ferielejligheder til eget brug",
      "presentText": "Bygning med ferielejligheder til eget brug",
      "presentShortText": "Ferielejligheder til eget brug",
      "description": " "
    },
    "529": {
      "title": "Anden bygning til ferieformål",
      "presentText": "Anden bygning til ferieformål",
      "presentShortText": "Anden bygning til ferieformål",
      "description": " "
    },
    "530": {
      "title": "(UDFASES) Bygning i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.)",
      "presentText": "Bygning i forbindelse m. idrætsudøvelse",
      "presentShortText": "Idrætsudøvelse",
      "description": " "
    },
    "531": {
      "title": "Klubhus i forbindelse med fritid og idræt",
      "presentText": "Klubhus til fritid og idræt",
      "presentShortText": "Klubhus til fritid og idræt",
      "description": " "
    },
    "532": {
      "title": "Svømmehal",
      "presentText": "Svømmehal",
      "presentShortText": "Svømmehal",
      "description": " "
    },
    "533": {
      "title": "Idrætshal",
      "presentText": "Idrætshal",
      "presentShortText": "Idrætshal",
      "description": " "
    },
    "534": {
      "title": "Tribune i forbindelse med stadion",
      "presentText": "Tribune til stadion",
      "presentShortText": "Tribune til stadion",
      "description": " "
    },
    "535": {
      "title": "Rideskole",
      "presentText": "Rideskole",
      "presentShortText": "Rideskole",
      "description": " "
    },
    "539": {
      "title": "Anden bygning til idrætformål",
      "presentText": "Anden bygning til idræt",
      "presentShortText": "Anden bygning til idræt",
      "description": " "
    },
    "540": {
      "title": "Kolonihavehus",
      "presentText": "Kolonihavehus",
      "presentShortText": "Kolonihavehus",
      "description": " "
    },
    "585": {
      "title": "Anneks i tilknytning til fritids- og sommerhus",
      "presentText": "Anneks til fritids- el. sommerhus",
      "presentShortText": "Anneks til fritids- el. sommerhus",
      "description": " "
    },
    "590": {
      "title": "Anden bygning til fritidsformål",
      "presentText": "Anden bygning til fritidsformål",
      "presentShortText": "Anden bygning til fritidsformål",
      "description": " "
    },
    "910": {
      "title": "Garage (med plads til et eller to køretøjer)",
      "presentText": "Garage til en eller to biler",
      "presentShortText": "Garage til en eller to biler",
      "description": " "
    },
    "920": {
      "title": "Carport",
      "presentText": "Carport",
      "presentShortText": "Carport",
      "description": " "
    },
    "930": {
      "title": "Udhus",
      "presentText": "Udhus",
      "presentShortText": "Udhus",
      "description": " "
    },
    "940": {
      "title": "Drivhus",
      "presentText": "Drivhus",
      "presentShortText": "Drivhus",
      "description": " "
    },
    "950": {
      "title": "Fritliggende overdækning",
      "presentText": "Fritliggende overdækning",
      "presentShortText": "Fritliggende overdækning",
      "description": " "
    },
    "960": {
      "title": "Fritliggende udestue",
      "presentText": "Fritliggende udestue",
      "presentShortText": "Fritliggende udestue",
      "description": " "
    },
    "970": {
      "title": "Tiloversbleven landbrugsbygning",
      "presentText": "Tiloversbleven landbrugsbygning",
      "presentShortText": "Tiloversbleven landbrugsbygning",
      "description": " "
    },
    "990": {
      "title": "Faldefærdig bygning",
      "presentText": "Faldefærdig bygning",
      "presentShortText": "Faldefærdig bygning",
      "description": " "
    },
    "999": {
      "title": "Ukendt bygning",
      "presentText": "Ukendt bygning",
      "presentShortText": "Ukendt bygning",
      "description": " "
    }
  },
  "Byggesagskode": {
    "1": {
      "title": "BR - Tilladelsessag uden ibrugtagningstilladelse",
      "presentText": "BR - Tilladelsessag u. ibrugtagningstill.",
      "presentShortText": "BR - Tilladelsessag u. ibrugtagningstill.",
      "description": " "
    },
    "2": {
      "title": "BR - Anmeldelsessag (garager, carporte, udhuse og nedrivning)",
      "presentText": "BR - Anmeldelsessag (garager, udhuse o.l. nedrivning)",
      "presentShortText": "BR - Anmeldelsessag (garager, udhuse o.l. nedrivning)",
      "description": " "
    },
    "3": {
      "title": "BR - Tilladelsessag med ibrugtagningstilladelse",
      "presentText": "BR - Tilladelsessag med ibrugtagningstill.",
      "presentShortText": "BR - Tilladelsessag med ibrugtagningstill.",
      "description": " "
    },
    "4": {
      "title": "BR - Tilladelsessag landbrugsbygning",
      "presentText": "BR - Tilladelsessag landbrugsbygning",
      "presentShortText": "BR - Tilladelsessag landbrugsbygning",
      "description": " "
    },
    "5": {
      "title": "BR - Anmeldelsessag (øvrige)",
      "presentText": "BR - Anmeldelsessag (øvrige)",
      "presentShortText": "BR - Anmeldelsessag (øvrige)",
      "description": " "
    }
  },
  "Byggeskadeforsikringsselskab": {
    "0": {
      "title": "Ingen byggeskadeforsikring",
      "presentText": "Ingen byggeskadeforsikring",
      "presentShortText": "Ingen byggeskadeforsikring",
      "description": " "
    },
    "1": {
      "title": "Tryg",
      "presentText": "Tryg",
      "presentShortText": "Tryg",
      "description": " "
    },
    "2": {
      "title": "Topdanmark",
      "presentText": "Topdanmark",
      "presentShortText": "Topdanmark",
      "description": " "
    },
    "4": {
      "title": "Codan",
      "presentText": "Codan",
      "presentShortText": "Codan",
      "description": " "
    },
    "5": {
      "title": "If Forsikring",
      "presentText": "If Forsikring",
      "presentShortText": "If Forsikring",
      "description": " "
    },
    "6": {
      "title": "Alm. Brand",
      "presentText": "Alm. Brand",
      "presentShortText": "Alm. Brand",
      "description": " "
    },
    "7": {
      "title": "Danske Forsikring",
      "presentText": "Danske Forsikring",
      "presentShortText": "Danske Forsikring",
      "description": " "
    },
    "8": {
      "title": "Caplloyd A/S",
      "presentText": "Caplloyd A/S",
      "presentShortText": "Caplloyd A/S",
      "description": " "
    },
    "10": {
      "title": "Købstædernes Forsikring",
      "presentText": "Købstædernes Forsikring",
      "presentShortText": "Købstædernes Forsikring",
      "description": " "
    },
    "11": {
      "title": "ALKA",
      "presentText": "ALKA",
      "presentShortText": "ALKA",
      "description": " "
    },
    "12": {
      "title": "Frida Forsikring Agentur",
      "presentText": "Frida Forsikring Agentur",
      "presentShortText": "Frida Forsikring Agentur",
      "description": " "
    },
    "13": {
      "title": "NemForsikring",
      "presentText": "NemForsikring",
      "presentShortText": "NemForsikring",
      "description": " "
    },
    "14": {
      "title": "AXA",
      "presentText": "AXA",
      "presentShortText": "AXA",
      "description": " "
    },
    "15": {
      "title": "Husejernes Forsikring",
      "presentText": "Husejernes Forsikring",
      "presentShortText": "Husejernes Forsikring",
      "description": " "
    },
    "16": {
      "title": "Garbo",
      "presentText": "Garbo",
      "presentShortText": "Garbo",
      "description": " "
    },
    "17": {
      "title": "Marsh og McLennan Agency A/S",
      "presentText": "Marsh og McLennan Agency A/S",
      "presentShortText": "Marsh og McLennan Agency A/S",
      "description": " "
    },
    "18": {
      "title": "First Marine",
      "presentText": "First Marine",
      "presentShortText": "First Marine",
      "description": " "
    },
    "19": {
      "title": "Domus Forsikring A/S",
      "presentText": "Domus Forsikring A/S",
      "presentShortText": "Domus Forsikring A/S",
      "description": ""
    },
    "99": {
      "title": "Ingen forsikring på grund af dispensation",
      "presentText": "Ingen forsikring på grund af dispensation",
      "presentShortText": "Ingen forsikring på grund af dispensation",
      "description": " "
    }
  },
  "BygherreForhold": {
    "10": {
      "title": "Privatpersoner eller interessentskab",
      "presentText": "Privatpersoner eller I/S",
      "presentShortText": "Privatpersoner el. I/S",
      "description": " "
    },
    "20": {
      "title": "Alment boligselskab",
      "presentText": "Alment boligselskab",
      "presentShortText": "Alment boligselskab",
      "description": " "
    },
    "30": {
      "title": "Aktie-, anpart- eller andet selskab (undtagen interessent­skab)",
      "presentText": "A/S, ApS eller andet selskab (undtagen I/S)",
      "presentShortText": "A/S, ApS el. andet selskab (undt. I/S)",
      "description": " "
    },
    "40": {
      "title": "Forening, legat eller selvejende institution",
      "presentText": "Forening, legat eller selvejende institution",
      "presentShortText": "Forening, legat el. selvejende inst.",
      "description": " "
    },
    "41": {
      "title": "Privat andelsboligforening",
      "presentText": "Privat andelsboligforening",
      "presentShortText": "Privat andelsboligforening",
      "description": " "
    },
    "50": {
      "title": "Den kommune, hvori ejendommen er beliggende",
      "presentText": "Kommunen",
      "presentShortText": "Kommunen",
      "description": " "
    },
    "60": {
      "title": "Anden primærkommune",
      "presentText": "Anden kommune",
      "presentShortText": "Anden kommune",
      "description": " "
    },
    "70": {
      "title": "Region",
      "presentText": "Region",
      "presentShortText": "Region",
      "description": " "
    },
    "80": {
      "title": "Staten",
      "presentText": "Staten",
      "presentShortText": "Staten",
      "description": " "
    },
    "90": {
      "title": "Andet, herunder moderejendomme for bebyggelser, der er op­delt i ejerlejligheder samt ejendomme, der ejes af flere ka­te­gorier af ejere",
      "presentText": "Andet, herunder moderejend. f. ejerlejligheder",
      "presentShortText": "Andet, bl.a. ejendom f. ejerlejligh.",
      "description": " "
    }
  },
  "BygningSortering": {
    "0": {
      "title": "Bygnings nummer",
      "presentText": "Bygningsnummer",
      "presentShortText": "Bygningsnummer",
      "description": " "
    },
    "1": {
      "title": "Vejkode",
      "presentText": "Vejkode",
      "presentShortText": "Vejkode",
      "description": " "
    },
    "2": {
      "title": "Vejnavn",
      "presentText": "Vejnavn",
      "presentShortText": "Vejnavn",
      "description": " "
    },
    "3": {
      "title": "Husnummer",
      "presentText": "Husnummer",
      "presentShortText": "Husnummer",
      "description": " "
    },
    "4": {
      "title": "Postnummer",
      "presentText": "Postnummer",
      "presentShortText": "Postnummer",
      "description": " "
    },
    "5": {
      "title": "Postdistrikt",
      "presentText": "Postdistrikt",
      "presentShortText": "Postdistrikt",
      "description": " "
    },
    "6": {
      "title": "Anvendelseskode (samt som klartekst i tooltip)",
      "presentText": "Anvendelseskode (samt som klartekst i tooltip)",
      "presentShortText": "Anvendelseskode (samt som klartekst i tooltip)",
      "description": " "
    },
    "9": {
      "title": "Sagsnummer",
      "presentText": "Sagsnummer",
      "presentShortText": "Sagsnummer",
      "description": " "
    },
    "10": {
      "title": "Opførelsesår",
      "presentText": "Opførelsesår",
      "presentShortText": "Opførelsesår",
      "description": " "
    }
  },
  "BygSupplerendeVarme": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Varmepumpeanlæg",
      "presentText": "Varmepumpeanlæg",
      "presentShortText": "Varmepumpeanlæg",
      "description": " "
    },
    "2": {
      "title": "Ovne til fast brændsel (brændeovn o. lign.)",
      "presentText": "Ovne til fast brændsel (brændeovn o.l.)",
      "presentShortText": "Ovne til fast brændsel (brændeovn o.l.)",
      "description": " "
    },
    "3": {
      "title": "Ovne til flydende brændsel",
      "presentText": "Ovne til flydende brændsel",
      "presentShortText": "Ovne til flydende brændsel",
      "description": " "
    },
    "4": {
      "title": "Solpaneler",
      "presentText": "Solpaneler",
      "presentShortText": "Solpaneler",
      "description": " "
    },
    "5": {
      "title": "Pejs",
      "presentText": "Pejs",
      "presentShortText": "Pejs",
      "description": " "
    },
    "6": {
      "title": "Gasradiator",
      "presentText": "Gasradiator",
      "presentShortText": "Gasradiator",
      "description": " "
    },
    "7": {
      "title": "Elovne, elpaneler",
      "presentText": "Elovne, elpaneler",
      "presentShortText": "Elovne, elpaneler",
      "description": " "
    },
    "10": {
      "title": "Biogasanlæg",
      "presentText": "Biogasanlæg",
      "presentShortText": "Biogasanlæg",
      "description": " "
    },
    "80": {
      "title": "Andet",
      "presentText": "Andet",
      "presentShortText": "Andet",
      "description": " "
    },
    "90": {
      "title": "Bygningen har ingen supplerende varme",
      "presentText": "Ingen supplerende varme",
      "presentShortText": "Ingen supplerende varme",
      "description": " "
    }
  },
  "BygVandforsyning": {
    "1": {
      "title": "Alment vandforsyningsanlæg (tidligere offentligt)",
      "presentText": "Alment vandforsyningsanlæg",
      "presentShortText": "Alment vandforsyningsanlæg",
      "description": " "
    },
    "2": {
      "title": "Privat, alment vandforsyningsanlæg",
      "presentText": "Privat, alment vandforsyningsanlæg",
      "presentShortText": "Privat, alment vandforsyningsanlæg",
      "description": " "
    },
    "3": {
      "title": "Enkeltindvindingsanlæg (egen boring til 1 eller 2 ejendomme)",
      "presentText": "Enkeltindvindingsanlæg (egen boring, 1-2 ejd.)",
      "presentShortText": "Enkeltindvindingsanlæg (egen boring, 1-2 ejd.)",
      "description": " "
    },
    "4": {
      "title": "Brønd",
      "presentText": "Brønd",
      "presentShortText": "Brønd",
      "description": " "
    },
    "6": {
      "title": "Ikke alment vandforsyningsanlæg (forsyner < 10 ejendomme)",
      "presentText": "Ikke alment vandforsyningsanlæg (< 10 ejd.)",
      "presentShortText": "Ikke alment vandforsyningsanlæg (< 10 ejd.)",
      "description": " "
    },
    "7": {
      "title": "Blandet vandforsyning",
      "presentText": "Blandet vandforsyning",
      "presentShortText": "Blandet vandforsyning",
      "description": " "
    },
    "9": {
      "title": "Ingen vandforsyning",
      "presentText": "Ingen vandforsyning",
      "presentShortText": "Ingen vandforsyning",
      "description": " "
    }
  },
  "BygVarmeinstallation": {
    "1": {
      "title": "Fjernvarme/blokvarme (radiatorsystemer el. varmluftanlæg)",
      "presentText": "Fjernvarme eller blokvarme",
      "presentShortText": "Fjernvarme eller blokvarme",
      "description": " "
    },
    "2": {
      "title": "Centralvarme fra eget anlæg, et-kammer fyr",
      "presentText": "Centralvarme fra eget anlæg",
      "presentShortText": "Centralvarme fra eget anlæg",
      "description": " "
    },
    "3": {
      "title": "Ovne (kakkelovne, kamin, brændeovne o.l.)",
      "presentText": "Ovne (kakkelovn, brændeovn o.l.)",
      "presentShortText": "Ovne (kakkelovn, brændeovn o.l.)",
      "description": " "
    },
    "5": {
      "title": "Varmepumpe",
      "presentText": "Varmepumpe",
      "presentShortText": "Varmepumpe",
      "description": " "
    },
    "6": {
      "title": "Centralvarme med to fyringsenheder (fast og olie eller gas)",
      "presentText": "Centralvarme med to enheder (fast og olie/gas)",
      "presentShortText": "Centralvarme med to enheder (fast og olie/gas)",
      "description": " "
    },
    "7": {
      "title": "Elovne, elpaneler",
      "presentText": "Elovne eller elpaneler",
      "presentShortText": "Elovne eller elpaneler",
      "description": " "
    },
    "8": {
      "title": "Gasradiator",
      "presentText": "Gasradiator",
      "presentShortText": "Gasradiator",
      "description": " "
    },
    "9": {
      "title": "Ingen varmeinstallation",
      "presentText": "Ingen varmeinstallation",
      "presentShortText": "Ingen varmeinstallation",
      "description": " "
    },
    "99": {
      "title": "Blandet (Kræver specifikation på enhedsniveau)",
      "presentText": "Blandet (angivet på enhedsniveau)",
      "presentShortText": "Blandet (angivet på enhedsniveau)",
      "description": " "
    }
  },
  "DispensationFritagelseIftKollektivVarmeforsyning": {
    "1": {
      "title": "Dispensation er tidsbegrænset",
      "presentText": "Dispensation er tidsbegrænset",
      "presentShortText": "Dispensation er tidsbegrænset",
      "description": " "
    },
    "2": {
      "title": "Dispensationen er ikke tidsbegrænset",
      "presentText": "Dispensationen er ikke tidsbegrænset",
      "presentShortText": "Dispensationen er ikke tidsbegrænset",
      "description": " "
    }
  },
  "Driftstatus": {
    "1": {
      "title": "I drift",
      "presentText": "I drift",
      "presentShortText": "I drift",
      "description": " "
    },
    "2": {
      "title": "Ikke i drift",
      "presentText": "Ikke i drift",
      "presentShortText": "Ikke i drift",
      "description": " "
    }
  },
  "Ejendomstype": {
    "1": {
      "title": "Matrikuleret Areal",
      "presentText": "Matrikuleret Areal",
      "presentShortText": "Matrikuleret Areal",
      "description": " "
    },
    "2": {
      "title": "BPFG",
      "presentText": "BPFG",
      "presentShortText": "BPFG",
      "description": " "
    },
    "3": {
      "title": "Ejerlejlighed",
      "presentText": "Ejerlejlighed",
      "presentShortText": "Ejerlejlighed",
      "description": " "
    }
  },
  "Ejerforholdskode": {
    "10": {
      "title": "Privatpersoner eller interessentskab",
      "presentText": "Privatpersoner eller I/S",
      "presentShortText": "Privatpersoner el. I/S",
      "description": " "
    },
    "20": {
      "title": "Alment boligselskab",
      "presentText": "Alment boligselskab",
      "presentShortText": "Alment boligselskab",
      "description": " "
    },
    "30": {
      "title": "Aktie-, anpart- eller andet selskab (undtagen interessent­skab)",
      "presentText": "A/S, ApS eller andet selskab (undtagen I/S)",
      "presentShortText": "A/S, ApS el. andet selskab (undt. I/S)",
      "description": " "
    },
    "40": {
      "title": "Forening, legat eller selvejende institution",
      "presentText": "Forening, legat eller selvejende institution",
      "presentShortText": "Forening, legat el. selvejende inst.",
      "description": " "
    },
    "41": {
      "title": "Privat andelsboligforening",
      "presentText": "Privat andelsboligforening",
      "presentShortText": "Privat andelsboligforening",
      "description": " "
    },
    "50": {
      "title": "Den kommune, hvori ejendommen er beliggende",
      "presentText": "Kommunen",
      "presentShortText": "Kommunen",
      "description": " "
    },
    "60": {
      "title": "Anden primærkommune",
      "presentText": "Anden kommune",
      "presentShortText": "Anden kommune",
      "description": " "
    },
    "70": {
      "title": "Region",
      "presentText": "Region",
      "presentShortText": "Region",
      "description": " "
    },
    "80": {
      "title": "Staten",
      "presentText": "Staten",
      "presentShortText": "Staten",
      "description": " "
    },
    "90": {
      "title": "Andet, herunder moderejendomme for bebyggelser, der er opdelt i ejerlejligheder samt ejendomme, der ejes af flere kategorier af ejere",
      "presentText": "Andet, bl.a. moderejendom f. ejerlejligheder",
      "presentShortText": "Andet, bl.a. ejendom f. ejerlejligh.",
      "description": " "
    }
  },
  "Elevator": {
    "0": {
      "title": "Der er ikke elevator i opgangen/bygningen",
      "presentText": "Der er ikke elevator i opgangen/bygningen",
      "presentShortText": "Der er ikke elevator i opgangen/bygningen",
      "description": " "
    },
    "1": {
      "title": "Der findes person- eller vareelevator i opgangen/bygningen",
      "presentText": "Der findes person- eller vareelevator i opgangen/bygningen",
      "presentShortText": "Der findes person- eller vareelevator i opgangen/bygningen",
      "description": " "
    }
  },
  "Energiforsyning": {
    "1": {
      "title": "Gas fra værk",
      "presentText": "Gas fra værk",
      "presentShortText": "Gas fra værk",
      "description": " "
    },
    "2": {
      "title": "230 V el fra værk",
      "presentText": "230 V el fra værk",
      "presentShortText": "230 V el fra værk",
      "description": " "
    },
    "3": {
      "title": "400 V el fra værk",
      "presentText": "400 V el fra værk",
      "presentShortText": "400 V el fra værk",
      "description": " "
    },
    "4": {
      "title": "Både 230 V el og gas fra værk",
      "presentText": "Både 230 V el og gas fra værk",
      "presentShortText": "Både 230 V el og gas fra værk",
      "description": " "
    },
    "5": {
      "title": "Både 400 V el og gas fra værk",
      "presentText": "Både 400 V el og gas fra værk",
      "presentShortText": "Både 400 V el og gas fra værk",
      "description": " "
    },
    "6": {
      "title": "Hverken el eller gas fra værk",
      "presentText": "Hverken el eller gas fra værk",
      "presentShortText": "Hverken el eller gas fra værk",
      "description": " "
    }
  },
  "EnhAnvendelse": {
    "110": {
      "title": "Stuehus til landbrugsejendom",
      "presentText": "Stuehus til landbrug",
      "presentShortText": "Stuehus til landbrug",
      "description": " "
    },
    "120": {
      "title": "Fritliggende enfamilieshus (parcelhus).",
      "presentText": "Bolig i fritliggende enfamiliehus",
      "presentShortText": "Bolig i fritliggende enfamiliehus",
      "description": " "
    },
    "121": {
      "title": "Sammenbygget enfamiliehus",
      "presentText": "Bolig i sammenbygget enfamiliehus",
      "presentShortText": "Bolig i sammenbygget enfamiliehus",
      "description": ""
    },
    "130": {
      "title": "(UDFASES) Række-, kæde- eller dobbelthus (lodret adskillelse mellem enhederne).",
      "presentText": "Bolig i række-, kæde- el. dobbelthus",
      "presentShortText": "Bolig i række-, kæde- el. dobbelthus",
      "description": " "
    },
    "131": {
      "title": "Række- og kædehus",
      "presentText": "Bolig i række- el. kædehus",
      "presentShortText": "Bolig i række- el. kædehus",
      "description": " "
    },
    "132": {
      "title": "Dobbelthus",
      "presentText": "Bolig i dobbelthus",
      "presentShortText": "Bolig i dobbelthus",
      "description": " "
    },
    "140": {
      "title": "Bolig i etageejendom, flerfamiliehus eller to-familiehus",
      "presentText": "Bolig i etageejendom, to- eller flerfamiliehus",
      "presentShortText": "Bolig i etageejendom, to- eller flerfamiliehus",
      "description": " "
    },
    "150": {
      "title": "Kollegiebolig",
      "presentText": "Kollegiebolig",
      "presentShortText": "Kollegiebolig",
      "description": " "
    },
    "160": {
      "title": "Bolig i døgninstitution",
      "presentText": "Bolig i  døgninstitution",
      "presentShortText": "Bolig i  døgninstitution",
      "description": " "
    },
    "185": {
      "title": "Anneks i tilknytning til helårsbolig",
      "presentText": "Anneks til helårsbolig",
      "presentShortText": "Anneks til helårsbolig",
      "description": " "
    },
    "190": {
      "title": "Anden enhed til helårsbeboelse",
      "presentText": "Anden helårsbeboelse",
      "presentShortText": "Anden helårsbeboelse",
      "description": " "
    },
    "210": {
      "title": "(UDFASES) Erhvervsmæssig produktion vedrørende landbrug, skovbrug, gartneri, råstofudvinding og lign.",
      "presentText": "Landbrug, gartneri mv.",
      "presentShortText": "Landbrug, gartneri mv.",
      "description": " "
    },
    "211": {
      "title": "Stald til svin",
      "presentText": "Stald til svin",
      "presentShortText": "Stald til svin",
      "description": " "
    },
    "212": {
      "title": "Stald til kvæg, får mv.",
      "presentText": "Stald til kvæg, får mv.",
      "presentShortText": "Stald til kvæg, får mv.",
      "description": " "
    },
    "213": {
      "title": "Stald til fjerkræ",
      "presentText": "Stald til fjerkræ",
      "presentShortText": "Stald til fjerkræ",
      "description": " "
    },
    "214": {
      "title": "Minkhal",
      "presentText": "Minkhal",
      "presentShortText": "Minkhal",
      "description": " "
    },
    "215": {
      "title": "Væksthus",
      "presentText": "Væksthus",
      "presentShortText": "Væksthus",
      "description": " "
    },
    "216": {
      "title": "Lade til foder, afgrøder mv.",
      "presentText": "Lade til foder, afgrøder mv.",
      "presentShortText": "Lade til foder, afgrøder mv.",
      "description": " "
    },
    "217": {
      "title": "Maskinhus,  garage mv.",
      "presentText": "Maskinhus, garage mv.",
      "presentShortText": "Maskinhus, garage mv.",
      "description": " "
    },
    "218": {
      "title": "Lade til halm, hø mv.",
      "presentText": "Lade til halm, hø mv.",
      "presentShortText": "Lade til halm, hø mv.",
      "description": " "
    },
    "219": {
      "title": "Anden enhed til landbrug mv.",
      "presentText": "Anden enhed til landbrug mv.",
      "presentShortText": "Anden enhed til landbrug mv.",
      "description": " "
    },
    "220": {
      "title": "(UDFASES) Erhvervsmæssig produktion vedrørende industri, håndværk m.v. (fabrik, værksted o. lign.)",
      "presentText": "Bygning til fabrik, værksted e.l.",
      "presentShortText": "Bygning til fabrik, værksted e.l.",
      "description": " "
    },
    "221": {
      "title": "Enhed til industri med integreret produktionsapparat",
      "presentText": "Industri m. produktion",
      "presentShortText": "Industri m. produktion",
      "description": " "
    },
    "222": {
      "title": "Enhed til industri uden integreret produktionsapparat",
      "presentText": "Industri u. produktion",
      "presentShortText": "Industri u. produktion",
      "description": " "
    },
    "223": {
      "title": "Værksted",
      "presentText": "Værksted",
      "presentShortText": "Værksted",
      "description": " "
    },
    "229": {
      "title": "Anden enhed til produktion",
      "presentText": "Anden enhed til produktion",
      "presentShortText": "Anden enhed til produktion",
      "description": " "
    },
    "230": {
      "title": "(UDFASES) El-, gas-, vand- eller varmeværk, forbrændingsanstalt o. lign.",
      "presentText": "Forsyningsvirksomh. mv.",
      "presentShortText": "Forsyningsvirksomh. mv.",
      "description": " "
    },
    "231": {
      "title": "Enhed til energiproduktion",
      "presentText": "Energiproduktion",
      "presentShortText": "Energiproduktion",
      "description": " "
    },
    "232": {
      "title": "Enhed til forsyning- og energidistribution",
      "presentText": "Forsyning- og energi",
      "presentShortText": "Forsyning- og energi",
      "description": " "
    },
    "233": {
      "title": "Enhed til vandforsyning",
      "presentText": "Vandforsyning",
      "presentShortText": "Vandforsyning",
      "description": " "
    },
    "234": {
      "title": "Enhed til håndtering af affald og spildevand",
      "presentText": "Affald el. spildevand",
      "presentShortText": "Affald el. spildevand",
      "description": " "
    },
    "239": {
      "title": "Anden enhed til energiproduktion og -distribution",
      "presentText": "Anden forsyningsvirksomhed",
      "presentShortText": "Anden forsyningsvirksomhed",
      "description": " "
    },
    "290": {
      "title": "(UDFASES) Anden enhed til produktion og lager i forbindelse med landbrug, industri o. lign.",
      "presentText": "Anden enhed t. landbrug, industri mv.",
      "presentShortText": "Anden enhed t. landbrug, industri mv.",
      "description": " "
    },
    "310": {
      "title": "(UDFASES) Transport- og garageanlæg (fragtmandshal, lufthavnsbygning,banegårdsbygning o. lign.)",
      "presentText": "Transport- el. garageanlæg",
      "presentShortText": "Transport- el. garageanlæg",
      "description": " "
    },
    "311": {
      "title": "Enhed til jernbane- og busdrift",
      "presentText": "Jernbane- el. busdrift",
      "presentShortText": "Jernbane- el. busdrift",
      "description": " "
    },
    "312": {
      "title": "Enhed til luftfart",
      "presentText": "Luftfart",
      "presentShortText": "Luftfart",
      "description": " "
    },
    "313": {
      "title": "Enhed til parkerings- og transportanlæg",
      "presentText": "Parkering- el. transport",
      "presentShortText": "Parkering- el. transport",
      "description": " "
    },
    "314": {
      "title": "Enhed til parkering af flere end to køretøjer i tilknytning til boliger",
      "presentText": "Garage til boliger, t. mere end 2 biler",
      "presentShortText": "Garage til boliger, t. mere end 2 biler",
      "description": " "
    },
    "315": {
      "title": "Havneanlæg",
      "presentText": "Havneanlæg",
      "presentShortText": "Havneanlæg",
      "description": " "
    },
    "319": {
      "title": "Andet transportanlæg",
      "presentText": "Andet transportanlæg",
      "presentShortText": "Andet transportanlæg",
      "description": " "
    },
    "320": {
      "title": "(UDFASES) Engroshandel og lager.",
      "presentText": "Engroshandel, lager",
      "presentShortText": "Engroshandel, lager",
      "description": " "
    },
    "321": {
      "title": "Enhed til kontor",
      "presentText": "Kontor",
      "presentShortText": "Kontor",
      "description": " "
    },
    "322": {
      "title": "Enhed til detailhandel",
      "presentText": "Detailhandel",
      "presentShortText": "Detailhandel",
      "description": " "
    },
    "323": {
      "title": "Enhed til lager",
      "presentText": "Lager",
      "presentShortText": "Lager",
      "description": " "
    },
    "324": {
      "title": "Butikscenter",
      "presentText": "Butikscenter",
      "presentShortText": "Butikscenter",
      "description": " "
    },
    "325": {
      "title": "Tankstation",
      "presentText": "Tankstation",
      "presentShortText": "Tankstation",
      "description": " "
    },
    "329": {
      "title": "Anden enhed til kontor, handel og lager",
      "presentText": "Anden enhed til kontor, handel mv. ",
      "presentShortText": "Anden enhed til kontor, handel mv. ",
      "description": " "
    },
    "330": {
      "title": "(UDFASES) Detailhandel m.v.",
      "presentText": "Detailhandel mv.",
      "presentShortText": "Detailhandel mv.",
      "description": " "
    },
    "331": {
      "title": "Hotel, kro eller konferencecenter med overnatning",
      "presentText": "Hotel, konference- e.l. med overnatning",
      "presentShortText": "Hotel, konference- e.l. med overnatning",
      "description": " "
    },
    "332": {
      "title": "Bed & breakfast mv.",
      "presentText": "Bed & breakfast mv.",
      "presentShortText": "Bed & breakfast mv.",
      "description": " "
    },
    "333": {
      "title": "Restaurant, café og konferencecenter uden overnatning",
      "presentText": "Restaurant, konference- u. overnatning ",
      "presentShortText": "Restaurant, konference- u. overnatning ",
      "description": " "
    },
    "334": {
      "title": "Privat servicevirksomhed som frisør, vaskeri, netcafé mv.",
      "presentText": "Privat service fx frisør, vaskeri, netcafé",
      "presentShortText": "Privat service fx frisør, vaskeri, netcafé",
      "description": " "
    },
    "339": {
      "title": "Anden enhed til serviceerhverv",
      "presentText": "Andet serviceerhverv",
      "presentShortText": "Andet serviceerhverv",
      "description": " "
    },
    "340": {
      "title": "(UDFASES) Pengeinstitut, forsikringsvirksomhed m.v.",
      "presentText": "Pengeinstitut, forsikringsvirksomhed mv.",
      "presentShortText": "Pengeinstitut, forsikringsvirksomhed mv.",
      "description": " "
    },
    "350": {
      "title": "(UDFASES) Kontor og liberale erhverv bortset fra offentlig administration  (kontorer for advokater, rådgivende ingeniører, klinikker o.lign.)",
      "presentText": "Kontor, liberale erhverv undtagen off. adm.",
      "presentShortText": "Kontor, liberale erhverv undtagen off. adm.",
      "description": " "
    },
    "360": {
      "title": "(UDFASES) Offentlig administration.",
      "presentText": "Offentlig administration",
      "presentShortText": "Offentlig administration",
      "description": " "
    },
    "370": {
      "title": "(UDFASES) Hotel, restauration, vaskeri, frisør og anden servicevirksomhed.",
      "presentText": "Hotel, restauration, vaskeri, frisør o.l",
      "presentShortText": "Hotel, restauration, vaskeri, frisør o.l",
      "description": " "
    },
    "390": {
      "title": "(UDFASES) Anden enhed til handel, transport etc.",
      "presentText": "Anden handel, transport etc.",
      "presentShortText": "Anden handel, transport etc.",
      "description": " "
    },
    "410": {
      "title": "(UDFASES) Biograf, teater, erhvervsmæssig udstilling m.v.",
      "presentText": "Biograf, teater, udstilling mv.",
      "presentShortText": "Biograf, teater, udstilling mv.",
      "description": " "
    },
    "411": {
      "title": "Biograf, teater, koncertsted mv.",
      "presentText": "Biograf, teater, koncertsted mv.",
      "presentShortText": "Biograf, teater, koncertsted mv.",
      "description": " "
    },
    "412": {
      "title": "Museum",
      "presentText": "Museum",
      "presentShortText": "Museum",
      "description": " "
    },
    "413": {
      "title": "Bibliotek",
      "presentText": "Bibliotek",
      "presentShortText": "Bibliotek",
      "description": " "
    },
    "414": {
      "title": "Kirke eller anden enhed til trosudøvelse for statsanerkendte trossamfund",
      "presentText": "Kirke el. anden enhed til trosudøvelse",
      "presentShortText": "Kirke el. anden enhed til trosudøvelse",
      "description": " "
    },
    "415": {
      "title": "Forsamlingshus",
      "presentText": "Forsamlingshus",
      "presentShortText": "Forsamlingshus",
      "description": " "
    },
    "416": {
      "title": "Forlystelsespark",
      "presentText": "Forlystelsespark",
      "presentShortText": "Forlystelsespark",
      "description": " "
    },
    "419": {
      "title": "Anden enhed til kulturelle formål",
      "presentText": "Andre kulturelle formål",
      "presentShortText": "Andre kulturelle formål",
      "description": " "
    },
    "420": {
      "title": "(UDFASES) Bibliotek, museum, kirke o. lign.",
      "presentText": "Bibliotek, museum, kirke o.l.",
      "presentShortText": "Bibliotek, museum, kirke o.l.",
      "description": " "
    },
    "421": {
      "title": "Grundskole",
      "presentText": "Grundskole",
      "presentShortText": "Grundskole",
      "description": " "
    },
    "422": {
      "title": "Universitet",
      "presentText": "Universitet",
      "presentShortText": "Universitet",
      "description": " "
    },
    "429": {
      "title": "Anden enhed til undervisning og forskning",
      "presentText": "Anden undervisning el. forskning",
      "presentShortText": "Anden undervisning el. forskning",
      "description": " "
    },
    "430": {
      "title": "(UDFASES) Undervisning og forskning (skole, gymnasium, forskningslaboratorium).",
      "presentText": "Undervisning og forskning",
      "presentShortText": "Undervisning og forskning",
      "description": " "
    },
    "431": {
      "title": "Hospital og sygehus",
      "presentText": "Hospital eller sygehus",
      "presentShortText": "Hospital eller sygehus",
      "description": " "
    },
    "432": {
      "title": "Hospice, behandlingshjem mv.",
      "presentText": "Hospice, behandlingshjem mv.",
      "presentShortText": "Hospice, behandlingshjem mv.",
      "description": " "
    },
    "433": {
      "title": "Sundhedscenter, lægehus, fødeklinik mv.",
      "presentText": "Sundhedscenter, lægehus, fødeklinik e.l.",
      "presentShortText": "Sundhedscenter, lægehus, fødeklinik e.l.",
      "description": " "
    },
    "439": {
      "title": "Anden enhed til sundhedsformål",
      "presentText": "Andet sundhedsformål",
      "presentShortText": "Andet sundhedsformål",
      "description": " "
    },
    "440": {
      "title": "(UDFASES) Hospital, fødeklinik o. lign.",
      "presentText": "Hospital, fødeklinik o.l.",
      "presentShortText": "Hospital, fødeklinik o.l.",
      "description": " "
    },
    "441": {
      "title": "Daginstitution",
      "presentText": "Daginstitution",
      "presentShortText": "Daginstitution",
      "description": " "
    },
    "442": {
      "title": "Servicefunktion på døgninstitution",
      "presentText": "Servicefunktion på døgninstitution",
      "presentShortText": "Servicefunktion på døgninstitution",
      "description": " "
    },
    "443": {
      "title": "Kaserne",
      "presentText": "Kaserne",
      "presentShortText": "Kaserne",
      "description": " "
    },
    "444": {
      "title": "Fængsel, arresthus mv.",
      "presentText": "Fængsel, arresthus mv.",
      "presentShortText": "Fængsel, arresthus mv.",
      "description": " "
    },
    "449": {
      "title": "Anden enhed til institutionsformål",
      "presentText": "Andre institutionsformål",
      "presentShortText": "Andre institutionsformål",
      "description": " "
    },
    "450": {
      "title": "(UDFASES) Daginstitution.",
      "presentText": "Daginstitution",
      "presentShortText": "Daginstitution",
      "description": " "
    },
    "490": {
      "title": "(UDFASES) Anden institution, herunder kaserne, fængsel m.v.",
      "presentText": "Anden institution",
      "presentShortText": "Anden institution",
      "description": " "
    },
    "510": {
      "title": "Sommerhus.",
      "presentText": "Sommerhus",
      "presentShortText": "Sommerhus",
      "description": " "
    },
    "520": {
      "title": "(UDFASES) Enhed til feriekoloni, vandrehjem o.lign. bortset fra sommerhus",
      "presentText": "Feriekoloni, vandrehjem e.l. ",
      "presentShortText": "Feriekoloni, vandrehjem e.l. ",
      "description": " "
    },
    "521": {
      "title": "Feriecenter, center til campingplads mv.",
      "presentText": "Feriecenter, center til campingplads e.l.",
      "presentShortText": "Feriecenter, center til campingplads e.l.",
      "description": " "
    },
    "522": {
      "title": "Ferielejlighed til erhvervsmæssig udlejning",
      "presentText": "Ferielejlighed t. udlejning",
      "presentShortText": "Ferielejlighed t. udlejning",
      "description": " "
    },
    "523": {
      "title": "Ferielejlighed til eget brug",
      "presentText": "Ferielejlighed til eget brug",
      "presentShortText": "Ferielejlighed til eget brug",
      "description": " "
    },
    "529": {
      "title": "Anden enhed til ferieformål",
      "presentText": "Anden enhed til ferieformål",
      "presentShortText": "Anden enhed til ferieformål",
      "description": " "
    },
    "530": {
      "title": "(UDFASES) Enhed i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.).",
      "presentText": "Idrætsudøvelse",
      "presentShortText": "Idrætsudøvelse",
      "description": " "
    },
    "531": {
      "title": "Klubhus i forbindelse med fritid- og idræt",
      "presentText": "Klubhus til fritid og idræt",
      "presentShortText": "Klubhus til fritid og idræt",
      "description": " "
    },
    "532": {
      "title": "Svømmehal",
      "presentText": "Svømmehal",
      "presentShortText": "Svømmehal",
      "description": " "
    },
    "533": {
      "title": "Idrætshal",
      "presentText": "Idrætshal",
      "presentShortText": "Idrætshal",
      "description": " "
    },
    "534": {
      "title": "Tribune i forbindelse med stadion",
      "presentText": "Tribune til stadion",
      "presentShortText": "Tribune til stadion",
      "description": " "
    },
    "535": {
      "title": "Rideskole",
      "presentText": "Rideskole",
      "presentShortText": "Rideskole",
      "description": " "
    },
    "539": {
      "title": "Anden enhed til idrætsformål",
      "presentText": "Anden bygning til idræt",
      "presentShortText": "Anden bygning til idræt",
      "description": " "
    },
    "540": {
      "title": "Kolonihavehus",
      "presentText": "Kolonihavehus",
      "presentShortText": "Kolonihavehus",
      "description": " "
    },
    "585": {
      "title": "Anneks i tilknytning til fritids- og sommerhus",
      "presentText": "Anneks til fritids- el. sommerhus",
      "presentShortText": "Anneks til fritids- el. sommerhus",
      "description": " "
    },
    "590": {
      "title": "Anden enhed til fritidsformål",
      "presentText": "Anden bygning til fritidsformål",
      "presentShortText": "Anden bygning til fritidsformål",
      "description": " "
    }
  },
  "EnhedHvorSkalEnhedVises": {
    "0": {
      "title": "Vis Enheder under Opgange",
      "presentText": "Vis Enheder under Opgange",
      "presentShortText": "Vis Enheder under Opgange",
      "description": " "
    },
    "1": {
      "title": "Vis Enheder under Etager",
      "presentText": "Vis Enheder under Etager",
      "presentShortText": "Vis Enheder under Etager",
      "description": " "
    },
    "2": {
      "title": "Vis Enheder under både Opgange og Etager",
      "presentText": "Vis Enheder under både Opgange og Etager",
      "presentShortText": "Vis Enheder under både Opgange og Etager",
      "description": " "
    }
  },
  "EnhedSortering": {
    "0": {
      "title": "Bygnings nummer",
      "presentText": "Bygningsnummer",
      "presentShortText": "Bygningsnummer",
      "description": " "
    },
    "1": {
      "title": "Vejkode",
      "presentText": "Vejkode",
      "presentShortText": "Vejkode",
      "description": " "
    },
    "2": {
      "title": "Vejnavn",
      "presentText": "Vejnavn",
      "presentShortText": "Vejnavn",
      "description": " "
    },
    "3": {
      "title": "Husnummer",
      "presentText": "Husnummer",
      "presentShortText": "Husnummer",
      "description": " "
    },
    "4": {
      "title": "Etage",
      "presentText": "Etage",
      "presentShortText": "Etage",
      "description": " "
    },
    "5": {
      "title": "Side/dør",
      "presentText": "Side/dør",
      "presentShortText": "Side/dør",
      "description": " "
    },
    "6": {
      "title": "Postnummer",
      "presentText": "Postnummer",
      "presentShortText": "Postnummer",
      "description": " "
    },
    "7": {
      "title": "Postdistrikt",
      "presentText": "Postdistrikt",
      "presentShortText": "Postdistrikt",
      "description": " "
    },
    "8": {
      "title": "Anvendelseskode (samt som klartekst i tooltip)",
      "presentText": "Anvendelseskode (samt som klartekst i tooltip)",
      "presentShortText": "Anvendelseskode (samt som klartekst i tooltip)",
      "description": " "
    },
    "10": {
      "title": "Sagsnummer",
      "presentText": "Sagsnummer",
      "presentShortText": "Sagsnummer",
      "description": " "
    }
  },
  "EnhSupplerendeVarme": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Varmepumpeanlæg",
      "presentText": "Varmepumpeanlæg",
      "presentShortText": "Varmepumpeanlæg",
      "description": " "
    },
    "2": {
      "title": "Ovne til fast brændsel (brændeovn o. lign.)",
      "presentText": "Ovne til fast brændsel (brændeovn o.l.)",
      "presentShortText": "Ovne til fast brændsel (brændeovn o.l.)",
      "description": " "
    },
    "3": {
      "title": "Ovne til flydende brændsel",
      "presentText": "Ovne til flydende brændsel",
      "presentShortText": "Ovne til flydende brændsel",
      "description": " "
    },
    "4": {
      "title": "Solpaneler",
      "presentText": "Solpaneler",
      "presentShortText": "Solpaneler",
      "description": " "
    },
    "5": {
      "title": "Pejs",
      "presentText": "Pejs",
      "presentShortText": "Pejs",
      "description": " "
    },
    "6": {
      "title": "Gasradiator",
      "presentText": "Gasradiator",
      "presentShortText": "Gasradiator",
      "description": " "
    },
    "7": {
      "title": "Elovne, elpaneler",
      "presentText": "Elovne, elpaneler",
      "presentShortText": "Elovne, elpaneler",
      "description": " "
    },
    "10": {
      "title": "Biogasanlæg",
      "presentText": "Biogasanlæg",
      "presentShortText": "Biogasanlæg",
      "description": " "
    },
    "80": {
      "title": "Andet",
      "presentText": "Andet",
      "presentShortText": "Andet",
      "description": " "
    },
    "90": {
      "title": "Bygningen har ingen supplerende varme",
      "presentText": "Bygningen har ingen supplerende varme",
      "presentShortText": "Bygningen har ingen supplerende varme",
      "description": " "
    }
  },
  "EnhVarmeinstallation": {
    "1": {
      "title": "Fjernvarme/blokvarme (radiatorsystemer el. varmluftanlæg)",
      "presentText": "Fjernvarme eller blokvarme",
      "presentShortText": "Fjernvarme eller blokvarme",
      "description": " "
    },
    "2": {
      "title": "Centralvarme fra eget anlæg, et-kammer fyr",
      "presentText": "Centralvarme fra eget anlæg",
      "presentShortText": "Centralvarme fra eget anlæg",
      "description": " "
    },
    "3": {
      "title": "Ovne (kakkelovne, kamin, brændeovne o.l.)",
      "presentText": "Ovne (kakkelovn, brændeovn o.l.)",
      "presentShortText": "Ovne (kakkelovn, brændeovn o.l.)",
      "description": " "
    },
    "5": {
      "title": "Varmepumpe",
      "presentText": "Varmepumpe",
      "presentShortText": "Varmepumpe",
      "description": " "
    },
    "6": {
      "title": "Centralvarme med to fyringsenheder (fast og olie eller gas)",
      "presentText": "Centralvarme med to enheder (fast og olie/gas)",
      "presentShortText": "Centralvarme med to enheder (fast og olie/gas)",
      "description": " "
    },
    "7": {
      "title": "Elovne, elpaneler",
      "presentText": "Elovne eller elpaneler",
      "presentShortText": "Elovne eller elpaneler",
      "description": " "
    },
    "8": {
      "title": "Gasradiator",
      "presentText": "Gasradiator",
      "presentShortText": "Gasradiator",
      "description": " "
    },
    "9": {
      "title": "Ingen varmeinstallation",
      "presentText": "Ingen varmeinstallation",
      "presentShortText": "Ingen varmeinstallation",
      "description": " "
    },
    "99": {
      "title": "Blandet (Kræver specifikation på enhedsniveau)",
      "presentText": "Blandet (angivet på enhedsniveau)",
      "presentShortText": "Blandet (angivet på enhedsniveau)",
      "description": " "
    }
  },
  "EtageSortering": {
    "0": {
      "title": "Etagebetegnelse",
      "presentText": "Etagebetegnelse",
      "presentShortText": "Etagebetegnelse",
      "description": " "
    }
  },
  "EtageType": {
    "0": {
      "title": "Ikke tagetage",
      "presentText": "Ikke tagetage",
      "presentShortText": "Ikke tagetage",
      "description": " "
    },
    "1": {
      "title": "Tagetage",
      "presentText": "Tagetage",
      "presentShortText": "Tagetage",
      "description": " "
    }
  },
  "Fordelingsnoegle": {
    "1": {
      "title": "Manuel fordeling",
      "presentText": "Manuel fordeling",
      "presentShortText": "Manuel fordeling",
      "description": " "
    },
    "2": {
      "title": "Ligelig fordeling",
      "presentText": "Ligelig fordeling",
      "presentShortText": "Ligelig fordeling",
      "description": " "
    },
    "3": {
      "title": "Institutions fordeling",
      "presentText": "Institutions fordeling",
      "presentShortText": "Institutions fordeling",
      "description": " "
    }
  },
  "ForretningsHaendelse": {
    "BUH": {
      "title": "Bygning uden Husnummer",
      "presentText": "Bygning uden Husnummer",
      "presentShortText": "Bygning uden Husnummer",
      "description": " "
    },
    "BYG": {
      "title": "Bygning",
      "presentText": "Bygning",
      "presentShortText": "Bygning",
      "description": " "
    },
    "ENH": {
      "title": "Enhed",
      "presentText": "Enhed",
      "presentShortText": "Enhed",
      "description": " "
    },
    "GRU": {
      "title": "Grund",
      "presentText": "Grund",
      "presentShortText": "Grund",
      "description": " "
    },
    "SAG": {
      "title": "BBR-sag",
      "presentText": "BBR-sag",
      "presentShortText": "BBR-sag",
      "description": " "
    },
    "TEK": {
      "title": "Teknisk Anlæg",
      "presentText": "Teknisk Anlæg",
      "presentShortText": "Teknisk Anlæg",
      "description": " "
    },
    "TUH": {
      "title": "Teknisk Anlæg uden Husnummer",
      "presentText": "Teknisk Anlæg uden Husnummer",
      "presentShortText": "Teknisk Anlæg uden Husnummer",
      "description": " "
    }
  },
  "ForretningsOmraade": {
    "BBR": {
      "title": "54.15.05.05",
      "presentText": "54.15.05.05",
      "presentShortText": "54.15.05.05",
      "description": " "
    }
  },
  "ForretningsProcess": {
    "0": {
      "title": "Ikke angivet",
      "presentText": "Ikke angivet",
      "presentShortText": "Ikke angivet",
      "description": " "
    },
    "1": {
      "title": "Oprettet grundet nybyggeri",
      "presentText": "Oprettet grundet nybyggeri",
      "presentShortText": "Oprettet grundet nybyggeri",
      "description": " "
    },
    "2": {
      "title": "Opdateret grundet til/ombygning",
      "presentText": "Opdateret grundet til- eller ombygning",
      "presentShortText": "Opdateret grundet til- eller ombygning",
      "description": " "
    },
    "3": {
      "title": "Opdateret grundet nedrivning",
      "presentText": "Opdateret grundet nedrivning",
      "presentShortText": "Opdateret grundet nedrivning",
      "description": " "
    },
    "4": {
      "title": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "presentText": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "presentShortText": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "description": " "
    },
    "5": {
      "title": "Faktisk udført ændring uden byggesagsbehandling",
      "presentText": "Faktisk udført ændring uden byggesagsbehandling",
      "presentShortText": "Faktisk udført ændring uden byggesagsbehandling",
      "description": " "
    },
    "6": {
      "title": "Opdeling af enheder",
      "presentText": "Opdeling af enheder",
      "presentShortText": "Opdeling af enheder",
      "description": " "
    },
    "7": {
      "title": "Sammenlægning af enheder",
      "presentText": "Sammenlægning af enheder",
      "presentShortText": "Sammenlægning af enheder",
      "description": " "
    },
    "8": {
      "title": "Opdateret som følge af digital indberetning fra borger mm.",
      "presentText": "Opdateret som følge af digital indberetning fra borger m.m.",
      "presentShortText": "Opdateret som følge af digital indberetning fra borger m.m.",
      "description": " "
    },
    "9": {
      "title": "Opdateret som følge af digital indberetning fra SKAT",
      "presentText": "Opdateret som følge af digital indberetning fra SKAT",
      "presentShortText": "Opdateret som følge af digital indberetning fra SKAT",
      "description": " "
    },
    "10": {
      "title": "Anmeldelsessag",
      "presentText": "Anmeldelsessag",
      "presentShortText": "Anmeldelsessag",
      "description": " "
    },
    "11": {
      "title": "Tilladelsessag",
      "presentText": "Tilladelsessag",
      "presentShortText": "Tilladelsessag",
      "description": " "
    },
    "12": {
      "title": "Opdateret grundet ændring i grunddataregister: Matriklen",
      "presentText": "Opdateret grundet ændring i Matriklen",
      "presentShortText": "Opdateret grundet ændring i Matriklen",
      "description": " "
    },
    "13": {
      "title": "Opdateret grundet ændring i grunddataregister: DAR",
      "presentText": "Opdateret grundet ændring i DAR",
      "presentShortText": "Opdateret grundet ændring i DAR",
      "description": " "
    },
    "14": {
      "title": "Opdateret grundet ændring i grunddataregister: Ejerfortegnelsen",
      "presentText": "Opdateret grundet ændring i Ejerfortegnelsen",
      "presentShortText": "Opdateret grundet ændring i Ejerfortegnelsen",
      "description": " "
    },
    "15": {
      "title": "Opdateret grundet ændring i grunddataregister: Ejendomsbeliggenhedsregisteret",
      "presentText": "Opdateret grundet ændring i Ejendomsbeliggenhedsreg.",
      "presentShortText": "Opdateret grundet ændring i Ejendomsbeliggenhedsreg.",
      "description": " "
    },
    "16": {
      "title": "Automatisk lukning af anmeldelsessag",
      "presentText": "Automatisk lukning af anmeldelsessag",
      "presentShortText": "Automatisk lukning af anmeldelsessag",
      "description": " "
    },
    "17": {
      "title": "Flytning af underliggende elementer på matrikel (Matrikulær ændring)",
      "presentText": "Flytning af underliggende elementer på matrikel",
      "presentShortText": "Flytning af underliggende elementer på matrikel",
      "description": " "
    },
    "18": {
      "title": "Fordelingsareal af fordelingsareal",
      "presentText": "Fordelingsareal af fordelingsareal",
      "presentShortText": "Fordelingsareal af fordelingsareal",
      "description": " "
    },
    "19": {
      "title": "Opdateret grundet ændret Sikkerhedsklassificering",
      "presentText": "Opdateret grundet ændret sikkerhedsklassificering",
      "presentShortText": "Opdateret grundet ændret sikkerhedsklassificering",
      "description": " "
    },
    "20": {
      "title": "Fremdatering af indflytning",
      "presentText": "Fremdatering af indflytning",
      "presentShortText": "Fremdatering af indflytning",
      "description": " "
    },
    "21": {
      "title": "Opdatering af indberetning",
      "presentText": "Opdatering af indberetning",
      "presentShortText": "Opdatering af indberetning",
      "description": " "
    },
    "22": {
      "title": "ESR Event Processering",
      "presentText": "ESR Event Processering",
      "presentShortText": "ESR Event Processering",
      "description": " "
    },
    "23": {
      "title": "AWS Event Processering",
      "presentText": "AWS Event Processering",
      "presentShortText": "AWS Event Processering",
      "description": " "
    },
    "24": {
      "title": "Indberetnings services",
      "presentText": "Indberetnings services",
      "presentShortText": "Indberetnings services",
      "description": " "
    },
    "25": {
      "title": "SKATServices",
      "presentText": "SKATServices",
      "presentShortText": "SKATServices",
      "description": ""
    },
    "26": {
      "title": "EnergiindberetningProcessering",
      "presentText": "EnergiindberetningProcessering",
      "presentShortText": "EnergiindberetningProcessering",
      "description": ""
    },
    "27": {
      "title": "EJDbATilknytningHusnummerService",
      "presentText": "EJDbATilknytningHusnummerService",
      "presentShortText": "EJDbATilknytningHusnummerService",
      "description": ""
    },
    "28": {
      "title": "BPFG Tilknyttet gennem Ajorføring hos MU",
      "presentText": "BPFG Tilknyttet gennem Ajorføring hos MU",
      "presentShortText": "BPFG Tilknyttet gennem Ajorføring hos MU",
      "description": ""
    }
  },
  "ForretningsProcessUI": {
    "4": {
      "title": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "presentText": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "presentShortText": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "description": " "
    },
    "5": {
      "title": "Faktisk udført ændring uden byggesagsbehandling",
      "presentText": "Faktisk udført ændring uden byggesagsbehandling",
      "presentShortText": "Faktisk udført ændring uden byggesagsbehandling",
      "description": " "
    }
  },
  "ForretningsProcessUIBygningEnhed": {
    "4": {
      "title": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "presentText": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "presentShortText": "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
      "description": " "
    },
    "5": {
      "title": "Faktisk udført ændring uden byggesagsbehandling",
      "presentText": "Faktisk udført ændring uden byggesagsbehandling",
      "presentShortText": "Faktisk udført ændring uden byggesagsbehandling",
      "description": " "
    },
    "6": {
      "title": "Opdeling af enheder",
      "presentText": "Opdeling af enheder",
      "presentShortText": "Opdeling af enheder",
      "description": " "
    },
    "7": {
      "title": "Sammenlægning af enheder",
      "presentText": "Sammenlægning af enheder",
      "presentShortText": "Sammenlægning af enheder",
      "description": " "
    }
  },
  "Fredning": {
    "1": {
      "title": "Bygningen fredet iht. bygningsfredningsloven",
      "presentText": "Bygningen er fredet iht. bygningsfredningslov",
      "presentShortText": "Bygningen er fredet iht. bygningsfredningslov",
      "description": " "
    },
    "2": {
      "title": "Som 1, men med tinglyste bevaringsbestemmelser jf. lovens §15",
      "presentText": "Bygning er fredet m. tinglyste bevaringsbestemmelser",
      "presentShortText": "Bygning er fredet m. tinglyste bevaringsbestemmelser",
      "description": " "
    },
    "3": {
      "title": "Tinglyst bevaringsdeklaration, men bygningen ikke fredet",
      "presentText": "Tinglyst bevaringsdeklaration, men bygning er ikke fredet",
      "presentShortText": "Tinglyst bevaringsdeklaration, men bygning er ikke fredet",
      "description": " "
    },
    "4": {
      "title": "På bygningens middelalderlige bygningsdele er der tinglyst fredningsbestemmelser",
      "presentText": "På bygningens middelalderlige bygningsdele er der tinglyst fredningsbestemmelser",
      "presentShortText": "På bygningens middelalderlige bygningsdele er der tinglyst fredningsbestemmelser",
      "description": " "
    },
    "5": {
      "title": "Bygningen indeholder middelalderlige bygningsdele",
      "presentText": "Bygningen indeholder middelalderlige bygningsdele",
      "presentShortText": "Bygningen indeholder middelalderlige bygningsdele",
      "description": " "
    },
    "6": {
      "title": "Bygningen og dens umiddelbare omgivelser fredet iht. bygningsfredningsloven",
      "presentText": "Bygningen og umiddelbare omgivelser er fredet iht. bygningsfredningsloven",
      "presentShortText": "Bygningen og umiddelbare omgivelser er fredet iht. bygningsfredningsloven",
      "description": " "
    },
    "7": {
      "title": "Som 6, men med tinglyst bevaringsdeklaration",
      "presentText": "Bygning og omgivelser er fredet og der er tinglyst bevaringsdeklaration",
      "presentShortText": "Bygning og omgivelser er fredet og der er tinglyst bevaringsdeklaration",
      "description": " "
    },
    "8": {
      "title": "Bygningen bevaringsværdig",
      "presentText": "Bygningen bevaringsværdig",
      "presentShortText": "Bygningen bevaringsværdig",
      "description": " "
    },
    "9": {
      "title": "Bygningen medtaget i registrant, bevaringsplan mm.",
      "presentText": "Bygningen medtaget i registrant, bevaringsplan e.l.",
      "presentShortText": "Bygningen medtaget i registrant, bevaringsplan e.l.",
      "description": " "
    }
  },
  "GodkendtTomBolig": {
    "0": {
      "title": "Krav om persontilmelding",
      "presentText": "Krav om persontilmelding",
      "presentShortText": "Krav om persontilmelding",
      "description": " "
    },
    "100": {
      "title": "Bolig uden krav om persontilmelding",
      "presentText": "Bolig uden krav om persontilmelding",
      "presentShortText": "Bolig uden krav om persontilmelding",
      "description": " "
    }
  },
  "GruAfloebsforhold": {
    "1": {
      "title": "Fælleskloakeret: spildevand + tag- og overfladevand",
      "presentText": "Fælleskloakeret: Spildevand, tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Spildevand, tag- og overfladevand",
      "description": " "
    },
    "2": {
      "title": "Fælleskloakeret: spildevand + delvis tag- og overfladevand",
      "presentText": "Fælleskloakeret: Spildev. med delvis tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Spildev. med delvis tag- og overfladevand",
      "description": " "
    },
    "3": {
      "title": "Fælleskloakeret: spildevand",
      "presentText": "Fælleskloakeret: Spildevand",
      "presentShortText": "Fælleskloakeret: Spildevand",
      "description": " "
    },
    "4": {
      "title": "Fælleskloakeret: tag- og overfladevand",
      "presentText": "Fælleskloakeret: Tag- og overfladevand",
      "presentShortText": "Fælleskloakeret: Tag- og overfladevand",
      "description": " "
    },
    "5": {
      "title": "Separatkloakeret: spildevand + tag- og overfladevand",
      "presentText": "Separatkloakeret: Spildevand hhv. tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Spildevand hhv. tag- og overfladevand",
      "description": " "
    },
    "6": {
      "title": "Separatkloakeret: spildevand + delvis tag- og overfladevand",
      "presentText": "Separatkloakeret: Spildevand hhv. delvis tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Spildevand hhv. delvis tag- og overfladevand",
      "description": " "
    },
    "7": {
      "title": "Separatkloakeret: spildevand",
      "presentText": "Separatkloakeret: Spildevand",
      "presentShortText": "Separatkloakeret: Spildevand",
      "description": " "
    },
    "8": {
      "title": "Separatkloakeret: tag- og overfladevand",
      "presentText": "Separatkloakeret: Tag- og overfladevand",
      "presentShortText": "Separatkloakeret: Tag- og overfladevand",
      "description": " "
    },
    "9": {
      "title": "Spildevandskloakeret: Spildevand",
      "presentText": "Spildevandskloakeret: Spildevand",
      "presentShortText": "Spildevandskloakeret: Spildevand",
      "description": " "
    },
    "10": {
      "title": "Afløb til spildevandsforsyningens renseanlæg",
      "presentText": "Afløb til spildevandsforsyningens renseanlæg",
      "presentShortText": "Afløb til spildevandsforsyningens renseanlæg",
      "description": " "
    },
    "11": {
      "title": "Afløb til fællesprivat spildevandsanlæg",
      "presentText": "Afløb til fællesprivat spildevandsanlæg",
      "presentShortText": "Afløb til fællesprivat spildevandsanlæg",
      "description": " "
    },
    "12": {
      "title": "Afløb til fællesprivat kloakledning med tilslutning til spv. renseanlæg",
      "presentText": "Afløb til fællesprivat kloakledning m. tilslut. t. spv. renseanlæg",
      "presentShortText": "Afløb til fællesprivat kloakledning m. tilslut. t. spv. renseanlæg",
      "description": " "
    },
    "20": {
      "title": "Afløb til samletank",
      "presentText": "Afløb til samletank",
      "presentShortText": "Afløb til samletank",
      "description": " "
    },
    "21": {
      "title": "Afløb til samletank for toiletvand og mek. rensning af øvr. spildevand",
      "presentText": "Afløb til samletank for toiletv. og mek. rensning af øvrigt spildevand",
      "presentShortText": "Afløb til samletank for toiletv. og mek. rensning af øvrigt spildevand",
      "description": " "
    },
    "29": {
      "title": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "presentText": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "presentShortText": "Mekanisk rensning med nedsivningsanlæg med tilladelse",
      "description": " "
    },
    "30": {
      "title": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "presentText": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "presentShortText": "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
      "description": " "
    },
    "31": {
      "title": "Mekanisk rensning med privat udledn. dir. til vandløb, sø eller hav",
      "presentText": "Mekanisk rensning m. privat udledning til vandløb, sø eller hav",
      "presentShortText": "Mekanisk rensning m. privat udledning til vandløb, sø eller hav",
      "description": " "
    },
    "32": {
      "title": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "presentText": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "presentShortText": "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
      "description": " "
    },
    "70": {
      "title": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "presentText": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "presentShortText": "Udledning uden rensning direkte til vandløb, søer eller havet",
      "description": " "
    },
    "75": {
      "title": "Blandet afløbsforhold på ejendommen (er specificeret på bygningen)",
      "presentText": "Blandet afløbsforhold på ejendommen (angivet på bygningen)",
      "presentShortText": "Blandet afløbsforhold på ejendommen (angivet på bygningen)",
      "description": " "
    },
    "80": {
      "title": "Anden type afløb",
      "presentText": "Anden type afløb",
      "presentShortText": "Anden type afløb",
      "description": " "
    },
    "90": {
      "title": "Ingen udledning",
      "presentText": "Ingen udledning",
      "presentShortText": "Ingen udledning",
      "description": " "
    },
    "101": {
      "title": "SOP: Minirenseanlæg med direkte udledning",
      "presentText": "SOP: Minirenseanlæg med direkte udledning",
      "presentShortText": "SOP: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "102": {
      "title": "SOP: Minirenseanlæg med udledning til markdræn",
      "presentText": "SOP: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "SOP: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "103": {
      "title": "SOP: Minirenseanlæg med nedsivning i faskine",
      "presentText": "SOP: Minirenseanlæg med nedsivning i faskine",
      "presentShortText": "SOP: Minirenseanlæg med nedsivning i faskine",
      "description": " "
    },
    "104": {
      "title": "SOP: Nedsivning til sivedræn",
      "presentText": "SOP: Nedsivning til sivedræn",
      "presentShortText": "SOP: Nedsivning til sivedræn",
      "description": " "
    },
    "105": {
      "title": "SOP: Samletank",
      "presentText": "SOP: Samletank",
      "presentShortText": "SOP: Samletank",
      "description": " "
    },
    "106": {
      "title": "SOP: Pileanlæg med nedsivning (uden membran)",
      "presentText": "SOP: Pileanlæg med nedsivning (uden membran)",
      "presentShortText": "SOP: Pileanlæg med nedsivning (uden membran)",
      "description": " "
    },
    "107": {
      "title": "SOP: Pileanlæg uden udledning (med membran)",
      "presentText": "SOP: Pileanlæg uden udledning (med membran)",
      "presentShortText": "SOP: Pileanlæg uden udledning (med membran)",
      "description": " "
    },
    "108": {
      "title": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "presentText": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "presentShortText": "SOP: Beplantede filteranlæg med nedsivning i faskine",
      "description": " "
    },
    "109": {
      "title": "SOP: Sandfiltre med P-fældning i bundfældningstanken og direkte udledning",
      "presentText": "SOP: Sandfiltre m. P-fældning i tank og direkte udledning",
      "presentShortText": "SOP: Sandfiltre m. P-fældning i tank og direkte udledning",
      "description": " "
    },
    "110": {
      "title": "SOP: Sandfiltre med P-fældning i bundfældningstanken og udledning til markdræn",
      "presentText": "SOP: Sandfiltre m. P-fældning i tank og udledning til dræn",
      "presentShortText": "SOP: Sandfiltre m. P-fældning i tank og udledning til dræn",
      "description": " "
    },
    "190": {
      "title": "SOP: Andet",
      "presentText": "SOP: Andet",
      "presentShortText": "SOP: Andet",
      "description": " "
    },
    "201": {
      "title": "SO: Biologisk sandfilter med direkte udledning",
      "presentText": "SO: Biologisk sandfilter med direkte udledning",
      "presentShortText": "SO: Biologisk sandfilter med direkte udledning",
      "description": " "
    },
    "202": {
      "title": "SO: Biologisk sandfilter med udledning til markdræn",
      "presentText": "SO: Biologisk sandfilter med udledning til markdræn",
      "presentShortText": "SO: Biologisk sandfilter med udledning til markdræn",
      "description": " "
    },
    "203": {
      "title": "SO: Minirensanlæg med direkte udledning",
      "presentText": "SO: Minirensanlæg med direkte udledning",
      "presentShortText": "SO: Minirensanlæg med direkte udledning",
      "description": " "
    },
    "204": {
      "title": "SO: Minirenseanlæg med udledning til markdræn",
      "presentText": "SO: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "SO: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "205": {
      "title": "SO: Beplantede filteranlæg med direkte udledning",
      "presentText": "SO: Beplantede filteranlæg med direkte udledning",
      "presentShortText": "SO: Beplantede filteranlæg med direkte udledning",
      "description": " "
    },
    "206": {
      "title": "SO: Beplantede filteranlæg med udledning til markdræn",
      "presentText": "SO: Beplantede filteranlæg med udledning til markdræn",
      "presentShortText": "SO: Beplantede filteranlæg med udledning til markdræn",
      "description": " "
    },
    "290": {
      "title": "SO: Andet",
      "presentText": "SO: Andet",
      "presentShortText": "SO: Andet",
      "description": " "
    },
    "301": {
      "title": "OP: Minirenseanlæg med direkte udledning",
      "presentText": "OP: Minirenseanlæg med direkte udledning",
      "presentShortText": "OP: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "302": {
      "title": "OP: Minirenseanlæg med udledning til markdræn",
      "presentText": "OP: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "OP: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "390": {
      "title": "OP: Andet",
      "presentText": "OP: Andet",
      "presentShortText": "OP: Andet",
      "description": " "
    },
    "401": {
      "title": "O: Rodzoneanlæg med direkte udledning",
      "presentText": "O: Rodzoneanlæg med direkte udledning",
      "presentShortText": "O: Rodzoneanlæg med direkte udledning",
      "description": " "
    },
    "402": {
      "title": "O: Rodzoneanlæg med udledning til markdræn",
      "presentText": "O: Rodzoneanlæg med udledning til markdræn",
      "presentShortText": "O: Rodzoneanlæg med udledning til markdræn",
      "description": " "
    },
    "403": {
      "title": "O: Minirenseanlæg med direkte udledning",
      "presentText": "O: Minirenseanlæg med direkte udledning",
      "presentShortText": "O: Minirenseanlæg med direkte udledning",
      "description": " "
    },
    "404": {
      "title": "O: Minirenseanlæg med udledning til markdræn",
      "presentText": "O: Minirenseanlæg med udledning til markdræn",
      "presentShortText": "O: Minirenseanlæg med udledning til markdræn",
      "description": " "
    },
    "490": {
      "title": "O: Andet",
      "presentText": "O: Andet",
      "presentShortText": "O: Andet",
      "description": " "
    },
    "501": {
      "title": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "presentText": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "presentShortText": "Øvrige renseløsninger: Mekanisk med direkte udledning",
      "description": " "
    },
    "502": {
      "title": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "presentText": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "presentShortText": "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
      "description": " "
    },
    "503": {
      "title": "Øvrige renseløsninger: Ældre nedsivningsanlæg med nedsivning til sivebrønd",
      "presentText": "Øvrige renseløsninger: Ældre nedsivningsanlæg med sivebrønd",
      "presentShortText": "Øvrige renseløsninger: Ældre nedsivningsanlæg med sivebrønd",
      "description": " "
    },
    "504": {
      "title": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "presentText": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "presentShortText": "Øvrige renseløsninger: Udledning til jordoverfladen",
      "description": " "
    },
    "505": {
      "title": "Øvrige renseløsninger: Urenset",
      "presentText": "Øvrige renseløsninger: Urenset",
      "presentShortText": "Øvrige renseløsninger: Urenset",
      "description": " "
    },
    "590": {
      "title": "Øvrige renseløsninger: Andet",
      "presentText": "Øvrige renseløsninger: Andet",
      "presentShortText": "Øvrige renseløsninger: Andet",
      "description": " "
    },
    "601": {
      "title": "Anden type afløb (større end 30 PE med egen udledning)",
      "presentText": "Anden type afløb: Større end 30 PE med egen udledning",
      "presentShortText": "Anden type afløb: Større end 30 PE med egen udledning",
      "description": " "
    },
    "701": {
      "title": "Intet afløb",
      "presentText": "Intet afløb",
      "presentShortText": "Intet afløb",
      "description": " "
    }
  },
  "GrundSortering": {
    "1": {
      "title": "Vejkode",
      "presentText": "Vejkode",
      "presentShortText": "Vejkode",
      "description": " "
    },
    "2": {
      "title": "Vejnavn",
      "presentText": "Vejnavn",
      "presentShortText": "Vejnavn",
      "description": " "
    },
    "3": {
      "title": "Husnummer",
      "presentText": "Husnummer",
      "presentShortText": "Husnummer",
      "description": " "
    },
    "4": {
      "title": "Postnummer",
      "presentText": "Postnummer",
      "presentShortText": "Postnummer",
      "description": " "
    },
    "5": {
      "title": "Postdistrikt",
      "presentText": "Postdistrikt",
      "presentShortText": "Postdistrikt",
      "description": " "
    },
    "6": {
      "title": "Ejerforholdskode (samt som klartekst i tooltip)",
      "presentText": "Ejerforholdskode (samt som klartekst i tooltip)",
      "presentShortText": "Ejerforholdskode (samt som klartekst i tooltip)",
      "description": " "
    },
    "7": {
      "title": "Sagsnummer",
      "presentText": "Sagsnummer",
      "presentShortText": "Sagsnummer",
      "description": " "
    }
  },
  "GrundViewType": {
    "0": {
      "title": "Stamdata",
      "presentText": "Stamdata",
      "presentShortText": "Stamdata",
      "description": " "
    },
    "1": {
      "title": "Grundoplysninger",
      "presentText": "Grundoplysninger",
      "presentShortText": "Grundoplysninger",
      "description": " "
    }
  },
  "GruVandforsyning": {
    "1": {
      "title": "Alment vandforsyningsanlæg (tidligere offentligt)",
      "presentText": "Alment vandforsyningsanlæg",
      "presentShortText": "Alment vandforsyningsanlæg",
      "description": " "
    },
    "2": {
      "title": "Privat, alment vandforsyningsanlæg",
      "presentText": "Privat, alment vandforsyningsanlæg",
      "presentShortText": "Privat, alment vandforsyningsanlæg",
      "description": " "
    },
    "3": {
      "title": "Enkeltindvindingsanlæg (egen boring til 1 eller 2 ejendomme)",
      "presentText": "Enkeltindvindingsanlæg (egen boring, 1-2 ejd.)",
      "presentShortText": "Enkeltindvindingsanlæg (egen boring, 1-2 ejd.)",
      "description": " "
    },
    "4": {
      "title": "Brønd",
      "presentText": "Brønd",
      "presentShortText": "Brønd",
      "description": " "
    },
    "6": {
      "title": "Ikke alment vandforsyningsanlæg (forsyner < 10 ejendomme)",
      "presentText": "Ikke alment vandforsyningsanlæg (< 10 ejd.)",
      "presentShortText": "Ikke alment vandforsyningsanlæg (< 10 ejd.)",
      "description": " "
    },
    "7": {
      "title": "Blandet vandforsyning",
      "presentText": "Blandet vandforsyning",
      "presentShortText": "Blandet vandforsyning",
      "description": " "
    },
    "9": {
      "title": "Ingen vandforsyning",
      "presentText": "Ingen vandforsyning",
      "presentShortText": "Ingen vandforsyning",
      "description": " "
    }
  },
  "Gulvbelaegning": {
    "1": {
      "title": "Beton",
      "presentText": "Beton",
      "presentShortText": "Beton",
      "description": " "
    },
    "2": {
      "title": "Andet",
      "presentText": "Andet",
      "presentShortText": "Andet",
      "description": " "
    },
    "3": {
      "title": "Ingen",
      "presentText": "Ingen",
      "presentShortText": "Ingen",
      "description": " "
    }
  },
  "HenvendelserDirekteIndberetning": {
    "0": {
      "title": "Ingen",
      "presentText": "Ingen",
      "presentShortText": "Ingen",
      "description": " "
    },
    "1": {
      "title": "Få",
      "presentText": "Få",
      "presentShortText": "Få",
      "description": " "
    },
    "2": {
      "title": "Mange",
      "presentText": "Mange",
      "presentShortText": "Mange",
      "description": " "
    }
  },
  "HusnummerRolle": {
    "0": {
      "title": "Fastsat til denne",
      "presentText": "Fastsat til denne",
      "presentShortText": "Fastsat til denne",
      "description": " "
    },
    "1": {
      "title": "Kun vejledende",
      "presentText": "Kun vejledende",
      "presentShortText": "Kun vejledende",
      "description": " "
    }
  },
  "HusnummerType": {
    "2": {
      "title": "Lige husnr.",
      "presentText": "Lige husnr.",
      "presentShortText": "Lige husnr.",
      "description": " "
    },
    "3": {
      "title": "Ulige husnr.",
      "presentText": "Ulige husnr.",
      "presentShortText": "Ulige husnr.",
      "description": " "
    }
  },
  "IndberetningRolle": {
    "0": {
      "title": "Alle",
      "presentText": "Alle",
      "presentShortText": "Alle",
      "description": ""
    },
    "1": {
      "title": "BBR Erhvervsservice SKAT",
      "presentText": "BBR Erhvervsservice SKAT",
      "presentShortText": "BBR Erhvervsservice SKAT",
      "description": ""
    },
    "2": {
      "title": "Ejer",
      "presentText": "Ejer",
      "presentShortText": "Ejer",
      "description": ""
    },
    "3": {
      "title": "Repræsentant for ejer",
      "presentText": "Repræsentant for ejer",
      "presentShortText": "Repræsentant for ejer",
      "description": ""
    },
    "4": {
      "title": "Lejer",
      "presentText": "Lejer",
      "presentShortText": "Lejer",
      "description": ""
    },
    "5": {
      "title": "Administrator",
      "presentText": "Administrator",
      "presentShortText": "Administrator",
      "description": ""
    },
    "6": {
      "title": "Callcenter medarbejder",
      "presentText": "Callcenter medarbejder",
      "presentShortText": "Callcenter medarbejder",
      "description": ""
    },
    "7": {
      "title": "Andet",
      "presentText": "Andet",
      "presentShortText": "Andet",
      "description": ""
    },
    "8": {
      "title": "Landinspektør",
      "presentText": "Landinspektør",
      "presentShortText": "Landinspektør",
      "description": ""
    }
  },
  "Indhold": {
    "10": {
      "title": "Mineralske olieprodukter (Olietankbekendtgørelsens §6, stk. 1 nr. 13)",
      "presentText": "Mineralske olieprodukter (Olietankbek. §6, stk. 1 nr. 13)",
      "presentShortText": "Mineralske olieprodukter (Olietankbek. §6, stk. 1 nr. 13)",
      "description": " "
    },
    "11": {
      "title": "Fuelolie (”tung fuelolie” - kræver opvarmning)",
      "presentText": "Fuelolie (”tung fuelolie” - kræver opvarmning)",
      "presentShortText": "Fuelolie (”tung fuelolie” - kræver opvarmning)",
      "description": " "
    },
    "12": {
      "title": "Fyringsgasolie",
      "presentText": "Fyringsgasolie",
      "presentShortText": "Fyringsgasolie",
      "description": " "
    },
    "13": {
      "title": "Autogasolie (Dieselolie)",
      "presentText": "Autogasolie (Dieselolie)",
      "presentShortText": "Autogasolie (Dieselolie)",
      "description": " "
    },
    "14": {
      "title": "Benzin",
      "presentText": "Benzin",
      "presentShortText": "Benzin",
      "description": " "
    },
    "20": {
      "title": "Biobrændstoffer (Organiske olieprodukter som f.eks. rapsolie, bioethanol m.v.)",
      "presentText": "Biobrændstoffer (Organiske olieprod. fx rapsolie, bioethanol)",
      "presentShortText": "Biobrændstoffer (Organiske olieprod. fx rapsolie, bioethanol)",
      "description": " "
    },
    "30": {
      "title": "Affaldsprodukter",
      "presentText": "Affaldsprodukter",
      "presentShortText": "Affaldsprodukter",
      "description": " "
    },
    "31": {
      "title": "Oliebaserede affaldsprodukter (Spildolie)",
      "presentText": "Oliebaserede affaldsprodukter (Spildolie)",
      "presentShortText": "Oliebaserede affaldsprodukter (Spildolie)",
      "description": " "
    },
    "40": {
      "title": "Gylle",
      "presentText": "Gylle",
      "presentShortText": "Gylle",
      "description": " "
    },
    "50": {
      "title": "Ajle, ensilagesaft, mælkerumsvand eller møddingvand",
      "presentText": "Ajle, ensilagesaft, mælkerumsvand eller møddingvand",
      "presentShortText": "Ajle, ensilagesaft, mælkerumsvand eller møddingvand",
      "description": " "
    },
    "60": {
      "title": "Øvrige stoffer, produkter og materialer der kan forurene grundvand, jord og undergrund (§ 19)",
      "presentText": "Øvrige stoffer, produkter og materialer der kan forurene grundvand, jord og undergrund (§ 19)",
      "presentShortText": "Øvrige stoffer, produkter og materialer der kan forurene grundvand, jord og undergrund (§ 19)",
      "description": " "
    },
    "70": {
      "title": "Korn",
      "presentText": "Korn",
      "presentShortText": "Korn",
      "description": " "
    },
    "99": {
      "title": "Andet (f.eks. foderstoffer m.v)",
      "presentText": "Andet (f.eks. foderstoffer m.v)",
      "presentShortText": "Andet (f.eks. foderstoffer m.v)",
      "description": " "
    }
  },
  "Kilde": {
    "0": {
      "title": "Alle",
      "presentText": "Alle",
      "presentShortText": "Alle",
      "description": " "
    },
    "1": {
      "title": "Ret BBR",
      "presentText": "Ret BBR",
      "presentShortText": "Ret BBR",
      "description": " "
    }
  },
  "KildeTilKoordinatsaet": {
    "E": {
      "title": "Ejer",
      "presentText": "Ejer",
      "presentShortText": "Ejer",
      "description": " "
    },
    "K": {
      "title": "Kommune",
      "presentText": "Kommune",
      "presentShortText": "Kommune",
      "description": " "
    },
    "L": {
      "title": "Landinspektør",
      "presentText": "Landinspektør",
      "presentShortText": "Landinspektør",
      "description": " "
    },
    "M": {
      "title": "Maskinelt dannet",
      "presentText": "Maskinelt dannet",
      "presentShortText": "Maskinelt dannet",
      "description": " "
    }
  },
  "KildeTilOplysninger": {
    "1": {
      "title": "Oplyst af ejer (eller dennes repræsentant)",
      "presentText": "Oplyst af ejer eller dennes repræsentant",
      "presentShortText": "Oplyst af ejer eller dennes repræsentant",
      "description": " "
    },
    "2": {
      "title": "Oplyst af teknisk forvaltning",
      "presentText": "Oplyst af teknisk forvaltning",
      "presentShortText": "Oplyst af teknisk forvaltning",
      "description": " "
    },
    "3": {
      "title": "Oplyst af andre (lukket for indberetning)",
      "presentText": "Oplyst af andre (lukket for indberetning)",
      "presentShortText": "Oplyst af andre (lukket for indberetning)",
      "description": " "
    },
    "4": {
      "title": "Maskinelt oprettet",
      "presentText": "Maskinelt oprettet",
      "presentShortText": "Maskinelt oprettet",
      "description": " "
    },
    "5": {
      "title": "Oplyst og kontrolleret af teknisk forvaltning",
      "presentText": "Oplyst og kontrolleret af teknisk forvaltning",
      "presentShortText": "Oplyst og kontrolleret af teknisk forvaltning",
      "description": " "
    }
  },
  "Klassifikation": {
    "1110": {
      "title": "Tank (Produkt på væskeform)",
      "presentText": "Tank (Til produkt på væskeform)",
      "presentShortText": "Tank (Til produkt på væskeform)",
      "description": " "
    },
    "1120": {
      "title": "Silo (Produkt på fast form)",
      "presentText": "Silo (il produkt på fast form)",
      "presentShortText": "Silo (il produkt på fast form)",
      "description": " "
    },
    "1130": {
      "title": "Gasbeholder (Produkt på gasform)",
      "presentText": "Gasbeholder (Til produkt på gasform)",
      "presentShortText": "Gasbeholder (Til produkt på gasform)",
      "description": " "
    },
    "1140": {
      "title": "Affaldsbeholder",
      "presentText": "Affaldsbeholder",
      "presentShortText": "Affaldsbeholder",
      "description": " "
    },
    "1210": {
      "title": "Vindmølle (elproducerende)",
      "presentText": "Vindmølle (elproducerende)",
      "presentShortText": "Vindmølle (elproducerende)",
      "description": " "
    },
    "1220": {
      "title": "Slanger til jordvarme",
      "presentText": "Slanger til jordvarme",
      "presentShortText": "Slanger til jordvarme",
      "description": " "
    },
    "1230": {
      "title": "Solvarme-/ solcelleanlæg",
      "presentText": "Solvarme-/ solcelleanlæg",
      "presentShortText": "Solvarme-/ solcelleanlæg",
      "description": " "
    },
    "1240": {
      "title": "Nødstrømsforsyningsanlæg",
      "presentText": "Nødstrømsforsyningsanlæg",
      "presentShortText": "Nødstrømsforsyningsanlæg",
      "description": " "
    },
    "1250": {
      "title": "Transformerstation",
      "presentText": "Transformerstation",
      "presentShortText": "Transformerstation",
      "description": " "
    },
    "1260": {
      "title": "Elskab",
      "presentText": "Elskab",
      "presentShortText": "Elskab",
      "description": " "
    },
    "1265": {
      "title": "Naturgasfyr",
      "presentText": "Naturgasfyr",
      "presentShortText": "Naturgasfyr",
      "description": " "
    },
    "1270": {
      "title": "Andet energiproducerende eller - distribuerende anlæg",
      "presentText": "Andet energianlæg",
      "presentShortText": "Andet energianlæg",
      "description": " "
    },
    "1275": {
      "title": "Halmfyr",
      "presentText": "Halmfyr",
      "presentShortText": "Halmfyr",
      "description": " "
    },
    "1280": {
      "title": "Biogasanlæg",
      "presentText": "Biogasanlæg",
      "presentShortText": "Biogasanlæg",
      "description": " "
    },
    "1310": {
      "title": "Vandtårn",
      "presentText": "Vandtårn",
      "presentShortText": "Vandtårn",
      "description": " "
    },
    "1320": {
      "title": "Pumpestation",
      "presentText": "Pumpestation",
      "presentShortText": "Pumpestation",
      "description": " "
    },
    "1330": {
      "title": "Swimmingpool",
      "presentText": "Swimmingpool",
      "presentShortText": "Swimmingpool",
      "description": " "
    },
    "1340": {
      "title": "Private rensningsanlæg f.eks. pileanlæg, nedsivningsanlæg",
      "presentText": "Private rensningsanlæg fx pileanlæg, nedsivning",
      "presentShortText": "Private rensningsanlæg fx pileanlæg, nedsivning",
      "description": " "
    },
    "1350": {
      "title": "Offentlige rensningsanlæg",
      "presentText": "Offentlige rensningsanlæg",
      "presentShortText": "Offentlige rensningsanlæg",
      "description": " "
    },
    "1360": {
      "title": "Regnvandsanlæg",
      "presentText": "Regnvandsanlæg",
      "presentShortText": "Regnvandsanlæg",
      "description": " "
    },
    "1905": {
      "title": "Legeplads",
      "presentText": "Legeplads",
      "presentShortText": "Legeplads",
      "description": " "
    },
    "1910": {
      "title": "Teknikhus",
      "presentText": "Teknikhus",
      "presentShortText": "Teknikhus",
      "description": " "
    },
    "1915": {
      "title": "Døgnpostboks",
      "presentText": "Døgnpostboks",
      "presentShortText": "Døgnpostboks",
      "description": " "
    },
    "1920": {
      "title": "Køleanlæg (herunder aircondition)",
      "presentText": "Køleanlæg (herunder aircondition)",
      "presentShortText": "Køleanlæg (herunder aircondition)",
      "description": " "
    },
    "1925": {
      "title": "Kunstværk (springvand, mindesmærker m.v.)",
      "presentText": "Kunstværk (springvand, mindesmærker mv.)",
      "presentShortText": "Kunstværk (springvand, mindesmærker mv.)",
      "description": " "
    },
    "1930": {
      "title": "Sirene / mast med sirene",
      "presentText": "Sirene / mast med sirene",
      "presentShortText": "Sirene / mast med sirene",
      "description": " "
    },
    "1935": {
      "title": "Skilt",
      "presentText": "Skilt",
      "presentShortText": "Skilt",
      "description": " "
    },
    "1940": {
      "title": "Antenne / mast fx tv, radio- og telekommunikation",
      "presentText": "Antenne el. mast fx tv, radio- og telekommunikation",
      "presentShortText": "Antenne el. mast fx tv, radio- og telekommunikation",
      "description": " "
    },
    "1945": {
      "title": "Dambrug",
      "presentText": "Dambrug",
      "presentShortText": "Dambrug",
      "description": " "
    },
    "1950": {
      "title": "Møddingsanlæg",
      "presentText": "Møddingsanlæg",
      "presentShortText": "Møddingsanlæg",
      "description": " "
    },
    "1955": {
      "title": "Andet teknisk anlæg",
      "presentText": "Andet teknisk anlæg",
      "presentShortText": "Andet teknisk anlæg",
      "description": " "
    },
    "1960": {
      "title": "Ensilageanlæg",
      "presentText": "Ensilageanlæg",
      "presentShortText": "Ensilageanlæg",
      "description": " "
    },
    "1965": {
      "title": "Planlager",
      "presentText": "Planlager",
      "presentShortText": "Planlager",
      "description": " "
    },
    "1970": {
      "title": "Fortidsminde, historisk ruin",
      "presentText": "Fortidsminde, historisk ruin",
      "presentShortText": "Fortidsminde, historisk ruin",
      "description": " "
    }
  },
  "KodeForMereEndEnLejlighed": {
    "E": {
      "title": "En enhed",
      "presentText": "En enhed",
      "presentShortText": "En enhed",
      "description": " "
    },
    "M": {
      "title": "Mere end 1 enhed",
      "presentText": "Mere end 1 enhed",
      "presentShortText": "Mere end 1 enhed",
      "description": " "
    }
  },
  "Koekkenforhold": {
    "E": {
      "title": "Eget køkken (med afløb og kogeinstallation)",
      "presentText": "Eget køkken (med afløb og kogeinstallation)",
      "presentShortText": "Eget køkken (med afløb og kogeinstallation)",
      "description": " "
    },
    "F": {
      "title": "Adgang til fælles køkken",
      "presentText": "Adgang til fælles køkken",
      "presentShortText": "Adgang til fælles køkken",
      "description": " "
    },
    "G": {
      "title": "Fast kogeinstallation i værelse eller på gang",
      "presentText": "Fast kogeinstallation i værelse eller på gang",
      "presentShortText": "Fast kogeinstallation i værelse eller på gang",
      "description": " "
    },
    "H": {
      "title": "Ingen fast kogeinstallation",
      "presentText": "Ingen fast kogeinstallation",
      "presentShortText": "Ingen fast kogeinstallation",
      "description": " "
    }
  },
  "KommuneFelterNiveau": {
    "0": {
      "title": "Grund",
      "presentText": "Grund",
      "presentShortText": "Grund",
      "description": " "
    },
    "1": {
      "title": "Bygning",
      "presentText": "Bygning",
      "presentShortText": "Bygning",
      "description": " "
    },
    "2": {
      "title": "Opgang",
      "presentText": "Opgang",
      "presentShortText": "Opgang",
      "description": " "
    },
    "3": {
      "title": "Etage",
      "presentText": "Etage",
      "presentShortText": "Etage",
      "description": " "
    },
    "4": {
      "title": "Enhed",
      "presentText": "Enhed",
      "presentShortText": "Enhed",
      "description": " "
    },
    "5": {
      "title": "Teknisk Anlæg",
      "presentText": "Teknisk Anlæg",
      "presentShortText": "Teknisk Anlæg",
      "description": " "
    },
    "6": {
      "title": "Fordelingsareal",
      "presentText": "Fordelingsareal",
      "presentShortText": "Fordelingsareal",
      "description": " "
    }
  },
  "Kommunekode": {
    "0101": {
      "title": "Københavns Kommune",
      "presentText": "Københavns Kommune",
      "presentShortText": "København",
      "description": ""
    },
    "0147": {
      "title": "Frederiksberg Kommune",
      "presentText": "Frederiksberg Kommune",
      "presentShortText": "Frederiksberg",
      "description": ""
    },
    "0151": {
      "title": "Ballerup Kommune",
      "presentText": "Ballerup Kommune",
      "presentShortText": "Ballerup",
      "description": ""
    },
    "0153": {
      "title": "Brøndby Kommune",
      "presentText": "Brøndby Kommune",
      "presentShortText": "Brøndby",
      "description": ""
    },
    "0155": {
      "title": "Dragør Kommune",
      "presentText": "Dragør Kommune",
      "presentShortText": "Dragør",
      "description": ""
    },
    "0157": {
      "title": "Gentofte Kommune",
      "presentText": "Gentofte Kommune",
      "presentShortText": "Gentofte",
      "description": ""
    },
    "0159": {
      "title": "Gladsaxe Kommune",
      "presentText": "Gladsaxe Kommune",
      "presentShortText": "Gladsaxe",
      "description": ""
    },
    "0161": {
      "title": "Glostrup Kommune",
      "presentText": "Glostrup Kommune",
      "presentShortText": "Glostrup",
      "description": ""
    },
    "0163": {
      "title": "Herlev Kommune",
      "presentText": "Herlev Kommune",
      "presentShortText": "Herlev",
      "description": ""
    },
    "0165": {
      "title": "Albertslund Kommune",
      "presentText": "Albertslund Kommune",
      "presentShortText": "Albertslund",
      "description": ""
    },
    "0167": {
      "title": "Hvidovre Kommune",
      "presentText": "Hvidovre Kommune",
      "presentShortText": "Hvidovre",
      "description": ""
    },
    "0169": {
      "title": "Høje Taastrup Kommune",
      "presentText": "Høje Taastrup Kommune",
      "presentShortText": "Høje Taastrup",
      "description": ""
    },
    "0173": {
      "title": "Lyngby-Taarbæk Kommune",
      "presentText": "Lyngby-Taarbæk Kommune",
      "presentShortText": "Lyngby-Taarbæk",
      "description": ""
    },
    "0175": {
      "title": "Rødovre Kommune",
      "presentText": "Rødovre Kommune",
      "presentShortText": "Rødovre",
      "description": ""
    },
    "0183": {
      "title": "Ishøj Kommune",
      "presentText": "Ishøj Kommune",
      "presentShortText": "Ishøj",
      "description": ""
    },
    "0185": {
      "title": "Tårnby Kommune",
      "presentText": "Tårnby Kommune",
      "presentShortText": "Tårnby",
      "description": ""
    },
    "0187": {
      "title": "Vallensbæk Kommune",
      "presentText": "Vallensbæk Kommune",
      "presentShortText": "Vallensbæk",
      "description": ""
    },
    "0190": {
      "title": "Furesø Kommune",
      "presentText": "Furesø Kommune",
      "presentShortText": "Furesø",
      "description": ""
    },
    "0201": {
      "title": "Allerød Kommune",
      "presentText": "Allerød Kommune",
      "presentShortText": "Allerød",
      "description": ""
    },
    "0210": {
      "title": "Fredensborg Kommune",
      "presentText": "Fredensborg Kommune",
      "presentShortText": "Fredensborg",
      "description": ""
    },
    "0217": {
      "title": "Helsingør Kommune",
      "presentText": "Helsingør Kommune",
      "presentShortText": "Helsingør",
      "description": ""
    },
    "0219": {
      "title": "Hillerød Kommune",
      "presentText": "Hillerød Kommune",
      "presentShortText": "Hillerød",
      "description": ""
    },
    "0223": {
      "title": "Hørsholm Kommune",
      "presentText": "Hørsholm Kommune",
      "presentShortText": "Hørsholm",
      "description": ""
    },
    "0230": {
      "title": "Rudersdal Kommune",
      "presentText": "Rudersdal Kommune",
      "presentShortText": "Rudersdal",
      "description": ""
    },
    "0240": {
      "title": "Egedal Kommune",
      "presentText": "Egedal Kommune",
      "presentShortText": "Egedal",
      "description": ""
    },
    "0250": {
      "title": "Frederikssund Kommune",
      "presentText": "Frederikssund Kommune",
      "presentShortText": "Frederikssund",
      "description": ""
    },
    "0253": {
      "title": "Greve Kommune",
      "presentText": "Greve Kommune",
      "presentShortText": "Greve",
      "description": ""
    },
    "0259": {
      "title": "Køge Kommune",
      "presentText": "Køge Kommune",
      "presentShortText": "Køge",
      "description": ""
    },
    "0260": {
      "title": "Halsnæs Kommune",
      "presentText": "Halsnæs Kommune",
      "presentShortText": "Halsnæs",
      "description": ""
    },
    "0265": {
      "title": "Roskilde Kommune",
      "presentText": "Roskilde Kommune",
      "presentShortText": "Roskilde",
      "description": ""
    },
    "0269": {
      "title": "Solrød Kommune",
      "presentText": "Solrød Kommune",
      "presentShortText": "Solrød",
      "description": ""
    },
    "0270": {
      "title": "Gribskov Kommune",
      "presentText": "Gribskov Kommune",
      "presentShortText": "Gribskov",
      "description": ""
    },
    "0306": {
      "title": "Odsherred Kommune",
      "presentText": "Odsherred Kommune",
      "presentShortText": "Odsherred",
      "description": ""
    },
    "0316": {
      "title": "Holbæk Kommune",
      "presentText": "Holbæk Kommune",
      "presentShortText": "Holbæk",
      "description": ""
    },
    "0320": {
      "title": "Faxe Kommune",
      "presentText": "Faxe Kommune",
      "presentShortText": "Faxe",
      "description": ""
    },
    "0326": {
      "title": "Kalundborg Kommune",
      "presentText": "Kalundborg Kommune",
      "presentShortText": "Kalundborg",
      "description": ""
    },
    "0329": {
      "title": "Ringsted Kommune",
      "presentText": "Ringsted Kommune",
      "presentShortText": "Ringsted",
      "description": ""
    },
    "0330": {
      "title": "Slagelse Kommune",
      "presentText": "Slagelse Kommune",
      "presentShortText": "Slagelse",
      "description": ""
    },
    "0336": {
      "title": "Stevns Kommune",
      "presentText": "Stevns Kommune",
      "presentShortText": "Stevns",
      "description": ""
    },
    "0340": {
      "title": "Sorø Kommune",
      "presentText": "Sorø Kommune",
      "presentShortText": "Sorø",
      "description": ""
    },
    "0350": {
      "title": "Lejre Kommune",
      "presentText": "Lejre Kommune",
      "presentShortText": "Lejre",
      "description": ""
    },
    "0360": {
      "title": "Lolland Kommune",
      "presentText": "Lolland Kommune",
      "presentShortText": "Lolland",
      "description": ""
    },
    "0370": {
      "title": "Næstved Kommune",
      "presentText": "Næstved Kommune",
      "presentShortText": "Næstved",
      "description": ""
    },
    "0376": {
      "title": "Guldborgsund Kommune",
      "presentText": "Guldborgsund Kommune",
      "presentShortText": "Guldborgsund",
      "description": ""
    },
    "0390": {
      "title": "Vordingborg Kommune",
      "presentText": "Vordingborg Kommune",
      "presentShortText": "Vordingborg",
      "description": ""
    },
    "0400": {
      "title": "Bornholms Regionskommune",
      "presentText": "Bornholms Regionskommune",
      "presentShortText": "Bornholm",
      "description": ""
    },
    "0410": {
      "title": "Middelfart Kommune",
      "presentText": "Middelfart Kommune",
      "presentShortText": "Middelfart",
      "description": ""
    },
    "0420": {
      "title": "Assens Kommune",
      "presentText": "Assens Kommune",
      "presentShortText": "Assens",
      "description": ""
    },
    "0430": {
      "title": "Faaborg-Midtfyn Kommune",
      "presentText": "Faaborg-Midtfyn Kommune",
      "presentShortText": "Faaborg-Midtfyn",
      "description": ""
    },
    "0440": {
      "title": "Kerteminde Kommune",
      "presentText": "Kerteminde Kommune",
      "presentShortText": "Kerteminde",
      "description": ""
    },
    "0450": {
      "title": "Nyborg Kommune",
      "presentText": "Nyborg Kommune",
      "presentShortText": "Nyborg",
      "description": ""
    },
    "0461": {
      "title": "Odense Kommune",
      "presentText": "Odense Kommune",
      "presentShortText": "Odense",
      "description": ""
    },
    "0479": {
      "title": "Svendborg Kommune",
      "presentText": "Svendborg Kommune",
      "presentShortText": "Svendborg",
      "description": ""
    },
    "0480": {
      "title": "Nordfyns Kommune",
      "presentText": "Nordfyns Kommune",
      "presentShortText": "Nordfyn",
      "description": ""
    },
    "0482": {
      "title": "Langeland Kommune",
      "presentText": "Langeland Kommune",
      "presentShortText": "Langeland",
      "description": ""
    },
    "0492": {
      "title": "Ærø Kommune",
      "presentText": "Ærø Kommune",
      "presentShortText": "Ærø",
      "description": ""
    },
    "0510": {
      "title": "Haderslev Kommune",
      "presentText": "Haderslev Kommune",
      "presentShortText": "Haderslev",
      "description": ""
    },
    "0530": {
      "title": "Billund Kommune",
      "presentText": "Billund Kommune",
      "presentShortText": "Billund",
      "description": ""
    },
    "0540": {
      "title": "Sønderborg Kommune",
      "presentText": "Sønderborg Kommune",
      "presentShortText": "Sønderborg",
      "description": ""
    },
    "0550": {
      "title": "Tønder Kommune",
      "presentText": "Tønder Kommune",
      "presentShortText": "Tønder",
      "description": ""
    },
    "0561": {
      "title": "Esbjerg Kommune",
      "presentText": "Esbjerg Kommune",
      "presentShortText": "Esbjerg",
      "description": ""
    },
    "0563": {
      "title": "Fanø Kommune",
      "presentText": "Fanø Kommune",
      "presentShortText": "Fanø",
      "description": ""
    },
    "0573": {
      "title": "Varde Kommune",
      "presentText": "Varde Kommune",
      "presentShortText": "Varde",
      "description": ""
    },
    "0575": {
      "title": "Vejen Kommune",
      "presentText": "Vejen Kommune",
      "presentShortText": "Vejen",
      "description": ""
    },
    "0580": {
      "title": "Aabenraa Kommune",
      "presentText": "Aabenraa Kommune",
      "presentShortText": "Aabenraa",
      "description": ""
    },
    "0607": {
      "title": "Fredericia Kommune",
      "presentText": "Fredericia Kommune",
      "presentShortText": "Fredericia",
      "description": ""
    },
    "0615": {
      "title": "Horsens Kommune",
      "presentText": "Horsens Kommune",
      "presentShortText": "Horsens",
      "description": ""
    },
    "0621": {
      "title": "Kolding Kommune",
      "presentText": "Kolding Kommune",
      "presentShortText": "Kolding",
      "description": ""
    },
    "0630": {
      "title": "Vejle Kommune",
      "presentText": "Vejle Kommune",
      "presentShortText": "Vejle",
      "description": ""
    },
    "0657": {
      "title": "Herning Kommune",
      "presentText": "Herning Kommune",
      "presentShortText": "Herning",
      "description": ""
    },
    "0661": {
      "title": "Holstebro Kommune",
      "presentText": "Holstebro Kommune",
      "presentShortText": "Holstebro",
      "description": ""
    },
    "0665": {
      "title": "Lemvig Kommune",
      "presentText": "Lemvig Kommune",
      "presentShortText": "Lemvig",
      "description": ""
    },
    "0671": {
      "title": "Struer Kommune",
      "presentText": "Struer Kommune",
      "presentShortText": "Struer",
      "description": ""
    },
    "0706": {
      "title": "Syddjurs Kommune",
      "presentText": "Syddjurs Kommune",
      "presentShortText": "Syddjurs",
      "description": ""
    },
    "0707": {
      "title": "Norddjurs Kommune",
      "presentText": "Norddjurs Kommune",
      "presentShortText": "Norddjur",
      "description": ""
    },
    "0710": {
      "title": "Favrskov Kommune",
      "presentText": "Favrskov Kommune",
      "presentShortText": "Favrskov",
      "description": ""
    },
    "0727": {
      "title": "Odder Kommune",
      "presentText": "Odder Kommune",
      "presentShortText": "Odder",
      "description": ""
    },
    "0730": {
      "title": "Randers Kommune",
      "presentText": "Randers Kommune",
      "presentShortText": "Randers",
      "description": ""
    },
    "0740": {
      "title": "Silkeborg Kommune",
      "presentText": "Silkeborg Kommune",
      "presentShortText": "Silkeborg",
      "description": ""
    },
    "0741": {
      "title": "Samsø Kommune",
      "presentText": "Samsø Kommune",
      "presentShortText": "Samsø",
      "description": ""
    },
    "0746": {
      "title": "Skanderborg Kommune",
      "presentText": "Skanderborg Kommune",
      "presentShortText": "Skanderborg",
      "description": ""
    },
    "0751": {
      "title": "Aarhus Kommune",
      "presentText": "Aarhus Kommune",
      "presentShortText": "Aarhus",
      "description": ""
    },
    "0756": {
      "title": "Ikast-Brande Kommune",
      "presentText": "Ikast-Brande Kommune",
      "presentShortText": "Ikast-Brande",
      "description": ""
    },
    "0760": {
      "title": "Ringkøbing-Skjern Kommune",
      "presentText": "Ringkøbing-Skjern Kommune",
      "presentShortText": "Ringkøbing-Skjern",
      "description": ""
    },
    "0766": {
      "title": "Hedensted Kommune",
      "presentText": "Hedensted Kommune",
      "presentShortText": "Hedensted",
      "description": ""
    },
    "0773": {
      "title": "Morsø Kommune",
      "presentText": "Morsø Kommune",
      "presentShortText": "Morsø",
      "description": ""
    },
    "0779": {
      "title": "Skive Kommune",
      "presentText": "Skive Kommune",
      "presentShortText": "Skive",
      "description": ""
    },
    "0787": {
      "title": "Thisted Kommune",
      "presentText": "Thisted Kommune",
      "presentShortText": "Thisted",
      "description": ""
    },
    "0791": {
      "title": "Viborg Kommune",
      "presentText": "Viborg Kommune",
      "presentShortText": "Viborg",
      "description": ""
    },
    "0810": {
      "title": "Brønderslev Kommune",
      "presentText": "Brønderslev Kommune",
      "presentShortText": "Brønderslev",
      "description": ""
    },
    "0813": {
      "title": "Frederikshavn Kommune",
      "presentText": "Frederikshavn Kommune",
      "presentShortText": "Frederikshavn",
      "description": ""
    },
    "0820": {
      "title": "Vesthimmerlands Kommune",
      "presentText": "Vesthimmerlands Kommune",
      "presentShortText": "Vesthimmerland",
      "description": ""
    },
    "0825": {
      "title": "Læsø Kommune",
      "presentText": "Læsø Kommune",
      "presentShortText": "Læsø",
      "description": ""
    },
    "0840": {
      "title": "Rebild Kommune",
      "presentText": "Rebild Kommune",
      "presentShortText": "Rebild",
      "description": ""
    },
    "0846": {
      "title": "Mariagerfjord Kommune",
      "presentText": "Mariagerfjord Kommune",
      "presentShortText": "Mariagerfjord",
      "description": ""
    },
    "0849": {
      "title": "Jammerbugt Kommune",
      "presentText": "Jammerbugt Kommune",
      "presentShortText": "Jammerbugt",
      "description": ""
    },
    "0851": {
      "title": "Aalborg Kommune",
      "presentText": "Aalborg Kommune",
      "presentShortText": "Aalborg",
      "description": ""
    },
    "0860": {
      "title": "Hjørring Kommune",
      "presentText": "Hjørring Kommune",
      "presentShortText": "Hjørring",
      "description": ""
    }
  },
  "KondemneretBoligenhed": {
    "0": {
      "title": "Ikke kondemneret boligenhed",
      "presentText": "Ikke kondemneret boligenhed",
      "presentShortText": "Ikke kondemneret boligenhed",
      "description": " "
    },
    "1": {
      "title": "Kondemneret boligenhed",
      "presentText": "Kondemneret boligenhed",
      "presentShortText": "Kondemneret boligenhed",
      "description": " "
    }
  },
  "Konstruktion": {
    "1": {
      "title": "Åben konstruktion",
      "presentText": "Åben konstruktion",
      "presentShortText": "Åben konstruktion",
      "description": " "
    },
    "2": {
      "title": "Lukket konstruktion",
      "presentText": "Lukket konstruktion",
      "presentShortText": "Lukket konstruktion",
      "description": " "
    }
  },
  "Konstruktionsforhold": {
    "1": {
      "title": "Bygningen har jernbetonskelet",
      "presentText": "Bygningen har jernbetonskelet",
      "presentShortText": "Bygningen har jernbetonskelet",
      "description": " "
    },
    "2": {
      "title": "Bygningen har ikke jernbetonskelet",
      "presentText": "Bygningen har ikke jernbetonskelet",
      "presentShortText": "Bygningen har ikke jernbetonskelet",
      "description": " "
    }
  },
  "Koordinatsystem": {
    "1": {
      "title": "System 34",
      "presentText": "System 34",
      "presentShortText": "System 34",
      "description": " "
    },
    "2": {
      "title": "System 45",
      "presentText": "System 45",
      "presentShortText": "System 45",
      "description": " "
    },
    "3": {
      "title": "KP2000 (System 2000)",
      "presentText": "KP2000 (System 2000)",
      "presentShortText": "KP2000 (System 2000)",
      "description": " "
    },
    "4": {
      "title": "UTM ED50",
      "presentText": "UTM ED50",
      "presentShortText": "UTM ED50",
      "description": " "
    },
    "5": {
      "title": "UTM Euref89 (WGS 84)",
      "presentText": "UTM Euref89 (WGS 84)",
      "presentShortText": "UTM Euref89 (WGS 84)",
      "description": " "
    }
  },
  "KvalitetAfKoordinatsaet": {
    "1": {
      "title": "Sikker geokodning",
      "presentText": "Sikker geokodning",
      "presentShortText": "Sikker geokodning",
      "description": " "
    },
    "2": {
      "title": "Næsten sikker",
      "presentText": "Næsten sikker",
      "presentShortText": "Næsten sikker",
      "description": " "
    },
    "3": {
      "title": "Usikker geokodning",
      "presentText": "Usikker geokodning",
      "presentShortText": "Usikker geokodning",
      "description": " "
    }
  },
  "Livscyklus": {
    "1": {
      "title": "Start",
      "presentText": "Start",
      "presentShortText": "Start",
      "description": " "
    },
    "2": {
      "title": "Projekteret",
      "presentText": "Projekteret",
      "presentShortText": "Projekteret",
      "description": " "
    },
    "3": {
      "title": "Under opførsel",
      "presentText": "Under opførsel",
      "presentShortText": "Under opførsel",
      "description": " "
    },
    "4": {
      "title": "Sagsgrund",
      "presentText": "Sagsgrund",
      "presentShortText": "Sagsgrund",
      "description": " "
    },
    "5": {
      "title": "Oprettet",
      "presentText": "Oprettet",
      "presentShortText": "Oprettet",
      "description": " "
    },
    "6": {
      "title": "Opført",
      "presentText": "Opført",
      "presentShortText": "Opført",
      "description": " "
    },
    "7": {
      "title": "Gældende",
      "presentText": "Gældende",
      "presentShortText": "Gældende",
      "description": " "
    },
    "8": {
      "title": "Godkendt",
      "presentText": "Godkendt",
      "presentShortText": "Godkendt",
      "description": " "
    },
    "9": {
      "title": "Afsluttet",
      "presentText": "Afsluttet",
      "presentShortText": "Afsluttet",
      "description": " "
    },
    "10": {
      "title": "Historisk",
      "presentText": "Historisk",
      "presentShortText": "Historisk",
      "description": " "
    },
    "11": {
      "title": "Fejlregistreret",
      "presentText": "Fejlregistreret",
      "presentShortText": "Fejlregistreret",
      "description": " "
    },
    "12": {
      "title": "Midlertidig Afsluttet",
      "presentText": "Midlertidig Afsluttet",
      "presentShortText": "Midlertidig Afsluttet",
      "description": " "
    },
    "13": {
      "title": "Delvis Afsluttet",
      "presentText": "Delvis Afsluttet",
      "presentShortText": "Delvis Afsluttet",
      "description": " "
    },
    "14": {
      "title": "Henlagt",
      "presentText": "Henlagt",
      "presentShortText": "Henlagt",
      "description": " "
    },
    "15": {
      "title": "Modtaget",
      "presentText": "Modtaget",
      "presentShortText": "Modtaget",
      "description": " "
    },
    "16": {
      "title": "UnderBehandling",
      "presentText": "UnderBehandling",
      "presentShortText": "UnderBehandling",
      "description": " "
    },
    "17": {
      "title": "Fejl",
      "presentText": "Fejl",
      "presentShortText": "Fejl",
      "description": " "
    },
    "18": {
      "title": "Udført",
      "presentText": "Udført",
      "presentShortText": "Udført",
      "description": " "
    },
    "19": {
      "title": "Foreløbig",
      "presentText": "Foreløbig",
      "presentShortText": "Foreløbig",
      "description": " "
    }
  },
  "LovligAnvendelse": {
    "A": {
      "title": "Gammelt helårshus eller ikke-personlig disp. til helårsbeboelse",
      "presentText": "Gammelt helårshus eller ikke-personlig dispensation til helårsbeboelse",
      "presentShortText": "Gammelt helårshus eller ikke-personlig dispensation til helårsbeboelse",
      "description": " "
    },
    "B": {
      "title": "Personlig, tidsbegrænset dispensation til helårsbeboelse",
      "presentText": "Personlig, tidsbegrænset dispensation til helårsbeboelse",
      "presentShortText": "Personlig, tidsbegrænset dispensation til helårsbeboelse",
      "description": " "
    },
    "C": {
      "title": "Personlig, ikke-tidsbegrænset dispensation til helårsbeboelse",
      "presentText": "Personlig, ikke-tidsbegrænset dispensation til helårsbeboelse",
      "presentShortText": "Personlig, ikke-tidsbegrænset dispensation til helårsbeboelse",
      "description": " "
    },
    "D": {
      "title": "Personlig, ikke-tidsbegrænset ret til helårsbeboelse for pensionister",
      "presentText": "Personlig, ikke-tidsbegrænset ret til helårsbeboelse for pensionister",
      "presentShortText": "Personlig, ikke-tidsbegrænset ret til helårsbeboelse for pensionister",
      "description": " "
    },
    "E": {
      "title": "Dispensation til afvikling af ulovlig helårsbeboelse",
      "presentText": "Dispensation til afvikling af ulovlig helårsbeboelse",
      "presentShortText": "Dispensation til afvikling af ulovlig helårsbeboelse",
      "description": " "
    },
    "I": {
      "title": "Ikke relevant for denne enhed",
      "presentText": "Ikke relevant for denne enhed",
      "presentShortText": "Ikke relevant for denne enhed",
      "description": " "
    }
  },
  "Materiale": {
    "1": {
      "title": "Plast",
      "presentText": "Plast",
      "presentShortText": "Plast",
      "description": " "
    },
    "2": {
      "title": "Stål",
      "presentText": "Stål",
      "presentShortText": "Stål",
      "description": " "
    },
    "3": {
      "title": "Plasttank med udvendig stålvæg",
      "presentText": "Plasttank med udvendig stålvæg",
      "presentShortText": "Plasttank med udvendig stålvæg",
      "description": " "
    }
  },
  "MedlemsskabAfSplidevandforsyning": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Ikke medlemskab af spildevandsforsyning",
      "presentText": "Ikke medlemskab af spildevandsforsyning",
      "presentShortText": "Ikke medlemskab af spildevandsforsyning",
      "description": " "
    },
    "2": {
      "title": "Medlemskab af spildevandsforsyning",
      "presentText": "Medlemskab af spildevandsforsyning",
      "presentShortText": "Medlemskab af spildevandsforsyning",
      "description": " "
    }
  },
  "MidlertidigOprettelseEllerFuldfoersel": {
    "0": {
      "title": "Bygningen er ikke midlertidig oprettet",
      "presentText": "Bygningen er ikke midlertidig oprettet",
      "presentShortText": "Bygningen er ikke midlertidig oprettet",
      "description": " "
    },
    "1": {
      "title": "Bygningen er midlertidig oprettet, nybyggeri",
      "presentText": "Bygningen er midlertidig oprettet, nybyggeri",
      "presentShortText": "Bygningen er midlertidig oprettet, nybyggeri",
      "description": " "
    },
    "2": {
      "title": "Bygningen er midlertidig fuldført, nybyggeri",
      "presentText": "Bygningen er midlertidig fuldført, nybyggeri",
      "presentShortText": "Bygningen er midlertidig fuldført, nybyggeri",
      "description": " "
    },
    "3": {
      "title": "Bygningen er midlertidig oprettet, om-/tilbygning",
      "presentText": "Bygningen er midlertidig oprettet, om-/tilbygning",
      "presentShortText": "Bygningen er midlertidig oprettet, om-/tilbygning",
      "description": " "
    },
    "4": {
      "title": "Bygningen er midlertidig fuldført, om-/tilbygning",
      "presentText": "Bygningen er midlertidig fuldført, om-/tilbygning",
      "presentShortText": "Bygningen er midlertidig fuldført, om-/tilbygning",
      "description": " "
    }
  },
  "Niveau": {
    "ALL": {
      "title": "Alle",
      "presentText": "Alle",
      "presentShortText": "Alle",
      "description": " "
    },
    "BYG": {
      "title": "Bygning",
      "presentText": "Bygning",
      "presentShortText": "Bygning",
      "description": " "
    },
    "EJD": {
      "title": "Ejendom",
      "presentText": "Ejendom",
      "presentShortText": "Ejendom",
      "description": ""
    },
    "ENH": {
      "title": "Enhed",
      "presentText": "Enhed",
      "presentShortText": "Enhed",
      "description": " "
    },
    "GRU": {
      "title": "Grund",
      "presentText": "Grund",
      "presentShortText": "Grund",
      "description": " "
    },
    "SAG": {
      "title": "Byggesag",
      "presentText": "Byggesag",
      "presentShortText": "Byggesag",
      "description": " "
    },
    "TEK": {
      "title": "Teknisk Anlæg",
      "presentText": "Teknisk Anlæg",
      "presentShortText": "Teknisk Anlæg",
      "description": " "
    },
    "UMAT": {
      "title": "Umatrikuleret",
      "presentText": "Umatrikuleret",
      "presentShortText": "Umatrikuleret",
      "description": ""
    }
  },
  "NiveauType": {
    "1": {
      "title": "Grund",
      "presentText": "Grund",
      "presentShortText": "Grund",
      "description": " "
    },
    "2": {
      "title": "Bygning",
      "presentText": "Bygning",
      "presentShortText": "Bygning",
      "description": " "
    },
    "3": {
      "title": "TekniskAnlaeg",
      "presentText": "TekniskAnlaeg",
      "presentShortText": "TekniskAnlaeg",
      "description": " "
    },
    "4": {
      "title": "Etage",
      "presentText": "Etage",
      "presentShortText": "Etage",
      "description": " "
    },
    "5": {
      "title": "Opgang",
      "presentText": "Opgang",
      "presentShortText": "Opgang",
      "description": " "
    },
    "6": {
      "title": "Enhed",
      "presentText": "Enhed",
      "presentShortText": "Enhed",
      "description": " "
    }
  },
  "OffentligStoette": {
    "0": {
      "title": "Ingen offentlig støtte",
      "presentText": "Ingen offentlig støtte",
      "presentShortText": "Ingen offentlig støtte",
      "description": " "
    },
    "10": {
      "title": "Almen familiebolig",
      "presentText": "Almen familiebolig",
      "presentShortText": "Almen familiebolig",
      "description": " "
    },
    "15": {
      "title": "Støttet privat udlejningsbolig",
      "presentText": "Støttet privat udlejningsbolig",
      "presentShortText": "Støttet privat udlejningsbolig",
      "description": " "
    },
    "20": {
      "title": "Støttet privat andelsbolig",
      "presentText": "Støttet privat andelsbolig",
      "presentShortText": "Støttet privat andelsbolig",
      "description": " "
    },
    "25": {
      "title": "Almen ungdomsbolig",
      "presentText": "Almen ungdomsbolig",
      "presentShortText": "Almen ungdomsbolig",
      "description": " "
    },
    "30": {
      "title": "Støttet privat ungdomsbolig",
      "presentText": "Støttet privat ungdomsbolig",
      "presentShortText": "Støttet privat ungdomsbolig",
      "description": " "
    },
    "40": {
      "title": "Almen ældrebolig",
      "presentText": "Almen ældrebolig",
      "presentShortText": "Almen ældrebolig",
      "description": " "
    },
    "42": {
      "title": "Almen plejebolig",
      "presentText": "Almen plejebolig",
      "presentShortText": "Almen plejebolig",
      "description": " "
    },
    "80": {
      "title": "Serviceareal",
      "presentText": "Serviceareal",
      "presentShortText": "Serviceareal",
      "description": " "
    }
  },
  "OmfattetAfByggeskadeforsikring": {
    "0": {
      "title": "Bygningen er ikke omfattet af byggeskadeforsikring",
      "presentText": "Bygningen er ikke omfattet af byggeskadeforsikring",
      "presentShortText": "Bygningen er ikke omfattet af byggeskadeforsikring",
      "description": " "
    },
    "10": {
      "title": "Bygningen er omfattet af byggeskadeforsikring",
      "presentText": "Bygningen er omfattet af byggeskadeforsikring",
      "presentShortText": "Bygningen er omfattet af byggeskadeforsikring",
      "description": " "
    },
    "11": {
      "title": "Bygningen er opført som selvbyg",
      "presentText": "Bygningen er opført som selvbyg",
      "presentShortText": "Bygningen er opført som selvbyg",
      "description": " "
    },
    "12": {
      "title": "Udlejningsejendom",
      "presentText": "Udlejningsejendom",
      "presentShortText": "Udlejningsejendom",
      "description": " "
    }
  },
  "OpgangSortering": {
    "0": {
      "title": "Vejkode",
      "presentText": "Vejkode",
      "presentShortText": "Vejkode",
      "description": " "
    },
    "1": {
      "title": "Vejnavn",
      "presentText": "Vejnavn",
      "presentShortText": "Vejnavn",
      "description": " "
    },
    "2": {
      "title": "Husnummer",
      "presentText": "Husnummer",
      "presentShortText": "Husnummer",
      "description": " "
    },
    "3": {
      "title": "Postnummer",
      "presentText": "Postnummer",
      "presentShortText": "Postnummer",
      "description": " "
    },
    "4": {
      "title": "Postdistrikt",
      "presentText": "Postdistrikt",
      "presentShortText": "Postdistrikt",
      "description": " "
    },
    "5": {
      "title": "Sagsnummer",
      "presentText": "Sagsnummer",
      "presentShortText": "Sagsnummer",
      "description": " "
    }
  },
  "Opvarmningsmiddel": {
    "1": {
      "title": "Elektricitet",
      "presentText": "Elektricitet",
      "presentShortText": "Elektricitet",
      "description": " "
    },
    "2": {
      "title": "Gasværksgas",
      "presentText": "Gasværksgas",
      "presentShortText": "Gasværksgas",
      "description": " "
    },
    "3": {
      "title": "Flydende brændsel (olie, petroleum, flaskegas)",
      "presentText": "Flydende brændsel (olie, petroleum, flaskegas)",
      "presentShortText": "Flydende brændsel (olie, petroleum, flaskegas)",
      "description": " "
    },
    "4": {
      "title": "Fast brændsel (kul, koks, brænde mm.)",
      "presentText": "Fast brændsel (kul, koks, brænde m.m.)",
      "presentShortText": "Fast brændsel (kul, koks, brænde m.m.)",
      "description": " "
    },
    "6": {
      "title": "Halm",
      "presentText": "Halm",
      "presentShortText": "Halm",
      "description": " "
    },
    "7": {
      "title": "Naturgas",
      "presentText": "Naturgas",
      "presentShortText": "Naturgas",
      "description": " "
    },
    "9": {
      "title": "Andet",
      "presentText": "Andet",
      "presentShortText": "Andet",
      "description": " "
    }
  },
  "Oversvoemmelsesselvrisiko": {
    "0": {
      "title": "Ingen udbetalt erstatning fra Stormrådet",
      "presentText": "Ingen udbetalt erstatning fra Stormrådet",
      "presentShortText": "Ingen udbetalt erstatning fra Stormrådet",
      "description": " "
    },
    "1": {
      "title": "Bygningens selvrisiko er forhøjet til trin 1",
      "presentText": "Bygningens selvrisiko er forhøjet til trin 1",
      "presentShortText": "Bygningens selvrisiko er forhøjet til trin 1",
      "description": " "
    },
    "2": {
      "title": "Bygningens selvrisiko er forhøjet til trin 2",
      "presentText": "Bygningens selvrisiko er forhøjet til trin 2",
      "presentShortText": "Bygningens selvrisiko er forhøjet til trin 2",
      "description": " "
    },
    "3": {
      "title": "Stormrådet har registreret udbetalt erstatning fra stormflod (siden 2012) og oversvømmelse fra søer og vandløb (siden 2010). Læs mere om stormflods- og oversvømmelsesordningerne på www.stormraadet.dk",
      "presentText": "Stormrådet har registreret udbetalt erstatning fra stormflod (siden 2012) og oversvømmelse fra søer og vandløb (siden 2010). Læs mere om stormflods- og oversvømmelsesordningerne på www.stormraadet.dk",
      "presentShortText": "Stormrådet har registreret udbetalt erstatning fra stormflod (siden 2012) og oversvømmelse fra søer og vandløb (siden 2010). Læs mere om stormflods- og oversvømmelsesordningerne på www.stormraadet.dk",
      "description": " "
    }
  },
  "Placering": {
    "0": {
      "title": "Ukendt",
      "presentText": "Ukendt",
      "presentShortText": "Ukendt",
      "description": " "
    },
    "1": {
      "title": "Nedgravet/underjordisk",
      "presentText": "Nedgravet eller underjordisk",
      "presentShortText": "Nedgravet eller underjordisk",
      "description": " "
    },
    "2": {
      "title": "Over terræn, udendørs",
      "presentText": "Over terræn, udendørs",
      "presentShortText": "Over terræn, udendørs",
      "description": " "
    },
    "3": {
      "title": "Indendørs",
      "presentText": "Indendørs",
      "presentShortText": "Indendørs",
      "description": " "
    }
  },
  "PlaceringAfCursor": {
    "0": {
      "title": "Vejkode",
      "presentText": "Vejkode",
      "presentShortText": "Vejkode",
      "description": " "
    },
    "1": {
      "title": "Vejnavn",
      "presentText": "Vejnavn",
      "presentShortText": "Vejnavn",
      "description": " "
    },
    "2": {
      "title": "Matrikel",
      "presentText": "Matrikel",
      "presentShortText": "Matrikel",
      "description": " "
    },
    "4": {
      "title": "Ejendomsnummer",
      "presentText": "Ejendomsnummer",
      "presentShortText": "Ejendomsnummer",
      "description": " "
    },
    "5": {
      "title": "BFE",
      "presentText": "BFE",
      "presentShortText": "BFE",
      "description": " "
    },
    "6": {
      "title": "Kviksøgning",
      "presentText": "Kviksøgning",
      "presentShortText": "Kviksøgning",
      "description": " "
    }
  },
  "PaaSoeTerritorie": {
    "0": {
      "title": "Ikke på søterritorie",
      "presentText": "Ikke på søterritorie",
      "presentShortText": "Ikke på søterritorie",
      "description": " "
    },
    "1": {
      "title": "På søterritorie",
      "presentText": "På søterritorie",
      "presentShortText": "På søterritorie",
      "description": " "
    }
  },
  "Rensningspaabud": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Rensning ok. Intet påbud",
      "presentText": "Rensning ok. Intet påbud",
      "presentShortText": "Rensning ok. Intet påbud",
      "description": " "
    },
    "2": {
      "title": "Rensning skal forbedres til SOP",
      "presentText": "Rensning skal forbedres til SOP",
      "presentShortText": "Rensning skal forbedres til SOP",
      "description": " "
    },
    "3": {
      "title": "Rensning skal forbedres til SO",
      "presentText": "Rensning skal forbedres til SO",
      "presentShortText": "Rensning skal forbedres til SO",
      "description": " "
    },
    "4": {
      "title": "Rensning skal forbedres til OP",
      "presentText": "Rensning skal forbedres til OP",
      "presentShortText": "Rensning skal forbedres til OP",
      "description": " "
    },
    "5": {
      "title": "Rensning skal forbedres til O",
      "presentText": "Rensning skal forbedres til O",
      "presentShortText": "Rensning skal forbedres til O",
      "description": " "
    },
    "6": {
      "title": "Skal tilsluttes spildevandsforsyningsselskab",
      "presentText": "Skal tilsluttes spildevandsforsyningsselskab",
      "presentShortText": "Skal tilsluttes spildevandsforsyningsselskab",
      "description": " "
    },
    "7": {
      "title": "Skal tilsluttes separatkloakering",
      "presentText": "Skal tilsluttes separatkloakering",
      "presentShortText": "Skal tilsluttes separatkloakering",
      "description": " "
    }
  },
  "Sagsniveau": {
    "1": {
      "title": "Grund",
      "presentText": "Grund",
      "presentShortText": "Grund",
      "description": " "
    },
    "2": {
      "title": "Bygning",
      "presentText": "Bygning",
      "presentShortText": "Bygning",
      "description": " "
    },
    "3": {
      "title": "TekniskAnlaeg",
      "presentText": "TekniskAnlaeg",
      "presentShortText": "TekniskAnlaeg",
      "description": " "
    },
    "4": {
      "title": "Etage",
      "presentText": "Etage",
      "presentShortText": "Etage",
      "description": " "
    },
    "5": {
      "title": "Opgang",
      "presentText": "Opgang",
      "presentShortText": "Opgang",
      "description": " "
    },
    "6": {
      "title": "Enhed",
      "presentText": "Enhed",
      "presentShortText": "Enhed",
      "description": " "
    }
  },
  "Sagstype": {
    "0": {
      "title": "Sag på grund",
      "presentText": "Sag på grund",
      "presentShortText": "Sag på grund",
      "description": " "
    },
    "1": {
      "title": "Nybyggeri",
      "presentText": "Nybyggeri",
      "presentShortText": "Nybyggeri",
      "description": " "
    },
    "2": {
      "title": "Til/ombygning",
      "presentText": "Til/ombygning",
      "presentShortText": "Til/ombygning",
      "description": " "
    },
    "31": {
      "title": "Nedrivning (delvis)",
      "presentText": "Nedrivning (delvis)",
      "presentShortText": "Nedrivning (delvis)",
      "description": " "
    },
    "32": {
      "title": "Nedrivning (hel)",
      "presentText": "Nedrivning (hel)",
      "presentShortText": "Nedrivning (hel)",
      "description": " "
    }
  },
  "Sikkerhedsklassifikation": {
    "0": {
      "title": "Er ikke omfattet af sikkerhedshensyn, jfr. afsnit 7",
      "presentText": "Er ikke omfattet af sikkerhedshensyn, jfr. afsnit 7",
      "presentShortText": "Er ikke omfattet af sikkerhedshensyn, jfr. afsnit 7",
      "description": " "
    },
    "1": {
      "title": "Er beskyttet",
      "presentText": "Er beskyttet",
      "presentShortText": "Er beskyttet",
      "description": " "
    }
  },
  "Sloejfning": {
    "1": {
      "title": "Tanken er afblændet",
      "presentText": "Tanken er afblændet",
      "presentShortText": "Tanken er afblændet",
      "description": " "
    },
    "2": {
      "title": "Tanken er tømt og afblændet",
      "presentText": "Tanken er tømt og afblændet",
      "presentShortText": "Tanken er tømt og afblændet",
      "description": " "
    },
    "3": {
      "title": "Tanken er tømt, afblændet og opfyldt",
      "presentText": "Tanken er tømt, afblændet og opfyldt",
      "presentShortText": "Tanken er tømt, afblændet og opfyldt",
      "description": " "
    },
    "4": {
      "title": "Tanken er tømt, afblændet og påfyldningsstuds samt udluftningsrør afmonteret",
      "presentText": "Tanken er tømt, afblændet og påfyldningsstuds samt udluftningsrør afmonteret",
      "presentShortText": "Tanken er tømt, afblændet og påfyldningsstuds samt udluftningsrør afmonteret",
      "description": " "
    },
    "10": {
      "title": "Jordvarmeslangerne er sløjfet/taget ud af drift",
      "presentText": "Jordvarmeslangerne er sløjfet/taget ud af drift",
      "presentShortText": "Jordvarmeslangerne er sløjfet/taget ud af drift",
      "description": " "
    }
  },
  "StandardSoegniveau": {
    "0": {
      "title": "Alle",
      "presentText": "Alle",
      "presentShortText": "Alle",
      "description": " "
    },
    "1": {
      "title": "Grund",
      "presentText": "Grund",
      "presentShortText": "Grund",
      "description": " "
    },
    "2": {
      "title": "Bygning",
      "presentText": "Bygning",
      "presentShortText": "Bygning",
      "description": " "
    },
    "3": {
      "title": "Enhed",
      "presentText": "Enhed",
      "presentShortText": "Enhed",
      "description": " "
    },
    "4": {
      "title": "Teknisk Anlæg",
      "presentText": "Teknisk Anlæg",
      "presentShortText": "Teknisk Anlæg",
      "description": " "
    },
    "5": {
      "title": "Byggesag",
      "presentText": "Byggesag",
      "presentShortText": "Byggesag",
      "description": " "
    },
    "6": {
      "title": "Ejendom",
      "presentText": "Ejendom",
      "presentShortText": "Ejendom",
      "description": ""
    }
  },
  "Startside": {
    "0": {
      "title": "Bygning & bolig",
      "presentText": "Bygning & bolig",
      "presentShortText": "Bygning & bolig",
      "description": " "
    },
    "1": {
      "title": "Indbakke",
      "presentText": "Indbakke",
      "presentShortText": "Indbakke",
      "description": " "
    },
    "2": {
      "title": "Hændelseslog",
      "presentText": "Hændelseslog",
      "presentShortText": "Hændelseslog",
      "description": " "
    },
    "3": {
      "title": "Rapport",
      "presentText": "Rapport",
      "presentShortText": "Rapport",
      "description": " "
    }
  },
  "Stoerrelsesklasse": {
    "1": {
      "title": "Under 6.000 l",
      "presentText": "Under 6.000 l",
      "presentShortText": "Under 6.000 l",
      "description": " "
    },
    "2": {
      "title": "6.000 l - 100.000 l",
      "presentText": "6.000 l - 100.000 l",
      "presentShortText": "6.000 l - 100.000 l",
      "description": " "
    },
    "3": {
      "title": "Over 100.000 l",
      "presentText": "Over 100.000 l",
      "presentShortText": "Over 100.000 l",
      "description": " "
    }
  },
  "SupplerendeAnvendelseskode": {
    "210": {
      "title": "Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, råstofudvinding o. lign",
      "presentText": "Bygning til produktion vedr. landbrug, gartneri, råstofudvinding o.l.",
      "presentShortText": "Bygning til produktion vedr. landbrug, gartneri, råstofudvinding o.l.",
      "description": " "
    },
    "220": {
      "title": "Bygning til erhvervsmæssig produktion vedrørende industri, håndværk m.v.",
      "presentText": "Bygning til produktion vedr. industri, håndværk mv.",
      "presentShortText": "Bygning til produktion vedr. industri, håndværk mv.",
      "description": " "
    },
    "230": {
      "title": "El-, gas-, vand- eller varmeværk, forbrændingsanstalt m.v.",
      "presentText": "El-, gas-, vand- eller varmeværk, forbrændingsanstalt mv.",
      "presentShortText": "El-, gas-, vand- eller varmeværk, forbrændingsanstalt mv.",
      "description": " "
    },
    "290": {
      "title": "Anden bygning til landbrug, industri etc.",
      "presentText": "Anden bygning til landbrug, industri m.v.",
      "presentShortText": "Anden bygning til landbrug, industri m.v.",
      "description": " "
    },
    "310": {
      "title": "Transport- og garageanlæg (fragtmandshal, lufthavnsbygning, banegårdsbygning, parkeringshus). Garage med plads til et eller to køretøjer registreres med anvendelseskode 910",
      "presentText": "Transport- og garageanlæg, lufthavn, banegårdm parkeringshus",
      "presentShortText": "Transport- og garageanlæg, lufthavn, banegårdm parkeringshus",
      "description": " "
    },
    "320": {
      "title": "Bygning til kontor, handel, lager, herunder offentlig administration",
      "presentText": "Bygning til kontor, handel, lager, herunder offentlig administration",
      "presentShortText": "Bygning til kontor, handel, lager, herunder offentlig administration",
      "description": " "
    },
    "330": {
      "title": "Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
      "presentText": "Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
      "presentShortText": "Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
      "description": " "
    },
    "390": {
      "title": "Anden bygning til transport, handel etc",
      "presentText": "Anden bygning til transport, handel etc",
      "presentShortText": "Anden bygning til transport, handel etc",
      "description": " "
    },
    "410": {
      "title": "Bygning til biograf, teater, erhvervsmæssig udstilling, bibliotek, museum, kirke o. lign.",
      "presentText": "Bygning til biograf, teater, bibliotek, museum, kirke o.l.",
      "presentShortText": "Bygning til biograf, teater, bibliotek, museum, kirke o.l.",
      "description": " "
    },
    "420": {
      "title": "Bygning til undervisning og forskning.",
      "presentText": "Bygning til undervisning og forskning",
      "presentShortText": "Bygning til undervisning og forskning",
      "description": " "
    },
    "430": {
      "title": "Bygning til hospital, sygehjem, fødeklinik o. lign.",
      "presentText": "Bygning til hospital, sygehjem, fødeklinik o.l.",
      "presentShortText": "Bygning til hospital, sygehjem, fødeklinik o.l.",
      "description": " "
    },
    "440": {
      "title": "Bygning til daginstitution",
      "presentText": "Bygning til daginstitution",
      "presentShortText": "Bygning til daginstitution",
      "description": " "
    },
    "490": {
      "title": "Bygning til anden institution, herunder kaserne, fængsel o. lign.",
      "presentText": "Anden institution, herunder kaserne, fængsel o.l.",
      "presentShortText": "Anden institution, herunder kaserne, fængsel o.l.",
      "description": " "
    },
    "520": {
      "title": "Bygning til ferieformål m.v., bortset fra sommerhus (feriekoloni, vandrehjem o. lign.)",
      "presentText": "Bygning til ferieformål mv., bortset fra sommerhus",
      "presentShortText": "Bygning til ferieformål mv., bortset fra sommerhus",
      "description": " "
    },
    "530": {
      "title": "Bygning i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.)",
      "presentText": "Bygning i forbindelse med idrætsudøvelse",
      "presentShortText": "Bygning i forbindelse med idrætsudøvelse",
      "description": " "
    },
    "590": {
      "title": "Anden bygning til fritidsformål",
      "presentText": "Anden bygning til fritidsformål",
      "presentShortText": "Anden bygning til fritidsformål",
      "description": " "
    }
  },
  "SupplerendeIndvendigKorrosionsbeskyttelse": {
    "1": {
      "title": "Glasfiberbelægning",
      "presentText": "Glasfiberbelægning",
      "presentShortText": "Glasfiberbelægning",
      "description": " "
    },
    "2": {
      "title": "Organisk belægning",
      "presentText": "Organisk belægning",
      "presentShortText": "Organisk belægning",
      "description": " "
    },
    "3": {
      "title": "Anoder",
      "presentText": "Anoder",
      "presentShortText": "Anoder",
      "description": " "
    },
    "4": {
      "title": "zinkstøvmaling",
      "presentText": "Zinkstøvmaling",
      "presentShortText": "Zinkstøvmaling",
      "description": " "
    }
  },
  "SupplerendeOplysningerOmKoordinatsaet": {
    "11": {
      "title": "Koordinatsæt ligger i bygningen/anlægget (over jorden)",
      "presentText": "Punktet ligger i bygning/anlæg (over jorden)",
      "presentShortText": "Punktet ligger i bygning/anlæg (over jorden)",
      "description": " "
    },
    "12": {
      "title": "Koordinatsæt ligger i bygningen/anlægget (under jorden)",
      "presentText": "Punktet ligger i bygning/anlæg (under jorden)",
      "presentShortText": "Punktet ligger i bygning/anlæg (under jorden)",
      "description": " "
    },
    "21": {
      "title": "Koordinatsæt ligger i bygningen/anlægget (over jorden)",
      "presentText": "Punktet ligger i bygning/anlæg (over jorden)",
      "presentShortText": "Punktet ligger i bygning/anlæg (over jorden)",
      "description": " "
    },
    "22": {
      "title": "Koordinatsæt ligger i bygningen/anlægget (under jorden)",
      "presentText": "Punktet ligger i bygning/anlæg (under jorden)",
      "presentShortText": "Punktet ligger i bygning/anlæg (under jorden)",
      "description": " "
    },
    "31": {
      "title": "Koordinatsæt ligger på matriklen",
      "presentText": "Punktet ligger på jordstykket",
      "presentShortText": "Punktet ligger på jordstykket",
      "description": " "
    },
    "32": {
      "title": "Ukendt",
      "presentText": "Ukendt",
      "presentShortText": "Ukendt",
      "description": " "
    }
  },
  "SupplerendeVarme": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Varmepumpeanlæg",
      "presentText": "Varmepumpeanlæg",
      "presentShortText": "Varmepumpeanlæg",
      "description": " "
    },
    "2": {
      "title": "Ovne til fast brændsel (brændeovn o. lign.)",
      "presentText": "Ovne til fast brændsel (brændeovn o.l.)",
      "presentShortText": "Ovne til fast brændsel (brændeovn o.l.)",
      "description": " "
    },
    "3": {
      "title": "Ovne til flydende brændsel",
      "presentText": "Ovne til flydende brændsel",
      "presentShortText": "Ovne til flydende brændsel",
      "description": " "
    },
    "4": {
      "title": "Solpaneler",
      "presentText": "Solpaneler",
      "presentShortText": "Solpaneler",
      "description": " "
    },
    "5": {
      "title": "Pejs",
      "presentText": "Pejs",
      "presentShortText": "Pejs",
      "description": " "
    },
    "6": {
      "title": "Gasradiator",
      "presentText": "Gasradiator",
      "presentShortText": "Gasradiator",
      "description": " "
    },
    "7": {
      "title": "Elovne, elpaneler",
      "presentText": "Elovne, elpaneler",
      "presentShortText": "Elovne, elpaneler",
      "description": " "
    },
    "10": {
      "title": "Biogasanlæg",
      "presentText": "Biogasanlæg",
      "presentShortText": "Biogasanlæg",
      "description": " "
    },
    "80": {
      "title": "Andet",
      "presentText": "Andet",
      "presentShortText": "Andet",
      "description": " "
    },
    "90": {
      "title": "Bygningen har ingen supplerende varme",
      "presentText": "Ingen supplerende varme",
      "presentShortText": "Ingen supplerende varme",
      "description": " "
    }
  },
  "Tagdaekningsmateriale": {
    "1": {
      "title": "Built-up",
      "presentText": "Built-up",
      "presentShortText": "Built-up",
      "description": " "
    },
    "2": {
      "title": "Tagpap (med taghældning)",
      "presentText": "Tagpap (med taghældning)",
      "presentShortText": "Tagpap (med taghældning)",
      "description": " "
    },
    "3": {
      "title": "Fibercement, herunder asbest (bølge- eller skifer-eternit)",
      "presentText": "Fibercement, herunder asbest (bølge-/skifer-eternit)",
      "presentShortText": "Fibercement, bl.a. asbest (bølge-/skifer-eternit)",
      "description": " "
    },
    "4": {
      "title": "Cementsten",
      "presentText": "Cementsten",
      "presentShortText": "Cementsten",
      "description": " "
    },
    "5": {
      "title": "Tegl",
      "presentText": "Tegl",
      "presentShortText": "Tegl",
      "description": " "
    },
    "6": {
      "title": "Metalplader (bølgeblik, aluminium, o.lign.)",
      "presentText": "Metalplader (bølgeblik, aluminium, o.lign.)",
      "presentShortText": "Metalplader (bølgeblik, aluminium, o.lign.)",
      "description": " "
    },
    "7": {
      "title": "Stråtag",
      "presentText": "Stråtag",
      "presentShortText": "Stråtag",
      "description": " "
    },
    "10": {
      "title": "Fibercement (asbestfri)",
      "presentText": "Fibercement (asbestfri)",
      "presentShortText": "Fibercement (asbestfri)",
      "description": " "
    },
    "11": {
      "title": "PVC",
      "presentText": "PVC",
      "presentShortText": "PVC",
      "description": " "
    },
    "12": {
      "title": "Glas",
      "presentText": "Glas",
      "presentShortText": "Glas",
      "description": " "
    },
    "20": {
      "title": "Grønne tage",
      "presentText": "Grønne tage",
      "presentShortText": "Grønne tage",
      "description": " "
    },
    "80": {
      "title": "Ingen",
      "presentText": "Ingen",
      "presentShortText": "Ingen",
      "description": " "
    },
    "90": {
      "title": "Andet materiale",
      "presentText": "Andet materiale",
      "presentShortText": "Andet materiale",
      "description": " "
    }
  },
  "TekniskAnlaegBygningSortering": {
    "0": {
      "title": "Teknisk anlægs nummer",
      "presentText": "Teknisk anlægs nummer",
      "presentShortText": "Teknisk anlægs nummer",
      "description": " "
    },
    "1": {
      "title": "Vejkode",
      "presentText": "Vejkode",
      "presentShortText": "Vejkode",
      "description": " "
    },
    "2": {
      "title": "Vejnavn",
      "presentText": "Vejnavn",
      "presentShortText": "Vejnavn",
      "description": " "
    },
    "3": {
      "title": "Husnummer",
      "presentText": "Husnummer",
      "presentShortText": "Husnummer",
      "description": " "
    },
    "4": {
      "title": "Postnummer",
      "presentText": "Postnummer",
      "presentShortText": "Postnummer",
      "description": " "
    },
    "5": {
      "title": "Postdistrikt",
      "presentText": "Postdistrikt",
      "presentShortText": "Postdistrikt",
      "description": " "
    },
    "6": {
      "title": "Klassifikation (samt som klartekst i tool tip)",
      "presentText": "Klassifikation (samt som klartekst i tool tip)",
      "presentShortText": "Klassifikation (samt som klartekst i tool tip)",
      "description": " "
    },
    "9": {
      "title": "Sagsnummer",
      "presentText": "Sagsnummer",
      "presentShortText": "Sagsnummer",
      "description": " "
    }
  },
  "TekniskAnlaegEnhedSortering": {
    "0": {
      "title": "Teknisk anlægs nummer",
      "presentText": "Teknisk anlægs nummer",
      "presentShortText": "Teknisk anlægs nummer",
      "description": " "
    },
    "1": {
      "title": "Klassifikation (samt som klartekst i tool tip)",
      "presentText": "Klassifikation (samt som klartekst i tool tip)",
      "presentShortText": "Klassifikation (samt som klartekst i tool tip)",
      "description": " "
    },
    "4": {
      "title": "Sagsnummer",
      "presentText": "Sagsnummer",
      "presentShortText": "Sagsnummer",
      "description": " "
    }
  },
  "TekniskAnlaegMatrikelSortering": {
    "0": {
      "title": "Teknisk anlægs nummer",
      "presentText": "Teknisk anlægs nummer",
      "presentShortText": "Teknisk anlægs nummer",
      "description": " "
    },
    "1": {
      "title": "Vejkode",
      "presentText": "Vejkode",
      "presentShortText": "Vejkode",
      "description": " "
    },
    "2": {
      "title": "Vejnavn",
      "presentText": "Vejnavn",
      "presentShortText": "Vejnavn",
      "description": " "
    },
    "3": {
      "title": "Husnummer",
      "presentText": "Husnummer",
      "presentShortText": "Husnummer",
      "description": " "
    },
    "4": {
      "title": "Postnummer",
      "presentText": "Postnummer",
      "presentShortText": "Postnummer",
      "description": " "
    },
    "5": {
      "title": "Postdistrikt",
      "presentText": "Postdistrikt",
      "presentShortText": "Postdistrikt",
      "description": " "
    },
    "6": {
      "title": "Klassifikation (samt som klartekst i tool tip)",
      "presentText": "Klassifikation (samt som klartekst i tool tip)",
      "presentShortText": "Klassifikation (samt som klartekst i tool tip)",
      "description": " "
    },
    "9": {
      "title": "Sagsnummer",
      "presentText": "Sagsnummer",
      "presentShortText": "Sagsnummer",
      "description": " "
    }
  },
  "Tilladelsesart": {
    "1": {
      "title": "Upersonlig tilladelse uden tidsbegrænsning",
      "presentText": "Upersonlig tilladelse uden tidsbegrænsning",
      "presentShortText": "Upersonlig tilladelse uden tidsbegrænsning",
      "description": " "
    },
    "2": {
      "title": "Personlig tilladelse uden tidsbegrænsning",
      "presentText": "Personlig tilladelse uden tidsbegrænsning",
      "presentShortText": "Personlig tilladelse uden tidsbegrænsning",
      "description": " "
    },
    "3": {
      "title": "Upersonlig tilladelse med tidsbegrænsing",
      "presentText": "Upersonlig tilladelse med tidsbegrænsing",
      "presentShortText": "Upersonlig tilladelse med tidsbegrænsing",
      "description": " "
    },
    "4": {
      "title": "Personlig tilladelse med tidsbegrænsing",
      "presentText": "Personlig tilladelse med tidsbegrænsing",
      "presentShortText": "Personlig tilladelse med tidsbegrænsing",
      "description": " "
    }
  },
  "TilladelseTilAlternativBortskaffelseEllerAfledning": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Tilladelse meddelt",
      "presentText": "Tilladelse meddelt",
      "presentShortText": "Tilladelse meddelt",
      "description": " "
    },
    "2": {
      "title": "Tilladelse bortfaldet",
      "presentText": "Tilladelse bortfaldet",
      "presentShortText": "Tilladelse bortfaldet",
      "description": " "
    }
  },
  "TilladelseTilUdtraeden": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Tilladelse meddelt",
      "presentText": "Tilladelse meddelt",
      "presentShortText": "Tilladelse meddelt",
      "description": " "
    },
    "2": {
      "title": "Tilladelse bortfaldet",
      "presentText": "Tilladelse bortfaldet",
      "presentShortText": "Tilladelse bortfaldet",
      "description": " "
    }
  },
  "Toiletforhold": {
    "A": {
      "title": "Vandskyllende toilet udenfor enheden",
      "presentText": "Vandskyllende toilet udenfor enheden",
      "presentShortText": "Vandskyllende toilet udenfor enheden",
      "description": " "
    },
    "B": {
      "title": "Anden type toilet udenfor enheden eller intet toilet i forbindelse med enheden",
      "presentText": "Anden type toilet udenfor enheden eller intet toilet i forbindelse med enheden",
      "presentShortText": "Anden type toilet udenfor enheden eller intet toilet i forbindelse med enheden",
      "description": " "
    },
    "T": {
      "title": "Vandskyllende toiletter i bolig- eller erhvervsenheden",
      "presentText": "Vandskyllende toiletter i bolig- eller erhvervsenheden",
      "presentShortText": "Vandskyllende toiletter i bolig- eller erhvervsenheden",
      "description": " "
    }
  },
  "TypeAfVaegge": {
    "1": {
      "title": "Enkeltvægget",
      "presentText": "Enkeltvægget",
      "presentShortText": "Enkeltvægget",
      "description": " "
    },
    "2": {
      "title": "Dobbeltvægget",
      "presentText": "Dobbeltvægget",
      "presentShortText": "Dobbeltvægget",
      "description": " "
    },
    "3": {
      "title": "Dobbeltvægget med overvågning",
      "presentText": "Dobbeltvægget med overvågning",
      "presentShortText": "Dobbeltvægget med overvågning",
      "description": " "
    },
    "4": {
      "title": "Overjordisk anlæg, hele anlægget er tilgængeligt for udvendig visuel inspektion",
      "presentText": "Overjordisk anlæg, hele anlægget er tilgængeligt for udvendig visuel inspektion",
      "presentShortText": "Overjordisk anlæg, hele anlægget er tilgængeligt for udvendig visuel inspektion",
      "description": " "
    },
    "5": {
      "title": "Tanke som er installeret før 1970, udvendig korrosionsbeskyttet bitumenbelægning",
      "presentText": "Tanke som er installeret før 1970, udvendig korrosionsbeskyttet bitumenbelægning",
      "presentShortText": "Tanke som er installeret før 1970, udvendig korrosionsbeskyttet bitumenbelægning",
      "description": " "
    }
  },
  "Udledningstilladelse": {
    "0": {
      "title": "Ikke oplyst",
      "presentText": "Ikke oplyst",
      "presentShortText": "Ikke oplyst",
      "description": " "
    },
    "1": {
      "title": "Udledningstilladelse mangler",
      "presentText": "Udledningstilladelse mangler",
      "presentShortText": "Udledningstilladelse mangler",
      "description": " "
    },
    "2": {
      "title": "Renseanlæg etableret før 1974, derfor ikke behov for tilladelse",
      "presentText": "Renseanlæg etableret før 1974, derfor ikke behov for tilladelse",
      "presentShortText": "Renseanlæg etableret før 1974, derfor ikke behov for tilladelse",
      "description": " "
    },
    "3": {
      "title": "Udledningstilladelse til enkeltprivat renseanlæg",
      "presentText": "Udledningstilladelse til enkeltprivat renseanlæg",
      "presentShortText": "Udledningstilladelse til enkeltprivat renseanlæg",
      "description": " "
    },
    "4": {
      "title": "Udledningstilladelse til fællesprivat renseanlæg",
      "presentText": "Udledningstilladelse til fællesprivat renseanlæg",
      "presentShortText": "Udledningstilladelse til fællesprivat renseanlæg",
      "description": " "
    },
    "5": {
      "title": "Der foreligger ingen kendt tilladelse",
      "presentText": "Der foreligger ingen kendt tilladelse",
      "presentShortText": "Der foreligger ingen kendt tilladelse",
      "description": " "
    },
    "6": {
      "title": "Der foreligger tilladelse",
      "presentText": "Der foreligger tilladelse",
      "presentShortText": "Der foreligger tilladelse",
      "description": " "
    },
    "7": {
      "title": "Tilladelsesforhold er oplyst på bygningsniveau",
      "presentText": "Tilladelsesforhold er oplyst på bygningsniveau",
      "presentShortText": "Tilladelsesforhold er oplyst på bygningsniveau",
      "description": " "
    }
  },
  "Udlejningsforhold": {
    "1": {
      "title": "Udlejet",
      "presentText": "Udlejet",
      "presentShortText": "Udlejet",
      "description": " "
    },
    "2": {
      "title": "Benyttet af ejeren",
      "presentText": "Benyttet af ejeren",
      "presentShortText": "Benyttet af ejeren",
      "description": " "
    },
    "3": {
      "title": "Ikke benyttet",
      "presentText": "Ikke benyttet",
      "presentShortText": "Ikke benyttet",
      "description": " "
    }
  },
  "Udskrivningsmatrikel": {
    "J": {
      "title": "Ja",
      "presentText": "Ja",
      "presentShortText": "Ja",
      "description": " "
    },
    "M": {
      "title": "Midlertidig",
      "presentText": "Midlertidig",
      "presentShortText": "Midlertidig",
      "description": " "
    },
    "N": {
      "title": "Nej",
      "presentText": "Nej",
      "presentShortText": "Nej",
      "description": " "
    },
    "X": {
      "title": "Slettet",
      "presentText": "Slettet",
      "presentShortText": "Slettet",
      "description": " "
    }
  },
  "Vandforsyning": {
    "1": {
      "title": "Alment vandforsyningsanlæg (tidligere offentligt)",
      "presentText": "Alment vandforsyningsanlæg",
      "presentShortText": "Alment vandforsyningsanlæg",
      "description": " "
    },
    "2": {
      "title": "Privat, alment vandforsyningsanlæg",
      "presentText": "Privat, alment vandforsyningsanlæg",
      "presentShortText": "Privat, alment vandforsyningsanlæg",
      "description": " "
    },
    "3": {
      "title": "Enkeltindvindingsanlæg (egen boring til 1 eller 2 ejendomme)",
      "presentText": "Enkeltindvindingsanlæg (egen boring, 1-2 ejd.)",
      "presentShortText": "Enkeltindvindingsanlæg (egen boring, 1-2 ejd.)",
      "description": " "
    },
    "4": {
      "title": "Brønd",
      "presentText": "Brønd",
      "presentShortText": "Brønd",
      "description": " "
    },
    "6": {
      "title": "Ikke alment vandforsyningsanlæg (forsyner < 10 ejendomme)",
      "presentText": "Ikke alment vandforsyningsanlæg (< 10 ejd.)",
      "presentShortText": "Ikke alment vandforsyningsanlæg (< 10 ejd.)",
      "description": " "
    },
    "7": {
      "title": "Blandet vandforsyning",
      "presentText": "Blandet vandforsyning",
      "presentShortText": "Blandet vandforsyning",
      "description": " "
    },
    "9": {
      "title": "Ingen vandforsyning",
      "presentText": "Ingen vandforsyning",
      "presentShortText": "Ingen vandforsyning",
      "description": " "
    }
  },
  "Varmeinstallation": {
    "1": {
      "title": "Fjernvarme/blokvarme (radiatorsystemer el. varmluftanlæg)",
      "presentText": "Fjernvarme eller blokvarme",
      "presentShortText": "Fjernvarme eller blokvarme",
      "description": " "
    },
    "2": {
      "title": "Centralvarme fra eget anlæg, et-kammer fyr",
      "presentText": "Centralvarme fra eget anlæg",
      "presentShortText": "Centralvarme fra eget anlæg",
      "description": " "
    },
    "3": {
      "title": "Ovne (kakkelovne, kamin, brændeovne o.l.)",
      "presentText": "Ovne (kakkelovn, brændeovn o.l.)",
      "presentShortText": "Ovne (kakkelovn, brændeovn o.l.)",
      "description": " "
    },
    "5": {
      "title": "Varmepumpe",
      "presentText": "Varmepumpe",
      "presentShortText": "Varmepumpe",
      "description": " "
    },
    "6": {
      "title": "Centralvarme med to fyringsenheder (fast og olie eller gas)",
      "presentText": "Centralvarme med to enheder (fast og olie/gas)",
      "presentShortText": "Centralvarme med to enheder (fast og olie/gas)",
      "description": " "
    },
    "7": {
      "title": "Elovne, elpaneler",
      "presentText": "Elovne eller elpaneler",
      "presentShortText": "Elovne eller elpaneler",
      "description": " "
    },
    "8": {
      "title": "Gasradiator",
      "presentText": "Gasradiator",
      "presentShortText": "Gasradiator",
      "description": " "
    },
    "9": {
      "title": "Ingen varmeinstallation",
      "presentText": "Ingen varmeinstallation",
      "presentShortText": "Ingen varmeinstallation",
      "description": " "
    },
    "99": {
      "title": "Blandet (Kræver specifikation på enhedsniveau)",
      "presentText": "Blandet (angivet på enhedsniveau)",
      "presentShortText": "Blandet (angivet på enhedsniveau)",
      "description": " "
    }
  },
  "YdervaeggenesMateriale": {
    "1": {
      "title": "Mursten (tegl, kalksten, cementsten)",
      "presentText": "Mursten (tegl, kalksten, cementsten)",
      "presentShortText": "Mursten (tegl, kalksten, cementsten)",
      "description": " "
    },
    "2": {
      "title": "Letbeton (lette bloksten, gasbeton)",
      "presentText": "Letbeton (lette bloksten, gasbeton)",
      "presentShortText": "Letbeton (lette bloksten, gasbeton)",
      "description": " "
    },
    "3": {
      "title": "Plader af fibercement, herunder asbest (eternit el. lign.)",
      "presentText": "Plader af fibercement, herunder asbest (eternit e.l.)",
      "presentShortText": "Plader af fibercement, herunder asbest (eternit e.l.)",
      "description": " "
    },
    "4": {
      "title": "Bindingsværk (med udvendigt synligt træværk)",
      "presentText": "Bindingsværk (med udvendigt synligt træværk)",
      "presentShortText": "Bindingsværk (med udvendigt synligt træværk)",
      "description": " "
    },
    "5": {
      "title": "Træbeklædning",
      "presentText": "Træbeklædning",
      "presentShortText": "Træbeklædning",
      "description": " "
    },
    "6": {
      "title": "Betonelementer (etagehøje betonelementer)",
      "presentText": "Betonelementer (etagehøje betonelementer)",
      "presentShortText": "Betonelementer (etagehøje betonelementer)",
      "description": " "
    },
    "8": {
      "title": "Metalplader",
      "presentText": "Metalplader",
      "presentShortText": "Metalplader",
      "description": " "
    },
    "10": {
      "title": "Plader af fibercement (asbestfri)",
      "presentText": "Plader af fibercement (asbestfri)",
      "presentShortText": "Plader af fibercement (asbestfri)",
      "description": " "
    },
    "11": {
      "title": "PVC",
      "presentText": "PVC",
      "presentShortText": "PVC",
      "description": " "
    },
    "12": {
      "title": "Glas",
      "presentText": "Glas",
      "presentShortText": "Glas",
      "description": " "
    },
    "80": {
      "title": "Ingen",
      "presentText": "Ingen",
      "presentShortText": "Ingen",
      "description": " "
    },
    "90": {
      "title": "Andet materiale",
      "presentText": "Andet materiale",
      "presentShortText": "Andet materiale",
      "description": " "
    }
  }
}