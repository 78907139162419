import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"

export default class TingbogsSearcher extends Searcher {
  constructor(options = {}) {
    if (!options.fetcher)
      throw new Error("TingbogsSearcher called without options.fetcher")

    super(options)

    this.source = "tingbogen"
    this.fetcher = options.fetcher
    this.types = { "servitut": new ResultType({ id: "servitut", singular: "Servitut", plural: "Servitutter", queryBehaviour: "none" }) }
    this.registerType(this.source, this.types.servitut)
  }
  
  async fetchData(query, caller) {
    caller.fetchSuccess(this.createQueryResult())
  }

  // eslint-disable-next-line no-unused-vars
  async sq(query) {
    return this.createQueryResult()
  }
}