/**
 * @module
 */

import {getString} from '../resources/strings.js'
import {getWKTParser} from '../util/getWKTParser.js'
import QueryResult from '../QueryResult.js'
import ResultType from "../ResultType.js"
import {fetch2} from "../utils.js"
import icons from "../resources/icons.js"

/**
 * Base class for all searchers - these options are available on all searchers
 * @example <caption>YAML Declaration:</caption>
    _type: Septima.Search.DawaSearcher
    _options:
      goal: adgangellerenhedsadresse
      minimumShowCount: 3
      showMinimumOnBlank: true
      resultTypes:
        adresse:
          singular: Adresse
          plural: Adresser
          iconURI: data:image/svg+xml;base64,.........

 * @api
 */
export default class Searcher {

  /**
   * @param {Object} options
   * @param {string} [options.blankBehavior="search"] "none"|"search"
   * @param {string} [options.queryBehaviour="auto"] "auto|none"|"search"
   * @param {string} [options.minimumShowCount=0] Instead of collapsing results show at least some
   * @param {string} [options.showMinimumOnBlank=false] Show minimum count even on blank search
   * @param {Object} [options.resultTypes={}] Use this options to override singular, plural, and iconURI of results (See example)
   * @param {int} [options.searchDelay=0] Delay in ms before executing the search
   * @param {boolean} [options.iconURI] Default icon for results if not set by searcher. Prefer to use options.resultTypes.[type].iconURI if possible
   * @param {function} [options.onSelect] Function to call when a result is selected by the user. Alternatively use the controller.addOnSelectHandler(callback)
   * @param {boolean} [options.usesGeoFunctions=false] [Internal use] Does the implementation need the geo functions
   * @param {Object} [options.logger] [Internal use]
   * @param {string} [options.logLevel] [Internal use]
   * @param {string} [options.defaultCrs]  [Internal use] Sets crs of createQueryResult, sets crs of translateWktToGeoJsonObject
   */
  constructor(options= {}) {
    this.customButtonDefs = {"any": []}
    this.detailHandlerDefs = {"all": [], "any": []}
    this._searchDelay = 0
    this._sources = []//[{source: "sss", types: [resultType..]}];
    this._matchesPhrase = getString("matches")
    this._onSelectCallback = function() {}
    this.blankBehavior = "search" //none, search
    this.queryBehaviour = "auto"
    this.minimumShowCount = 0
    this.showMinimumOnBlank = false
    this._id ="Searcher" + '_' + Math.floor(Math.random() * 999999999)
    this.defaultCrs

    this.resultTypes = {}
    if (options.resultTypes)
      this.resultTypes = options.resultTypes

    if (options.defaultCrs)
      this.defaultCrs = options.defaultCrs
    
    if (typeof options.source !== 'undefined') 
      this._sources = [{source: options.source, types: []}]
      
    if (typeof options.onSelect !== 'undefined') 
      this._onSelectCallback = options.onSelect
      
    if (options.matchesPhrase) 
      this._matchesPhrase = options.matchesPhrase

    if (typeof options.minimumShowCount !== 'undefined')
      if (options.minimumShowCount > 2) {
        this.minimumShowCount = options.minimumShowCount
        if (options.showMinimumOnBlank)
          this.showMinimumOnBlank = true
      }
          
    if (options.searchDelay) 
      this._searchDelay = options.searchDelay
      
    if (options.iconURI) 
      this.iconURI = options.iconURI
      
    if (options.blankBehavior) 
      this.blankBehavior = options.blankBehavior
      
    if (options.usesGeoFunctions) 
      this.wktParser = getWKTParser()
    
    if (options.queryBehaviour)
      this.queryBehaviour = options.queryBehaviour
    
    if (options.id) {
      this._id = options.id
    }
    
    if (options.detailhandlers)
      this.detailhandlers = options.detailhandlers
    
    if (options.logger) {
      let childBindings = {
        module: this._id
      }
      let childOptions = {}
      if (options.logLevel)
        childOptions.level = options.logLevel
      this._logger = options.logger.child(childBindings, childOptions)
    }

  }
  
  getLogger() {
    return this._logger
  }
  
  toJSON() {
    return {
      id: this.getId()
    }
  }
  
  fetch(url, options = {}) {
    return fetch2(url, options, this.getLogger())
  }
  
  ready() {
    return Promise.resolve(true)
  }

  asyncFetchData(query, delay = this._searchDelay) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          this.fetchData(
            query, {
              fetchSuccess: (queryResult) => {
                resolve(queryResult)
              },
              fetchError: (message) => {
                reject(new Error(message))
              }
            })
        } catch(e) {
          reject(e)
        }
      }, delay)
    })
  }
  /**
     * This method is called by the controller when the driver should fetch data. <br>
     * This method MUST be implemented by all searchers <br>
     * A call to this method MUST result in either a call to {@link Controller}.fetchSuccess({@link QueryResult}) or caller.fetchError()
     * @param {Controller} caller
     * */
  fetchData() {
    //Return error if fetchData hasn't been implemented in driver
    throw ("Bad driver implementation in " + this.getId() + ". Method fetchData MUST be implemented")
  }


  /**
     * @private
     */
  _onSelect(result) {
    return this.onSelect(result)
  }


  /**
   * onSelect event.
   *
   * @event onSelect
   * @type {module:js/Result}
   * @api
   */
  
  /**
     * This method is called by the controller when a result is selected. <br>
     * This method MAY be implemented by searchers. <br>
     * Implementations MUST call this._onSelectCallback(result)
     * @param {Searcher.Result} result
     */
  onSelect(result) {
    if (!result.isNewQuery())
      this._onSelectCallback(result)

  }
    
  async completeResult(result) {
    result.isComplete = true
    return result
  }

  //TODO: Optimize how sources/types are stored and queried.
  //Consider storing information as controller does
  registerType(sourceName, type) {
    //type: {id, singular, plural}
    let resultType
    let source = this.getSource(sourceName)
    if (typeof type === 'string' || type instanceof String) {
      //Ok only string is given - create object
      resultType = new ResultType({
        id: type,
        iconURI: this.iconURI
      })
    } else {
      if (typeof type.id === 'undefined') {
        throw this.getId() + ': registerType() called with invalid type'
      } else {
        //OK We've been called with a ResultType object
        resultType = type
        
        //put in some default values before extending with options
        if (!resultType.iconURI)
          resultType.iconURI = this.iconURI ? this.iconURI : icons.result.defaultIcon
        
        //Options win
        if (this.resultTypes[resultType.id]) {
          if (this.resultTypes[resultType.id].singular)
            resultType.singular = this.resultTypes[resultType.id].singular
          if (this.resultTypes[resultType.id].plural)
            resultType.plural = this.resultTypes[resultType.id].plural
          if (this.resultTypes[resultType.id].iconURI)
            resultType.iconURI = this.resultTypes[resultType.id].iconURI
        }
      }
    }
    // only keep queryBehaviour if this.queryBehaviour === 'auto'
    resultType.queryBehaviour = this.queryBehaviour === 'auto' ? resultType.queryBehaviour : this.queryBehaviour
    source.types.push(resultType)
  }
  
  getSource(sourceName) {
    for (let source of this._sources)
      if (source.source.toLowerCase() === sourceName.toLowerCase())
        return source
    let source = {source: sourceName, types: []}
    this._sources.push(source)
    return source
  }

  getSources() {
    //returns //[{source: "sss", types: [resultType..]}]
    return this._sources
  }

  hasSource(sourceName) {
    for (let source of this._sources)
      if (source.source.toLowerCase() === sourceName.toLowerCase())
        return true
    return false
  }

  //return true if at least one type has queryBehavior !== 'none'
  isSearchable() {
    for (let thisSource of this._sources)
      for (let thisType of thisSource.types)
        if (thisType.queryBehaviour !== 'none')
          return true
    return false
  }
  
  hasType(typeId) {
    for (let thisSource of this._sources) 
      for (let thisType of thisSource.types) 
        if (thisType.id.toLowerCase() === typeId.toLowerCase()) 
          return true
    return false
  }
  
  getType(sourceName, typeId) {
    let source = this.getSource(sourceName)
    for (let thisType of source.types)
      if (thisType.id.toLowerCase() === typeId.toLowerCase())
        return thisType
    return null
    
  }
    
  hasTarget(target) {
    if (target.source && target.type)
      return this.hasSource(target.source) && this.hasType(target.type)
  }

  /**
     * @private
     */
  getId() {
    return this._id 
  }

  /**
     * Create a {QueryResult}.
     * @returns {QueryResult}
     *
     */
  createQueryResult() {
    return new QueryResult(this, this.defaultCrs)
  }

  /**
     * Convert a wkt string to a geojson object
     * @param {string} wkt
     * @returns {GeoJsonObject}
     */
  translateWktToGeoJsonObject(wkt, epsg = null) {
    if (wkt === null) 
      return null
    
    let epsgToUse = epsg ? epsg : this.defaultCrs
      
    return this.wktParser.parse(wkt, epsgToUse)
  }

  translateGeoJsonObjectToWkt(geoJsonObject) {
    if (geoJsonObject)
      return this.wktParser.convert(geoJsonObject)
  }

  // eslint-disable-next-line no-unused-vars
  get(id) {
    return new Promise((resolve, reject) => {
      reject(new Error('Whoops! get(id) is not implemented in ' + this.getId()))
    })
  }

  async sq() {
    return this.createQueryResult()
  }

  hasdetailHandlerDefs(result) {
    if (typeof result.directDetailHandlerDefs === 'undefined') 
      result.directDetailHandlerDefs = this.getApplicableHandlerDefs(result)
      
    return result.directDetailHandlerDefs.length > 0
  }

  getApplicableHandlerDefs(result) {
    if (result.isNewQuery()) {
      return []
    } else {
      let typeId = result.typeId.toLowerCase()
      let tentativeHandlerDefs = this.getTentativeHandlerDefs(typeId)

      let applicableHandlerDefs = []
      for (let tentativeHandlerDef of tentativeHandlerDefs)
        if (typeof tentativeHandlerDef.isApplicable === 'undefined' || tentativeHandlerDef.isApplicable(result))
          applicableHandlerDefs.push(tentativeHandlerDef)

      return this.getDetailHandlersForResult(result).concat(applicableHandlerDefs)
    }
  }
  
  //This function may be overridden by implementations of search
  getDetailHandlersForResult() {
    return []
  }

  hasHandlerDefFor(source, typeId) {
    let tentativeHandlerDefs = this.getTentativeHandlerDefs(typeId)
    for (let tentativeHandlerDef of tentativeHandlerDefs)
      if (tentativeHandlerDef.hasTarget(source, typeId))
        return true
    return false
  }
  
  getTentativeHandlerDefs (typeId) {
    let tentativeHandlerDefs = []
    if (typeof this.detailHandlerDefs[typeId] === 'undefined')
      tentativeHandlerDefs = this.detailHandlerDefs.any
    else
      tentativeHandlerDefs = this.detailHandlerDefs[typeId].concat(this.detailHandlerDefs.any)
    return tentativeHandlerDefs
  }

  set detailhandlers(dhCollection) {
    for (var detailhandler of dhCollection) 
      this.addDetailHandlerDef(detailhandler)
  }

  //This function may be overridden by implementations of Searcher
  //Override with signature getRelations(result)
  // eslint-disable-next-line no-unused-vars
  async getRelations(result) {
    return {children: [], parents: [], siblings: []}
  }

  async getdetailHandlerDef(result, id) {
    if (result.isNewQuery()) {
      return null
    } else {
      let tentativeHandlerDefs = []
      if (typeof result.typeId === 'undefined' || typeof this.detailHandlerDefs[result.typeId.toLowerCase()] === 'undefined')
        tentativeHandlerDefs = this.detailHandlerDefs.any
      else
        tentativeHandlerDefs = this.detailHandlerDefs[result.typeId.toLowerCase()].concat(this.detailHandlerDefs.any)
      tentativeHandlerDefs = tentativeHandlerDefs.filter(def => def.id == id)
      if (tentativeHandlerDefs.length > 0) {
        let tentativeHandlerDef = tentativeHandlerDefs[0]
        if (tentativeHandlerDef.isApplicable(result))
          return tentativeHandlerDef
      }
    }
  }
  
  async getdetailHandlerDefs(result) {
    if (result.isNewQuery()) {
      return []
    } else {
      let tentativeHandlerDefs = []
      if (typeof result.typeId === 'undefined' || typeof this.detailHandlerDefs[result.typeId.toLowerCase()] === 'undefined') 
        tentativeHandlerDefs = this.detailHandlerDefs.any
      else 
        tentativeHandlerDefs = this.detailHandlerDefs[result.typeId.toLowerCase()].concat(this.detailHandlerDefs.any)
        
      let applicableHandlerDefs = []
      let tentativeParentHandlerDefs = []
      for (let tentativeHandlerDef of tentativeHandlerDefs) 
        if ((typeof tentativeHandlerDef.isApplicable === 'undefined') || (tentativeHandlerDef.isApplicable(result))) 
          applicableHandlerDefs.push(tentativeHandlerDef)
        else
          tentativeParentHandlerDefs.push(tentativeHandlerDef)
        
      let relations = await result.getRelations()
      let parentResults = relations.parents
        
      for (let parentResult of parentResults)
        for (let tentativeParentHandlerDef of tentativeParentHandlerDefs)
          if (tentativeParentHandlerDef.isApplicable(parentResult))
            applicableHandlerDefs.push(tentativeParentHandlerDef)
          
        
      return this.getDetailHandlersForResult(result).concat(applicableHandlerDefs)
    }
  }

  //This function may be overridden by implementations of search
  //Implementations may want to return this.customButtonDefs.any
  getCustomButtonDefs(result) {
    let buttonDefs = []
    if (result.isNewQuery()) {
      return buttonDefs
    } else {
      let tentativeButtonDefs = []
      if (typeof result.typeId === 'undefined' || typeof this.customButtonDefs[result.typeId.toLowerCase()] === 'undefined') 
        tentativeButtonDefs = this.getCustomButtonsForResult(result).concat(this.customButtonDefs.any)
      else 
        tentativeButtonDefs = this.getCustomButtonsForResult(result).concat(this.customButtonDefs[result.typeId.toLowerCase()].concat(this.customButtonDefs.any))
        
      for (let tentativeButtonDef of tentativeButtonDefs) 
        if (typeof tentativeButtonDef.isApplicable === 'undefined') 
          buttonDefs.push(tentativeButtonDef)
        else if (tentativeButtonDef.isApplicable(result)) 
          buttonDefs.push(tentativeButtonDef)
      return buttonDefs
    }
  }

  //This function may be overridden by implementations of search
  getCustomButtonsForResult() {
    return []
  }

  //{"buttonText": text, "buttonImage": imageUri, "handler": function(result, detailsContent)[, more: true|false]};
  //handler must return a thenable resolving to detail contents

  /**
   * 
   * @param {module:js/details/DetailsHandlerDef} detailHandlerDef
   * @param typeId
   */
  addDetailHandlerDef(detailHandlerDef, typeId) {
    this.detailHandlerDefs.all.push(detailHandlerDef)
    if (typeof typeId === 'undefined') {
      this.detailHandlerDefs.any.push(detailHandlerDef)
    } else {
      const lowerType = typeId.toLowerCase()
      if (typeof this.detailHandlerDefs[lowerType] === 'undefined') 
        this.detailHandlerDefs[lowerType] = [detailHandlerDef]
      else 
        this.detailHandlerDefs[lowerType].push(detailHandlerDef)
        
    }
  }
  
  getAllDetailHandlers() {
    return this.detailHandlerDefs.all
  }

  /**
   * Displays an icon next to each result.
   * @param {Object} customButtonDef
   * @param {string} customButtonDef.buttonText buttonText Text or tooltip
   * @param {string} customButtonDef.buttonImage URL of image (20*20)
   * @param {function} customButtonDef.callBack Called when icon is clicked (callBack(result))
   * @param {string} customButtonDef.target Name of target
   **/
  addCustomButtonDef(customButtonDef, typeId) {
    //fix the callback to make sure the result is completed before the callback is called
    const originalCallback = customButtonDef.callBack
    customButtonDef.callBack = async(result) => {
      return originalCallback(await result.complete())
    }
    //Put in correct array of buttondefs (any or target)
    if (typeof typeId === 'undefined') {
      this.customButtonDefs.any.push(customButtonDef)
    } else {
      const lowerType = typeId.toLowerCase()
      if (typeof this.customButtonDefs[lowerType] === 'undefined') 
        this.customButtonDefs[lowerType] = [customButtonDef]
      else 
        this.customButtonDefs[lowerType].push(customButtonDef)
        
    }
  }

}
