import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"

export default class JordstykkeSekundaerInfoProvider extends DetailsHandlerDef {    
  constructor(options={}) {
    if (!options.fetcher)
      throw new Error("JordstykkeInfoProvider called without options.fetcher")

    let defaultOptions = {
      buttonText: "Andre oplysninger"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.source === "Kortforsyningen" && result.typeId === "matrikelnumre"))
    }

    this.dafFetcher = options.fetcher
  }
  
  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "Kortforsyningen" && typeId === "matrikelnumre") {
      let ejerlavskode = result.data.elavskode
      let matrikelnr = result.data.matrnr
      let dafJordstykke = await this.dafFetcher.read("matrikel", "matrikel", "jordstykke", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
      if (dafJordstykke)
        return await this.getJordstykkeDetails(dafJordstykke)
    }
  }
  
  async getJordstykkeDetails(fokusJordStykke) {
    // eslint-disable-next-line no-unused-vars
    let exampleSfeResponse = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {
            "BFEnummer": 3140901,
            "arbejderbolig": false,
            "bygningPaaFremmedGrund": [],
            "ejerlejlighed": [],
            "erFaelleslod": false,
            "forretningshaendelse": "Afledt ændring",
            "sekundaerForretningshaendelse": [],
            "forretningsomraade": "52.20.05",
            "forretningsproces": "Anden sagskategori",
            "hovedejendomOpdeltIEjerlejligheder": false,
            "id_lokalId": "3140901",
            "id_namespace": "http://data.gov.dk/Matriklen/SamletFastEjendom",
            "jordstykke": [
              {
                "type": "Jordstykke",
                "properties": {
                  "arealberegningsmetode": "Areal beregnet efter opmåling - o",
                  "arealbetegnelse": null,
                  "arealtype": null,
                  "brugsretsareal": false,
                  "centroide": [
                    {
                      "geometri": "POINT(687507.577 6071959.899)",
                      "senesteSagLokalId": "7764108",
                      "status": "Gældende"
                    }
                  ],
                  "delnummer": null,
                  "ejerlavskode": 521455,
                  "ejerlavsnavn": "Sdr. Vedby By, Nykøbing F.",
                  "faelleslod": false,
                  "forretningshaendelse": "Afledt ændring",
                  "sekundaerForretningshaendelse": [],
                  "forretningsomraade": "52.20.05",
                  "forretningsproces": "Anden sagskategori",
                  "fredskov": {
                    "fredskovsareal": null,
                    "omfang": null
                  },
                  "id_lokalId": "1279873",
                  "id_namespace": "http://data.gov.dk/Matriklen/Jordstykke",
                  "jordrente": {
                    "omfang": null
                  },
                  "klitfredning": {
                    "klitfredningsareal": null,
                    "omfang": null
                  },
                  "kommuneLokalId": "0376",
                  "majoratskov": {
                    "majoratskovsnummer": null,
                    "omfang": null
                  },
                  "matrikelnummer": "1ab",
                  "paataenktHandling": null,
                  "regionLokalId": "1085",
                  "registreretAreal": 1252,
                  "registreringFra": "2018-06-03T07:18:13.526534+02:00",
                  "registreringTil": null,
                  "registreringsaktoer": "Matriklen",
                  "samletFastEjendomBFEnummer": 3140901,
                  "senesteSagLokalId": "7764108",
                  "skelforretningssagsLokalId": null,
                  "sognLokalId": "7578",
                  "stammerFraJordstykkeLokalId": null,
                  "status": "Gældende",
                  "stormfald": [],
                  "strandbeskyttelse": {
                    "omfang": null,
                    "strandbeskyttelsesareal": null
                  },
                  "supplerendeMaalingSagLokalId": null,
                  "vandarealinkludering": "ukendt",
                  "vejareal": "0",
                  "vejarealberegningsstatus": "vejareal beregnet",
                  "virkningFra": "1977-03-22T00:00:00.000000+01:00",
                  "virkningTil": null,
                  "virkningsaktoer": "Geodatastyrelsen"
                }
              }
            ],
            "landbrugsnotering": null,
            "paataenktHandling": null,
            "registreringFra": "2018-06-03T05:56:19.410888+02:00",
            "registreringTil": null,
            "registreringsaktoer": "Matriklen",
            "senesteSagLokalId": "7764108",
            "status": "Gældende",
            "udskiltVej": false,
            "virkningFra": "1977-03-22T00:00:00.000000+01:00",
            "virkningTil": null,
            "virkningsaktoer": "Geodatastyrelsen"
          }
        }
      ]
    }
    let detailItemsList = new DetailItemsList({
      itemType: "labelvalue",
      header: "Andre oplysninger",
      name: "jordstykke_anden_info",
      infoItemsHeaders: [
        {type: "labelvalue", label: ""},
        {type: "labelvalue", label: "Areal (m2)", valueformat: "int"}]
    })

    let falleslodOplysning = fokusJordStykke.properties.faelleslod? "Ja" : "-"
    detailItemsList.append({
      type: "labelvalue",
      value: "Er fælleslod",
      infoItems: [
        {type: "labelvalue", value: falleslodOplysning},
        {type: "labelvalue", value: null}]
    })

    let strandbeskyttelsesOplysning = fokusJordStykke.properties.strandbeskyttelse.omfang ? fokusJordStykke.properties.strandbeskyttelse.omfang : "-"
    let strandbeskyttelsesAreal = fokusJordStykke.properties.strandbeskyttelse.strandbeskyttelsesareal ? fokusJordStykke.properties.strandbeskyttelse.strandbeskyttelsesareal : null
    detailItemsList.append({
      type: "labelvalue",
      value: "Strandbeskyttelse",
      infoItems: [
        {type: "labelvalue", value: strandbeskyttelsesOplysning},
        {type: "labelvalue", value: strandbeskyttelsesAreal, valueformat: "int"}]
    })

    let klitfredningsOplysning = fokusJordStykke.properties.klitfredning.omfang ? fokusJordStykke.properties.klitfredning.omfang : "-"
    let klitfredningsAreal = fokusJordStykke.properties.klitfredning.klitfredningsareal ? fokusJordStykke.properties.klitfredning.klitfredningsareal : null
    detailItemsList.append({
      type: "labelvalue",
      value: "Klitfredning",
      infoItems: [
        {type: "labelvalue", value: klitfredningsOplysning},
        {type: "labelvalue", value: klitfredningsAreal, valueformat: "int"}]
    })

    let staormfaldsOplysning = fokusJordStykke.properties.stormfald.length > 0 ? fokusJordStykke.properties.stormfald[0] : "-"
    detailItemsList.append({
      type: "labelvalue",
      value: "Stormfald",
      infoItems: [
        {type: "labelvalue", value: staormfaldsOplysning},
        {type: "labelvalue", value: null}]
    })

    let majoratskovsOplysning = "-"
    let majoratskovsAreal = null
    if (fokusJordStykke.properties.majoratskov) {
      majoratskovsOplysning = fokusJordStykke.properties.majoratskov.majoratskovsnummer ? fokusJordStykke.properties.majoratskov.majoratskovsnummer : "-"
      majoratskovsAreal = fokusJordStykke.properties.majoratskov.omfang ? fokusJordStykke.properties.majoratskov.omfang : null
    } else {
      majoratskovsOplysning = fokusJordStykke.properties.majoratsskov.majoratsskovsnummer ? fokusJordStykke.properties.majoratskov.majoratskovsnummer : "-"
      majoratskovsAreal = fokusJordStykke.properties.majoratsskov.omfang ? fokusJordStykke.properties.majoratskov.omfang : null
    }
    detailItemsList.append({
      type: "labelvalue",
      value: "Majoratskov",
      infoItems: [
        {type: "labelvalue", value: majoratskovsOplysning},
        {type: "labelvalue", value: majoratskovsAreal, valueformat: "int"}]
    })
    return [detailItemsList.asItem()]  
  }
  
}