import domutil from "../../../lib/domutil.js"

export default class {
  static themeResult(result, customButtonDefs, extraButtonDefs, options) {
    const searcher = result.searcher
    if (result.searcher && typeof searcher.themeResult == "function") {
      return searcher.themeResult(result)
    } else {
      let output
      if (options && options.format == "compact") {
        output = domutil.createElement("div", "ssResult ssCompactResult")

        var outputTitle = domutil.createElement(
          "div",
          "ssResultTitle",
          result.title
        )
        domutil.setAttribute(outputTitle, "title", result.title)
        output.append(outputTitle)

        output.append(
          this.createCustomButtons(result, customButtonDefs, extraButtonDefs)
        )
      } else {
        output = domutil.createElement("div", "ssResult")
        let imageContainer = domutil.createElement("div", "ssResultImage")
        domutil.setAttribute(imageContainer, "aria-hidden", true)
        if (
          result.hasOwnProperty("image") &&
          result.image !== null &&
          result.image !== "" &&
          !(typeof options !== "undefined" && options.format === "noimage")
        ) {
          imageContainer.append(
            domutil.createImageElement(result.image, result.title)
          )
        }
        output.append(imageContainer)

        let titleContainer = domutil.createElement(
          "div",
          "ssResultTitle",
          result.title
        )

        let ariatitle = result.title
        if (result.hasOwnProperty("description") && result.description !== null) {
          titleContainer.append(
            domutil.createElement(
              "span",
              "ssResultDescription",
              result.description
            )
          )
          ariatitle += ', ' + result.description
        }
        domutil.setAttribute(output, "role", "button")
        domutil.setAttribute(output, "aria-label", ariatitle)
        domutil.setAttribute(titleContainer, "aria-hidden", true)
        domutil.setAttribute(titleContainer, "title", result.title)
        output.append(titleContainer)

        output.append(
          this.createCustomButtons(result, customButtonDefs, extraButtonDefs)
        )
      }
      return output
    }
  }

  static createCustomButtons(
    result,
    customButtonDefs,
    extraButtonDefs,
    options
  ) {
    let createFunction = this.createButton
    let customButtonCount = 0
    let extraButtonCount = 0
    if (options && options.format === "list")
      createFunction = this.createIconAndText

    const buttonsContainer = domutil.createElement("div", "ssButtonsContainer")
    let custombuttons = domutil.createElement("div", "ssCustomButtons")
    if (customButtonDefs !== undefined && result.searcher) {
      customButtonCount = customButtonDefs.length
      for (let customButtonDef of customButtonDefs) {
        let button = createFunction(result, customButtonDef)
        domutil.addClass(button, "ssCustombutton")
        custombuttons.append(button)
      }
    }
    let extrabuttons = domutil.createElement("div", "ssExtraButtons")
    if (extraButtonDefs !== undefined) {
      extraButtonCount += extraButtonDefs.length
      for (let extraButtonDef of extraButtonDefs) {
        let button = createFunction(result, extraButtonDef)
        domutil.addClass(button, "ssExtraButton")
        extrabuttons.append(button)
      }
    }
    domutil.addClass(
      buttonsContainer,
      "buttoncount" + (customButtonCount + extraButtonCount)
    )
    domutil.addClass(custombuttons, "buttoncount" + customButtonCount)
    domutil.addClass(extrabuttons, "buttoncount" + extraButtonCount)

    domutil.setAttribute(buttonsContainer, "aria-hidden", true)

    buttonsContainer.append(custombuttons)
    buttonsContainer.append(extrabuttons)
    return buttonsContainer
  }

  static createIconAndText(result, buttonDef) {
    const button = domutil.createElement("div")
    if (buttonDef.buttonImage !== undefined)
      button.append(
        domutil.createImageElement(buttonDef.buttonImage, buttonDef.buttonText)
      )

    button.append(domutil.createElement("span", "", buttonDef.buttonText))
    if (buttonDef.callBack) {
      let callBack = buttonDef.callBack
      domutil.setAttribute(button, "role", "button")
      button.addEventListener("click", () => {
        callBack(result)
        return false
      })
    }
    return button
  }

  static createButton(result, buttonDef) {
    let button = null
    if (buttonDef.buttonImage === undefined) {
      button = domutil.createElement("span", "ssButton", buttonDef.buttonText)
    } else {
      button = domutil.createImageElement(
        buttonDef.buttonImage,
        buttonDef.buttonText
      )
    }
    if (buttonDef.callBack) {
      let callBack = buttonDef.callBack
      domutil.setAttribute(button, "role", "button")
      button.addEventListener("click", async () => {
        let newButtonDef = callBack(result)
        if (newButtonDef instanceof Promise)
          newButtonDef = await newButtonDef
        if (typeof newButtonDef !== "undefined" && newButtonDef !== null)
          if (newButtonDef.buttonImage === undefined) {
            domutil.empty(button)
            domutil.append(button, newButtonDef.buttonText)
          } else {
            domutil.setAttributes(button,{
              src: newButtonDef.buttonImage, 
              title: newButtonDef.buttonText
            }
            )
          }
        return false
      })
    }
    return button
  }
}
