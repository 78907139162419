/**
 * @module
 */

/**
 * Convenience class. Assists detailHandlers in creating lists of detail items suited to be rendered table-like by clients.<br><br>
 * Clients are <strong>not</strong> required to render labels of list items. Handlers should therefore not specify individual labels for items. Use list header and infoItemHeaders instead.<br><br>
 * if <i>itemType</i> is set the list may only contain items of the same type<br><br>
 *   
 * 
 * InfoItems (optional for typed lists):
 *   <ul>
 *     <li><i>infoItemsHeaders</i> describe column headers and </li>
 *     <li><i>infoItems</i> populate each row in the rendered table</li>
 *   </ul>
 * 
 * Clients are <strong>not</strong> required to render infoItems
 * @api
 */
export default class DetailItemsList {
  /**
   * @param {Object} options
   * @param {string} [options.itemType] The type of items in the list. If set only items of this type may be appended
   * @param {string} [options.header] Describes the list. Suitable as table caption
   * @param {string} [options.image] Url to icon suitable as icon before caption
   * @param {string} [options.isHomogenous] The items represent the same concept
   * @param {Object[]} [options.infoItemsHeaders] Array of {type: "labelvalue"|"link"|"result" , label: "column header" } objects, where
   *   <ul>
   *     <li>type is the type of infoItem and</li>
   *     <li>label is a description suitable as column header</li>
   *   </ul>
   *   Only applicable when itemType is set. Else disregarded
   * @example
   * let detailItemsList = new DetailItemsList({
   *   itemType: "result",
   *   header: "Elever",
   *   isHomogenous: true,
   *   image: icons.person,
   *   infoItemsHeaders: [
        {type: "result", label: "Adresse"},
        {type: "labelvalue", label: "Alder"}]
   * })
   * @api
   **/
  constructor(options = {}) {

    this.header = null
    this.image = null
    this.itemType = null
    this.infoItemsHeaders = []
    this.isHomogenous = false
    
    this.name = "default"
    if (options.name)
      this.name = options.name

    if (options.header)
      this.header = options.header

    if (options.image)
      this.image = options.image

    if (options.itemType) {
      this.itemType = options.itemType
      if (options.infoItemsHeaders) {
        this.infoItemsHeaders = options.infoItemsHeaders
        this.isHomogenous = true
      } else {
        if (options.isHomogenous)
          this.isHomogenous = options.isHomogenous
      }
    }
    
    this.items = []
  }

  /**
   * @param {Object} detailItem A detailItem optionally decorated with infoItems
   * @param {Object[]} [detailItem.infoItems] Array of
   * @api
   * @example
   * let personResult = ...
   * let adressResult = dawaSearcher.get(adressId, "adresse")
   * detailItemsList.append({
   *   type: "result",
   *   result: personResult,
   *   infoItems: [{
              type: "result",
              result: adressResult},
            {
              type: "labelvalue",
              label: "Alder",
              value: 12,
              valueformat: "int"}]
   * })
   **/
  append(detailItem) {
    //Adds a detailItem to the list.
    if (this.itemType) {
      // Checks that the item fits with the list defintion
      if (!detailItem.type || detailItem.type != this.itemType)
        throw new Error("DetailItems must be of type: " + this.itemType)
      if (this.infoItemsHeaders.length > 0 && typeof detailItem.infoItems === 'undefined')
        throw new Error("DetailItem must must have infoItems")
      if (this.infoItemsHeaders.length > 0 && (this.infoItemsHeaders.length !== detailItem.infoItems.length))
        throw new Error("DetailItem must must have " + this.infoItemsHeaders.length + " infoItems")
    }
    this.items.push(detailItem)
  }

  /**
   * Returns an object representing the detailItemsList
   * @example
   * let detailItemsListItem = detailItemsList.asItem()
   * assertEquals(detailItemsListItem,{
      type: "list",
      itemType: "result",
      isHomogenous: true,
      header: "Elever",
      image: "data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cD............"
      infoItemsHeaders: [
        {type: "result", label: "Adresse"},
        {type: "labelvalue", label: "Alder"}],
      items: [
        {
          type: "result",
          result: personResult,
          infoItems: [
            {
              type: "result",
              result: adressResult},
            {
              type: "labelvalue",
              label: "Alder",
              value: 12,
              valueformat: "int"}]}
      ]
    })
   * @api
   */
  asItem() {
    
    let item = {
      type: "list",
      name: this.name,
      itemType: this.itemType,
      isHomogenous: this.isHomogenous
    }

    if (this.header)
      item.header = this.header
    if (this.image)
      item.image = this.image
    if (this.infoItemsHeaders.length > 0)
      item.infoItemsHeaders = this.infoItemsHeaders
    
    item.items = this.items

    return item
  }
  
  toJSON() {
    return this.asItem()
  }
}