import WKT from 'ol/format/WKT.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import Point from 'ol/geom/Point.js'
import {fromExtent} from 'ol/geom/Polygon.js'

function parse(wkt, crsEpsg) {
  const geometry = new WKT().readGeometry(wkt)
  const geojsonObject = new GeoJSON().writeGeometryObject(geometry)
  if (crsEpsg) {
    geojsonObject.crs = {
      "type": "name",
      "properties": {
        "name": `epsg:${crsEpsg}`
      }
    }
  }
  return geojsonObject
}

function convert(geojson) {
  const geometry = new GeoJSON().readGeometry(geojson)
  const wkt = new WKT().writeGeometry(geometry)
  return wkt
}

function getInteriorPoint(geometry) {
  // Accepts wkt and geoJson - returns same type
  let olGeom
  if (isObject(geometry)) {
    //geojson
    olGeom = new GeoJSON().readGeometry(geometry)
  } else {
    olGeom = new WKT().readGeometry(geometry)
  }

  let geomType = olGeom.getType()
  let interiorPoint
  if (geomType ==='MultiPolygon') {
    let biggestPolygon = olGeom.getPolygons().sort((polygon1, polygon2) => {
      return polygon2.getArea() - polygon1.getArea()
    })[0]
    interiorPoint = biggestPolygon.getInteriorPoint()
  } else if (geomType ==='Polygon') {
    interiorPoint = olGeom.getInteriorPoint()
  } else if (geomType ==='MultiLineString') {
    let firstLine = olGeom.getLineString(0)
    let firstCoordinates = firstLine.getFirstCoordinate()
    interiorPoint = new Point(firstCoordinates)
  } else if (geomType ==='LineString') {
    let firstCoordinates = olGeom.getFirstCoordinate()
    interiorPoint = new Point(firstCoordinates)
  } else if (geomType ==='MultiPoint') {
    interiorPoint = olGeom.getPoint(0)
  } else if (geomType ==='Point') {
    interiorPoint = olGeom
  } else {
    return null
  }

  //Fix coordinates if Z or M returned
  let coordinates = interiorPoint.getCoordinates()
  if (coordinates.length > 2) {
    let fixedCoordinates = [coordinates[0], coordinates[1]]
    interiorPoint = new Point(fixedCoordinates)
  }

  // Accepts wkt and geoJson - returns same type
  if (isObject(geometry)) {
    //geojson
    let returnObject = new GeoJSON().writeGeometryObject(interiorPoint)
    if (geometry.crs)
      returnObject.crs = geometry.crs
    return returnObject
  } else {
    return new WKT().writeGeometry(interiorPoint)
  }
}

function isObject(val) {
  if (val === null) {
    return false
  }
  return ( (typeof val === 'function') || (typeof val === 'object') )
}

function getExtent(polygonGometry) {
  // Accepts wkt and geoJson - returns same type
  let olGeom
  if (isObject(polygonGometry)) {
    //geojson
    olGeom = new GeoJSON().readGeometry(polygonGometry)
  } else {
    olGeom = new WKT().readGeometry(polygonGometry)
  }

  let extent = olGeom.getExtent()
  let extentPolygon = fromExtent(extent)

  // Accepts wkt and geoJson - returns same type
  if (isObject(polygonGometry)) {
    //geojson
    let returnObject = new GeoJSON().writeGeometryObject(extentPolygon)
    if (polygonGometry.crs)
      returnObject.crs = polygonGometry.crs
    return returnObject
  } else {
    return new WKT().writeGeometry(extentPolygon)
  }

}

export function getWKTParser() {
  return {
    parse,
    convert,
    getInteriorPoint,
    getExtent
  }
}
