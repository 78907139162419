import Fetcher from "./Fetcher.js"
import PersonSearcher from "./PersonSearcher.js"
import CvrSearcher from "./CvrSearcher.js"
import ClassRegistry from "../../ClassRegistry.js"
import CvrRegistreringerForHusnummer from "./CvrRegistreringerForHusnummer.js"
const redataTypes = new ClassRegistry("Septima.Search.Redata.")

const typesToAdd = {
  Fetcher,
  PersonSearcher,
  CvrSearcher,
  CvrRegistreringerForHusnummer
}

for (let [key, value] of Object.entries(typesToAdd))
  redataTypes.addClass(value, key)

export default redataTypes