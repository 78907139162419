/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
export default class CvrLinkHandler extends DetailsHandlerDef {
  constructor(options= {}) {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      try{
        // eslint-disable-next-line no-console
        console.warn("CvrLinkHandler is deprecated - Use Septima.Search.DataApi")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }

    let defaultOptions = {
      buttonText: "link",
      buttonImage: icons.searchers.cvr,
      targets: [{source: "cvr", typeId: "produktionsenhed"}]
    }
    super(Object.assign(defaultOptions, options))
    this.handlerFunction = this.myHandler
  }

  async myHandler() {
    var items = []
    return items
  }
}