import EsriJSON from 'ol/format/EsriJSON.js'
import GeoJSON from 'ol/format/GeoJSON.js'

function parse(esrijson) {
  const geometry = new EsriJSON().readGeometry(esrijson)
  const geojsonObject = new GeoJSON().writeGeometryObject(geometry)
  return geojsonObject
}

export function getArcGisParser() {
  return {
    parse
  }
}
