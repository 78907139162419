export default class HusnummerAdresseTilBfeResolver {
  constructor(options={}) {
    this.fetcher = options.fetcher
    this.medtagAdresser = false
    if (options.medtagadresser == true)
      this.medtagAdresser = true
  }
  
  async resolveAdresse(adresseUUID) {
    let resolvedBfes = []
    let bfeForAdresseResult = await this.fetcher.read ("ebr", "ejendomsbeliggenhed", "bfenradresse", {AdresseId: adresseUUID})
    for (let bfe of bfeForAdresseResult.features)
      resolvedBfes.push(bfe.properties.bestemtFastEjendomBFENr)
    return resolvedBfes
    
  }
  
  async resolveHusnummer (husnummerUUID) {
    let resolvedBfes = {
      sfe: [],
      bfg: [],
      ejl: []
    }
    //resolves to Array of BFE
    //https://docs.google.com/document/d/1S2C9_7KPSLhZUmq5k1ha5VgroSZ27Js8GrZwypWFkzU/edit
    let adresserResultPromise
    if (this.medtagAdresser)
      adresserResultPromise = this.fetcher.read ("DAR", "DAR", "Adresse", {Status: 3, MedDybde: "false", Husnummer: husnummerUUID})

    let jordstykkeBfe = null
    let jordstykkeForHusnummerResult = await this.fetcher.read ("DAR", "DAR", "husnummertiljordstykke", {husnummerId: husnummerUUID})
    if (jordstykkeForHusnummerResult && jordstykkeForHusnummerResult.gældendeJordstykke) {
      jordstykkeBfe = jordstykkeForHusnummerResult.gældendeJordstykke.samletFastEjendom
      resolvedBfes.sfe.push(jordstykkeBfe)
    }
    
    let bfeForHusnummerResult = await this.fetcher.read ("ebr", "ejendomsbeliggenhed", "bfenradresse", {HusnummerId: husnummerUUID})
    for (let bfe of bfeForHusnummerResult.features) {
      if (!(jordstykkeBfe && bfe.properties.Ejendomstype === "SamletFastEjendom"))
        resolvedBfes.bfg.push(bfe.properties.bestemtFastEjendomBFENr)
    }

    if (this.medtagAdresser) {
      let adresser = []
      let adresserResult = await adresserResultPromise
      for (let adresseResult of adresserResult)
        adresser.push(adresseResult.id_lokalId)

      let bfeForAdresserResult = await this.fetcher.read ("ebr", "ejendomsbeliggenhed", "bfenradresse", {AdresseId: adresser.join("|")})
      for (let bfe of bfeForAdresserResult.features)
        resolvedBfes.ejl.push(bfe.properties.bestemtFastEjendomBFENr)
    }

    return resolvedBfes
  }
}