/**
 * @module
 */
import DetailsHandlerDef from "./DetailsHandlerDef.js"
import icons from "../resources/icons.js"
/**
 * Samler flere detailhandlere i ét output
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration</caption>
  _type: Septima.Search.DawaSearcher
  detailhandlers:
    - _type: Septima.Search.ComposedDetailsHandler
      _options:
        id: "OmHusnummer"
        more: true
        buttonText: 'Om husnummeret'
      detailhandlers:
        - _type: Septima.Search.HusnummerInfoProvider
          _options:
            geosearcher:
              _ref: "$.mysearchers.geosearcher"
        - _type: Septima.Search.AdresserForHusnummerProvider
 * @api
 **/
export default class ComposedDetailsHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.id=ComposedDetailsHandler_xxx]
   **/
  constructor(options = {}) {
    let defaultOptions = {
      id: "ComposedDetailsHandler" + "_" + Math.floor(Math.random() * 999999999)
    }
    super(Object.assign(defaultOptions, options))

    this._detailHandlers = []

    if (options.detailhandlers)
      this._detailHandlers = options.detailhandlers

    //This is deprecated (spelling error. Keep backwards comp)
    if (options.detailshandlers)
      this._detailHandlers = options.detailshandlers

    this.handlerFunction = this.myHandler
    
    this.isApplicableFunction = this.myIsApplicable

  }

  set detailhandlers(dhCollection) {
    for (let detailhandler of dhCollection)
      this.addDetailsHandler(detailhandler)
  }
  
  //This is deprecated (spelling error. Keep backwards comp)
  set detailshandlers(dhCollection) {
    for (let detailhandler of dhCollection)
      this.addDetailsHandler(detailhandler)
  }

  get detailshandlers() {
    return this._detailHandlers
  }
    
  addDetailsHandler(detailHandler) {
    this._detailHandlers.push(detailHandler)
  }

  async myHandler(result, logger, contextResult) {
    let detailItems = []
    const calls = []
    for (let detailHandler of this._detailHandlers) {
      if (detailHandler.isApplicable(result, contextResult))
        calls.push({detailHandler, promise: detailHandler.handler(result, true, contextResult)})
    }
    
    await Promise.allSettled(calls.map((call)=>{
      return call.promise
    }))
      
    for (let call of calls)
      try {
        let detailHandlerResult = await call.promise
        detailItems = [...detailItems, ...detailHandlerResult]
      } catch(e) {
        try {
          let label = call.detailHandler.getbuttonText(result)
          if (label !== "") {
            detailItems.push({
              "type": "error",
              "icon": icons.infoRed,
              "label": label,
              "value": "Der skete en fejl",
              "message": e.message
            })
          }
        } catch (e2) {
          throw e
        }
        // Stupid noop to fool eslint
      }
    return detailItems
  }

  myIsApplicable(result, contextResult) {
    for (let detailHandler of this._detailHandlers) 
      if (detailHandler.isApplicable(result, contextResult)) 
        return true
    return false
  }

}
