import QueryResult from './QueryResult.js'

export default function DataFetcher(controller, completeCallback) {
  this.completeCallback = completeCallback ? completeCallback : ()=>{}
  this.async = true
  this.controller = controller
  //this.searchers = []
  this._isActive = true
  this.onCancelHandlers = []
  this.searcherStates = []
  
  this.finalQueryResult = new QueryResult(null)
}

DataFetcher.prototype.fetch = function(queries) {
  //Do not move to async await. Needs to be parallel
  return new Promise((resolve)=>{
    for (let query of queries) {
      let searcher = query.searcher
      this.searcherStates.push({"searcherId": searcher.getId(), "state": "pending"})
      searcher.asyncFetchData(query).then(
        (queryResult) => {
          this.fetchSuccess(queryResult)
          if (!this.anyPending())
            resolve(this.finalQueryResult)
        },
        (error) => {
          this.fetchError(searcher, error)
          if (!this.anyPending())
            resolve(this.finalQueryResult)
        }
      ).catch(error => {
        this.fetchError(searcher, error)
        if (!this.anyPending())
          resolve(this.finalQueryResult)
      })
    } 
  })
}

DataFetcher.prototype.cancel = function() {
  for (let onCancelHandler of this.onCancelHandlers) 
    onCancelHandler()
  this.clean()
}

DataFetcher.prototype.clean = function() {
  if (this._isActive) {
    this._isActive = false
    this.controller = null
    this.onCancelHandlers = []
    this.searcherStates = []
    this.completeCallback([], true)
  }
}

DataFetcher.prototype.fetchSuccess = function(queryResult) {
  if (this._isActive) {
    const searcher = queryResult.getSearcher()
    this.setSearcherState(searcher.getId(), "complete", queryResult)
    if (this.async) 
      this.completeCallback(queryResult.getAllResults(), false)
    this.finishIfNoPending()
  }
}

DataFetcher.prototype.anyPending = function() {
  for (let searcherState of this.searcherStates) 
    if (searcherState.state === "pending") 
      return true
  return false
}

DataFetcher.prototype.fetchError = function(searcher, errorThrown) {
  if (this._isActive) {
    this.setSearcherState(searcher.getId(), "error")
    this.controller.fetchError(this, searcher, errorThrown)
    this.finishIfNoPending()
  }
}

DataFetcher.prototype.setSearcherState = function (searcherId, state, queryResult) {
  for (let searcherState of this.searcherStates)
    if (searcherState.searcherId === searcherId) {
      searcherState.state = state
      if (state == "complete" && queryResult)
        searcherState.queryResult = queryResult
    }
}

DataFetcher.prototype.finishIfNoPending = function() {
  if (!this.anyPending()) {
    for (let thisSearcherState of this.searcherStates)
      if (thisSearcherState.state == "complete") {
        let queryResult = thisSearcherState.queryResult
        this.finalQueryResult.add(queryResult)
      }
    this.completeCallback(this.finalQueryResult, true)
    this.clean()
  }
}
