import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"

import DawaSearcher from "../DawaSearcher.js"

export default class HusnumreForJordstykke extends DetailsHandlerDef {
  constructor(options={}) {

    if (!options.fetcher)
      throw "HusnumreForJordstykke must be created with options.fetcher"

    let defaultOptions = {
      buttonText: "Adresser"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre")
    }

    this.fetcher = options.fetcher
    this.dawaSearcher = new DawaSearcher()
  }

  async myHandler(matResult) {
    let items = []
    let husnumreResponse = await this.fetcher.read("dar", "dar", "husnummer", {jordstykke: matResult.data.lokalId}, this.getLogger())
    let detailItemsList = new DetailItemsList({
      header: "Adresser",
      name: "husnumre"
    })
    if (husnumreResponse && husnumreResponse.length > 0) {
      for (let husnummer of husnumreResponse) {
        let husnummerId = husnummer.id_lokalId
        let adresseResult = await this.dawaSearcher.get(husnummerId, "adresse")
        if (adresseResult) {
          detailItemsList.append({
            type: "result",
            result: adresseResult
          })
        }
      }
      items.push(detailItemsList.asItem())
    }
    if (items.length > 0) {
      return items
    } else {
      detailItemsList.append({
        type: "labelvalue",
        value: "Ingen adresser registreret på jordstykket"
      })
      items.push(detailItemsList.asItem())
      return items
    }
  }
}