/**
 * @module
**/
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
/**
 * Viser info for en sogn fra GeoSearch
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.GeoSearch
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.InfoForSognProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 *geoSearcher.addDetailHandlerDef(new Septima.Search.InfoForSognProvider())
 *
 * @example <caption>ES6:</caption>
 * import InfoForSognProvider from './searchers/detailhandlers/InfoForSognProvider.js'
 * geoSearcher.addDetailHandlerDef(new InfoForSognProvider())
 * @api
 **/
export default class InfoForSognProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields=["presentationString","id", "name"] Felter, der skal medtages i info
   **/
  constructor(options={}) {
    let defaultOptions = {
      buttonText: "Sogn",
      more: true,
      targets: [{source: "Kortforsyningen", typeId: "sogne"}]
    }
    super(Object.assign(defaultOptions, options))
    
    this.handlerFunction = this.myHandler

    //Default fields
    this.fields = ["presentationString","id", "name"]

    if (typeof options.fields !== 'undefined')
      this.fields = options.fields
  }

  async myHandler(result) {
    let items = []
    for (let field of this.fields) {
      let item = this.createItem(result, field)
      if (typeof item !== 'undefined')
        items.push(item)
    }
    return items
  }
  
  createItem(result, field) {
  
    let props = result.data
    if (field === 'presentationString')
      return {
        type: 'labelvalue',
        label: 'Beskrivelse',
        value: props.presentationString
      }
    else if(field === 'id')
      return {
        type: 'labelvalue',
        label: 'Kommunekode',
        value: props.id
      }
    
    else if(field === 'name')
      return {
        type: 'labelvalue',
        label: 'Navn',
        value: props.name
      }
   
    return 
  }


}