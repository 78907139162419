import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"

export default class HusnumreForVejProvider extends DetailsHandlerDef {
  constructor(options= {}) {

    let defaultOptions = {
      buttonText: "Husnumre for vejen",
      targets: [{source: "Dawa", typeId: "vej"}]
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
  }
  async myHandler(result) {
    let items = []
    let husnummerResults = await result.searcher.getHusnumreForVej(result)
    for (let husnummerResult of husnummerResults) {
      items.push({
        type: "result",
        result: husnummerResult
      })
    }

    let detailItemsListOptions = {itemType: "result",
      isHomogenous: true,
      header: items.length !=1? items.length + " husnumre" : "1 husnummer"}
    let detailItemsList = new DetailItemsList(detailItemsListOptions)
    for (let item of items)
      detailItemsList.append(item)
    return [detailItemsList.asItem()]
  }
}