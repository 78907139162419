/**
 * @module
 */

import Searcher from './Searcher.js'
import { getArcGisParser } from '../util/getArcGisParser.js'
import icons from "../resources/icons.js"
import ResultType from "../ResultType.js"
import * as reproject from "../util/reproject.js"

/**
 * Searches an arcGIS service.<br/><br/>
 * Supports GET<br/>
 * No sq support<br/>
 * @extends module:js/searchers/Searcher
 * 
 *
 * @example <caption>YAML Declaration</caption>
 _type: Septima.Search.ArcGisSearcher
 _options:
   source: "Gentofte ArcGIS"
   catalogUrl: "http://gis.gentofte.dk/ekstern/rest/services"
   serviceName: "NetGIS/NG_Kultur_og_Fritid"
   layers: "Andet grønt område"

 * @example <caption> JS options:</caption>
 * arcGisSearcherOptions = {
 *     // Specific options
 *     catalogUrl : http:// <catalog-url>" eg: "http://kortservice.vejle.dk/gis/rest/services",
 *     serviceName: "[folder/]service" eg: "GISWEB2/FamilieSocial",
 *     layers : "*" or ["layer1Name", "layer2Name"],
 *     // General options
 *     onSelect : function(result) {
 *          log('<b>'+ ArcGIS objekt + ':</b> '+ result.title + '<br/>');
 *     },
 *     matchesPhrase: "Begynder med ",
 *     searchDelay: 200
 * }
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.ArcGisSearcher(arcGisSearcherOptions))
 *
 * @example <caption>ES6:</caption>
 * import ArcGisSearcher from './searchers/ArcGisSearcher.js'
 * controller.addSearcher(new ArcGisSearcher(arcGisSearcherOptions))
 * 
 * @api
 */
export default class ArcGisSearcher extends Searcher {

  /**
   * @param {Object} options arcGisSearcher expects these properties:<br/>
   * @param {string} [options.source=ArcGis] The source of results.
   * @param {string} options.catalogUrl catalog-url eg: "http://kortservice.vejle.dk/gis/rest/services"
   * @param {string} options.serviceName [folder/]service eg: "GISWEB2/FamilieSocial"
   * @param {string} [options.layers=*] "*" or "layer1Name[, layer2Name]"
   */
  constructor(options = {}) {
    let defaultOptions = {
      usesGeoFunctions: true,
      iconURI:icons.result.defaultIcon,
      defaultCrs: "25832"
    }
    super(Object.assign( defaultOptions, options))
    
    if (defaultOptions.defaultCrs == "25832")
      reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")

    if (!options.source) 
      options.source = "ArcGis"
      
    this.source = options.source
    this.catalogUrl = options.catalogUrl
    this.serviceName = options.serviceName
    this.serviceLayers = []
    this.serviceLayerIds = []
    if (options.layers === undefined || this.layersToSearch === "" || this.layersToSearch === " ") 
      this.layersToSearch = "*"
    else
      this.layersToSearch = options.layers
      
    this.serviceURL = this.catalogUrl + '/' + this.serviceName + '/MapServer'

    this.layersReady = this.getLayers()
    this.arcGisParser = getArcGisParser()

  }

  async getLayers() {
    let data = await this.fetch(this.serviceURL + '/layers?f=json', {})
    if (typeof data.layers !== 'undefined') 
      for (let layer of data.layers) 
        if (layer.type === 'Feature Layer' && this.inLayersToSearch(layer)) {
          this.serviceLayers.push(layer)
          this.serviceLayerIds.push(layer.id)
          let resultType = new ResultType({
            id: layer.name
          })
          this.registerType(this.source, resultType)
        }
  }
  
  ready() {
    return this.layersReady 
  }

  inLayersToSearch(layer) {
    if (this.layersToSearch === "*") {
      return true
    }else{
      let arrLayersToSearch = this.layersToSearch.split(",")
      for (let arrLayer of arrLayersToSearch) 
        if (arrLayer.toLowerCase().trim()===layer.name.toLowerCase()) 
          return true
    }
    return false
  }

  async fetchData(query, caller) {
    if (this.serviceLayers === null || this.serviceLayers.length === 0) {
      //Return immediately with no results if layers hasn't been initialized or if no layers are indexed
      caller.fetchSuccess(this.createQueryResult())
      return
    }

    switch (query.type) {
      case 'collapse': {
        caller.fetchSuccess(this.getLayersAsNewQueries())
        break
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let layerIds = this.serviceLayerIds
        if (query.hasTarget) 
          layerIds = [this.getlayerIdFromType(query.target.type)]
          
        const searchText = query.queryString

        if (query.isBlank) {
          if (layerIds.length === 1) {
            let layer = this.getLayerById(layerIds[0])
            try {
              let data = await this.fetch(this.serviceURL +  '/' + layerIds[0] + '/query?f=json', {timeout: 1000, data: {where: '1=1', outFields: '*', returnGeometry: true,  geometryPrecision: 3}})
              data.query = query
              caller.fetchSuccess(this.parseQueryResult(data, layer))
            }catch(e) {
              caller.fetchSuccess(this.getLayersAsNewQueries())
            }
          }else{
            caller.fetchSuccess(this.getLayersAsNewQueries())
          }
        }else{
          try {
            let data = await this.fetch(this.serviceURL + '/find?f=json', {timeout: 1000, data: {returnGeometry: true, searchText: searchText, layers: layerIds.join(), returnFieldName: true}})
            data.query = query
            caller.fetchSuccess(this.parseFindResult(data))
          }catch(e) {
            caller.fetchSuccess(this.getLayersAsNewQueries())
          }
        }
      }
    }
  }
		
  getlayerIdFromType(type) {
    for (let serviceLayer of this.serviceLayers) 
      if (type.toLowerCase() === serviceLayer.name.toLowerCase()) 
        return serviceLayer.id
    return ""
  }
		
  getLayersAsNewQueries() {
    const queryResult = this.createQueryResult()
    for (let layer of this.serviceLayers) {
      let plural = this.getType(this.source, layer.name).plural
      queryResult.addNewQuery(this.source, layer.name, plural, null, "", null, null)
    }
    return queryResult
  }
		
  error(caller, jqXHR, textStatus, errorThrown) {
    caller.fetchError(this, errorThrown)
  }
		
  parseQueryResult(data, layer) {
    const queryResult = this.createQueryResult()
    const query = data.query
    const features = data.features
    const displayFieldName = data.displayFieldName
    let count = features.length
    let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
    let plural = this.getType(this.source, layer.name).plural

    for (let arcFeature of features.slice(0, hitsShown)) {
      arcFeature.layerId = layer.id
      let title = arcFeature.attributes[displayFieldName]
      let geometry = null
      if (arcFeature.geometry) {
        geometry = this.arcGisParser.parse((arcFeature.geometry))
        geometry.crs = {
          "type": "name",
          "properties": {
            "name": `epsg:${this.defaultCrs}`
          }
        }
      }
      let result = queryResult.addResult(this.source, layer.name, title, null, geometry, arcFeature)
      result.id = arcFeature.attributes['OBJECTID']
    }
    if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 )
      queryResult.addNewQuery(this.source, layer.name, plural +  " (" + count + ")", null, "", null, null)
    return queryResult
  }
		
  parseFindResult(data) {
    const queryResult = this.createQueryResult()
    const query = data.query
			
    let returnedLayerIds = this.uniqueBy(data.results, function(result) {
      return result.layerId
    })

    for (let {key, results} of returnedLayerIds) {
      let layer = this.getLayerById(key)
      let type = layer.name
      let plural = this.getType(this.source, type).plural
      let count = results.length
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let arcResult of results.slice(0, hitsShown))
        this.addResultFromArcResult(queryResult, arcResult, type)

      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 )
        queryResult.addNewQuery(this.source, type, plural +  " (" + count + ")", null, query.queryString, null, null)
    }
    return queryResult
  }
    
  addResultFromArcResult(queryResult, arcResult, type) {
    let displayAttribute = arcResult.displayFieldName
    let title = arcResult.attributes[displayAttribute]
    let geometry = null
    if (arcResult.geometry) {
      geometry = this.arcGisParser.parse((arcResult.geometry))
      geometry.crs = {
        "type": "name",
        "properties": {
          "name": `epsg:${this.defaultCrs}`
        }
      }
    }
    let result = queryResult.addResult(this.source, type, title, type, geometry, arcResult)
    result.isComplete = false
    result.id = arcResult.attributes['OBJECTID']
  }
    
  uniqueBy(arr, fn) {
    const count = {}
    const distinct = []
    arr.forEach(function(x) {
      let key = fn(x)
      if (!count[key]) 
        count[key] = [x]
      else
        count[key].push(x)
        
    })
    for (let prop in count) 
      distinct.push({key: prop, results: count[prop]})
    return distinct
  }
		
  getLayer(feature) {
    const layerId = feature.layerId
    return this.getLayerById(layerId)
  }
		
  getLayerById(layerId) {
    for (let serviceLayer of this.serviceLayers) 
      if ("" + layerId === "" + serviceLayer.id) 
        return serviceLayer
    return null
  }
		
  async completeResult(result) {
    if (result.isComplete) {
      return result           
    }else{
      result.isComplete = true
      let feature =  result.data
      let layerId = feature.layerId
      let data = await this.fetch(this.serviceURL +  '/' + layerId + '/query?f=json', {data: {where: 'OBJECTID=' + result.id, outFields: '*', returnGeometry: true,  geometryPrecision: 3}})
      if (data.features && data.features.length > 0 && data.features[0].geometry) {
        feature = data.features[0]
        result.geometry = this.arcGisParser.parse(feature.geometry)
        result.geometry.crs = {
          "type": "name",
          "properties": {
            "name": `epsg:${this.defaultCrs}`
          }
        }
        feature.layerId = layerId
        result.data = feature
      }
      return result
    }
  }
		
  async get(id, type) {
    const queryResult = this.createQueryResult()
    let layerId = this.getlayerIdFromType(type)
    let layer = this.getLayerById(layerId)
    let result
    let data = await this.fetch(this.serviceURL +  '/' + layerId + '/query?f=json', {data: {where: 'OBJECTID=' + id, outFields: '*', returnGeometry: true,  geometryPrecision: 3}})
    if (data.features && data.features.length > 0 && data.features[0].geometry) {
      let arcFeature = data.features[0]
      arcFeature.layerId = layerId
      const displayFieldName = data.displayFieldName
      let title = arcFeature.attributes[displayFieldName]
      result = queryResult.addResult(this.source, layer.name, title, layer.name, null, arcFeature)
      result.id = id
      if (arcFeature.geometry) {
        result.geometry = this.arcGisParser.parse(data.features[0].geometry)
        result.geometry.crs = {
          "type": "name",
          "properties": {
            "name": `epsg:${this.defaultCrs}`
          }
        }
      } 
    }
    return result
  }
}