/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import EnhedsSearcher from "./EnhedsSearcher.js"
import DawaSearcher from "../DawaSearcher.js"

/**
 * Viser BbrEnhedInfo for ejerlejlighed(daf.ejl), husnummer/adresse og bbr enhed (daf.bbr_enhed)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: datafordeler.BbrEnhedInfo
 *   _options:
 *     fetcher:
 *       _type: datafordeler.Fetcher
 *       _options:
 *         ...
 * @api
 **/
export default class BbrEnhedInfo extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText] Titel
   * @param {Object} options.fetcher datafordeler.Fetcher instance
   **/
  constructor(options = {}) {
    if (!options.fetcher)
      throw new Error("BbrEnhedInfo called without options.fetcher")

    let defaultOptions = {}

    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.typeId === "ejl" ||
        result.typeId === "enhedsadresse" ||
        result.typeId === "adresse" ||
        result.typeId === "bbr_enhed"))
    }
    
    this.fetcher = options.fetcher
    this.bygningsSearcher = null
    if (options.bygningssearcher)
      this.bygningsSearcher = options.bygningssearcher
    this.configuredButtonText = null
    if (options.buttonText)
      this.configuredButtonText = options.buttonText 
  }

  getbuttonText(result) {
    if (this.configuredButtonText) {
      return this.configuredButtonText
    } else {
      let tabtext = 'BBRinformation'
      if (result.typeId === "bbr_enhed")
        tabtext = 'Om enheden'
      return tabtext
    }
  }
  
  async myHandler(result) {
    return await this.getBbrDetails(result)
  }

  async getBbrDetails(result) {

    let items = []
    let bbrResponse
    let bbrObjects
    if (result.typeId === 'enhedsadresse') {
      bbrResponse = await this.fetcher.read("bbr", "bbr", "enhed", { AdresseIdentificerer: result.id }, this.getLogger())
      if (bbrResponse.length == 0) {
        return [{
          type: "labelvalue",
          label: "BBR-info",
          value: "Ingen enhed fundet på adressen"
        }]
      } else {
        bbrObjects = bbrResponse
      }
    } else if (result.typeId === 'adresse') {
      bbrObjects = await this.getBbrObjectsForHusnummer(result.id)
      if (bbrObjects.length == 0) {
        return [{
          type: "labelvalue",
          label: "BBR-info",
          value: "Ingen enheder fundet på husnummeret"
        }]
      }
    } else if (result.typeId === 'ejl') {
      bbrResponse = await this.fetcher.read("bbr", "bbr", "enhed", { BFENummer: result.id }, this.getLogger())
      if (bbrResponse.length == 0) {
        return [{
          type: "labelvalue",
          label: "BBR-info",
          value: "Ingen enhed fundet for lejligheden"
        }]
      } else {
        bbrObjects = bbrResponse
      }
    } else if (result.typeId === 'bbr_enhed') {
      bbrObjects = [result.data]
    }
    
    if (bbrObjects.length == 1) {
      let bbrObject = bbrObjects[0]

      let omenhedenSectionPromise = this.getOmSection(bbrObject)

      let enhedsResultPromise
      if (result.typeId != 'bbr_enhed') {
        let enhedsSearcher = new EnhedsSearcher({fetcher: this.fetcher})
        enhedsResultPromise = enhedsSearcher.get( bbrObject.id_lokalId, "bbr_enhed")
      }
      
      let bygningsResultPromise
      if (this.bygningsSearcher) {
        let bygningsId = bbrObject.bygning
        bygningsResultPromise = this.bygningsSearcher.get(bygningsId, "bbr_bygning")
      }

      items = [
        ...await omenhedenSectionPromise
      ]

      if (result.typeId == 'bbr_enhed' || !this.more) {
        let arealerSectionPromise = this.getArealerSection(bbrObject)
        let badSectionPromise = this.getBadSection(bbrObject)
        let toiletSectionPromise = this.getToiletSection(bbrObject)
        let koekkenSectionPromise = this.getKoekkenSection(bbrObject)
        items.push(...await arealerSectionPromise)
        items.push(...await badSectionPromise)
        items.push(...await toiletSectionPromise)
        items.push(...await koekkenSectionPromise)
      }

      if (result.typeId != 'bbr_enhed') {
        items.push({
          type: "result",
          label: "BBR-enhed",
          result: await enhedsResultPromise
        })
      } 
      if (bygningsResultPromise && (result.typeId == 'bbr_enhed' || !this.more)) {
        items.push({
          type: "result",
          label: "BBR-bygning",
          result: await bygningsResultPromise
        })
      }
      
    } else {
      let detailItemsList = new DetailItemsList({
        header: "BBR-enheder",
        name: "bbrenheder"
      })
      let enhedsSearcher = new EnhedsSearcher({fetcher: this.fetcher})
      for (let bbrObject of bbrObjects) {
        let enhedsResult = await enhedsSearcher.get( bbrObject.id_lokalId, "bbr_enhed")
        detailItemsList.append({
          type: "result",
          result: enhedsResult
        })
      }
      items.push(detailItemsList.asItem())
      if (result.typeId == 'adresse' && this.bygningsSearcher) {
        let bygningsId = bbrObjects[0].bygning
        let bygningsResultPromise = this.bygningsSearcher.get(bygningsId, "bbr_bygning")
        items.push({
          type: "result",
          label: "BBR-bygning",
          result: await bygningsResultPromise
        })
      }
      
    }
    
    return items
  }

  async getOmSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Generelt",
      name: "bbrenhed_genrelt"
    })
    let anvendelseTekst = this.fetcher.findBbrKode("EnhAnvendelse", bbrdata.enh020EnhedensAnvendelse)
    detailItemsList.append({
      type: "labelvalue",
      label: "Anvendelse",
      value: anvendelseTekst
    })
    let boligtype = bbrdata.enh023Boligtype
    if (boligtype && boligtype != "") {
      let boligtypeTekst = this.fetcher.findBbrKode("Boligtype", bbrdata.enh023Boligtype)
      detailItemsList.append({
        type: "labelvalue",
        label: "Boligtype",
        value: boligtypeTekst
      })
    }
    let antalvaerelser = bbrdata.enh031AntalVærelser
    if (antalvaerelser && antalvaerelser != "") {

      detailItemsList.append({
        type: "labelvalue",
        label: "Antal værelser",
        value: bbrdata.enh031AntalVærelser,
        valueformat: "int"
      })
    }
    let udlejningsforhold = bbrdata.enh045Udlejningsforhold
    if (udlejningsforhold && udlejningsforhold != "") {
      let udlejningTekst = this.fetcher.findBbrKode("Udlejningsforhold", bbrdata.enh045Udlejningsforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Udlejningsforhold",
        value: udlejningTekst
      })
    }

    return [detailItemsList.asItem()]
  }



  async getArealerSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Arealer (m2)",
      name: "bbrenhed_arealer"
    })
    let kilde = bbrdata.enh030KildeTilEnhedensArealer
    if (kilde && kilde != "") {
      let kildeTekst = this.fetcher.findBbrKode("KildeTilOplysninger", bbrdata.enh030KildeTilEnhedensArealer)
      detailItemsList.append({
        type: "labelvalue",
        label: "Kilde til oplysninger",
        value: kildeTekst

      })
    }
    detailItemsList.append({
      type: "labelvalue",
      label: "Samlet areal",
      value: bbrdata.enh026EnhedensSamledeAreal,
      valueformat: "int"
    })
    let beboelse = bbrdata.enh027ArealTilBeboelse
    if (beboelse && beboelse != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Beboelse",
        value: bbrdata.enh027ArealTilBeboelse,
        valueformat: "int"
      })
    }
    let andet = bbrdata.enh039AndetAreal
    if (andet && andet != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Andet",
        value: bbrdata.enh039AndetAreal,
        valueformat: "int"
      })
    }

    return [detailItemsList.asItem()]
  }
  async getBadSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Bad",
      name: "bbrenhed_bad"
    })
    let badeforhold = bbrdata.enh033Badeforhold
    if (badeforhold && badeforhold != "") {
      let badeforholdTekst = this.fetcher.findBbrKode("Badeforhold", bbrdata.enh033Badeforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Badeforhold",
        value: badeforholdTekst

      })
    }
    let antalbad = bbrdata.enh066AntalBadeværelser
    if (antalbad && antalbad != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Antal badeværelser",
        value: bbrdata.enh066AntalBadeværelser,
        valueformat: "int"

      })
    }
      
    return [detailItemsList.asItem()]
  }
  async getToiletSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Toilet",
      name: "bbrenhed_toilet"
    })
    let toiletforhold = bbrdata.enh032Toiletforhold
    if (toiletforhold && toiletforhold != "") {
      let toiletforholdTekst = this.fetcher.findBbrKode("Toiletforhold", bbrdata.enh032Toiletforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Toiletforhold",
        value: toiletforholdTekst

      })
    }
    let antalvandskyllendetoiletter = bbrdata.enh065AntalVandskylledeToiletter
    if (antalvandskyllendetoiletter && antalvandskyllendetoiletter != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Antal vandskyllende toiletter",
        value: antalvandskyllendetoiletter,
        valueformat: "int"

      })
    }
      
    return [detailItemsList.asItem()]
  }
  async getKoekkenSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Køkken",
      name: "bbrenhed_koekken"
    })
    let koekkenforhold = bbrdata.enh034Køkkenforhold
    if (koekkenforhold && koekkenforhold != "") {
      let koekkenforholdTekst = this.fetcher.findBbrKode("Koekkenforhold", bbrdata.enh034Køkkenforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Køkkenforhold",
        value: koekkenforholdTekst

      })
    }
    let antalvandskyllendetoiletter = bbrdata.enh065AntalVandskylledeToiletter
    if (antalvandskyllendetoiletter && antalvandskyllendetoiletter != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Antal vandskyllende toiletter",
        value: antalvandskyllendetoiletter,
        valueformat: "int"

      })
    }
      
    return [detailItemsList.asItem()]
  }


  async getBbrObjectsForHusnummer(id) {
    let objects = []
    let dawaSearcher = new DawaSearcher({})
    let enhedsAdresser = await dawaSearcher.getEnhedsAdresserForAdresse(id)
    let adresseIder = enhedsAdresser.features.map(f => f.properties.id)
    for (let adresseId of adresseIder)
      objects.push(...await this.fetcher.read("bbr", "bbr", "enhed", { AdresseIdentificerer: adresseId }, this.getLogger()))
    return objects 
  }
}