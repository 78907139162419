import {JSONPath} from "jsonpath-plus"

export default {
  nodes(json, path) {
    return JSONPath({
      path,
      json,
      resultType: 'all'
    })
  },
  value(json, path, newValue) {
    let nodes = JSONPath({
      path,
      json,
      resultType: 'all'
    })
    if (nodes.length === 0)
      return
    if (newValue) {
      const pathArray = JSONPath.toPathArray(path)
      const lastPathSegment = pathArray[pathArray.length - 1]
      nodes[0].parent[lastPathSegment] = newValue
      return newValue
    }
    let value = nodes[0].value 
    return value
  },
  normalize(path) {
    JSONPath.cache = {}
    return JSONPath.toPathString(JSONPath.toPathArray(path))
  }
}