import DataApiFetcher from "../data-api/Fetcher.js"
import DawaSearcher from "../DawaSearcher.js"
import {getWKTParser} from "../../util/getWKTParser.js"
import OffentligeLinksProvider from "../detailhandlers/OffentligeLinksProvider.js"

export default class OffentligeLinks extends OffentligeLinksProvider {
  constructor(options = {}) {
    super(options)

    if (options.dataApiFetcher)
      this.dataApiFetcher = options.dataApiFetcher
    else
      this.dataApiFetcher = new DataApiFetcher()

    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler
    this.dawaSearcher = new DawaSearcher()
  }

  isApplicable(result) {
    return (result.source === "DAF" && (result.typeId === "sfe" || result.typeId === "ejl" || result.typeId === "bfg"))
  }

  async myHandler(result) {
    let items
    let komnr
    let esrnr
    let pointGeom
    let elavskode
    let matrnr
    let bfenr = result.id

    try {
      let esrEjendomme = await this.dataApiFetcher.get("bestemtfastejendom", {bfenummer: `eq.${result.id}`, select: "esrejendom(*)"})
      if (esrEjendomme && esrEjendomme[0].esrejendom && esrEjendomme[0].esrejendom.length > 0) {
        esrnr = esrEjendomme[0].esrejendom[0].ejendomsnummer
        komnr = esrEjendomme[0].esrejendom[0].kommunekode
      }

    } catch{
      komnr = null
    }
    
    if (result.typeId === "sfe") {
      if (result.data.bfeResponse.properties.jordstykke.length === 1) {
        let wktParser = getWKTParser()
        pointGeom = wktParser.getInteriorPoint(result.geometry)
        elavskode = result.data.bfeResponse.properties.jordstykke.ejerlavskode
        matrnr = result.data.bfeResponse.properties.jordstykke.matrikelnummer
      } else {
        try {
          if (result.data.ebrResponse && result.data.ebrResponse.features && result.data.ebrResponse.features.length > 0 && result.data.ebrResponse.features[0].properties.husnummer) {
            let husnummer = result.data.ebrResponse.features[0].properties.husnummer[0]
            let husnummerUid = husnummer.id_lokalId
            pointGeom = (await this.dawaSearcher.get(husnummerUid, 'adresse')).geometry
            let jordstykkeId_hemmeligt = husnummer.jordstykke
            for (let jordstykke of result.data.bfeResponse.properties.jordstykke) {
              if (jordstykke.properties.id_lokalId == jordstykkeId_hemmeligt) {
                elavskode = jordstykke.properties.ejerlavskode
                matrnr = jordstykke.properties.matrikelnummer
              }
            }
          }
        } catch(e) {
          elavskode = null
          matrnr = null
        }
      }
      items = this.createLinks(result, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr)
    } else if (result.typeId === "ejl" || result.typeId === "bfg") {
      pointGeom = result.geometry
      //Find hoved ejendommen
      let hovedBfe = result.data.bfeResponse.properties.samletFastEjendomBFEnummer
      let hovedBfeResponse = await result.searcher.fetcher.read("matrikel", "matrikel", "bestemtFastEjendom", {BFEnr: hovedBfe})
      if (hovedBfeResponse.SamletFastEjendom.features[0].properties.jordstykke.length === 1) {
        elavskode = hovedBfeResponse.SamletFastEjendom.features[0].properties.jordstykke.ejerlavskode 
        matrnr = hovedBfeResponse.SamletFastEjendom.features[0].properties.jordstykke.matrikelnummer
      }
      items = this.createLinks(result, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr)
    }
    return items
  }
}
