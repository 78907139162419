/**
 * @module
 */

import icons from '../resources/icons.js'
import Searcher from './Searcher.js'
import ResultType from "../ResultType.js"

/**
 *
 * Enables a client to add a custom searcher to Septima Search
 * @extends module:js/searchers/Searcher
 *
 * @example
 *  //Create a provider object, which MUST have a query method (see signature) and MAY have a get method. If results have an id attribute the get method MUST be implemented
    //  provider.query(query, limit) must return
    //  {
    //    status: "ok|error",
    //    hits: nnn,
    //    results: results
    //  }, where
    //  results is an array of
    //  {
    //    title: "title ",
    //    description: "description",
    //    geometry: null|geojsonobject
    //  }
    // or a promise of such
 *       var provider = {
 *                "query": function(query, limit){
 *                    var results = [];
 *                    for (var i = 0;i<limit;i++){
 *                        results.push({
 *                            title: "title " + i,
 *                            description: "description " + i,
 *                            geometry: null
 *                        });
 *                    }
 *                    var result = {status: "ok", hits: 9999, results: results};
 *                    return result;
 *                },
 *                // The select method is optional. The controller will NOT emit SELECT in SS if defined 
 *                "select": function(result){
 *                  //Do something with result
 *                }
 *       };
 *
 * // Create a clientSearcher
 *        var clientSearcher = new Septima.Search.ClientSearcher({
 *            singular: "objekt",
 *            plural: "objekter",
 *            provider: provider
 *       });
 *
 * // Add the searcher to septima search
 * controller.addSearcher(clientSearcher);
 *
 * @api
 */

export default class ClientSearcher extends Searcher {

  /**
   * @param {Object} options ClientSearcher expects these properties:
   * @param options.singular {string} The singular form of the object type returned by the searcher, eg "Photo"
   * @param options.plural {string} The plural form of the object type returned by the searcher, eg "Photos"
   * @param options.provider {Object|string} searcher The provider or the global name of the provider object (provider = window[provider]) Must implement query(query, limit)
   */
  constructor(options) {

    super(Object.assign({
      iconURI:icons.result.defaultIcon
    },
    options))
    if (typeof options.provider === 'string') 
      this.provider = window[options.provider]
    else 
      this.provider = options.provider
      

    if (!options.source) 
      options.source = options.plural
      
    this.source = options.source
    this.singular = options.singular
    this.plural = options.plural
    let resultType = new ResultType({
      id: this.singular,
      singular: this.singular,
      plural: this.plural
    })

    this.registerType(this.source, resultType)
  }

  async fetchData(query, caller) {
    try {
      let providerResult = await this.provider.query(query.queryString, query.limit + 2)
      caller.fetchSuccess(this.formatResult(query, providerResult))
    } catch (error) {
      caller.fetchError(this, error)
    }
  }

  formatResult(query, providerResultArg) {

    let providerResult = null
    if (typeof providerResultArg === 'string') 
      providerResult = JSON.parse(providerResultArg)
    else 
      providerResult = providerResultArg

    let results = null
    let totalMatchCount = null
    const queryResult = this.createQueryResult()
    if (Array.isArray(providerResult)) { 
      //Old style, only results returned
      results = providerResult 
    } else 
      if (providerResult.status.toLowerCase() === "ok") {
        if (typeof providerResult.hits !== 'undefined') 
          totalMatchCount = providerResult.hits
        results = providerResult.results
      } else {
        return queryResult
      }
    const count = results.length
    if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let hit of results.slice(0, hitsShown))
        this.addResultFromHit(query, queryResult, hit)

      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        let title = this.plural
        if (totalMatchCount != null) 
          title = title + " (" + totalMatchCount + ")"
          
        queryResult.addNewQuery(this.source, this.singular, title + "", null, query.queryString, null, null)
      }
    }else if (count > 0) {
      let title2 = this.plural + ""
      if (totalMatchCount != null) 
        title2 = title2 + " (" + totalMatchCount + ")"
        
      queryResult.addNewQuery(this.source, this.singular, title2, null, query.queryString, null, null)
    }
    return queryResult
  }
    
  addResultFromHit(query, queryResult, hit) {
    let displayname = hit.title

    let description = null
    if (hit.description)
      description = hit.description

    let geometry = null
    if (hit.geometry)
      geometry = hit.geometry

    let result = queryResult.addResult(this.source, this.singular, displayname, description, geometry, hit)
    if (typeof hit.id !== 'undefined')
      result.id = hit.id
  }

  resultFromHit(hit) {
    const queryResult = this.createQueryResult()
    let displayname = hit.title
    let description = null
    if (hit.description) 
      description = hit.description
      
    let geometry = null
    if (hit.geometry) 
      geometry = hit.geometry
      
    let result = queryResult.addResult(this.source, this.singular, displayname, description, geometry, hit)
    if (typeof hit.id !== 'undefined') 
      result.id = hit.id
      
    return result
  }

  async get(id) {
    if (typeof this.provider.get !== 'undefined') {
      let hit = await Promise.resolve(this.provider.get(id))
      return this.resultFromHit(hit)
    } else 
      throw new Error('Whoops! get(id) is not implemented in ' + this.singular + ' searcher')
      
  }
}
