import {fetch2} from "../utils.js"

class RessourcesCollection {
  constructor(options={}) {
    this.ressources = {}
    if (options.ttl)
      this.ttl = options.ttl
    else
      this.ttl = -1
  }

  setTimeToLive(ttl) {
    this.ttl = ttl
  }

  nowInSeconds() {
    return new Date().getTime() / 1000
  }
  
  putRessource(ressource) {
    if (this.ttl > -1) {
      ressource._setExpiry(this.nowInSeconds() + this.ttl)
    }
    let id = Date.now().toString(36) + Math.random().toString(36).substring(2)
    this.ressources[id] = ressource
    return id
  }

  getRessource(ressourceId) {
    this.deleteExpiredRessources()
    let ressource = this.ressources[ressourceId]
    if (typeof ressource != 'undefined')
      return ressource
    else
      return null
  }

  deleteExpiredRessources() {
    if (this.ttl > -1) {
      let checkTime = this.nowInSeconds()
      for (const [resourceId, resource] of Object.entries(this.ressources)) {
        if (resource._isExpired(checkTime))
          delete this.ressources[resourceId]
      }
    }
  }
  
}

export class Ressource {
  constructor(url) {
    this.type = "fetch2"
    this.url = url
    this.fetch2Options = {}
    this.expires = -1
    this.title = null
  }

  _setExpiry (expires) {
    this.expires = expires
  }

  _isExpired(atTimeInSeconds) {
    if (this.expires == -1) {
      return false
    } else {
      return this.expires < atTimeInSeconds
    }
  }
  
  static createFetch2Ressource(url, fetch2Options={}, ressourceOptions = {}) {
    let ressource = new Ressource(url)
    if (ressourceOptions.type)
      ressource.type = ressourceOptions.type
    if (ressourceOptions.title)
      ressource.title = ressourceOptions.title
    ressource.fetch2Options = fetch2Options
    return ressource
  }

  async getObjectURL() {
    let blob = await fetch2(this.url, Object.assign({}, this.fetch2Options, {expects: "blob"}))
    let objectURL = URL.createObjectURL(blob)
    return objectURL
  }
  
  async getFetch2Response() {
    let response = await fetch2(this.url, Object.assign({}, this.fetch2Options, {expects: "response"}))
    return response
  }
}

export const ressources = new RessourcesCollection()