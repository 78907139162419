/**
 * @module
 */
import Searcher from './Searcher.js'

export default class CVR_enhedSearcher extends Searcher {
  constructor(options= {}) {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      try{
        // eslint-disable-next-line no-console
        console.warn("CVR_enhedSearcher is deprecated - Use Septima.Search.DataApi.CvrSearcher")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }
    
    super(options)
  }
  
}
