import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import TingbogsFetcher from "./TingbogsFetcher.js"
import {Ressource, ressources} from "../../util/Ressources.js"

export default class TingbogsOplysningerForBfe extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {String[]} [options.fetcher]
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "Tingbog",
      targets: [{source: 'DAF', typeId: ['sfe', 'ejl', 'bfg', 'bfenummer']}]
    }
    super(Object.assign(defaultOptions, options))
    
    this.searcher = null
    this.fetcher = null
    if (options.searcher) {
      this.searcher = options.searcher
      this.fetcher = options.searcher.fetcher
    } else if (options.fetcher) {
      this.fetcher = options.fetcher
    } else {
      this.fetcher = new TingbogsFetcher()
    }

    this.handlerFunction = this.myHandler

    this.ressources = {}
  }
  
  // eslint-disable-next-line no-unused-vars
  async myHandler(result, logger) {
    let ejendomSummarisk = await this.fetcher.getEjendomSummarisk(result.id, logger)
    let items = []
    //let url = this.fetcher.endpoint + "/HentEjendomsummariskPdf?EjdUuid=02ff7120-ee7d-4453-9387-378cbe8ad23e"
    let url = ejendomSummarisk.Pdf
    //let options = this.fetcher.getRequestOptions()
    let ressource = Ressource.createFetch2Ressource(url, this.fetcher.getRequestOptions(), {title: "Tingbogsattest.pdf"})
    let ressourceId = ressources.putRessource(ressource)
    /*
    let ressourceId = this.putRessource({url, options})
    let getObjectURL = async () => {
      let ressource = this.getRessource(ressourceId)
      let blob = await fetch2(ressource.url, ressource.options)
      let objectURL = URL.createObjectURL(blob)
      return objectURL
    }
    
     */
    items.push({
      ressourceId,
      linkTitle: "Tingbogsattest",
      name: "attest",
      type: "link"
    })
    return items
  }

  /*
  putRessource(ressource) {
    let id = Date.now().toString(36) + Math.random().toString(36).substring(2)
    ressource.options.expects = "blob"
    this.ressources[id] = ressource
    return id
  }

  getRessource(ressourceId) {
    return this.ressources[ressourceId]
  }
   */
}