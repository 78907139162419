import ResultStore from "./ResultStore.js"

export default class StaticResultStore extends ResultStore {
  constructor(options={}) {
    super(options)
    if (options.data) {
      this.resultDefs = options.data
      this.resultDefs.forEach((resultDef, index)=>{
        resultDef.id = index
        //resultDef.description = resultDef.description ? resultDef.description : null
        resultDef.timestamp = new Date().getTime()
        //resultDef.image = resultDef.image ? resultDef.image : icons.result.defaultIcon
      })
    } else
      throw "Resultstore must have a 'data' array option"
  }
  
  //Context not used in Cookie implementation of ResultStore
  // eslint-disable-next-line no-unused-vars
  async getAll(context) {
    return this.resultDefs
  }

  // eslint-disable-next-line no-unused-vars
  async remove(id) {
    // noop - remove necessary in Static implementation
  }

  // eslint-disable-next-line no-unused-vars
  async put(resultDef, context) {
    // noop - remove necessary in Static implementation
  }  
}