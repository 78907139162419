import Searcher from "./Searcher.js"
import jsonpath from "../util/JsonParser.js"
import * as reproject from "../util/reproject.js"
import {getWKTParser} from "../util/getWKTParser.js"
import ResultType from "../ResultType.js"

export default class GenericWfsSearcher extends Searcher {

  constructor(options={}) {
    
    let defaultOptions = {
      queryBehaviour: "match"
    }

    super(Object.assign(defaultOptions, options))
    /*
    this.featureType = { name, singular, plural}
    this.endpoint
    this.fields = { id[, title] [, description], geometry }
    this.idField = {name:, type:}
    [this.queryFields = ["field1", "field2"]]
     */
    if (!options.featureType) {
      throw new Error("GenericWfsSearcher needs option.featureType")
    } else {
      if (typeof options.featureType == "string") {
        this.featureType = {
          name: options.featureType,
          singular: options.featureType,
          plural: options.featureType
        }
      } else {
        if (typeof options.featureType == "object" && options.featureType.name) {
          this.featureType = options.featureType
          if (!options.featureType.singular)
            options.featureType.singular = options.featureType.name
          if (!options.featureType.plural)
            options.featureType.plural = options.featureType.singular
        } else {
          throw new Error("GenericWfsSearcher needs option.featureType.name")
        }
      }
    }

    this.source = this.featureType.name
    if (options.source)
      this.source = options.source

    if (!options.endpoint)
      throw new Error("GenericWfsSearcher needs option.endpoint")
    this.endpoint = options.endpoint

    if (!options.fields)
      throw new Error("GenericWfsSearcher needs option.fields")
    this.fields = options.fields

    if (!options.idField)
      throw new Error("GenericWfsSearcher needs option.idField")
    this.idField = options.idField

    if (!options.otherFields)
      this.otherFields = [{
        field: "",
        presentation: {
          type: "labelvalue",
          label: "label",
          valueformat: "int",
          valueunit: "Mbit/s"
        }
      }]
    
    let myType = new ResultType({
      id: this.featureType.name,
      singular: this.featureType.singular,
      plural: this.featureType.plural,
      queryBehaviour: this.queryBehaviour,
      geometrySupport: "sq"
    })
    this.registerType(this.source, myType)
  }
  
  async asyncFetchData(query) {
    if (this.queryBehaviour == "search" || this.queryBehaviour == "match") {
      let promises = []
      //By Id
      if (!query.isBlank) {
        promises.push(this.fetchFeatureById(query.queryString))
      }
      if (this.queryBehaviour == "search") {
        for (let queryField of this.queryFields)
          promises.push(this.fetchFeatures(`${queryField} like %27${query.queryString}%25%27`, {limit: query.limit}))
      }
      await Promise.all(promises)
      let features = []
      for (let promise of promises)
        features = features.concat(await promise)
      return this.createQueryResultFromFeatures(features)
    } else {
      return this.createQueryResult()
    }
  }

  async get(id) {
    let features = await this.fetchFeatureById(id)
    let queryResult = this.createQueryResultFromFeatures(features)
    let results = queryResult.getResults()
    if (results.length === 1)
      return results[0]
  }

  async sq(query) {
    let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")
    let features = await this.fetchFeatureByGeometry(queryGeometry, {limit: query.limit})
    return this.createQueryResultFromFeatures(features)
  }

  async fetchFeatureById(id) {
    try {
      let value = id
      if (this.idField.type == "string")
        value = "'" + value + "'"
      return await this.fetchFeatures(`${this.idField.name}=${value}`)
    } catch {
      return []
    }
  }

  async fetchFeatureByGeometry(queryGeometry, options= {}) {
    let wktParser = getWKTParser()
    const queryWkt = wktParser.convert(queryGeometry)
    try {
      return await this.fetchFeatures(`intersects(${this.fields.geometry},${queryWkt})`, options)
    } catch {
      return []
    }
  }

  async fetchFeatures(cqlFilter, options= {}) {
    let method = "get"
    if (options.method == "post")
      method = "post"
    let maxFeatures = 200
    if (options.maxFeatures)
      maxFeatures = options.maxFeatures
    try {
      let endPoint = "https://geoserver.plandata.dk/geoserver/wfs"
      let result
      if (method === 'get') {
        result = await this.fetch(`${this.endpoint}?request=getFeature&srs=EPSG:25832&typename=${this.featureType.name}&cql_filter=${cqlFilter}&outputFormat=json&count=${maxFeatures}`)
      } else {
        let params = {
          servicename: 'WFS',
          typename: this.featureType.name,
          request: 'GetFeature',
          version: '1.0.0',
          outputformat: 'json',
          CQL_FILTER: cqlFilter,
          count: maxFeatures
        }
        result = await this.fetch(endPoint, { data: params, method: 'post' })
      }
      return result.features
    } catch (e) {
      return []
    }
  }

  createQueryResultFromFeatures(features) {
    let queryResult = this.createQueryResult()
    for (let feature of features)
      this.addFeatureToQueryResult(feature, queryResult)
    return queryResult
  }

  addFeatureToQueryResult(feature, queryResult) {
    let title = (this.fields.title) ? jsonpath.value(feature, "$.properties." + this.fields.title) : this.featureType.singular
    let description = (this.fields.description) ? jsonpath.value(feature, "$.properties." + this.fields.description) : null
    let geometry = feature.geometry
    geometry.crs = {
      "type": "name",
      "properties": {
        "name": 'epsg:25832'
      }
    }
    let result = queryResult.addResult(this.source, this.featureType.name, title, description, geometry, feature)
    result.id = jsonpath.value(feature, "$.properties." + this.idField.name)
  }


}
  
