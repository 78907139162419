/**
 * @module
 */
import {fetch3} from '../utils.js'
import icons from '../resources/icons.js'
import Searcher from './Searcher.js'
import DetailsHandlerDef from "../details/DetailsHandlerDef.js"
import ResultType from "../ResultType.js"
import {getWKTParser} from "../util/getWKTParser.js"
import * as reproject from "../util/reproject.js"

/**
 *
 * Searches a Spatial Suite index. An S4 index must have been created and populated on the host. See https://github.com/Septima/spatialsuite-s4#5-configure-your-search-index
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
    _type: Septima.Search.S4IndexSearcher
    _options:
      host: "http://sps.test.septima.dk"
      datasources: "*"

 * @example <caption> JS options:</caption>
 * s4IndexSearcherOptions = {
 *     host : "http://sps.test.septima.dk/",
 *     datasources : "*"
 * };
 * 
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.S4IndexSearcher(s4IndexSearcherOptions))
 *
 * @example <caption>ES6:</caption>
 * import S4IndexSearcher from './searchers/S4IndexSearcher.js'
 * controller.addSearcher(new S4IndexSearcher(s4IndexSearcherOptions))
 * @api
 */
export default class S4IndexSearcher extends Searcher {
  /**
   *
   * @param {Object} options S4IndexSearcher expects these properties:<br/>
   * @param {string} options.host "Service endpoint (SpatialSuite Url) eg.: [http:]//sps-demo.septima.dk[:80]. Protocol and port are optional"
   * @param {string} [options.datasources="*"] Comma separated list of indexed data sources to search. Default: "*"
   * @param {string} [options.source="WebGis"] The source property of results. Type property
   *
   */
  constructor(options) {
    if (options === undefined)
      throw "New Septima.Search.S4IndexSearcher(options): Options missing."

    super(Object.assign({
      usesGeoFunctions: true,
      iconURI:icons.result.defaultIcon
    },
    options))

    this.source = "s4index"
      
    this.indexedDatasources = null

    this.ajaxURL = ''
    if (options.host) 
      this.ajaxURL = options.host.replace(/\/$/, "") //remove trailing slash
    else if (options.indexProtocol && options.indexHost && options.indexPort) 
      this.ajaxURL = options.indexProtocol + '://' + options.indexHost + ':' + options.indexPort
      

    this.allowDetails = false
    if (options.allowDetails) 
      this.allowDetails = options.allowDetails
      

    if (options.datasources === undefined) {
      this.datasourcesToSearch = "*"
    } else {
      this.datasourcesToSearch = options.datasources
      if (this.datasourcesToSearch === "" || this.datasourcesToSearch === " ") 
        this.datasourcesToSearch = "*"
    }
    this.wktParser = getWKTParser()
    
    this.s4Epsg = "25832"

    this.sessionId = null
    if (options.sessionId)
      this.sessionId = options.sessionId

    this.datasourcesReady = this.getDatasources()
  }

  ready() {
    return this.datasourcesReady
  }

  async getDatasources() {
    if (this.indexedDatasources === null) {
      this.indexedDatasources = []
      const url = this.ajaxURL + '/jsp/modules/s4/getDatasources.jsp'
      let options = {}
      if (this.sessionId)
        options.headers =  {
          "Session-Id" : this.sessionId
        }
      let data = await fetch3(url, options, this.getLogger())

      if (typeof data.results !== 'undefined') {
        for (let result of data.results) {
          let datasource = result.datasource
          if (datasource.featurecount > 0 && this.inDatasourcesToSearch(datasource.id)) {
            this.indexedDatasources.push(datasource)
            let resultType = new ResultType({
              id: datasource.id,
              singular: datasource.featuretypesingle,
              plural: datasource.featuretypeplural,
              geometrySupport: "sq",
              iconURI: datasource.iconuri
            })
            this.registerType(this.source, resultType)
          }
        }
      }

      if (typeof data.crs !== 'undefined') {
        this.s4Epsg = data.crs
      }
    }
  }

  inDatasourcesToSearch(datasource) {
    if (this.datasourcesToSearch == "*") {
      return true
    } else {
      const arrDatasourcesToSearch = this.datasourcesToSearch.split(" ")
      for (let arrDatasource of arrDatasourcesToSearch) 
        if (arrDatasource.toLowerCase() == datasource.toLowerCase()) 
          return true
    }
    return false
  }

  getDatasourceFromType_delete(type) {
    for (let indexedDatasource of this.indexedDatasources) 
      if (type.toLowerCase() == indexedDatasource.featuretypesingle.toLowerCase()) 
        return indexedDatasource
    return ""
  }

  async nearest(pointGeometry, limit, typeIds) {
    await this.datasourcesReady
    let params = {
      distwkt: this.wktParser.convert(pointGeometry), 
      datasources:  this.datasourcesToSearch,
      limit: limit ? limit : 3
    }
    if (typeIds && typeIds.length > 0 && typeIds[0] !== "*")
      params.datasources = typeIds.join(" ")

    let url = this.ajaxURL + '/jsp/modules/s4/queryIndex.jsp'
    let fetchOptions = {
      data: params
    }
    let data
    if (this.sessionId)
      fetchOptions.headers =  {
        "Session-Id" : this.sessionId
      }
    if (this.ajaxURL === "") {
      fetchOptions.method = 'post'
      fetchOptions.credentials = "include"
      data = await this.fetch(url, fetchOptions)
    } else {
      data = await fetch3(url, fetchOptions, this.getLogger())
    }
    return data.results
  }
  
  async sq(query) {
    await this.datasourcesReady
    return await this.mySq(query)
  }

  async mySq(query) {
    let datasources = this.datasourcesToSearch
    if (query.hasTarget) 
      if (this.hasType(query.target.type)) 
        datasources = query.target.type
    let params = {
      datasources: datasources,
      limit: 0
    }
    
    if (query.geometry) {
      let queryGeometry = reproject.reproject(query.geometry, null, "epsg:" + this.s4Epsg)
      let wktParser = getWKTParser()
      const queryWKT = wktParser.convert(queryGeometry)
      params.querywkt = queryWKT
    }

    let url = this.ajaxURL + '/jsp/modules/s4/queryIndex.jsp'
    let fetchOptions = {data: params}
    if (this.sessionId)
      fetchOptions.headers =  {
        "Session-Id" : this.sessionId
      }
    let data
    if (this.ajaxURL === "") {
      fetchOptions.method = 'post'
      fetchOptions.credentials = "include"
      data = await this.fetch(url, fetchOptions)
    } else {
      data = await fetch3(url, fetchOptions, this.getLogger())
    }
    let queryResult = this.createQueryResult()
    for (let result of data.results) {
      let datasource = result.datasource
      let features = result.features
      if (features.length > 0) 
        for (let feature of features) {
          let resultGeometry = this.translateWktToGeoJsonObject(feature.wkt, this.s4Epsg)
          let result = queryResult.addResult(this.source, datasource.id, feature.title, feature.description, resultGeometry, feature)
          result.id = feature.featureid
          if (datasource.iconuri !== undefined) 
            result.image = datasource.iconuri
        }
    }
    return queryResult
  }
  
  getIndexedDatasourcesAsNewQueries() {
    const queryResult = this.createQueryResult()
    for (let datasource of this.indexedDatasources) {
      let result = queryResult.addNewQuery(this.source, datasource.id, datasource.featuretypeplural + " (" + datasource.featurecount + ")", null, "", null, null)
      if (datasource.iconuri !== undefined) 
        result.image = datasource.iconuri
    }
    return queryResult
  }

  async fetchData(query, caller) {
    try {
      await this.datasourcesReady
      this.myFetchData(query, caller)
    } catch (error) {
      caller.fetchError()
    }
  }

  async myFetchData(query, caller) {
    switch (query.type) {
      case 'collapse': {
        caller.fetchSuccess(this.getIndexedDatasourcesAsNewQueries())
        break
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let datasources = this.datasourcesToSearch
        if (query.hasTarget)
          datasources = query.target.type
          
        let limitType = "collapse"
        if (query.type == 'list.force')
          limitType = "cut"
        const url = this.ajaxURL + '/jsp/modules/s4/queryIndex.jsp'
        try {
          let options = {
            data: {
              query: query.queryString,
              limit: (query.limit + 2 ),
              datasources: datasources,
              limitType: limitType
            }
          }
          if (this.sessionId)
            options.headers =  {
              "Session-Id" : this.sessionId
            }
          
          let data = await fetch3(url, options, this.getLogger())
          data.query = query
          caller.fetchSuccess(this.parseResult(data))
        } catch (error) {
          caller.fetchError()
        }

      }
    }
  }

  async get(id, typeId) {
    await this.datasourcesReady
    const url = this.ajaxURL + '/jsp/modules/s4/getFeature.jsp'
    let options = {
      data: {id: id, datasource: typeId}
    }
    if (this.sessionId)
      options.headers =  {
        "Session-Id" : this.sessionId
      }

    let data = await fetch3(url, options, this.getLogger())
    const feature = data
    let queryResult = this.createQueryResult()
    //feature.datasource = datasource
    let resultGeometry = null
    if (feature.wkt)
      resultGeometry = this.translateWktToGeoJsonObject(feature.wkt, this.s4Epsg)
    //Check for resultGeometry == null
    if (resultGeometry && resultGeometry.type == "MultiPoint" && resultGeometry.coordinates.length == 1) {
      resultGeometry.type = "Point"
      resultGeometry.coordinates = resultGeometry.coordinates[0]
    }
    let description = this.getFeatureDescription(feature)
    let result = queryResult.addResult(this.source, typeId, feature.title, description, resultGeometry, feature)
    result.id = id
    return result
  }

  datasourceInResults(indexedDatasource, results) {
    for (let result of results) 
      if (indexedDatasource.id === result.datasource.id) 
        return true
    return false
  }

  parseResult(data) {
    const queryResult = this.createQueryResult()
    const query = data.query
    if (!query.hasTarget) 
      for (let indexedDatasource of this.indexedDatasources) 
        if (indexedDatasource.featuretypesingle.toLowerCase().indexOf(query.queryString.toLowerCase()) === 0 && !this.datasourceInResults(indexedDatasource, data.results)) {
          const autoNewQuery = queryResult.addNewQuery(this.source, indexedDatasource.id, indexedDatasource.featuretypeplural, null, "", null, null)
          if (indexedDatasource.iconuri !== undefined) 
            autoNewQuery.image = indexedDatasource.iconuri
        }
    for (let datasourceResult of data.results) {
      let description
      const datasource = datasourceResult.datasource
      let type = datasource.id
      if (datasourceResult.type == 'DatasourceWithMatchingFeaturesIncluded') {
        let count = datasourceResult.features.length
        let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
        for (let feature of datasourceResult.features.slice(0, hitsShown)) {
          feature.datasource = datasource
          this.addResultFromFeature(query, queryResult, feature, datasource)
        }
        if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
          const newQuery = queryResult.addNewQuery(this.source, type, datasource.featuretypeplural, null, query.queryString, null, null)
          if (datasource.iconuri !== undefined)
            newQuery.image = datasource.iconuri
        }
            
      } else if (datasourceResult.type == 'DatasourceWithMatchingFeatures') {
        description = null
        const newQuery = queryResult.addNewQuery(this.source, type, datasource.featuretypeplural + " (" + datasource.featurecount + ")", description, query.queryString, null, null)
        if (datasource.iconuri !== undefined) 
          newQuery.image = datasource.iconuri
      }
    }
    return queryResult
  }
  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      let gotResult = await this.get(result.id, result.typeId)
      let gotGeometry = gotResult.geometry
      if (gotGeometry && gotGeometry.type == "MultiPoint" && gotGeometry.coordinates.length == 1) {
        gotGeometry.type = "Point"
        gotGeometry.coordinates = gotGeometry.coordinates[0]
      }
      result.geometry = gotGeometry
      result.isComplete = true
      return result
    }
  }
    
  addResultFromFeature(query, queryResult, feature, datasource) {
    //let resultGeometry = this.translateWktToGeoJsonObject(feature.wkt)
    let description = this.getFeatureDescription(feature)
    let result1
    if (query.hasTarget)
      //result1 = queryResult.addResult(this.source, type, feature.title, description, resultGeometry, feature)
      result1 = queryResult.addResult(this.source, datasource.id, feature.title, description, null, feature)
    else
      //result1 = queryResult.addResult(this.source, type, feature.title + " (" + type + ")", description, resultGeometry, feature)
      result1 = queryResult.addResult(this.source, datasource.id, feature.title + " (" + datasource.featuretypesingle + ")", description, null, feature)
    result1.id = feature.featureid
    result1.isComplete = false
    if (feature.datasource.iconuri !== undefined)
      result1.image = feature.datasource.iconuri
  }

  getFeatureDescription(feature) {
    return feature.description
  }

  hasDetails(result) {
    const feature = result.data
    if (this.allowDetails) 
      if (typeof feature.hasDetails === "undefined") {
        feature.hasDetails = false
        if (this.getCustomButtonsForResult(result).length > 0) {
          feature.hasDetails = true
          return true
        }
        for (let row of feature.pcol.row) 
          if (typeof row._name !== "undefined" && (row._name !== 'column' || (row._name === 'column' && row.format === '') || row.format === 'hyperlink')) {
            feature.hasDetails = true
            return true
          }
      } else {
        return feature.hasDetails
      }
    else 
      return false
  }

  getDetailHandlersForResult(result) {
    const feature = result.data
    let detailHandlerDefs = []

    if (this.hasDetails(result)) {
      const detailsHandler = () => {
        return new Promise(
          (resolve) => {
            let items = []
            for (let row of feature.pcol.row) 
              if (typeof row._name !== "undefined" && (row._name !== 'column' || (row._name === 'column' && row.format === '') || row.format === 'hyperlink' || row.format === 'imageURL')) 
                if (row.format === 'hyperlink') {
                  let icon = icons.linksymbol
                  let linkTitle = row.label
                  let link = row.value
                  items.push({type: "link", linkTitle: linkTitle, link: link, icon: icon})
                } else if (row.format === 'imageURL') {
                  let label = row.label
                  let value = row.value
                  items.push({type: "image", label: label, value: value})
                } else {
                  let label = row.label
                  let value = row.value
                  items.push({type: "labelvalue", label: label, value: value})
                }
            resolve(items)
          })
      }

      let detailHandlerDef = new DetailsHandlerDef(
        {
          "buttonText": "Attributter",
          "buttonImage": icons.details.moreHeader,
          "handler": detailsHandler,
          "more": true
        }
      )

      detailHandlerDefs.push(detailHandlerDef)
    }
    return detailHandlerDefs
  }

  getCustomButtonsForResult() {
    const customButtonDefs = []
    return customButtonDefs
  }

}
