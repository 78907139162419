/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
/**
 * Shows extra fields from S4IndexSearcher (Columns from presentations of index datasources in Spatial Suite)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
    _type: Septima.Search.S4IndexSearcher
    _options:
      host: "http://sps.test.septima.dk"
      datasources: "*"
      #allowDetails: true
    detailhandlers:
      - _type: Septima.Search.S4InfoHandler

 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * s4Searcher.addDetailHandlerDef(new Septima.Search.S4InfoHandler())
 *
 * @example <caption>ES6:</caption>
 * import S4InfoHandler from './searchers/detailhandlers/S4InfoHandler.js'
 * s4Searcher.addDetailHandlerDef(new S4InfoHandler())
 * @api
 **/
export default class S4InfoHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   **/
  constructor(options={}) {
    let defaultOptions = {
      buttonText: "Detaljer",
      buttonImage: icons.details.moreHeader,
      more: true
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = this.isApplicable
  }
    
  isApplicable(result) {
    if (result.searcher) {
      const feature = result.data
      if (typeof feature.hasDetails === "undefined") {
        feature.hasDetails = false
        if (feature.pcol) {
          for (let row of feature.pcol.row)
            if (typeof row._name !== "undefined" && (row._name !== 'column' || (row._name === 'column' && row.format === '') || row.format === 'hyperlink')) {
              feature.hasDetails = true
              return true
            }
        }
      } else {
        return feature.hasDetails
      }
    }
    return false
  }

  async myHandler(result) {
    const feature = result.data
    let items = []
    for (let row of feature.pcol.row)
      if (typeof row._name !== "undefined" && (row._name !== 'column' || (row._name === 'column' && row.format === '') || row.format === 'hyperlink' || row.format === 'imageURL'))
        if (row.format === 'hyperlink') {
          let icon = icons.linksymbol
          let linkTitle = row.label
          let link = row.value
          items.push({type: "link", linkTitle: linkTitle, link: link, icon: icon})
        } else if (row.format === 'imageURL') {
          let label = row.label
          let value = row.value
          items.push({type: "image", label: label, value: value})
        } else {
          let label = row.label
          let value = row.value
          items.push({type: "labelvalue", label: label, value: value})
        }
    return items
  }
}