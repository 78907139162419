import ResultStoreSearcher from "./ResultStoreSearcher.js"

export default class HistorySearcher extends ResultStoreSearcher {
  constructor(options) {
    let defaultOptions = {
      iconURI: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTUuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiCgkgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGlkPSJJY29ucyIgc3R5bGU9Im9wYWNpdHk6MC43NTsiPgoJPGcgaWQ9Imhpc3RvcnkiPgoJCTxwYXRoIGlkPSJjbG9ja19oYW5kcyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgZD0iTTE3LjI2LDE1LjA3NmMwLDAtMi4zODUtMS45MzUtNC4wMDUtMy4wNjIKCQkJYzAuNzItMi4zOTcsMS43MDItNi41NTksMS43MDItNi41NTlzLTQuMzUsNS4zNjMtNC44NzcsNi42OTljLTAuNDYzLDEuMTY4LDEuNDU5LDIuMjA5LDIuMzQ2LDEuNjc4CgkJCUMxNC4zMjYsMTQuMzgzLDE3LjI2LDE1LjA3NiwxNy4yNiwxNS4wNzZ6Ii8+CgkJPHBhdGggaWQ9ImFycm93IiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7IiBkPSJNMTIuMDg2LDIuMDg1Yy01LjQ3OCwwLTkuOTE2LDQuNDM4LTkuOTE2LDkuOTE2CgkJCWMwLDEuNzgzLDAuNDc2LDMuNDU0LDEuMzAxLDQuODk4bC0yLjIyMywyLjA0aDUuNjg4di01LjIxOWwtMi4wNjYsMS44OTZjLTAuNTUtMS4wODgtMC44NjYtMi4zMTItMC44NjYtMy42MTUKCQkJYzAtNC40NjUsMy42MTktOC4wODQsOC4wODQtOC4wODRjNC40NjQsMCw4LjA4MywzLjYxOSw4LjA4Myw4LjA4NGMwLDQuNDY0LTMuNjE5LDguMDgzLTguMDgzLDguMDgzCgkJCWMtMS4xNDUsMC0yLjIyOC0wLjI0Ny0zLjIxMy0wLjY3OGwtMC44MzMsMS42MzRjMS4yMzUsMC41NTcsMi42MDIsMC44NzQsNC4wNDUsMC44NzRjNS40NzYsMCw5LjkxNC00LjQzOCw5LjkxNC05LjkxNAoJCQlDMjIsNi41MjMsMTcuNTYyLDIuMDg1LDEyLjA4NiwyLjA4NXoiLz4KCTwvZz4KPC9nPgo8ZyBpZD0iR3VpZGVzIiBzdHlsZT0iZGlzcGxheTpub25lOyI+CjwvZz4KPC9zdmc+Cg=="
    }
    super(Object.assign(defaultOptions, options))
    this.controller.addOnSelectHandler(this.onSelectHandler.bind(this))
  }

  onSelectHandler(result) {
    if (!result.isNewQuery() && result.source != this.store.source)
      this.store.putResult(result) 
  }

  async fetchData(query, caller) {
    let queryResult = await this.myFetchData(query)
    caller.fetchSuccess(queryResult)
  }
}