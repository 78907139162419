export default class Query {
  constructor() {
    this.queryString = ""
    this.target = {source: null, type: null}
    this.geometry = null
    this.limit = null
    this.type = null
  }
  
  clone() {
    let query = new Query()
    query.queryString = this.queryString
    query.target = {source: this.target.source, type: this.target.type}
    query.geometry = this.geometry
    query.limit = this.limit
    query.type = this.type
    // list: return results until limit
    // cut: return results until limit - show new-query for the rest
    // no-cut return results if they fit in limit
    // collapse only return new-queries
    return query
  }

  get hasTarget() {
    return (this.target.source !== null && this.target.type !== null)
  }
  
  get isBlank() {
    return (this.queryString === null || this.queryString === "")
  }

  equals(testQuery) {
    if (this.queryString == testQuery.queryString && this.target.source == testQuery.target.source && this.target.type == testQuery.target.type && this.limit == testQuery.limit)
    //TODO: Test whether geometries are equal
      return true
    else
      return false
    
  }
}


