import Fetcher from "./Fetcher.js"
import T5710Searcher from "./T5710Searcher.js"
import T5710InfoProvider from "./T5710InfoProvider.js"
import ClassRegistry from "../../ClassRegistry.js"

const geoFaTypes = new ClassRegistry("Septima.Search.GeoFa.")

const typesToAdd = {
  Fetcher,
  T5710Searcher,
  T5710InfoProvider
}

for (let [key, value] of Object.entries(typesToAdd))
  geoFaTypes.addClass(value, key)

export default geoFaTypes