import kodeMap from './kommunekoder/kommunekoder-lookup.js'
/*
{

"0101":  "København",
"0147":  "Frederiksberg",
"0151":  "Ballerup",
}  
 */
export default class KommuneKodeliste {
  
  findKommune(code) {
    let kommune = kodeMap[code]
    if (kommune === undefined) 
      return 'Kommunenavn ikke fundet'
      
    return kommune
  
  }

}