import Searcher from "./Searcher.js"
import icons from "../resources/icons.js"
 
export default class ResultStoreSearcher extends Searcher {
  constructor(options) {
    let defaultOptions = {}
    
    super(Object.assign(defaultOptions, options))
    this.store = options.store
    this._resultType = options.resultType
    this.controller = options.controller

    this.registerType(this.store.source, this._resultType)
    this.cleanUpStore()
  }

  async cleanUpStore() {
    let storeResults = await this.store.getAll()
    for (let storeResult of storeResults) {
      try{
        await this.controller.get(storeResult.source, storeResult.typeId, storeResult.resultid)
        // eslint-disable-next-line no-empty
      } catch(e) {
        this.store.remove(storeResult.id)
      }
    }
  }

  async fetchData(query, caller) {
    let queryResult = await this.myFetchData(query)
    caller.fetchSuccess(queryResult)
  }
  
  async myFetchData(query) {
    let queryResult = this.createQueryResult()
    let hits = await this.store.query(query.queryString)

    const count = hits.length

    if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let hit of hits.slice(0, hitsShown)) {
        let displayname = hit.title
        if (!query.hasTarget && query.type !== "list")
          displayname += " (" + this._resultType.singular + ")"

        let description = hit.description ? hit.description : this._resultType.singular

        let result = queryResult.addResult(this.store.source, this._resultType.id, displayname, description, null, hit)
        result.id = hit.id
        result.image = hit.image ? hit.image : (this._resultType.iconURI ? this._resultType.iconURI : icons.result.defaultIcon) 
        result.isComplete = false
      }
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        let newQuery = queryResult.addNewQuery(this.store.source, this._resultType.id, this._resultType.plural + " (" + count + ")", null, query.queryString, null, null)
        newQuery.image = this._resultType.iconURI ? this._resultType.iconURI : icons.result.defaultIcon
      }

    } else {
      if (count > 0) {
        let newQuery = queryResult.addNewQuery(this.store.source, this._resultType.id, this._resultType.plural + " (" + count + ")", null, query.queryString, null, null)
        newQuery.image = this._resultType.iconURI ? this._resultType.iconURI : icons.result.defaultIcon
      }
    }
    
    return queryResult
  }
  
  async get(id) {
    let hit = await this.store.get(id)
    return await this.controller.get(hit.source, hit.typeId, hit.resultid)
  }

  async completeResult(result) {
    return this.get(result.id)
  }
  
}