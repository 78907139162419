import * as reproject from "../../util/reproject.js"
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import GeoJSON from 'ol/format/GeoJSON.js'
import { getCenter } from 'ol/extent.js'
import icons from "../../resources/icons.js"

export default class VidiLinkForGeometry extends DetailsHandlerDef {

  constructor(options= {}) {
    if (!options.host)
      throw "Vidi handler needs options.host i.e. https://kort.geofyn.dk/app/geofyn/_l_map_generel/#geodk.bright/15"
    
    let defaultOptions = {
      buttonText: "VIDI",
      more: false
    }

    super(Object.assign({}, defaultOptions, options))
    
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler
    this.host = options.host
    
    this.layers = ''
    if (options.layers)
      this.layers = options.layers
  }
 
  isApplicable() {
    return true
  }
 
  async myHandler(result) {
    let pointGeom = result.geometry
    if (pointGeom.type ==='Polygon' || pointGeom.type ==='MultiPolygon') {
      pointGeom = this.getCenter(pointGeom)
    }
    let latLon = this.convert2582GeometryTowgs84LatLon(pointGeom)
    let items = []
    items.push(
      {
        type: 'link',
        icon: icons.details.link,
        link: `${this.host}/${latLon.lon}/${latLon.lat}/${this.layers}`,
        linkTitle: 'Se i Vidi'
      }
    )
    return items
  }
 
  convert2582GeometryTowgs84LatLon(geometry) {
    let crss = {
      "EPSG:25832": "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
      "EPSG:4326": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
    }
    let geoJsonWgs84 = reproject.reproject(geometry, "EPSG:25832", "EPSG:4326", crss)
    return {lon: geoJsonWgs84.coordinates[0], lat: geoJsonWgs84.coordinates[1]}
  }
  
  getCenter(geometry) {
    let resgeom = new GeoJSON()
    let g = resgeom.readGeometry(geometry, { dataProjection: 'epsg:25832' })
    let extent = g.getExtent()
    let center = getCenter(extent)
    let geojson =    
    {
      "type": "Point",
      "crs": {
        "type": "name",
        "properties": {
          "name": "EPSG:25832"
        }
      },
      "coordinates": [
        center[0],
        center[1]
      ]
    }
    return geojson
  }

}

 
 