/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * Viser adresser for et husnummer (enhedsadresser for en dawa-adresse)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 dawa:
 _type: Septima.Search.DawaSearcher
 _options:
 kommunekode: '101'
 detailhandlers:
 - _type: Septima.Search.AdresserForHusnummerProvider
 _options:
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 dawaSearcher.addDetailHandlerDef(new Septima.Search.AdresserForHusnummerProvider())
 *
 * @example <caption>ES6:</caption>
 * import CvrVirksomhederForAdresseProvider from './searchers/detailhandlers/CvrVirksomhederForAdresseProvider.js'
 * dawaSearcher.addDetailHandlerDef(new AdresserForHusnummerProvider())
 * @api
 **/

export default class AdresserForHusnummerProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.mode=resultlist] "resultlist" for list of results / "list" for labelvalue list / "count" for number of results
   **/
  constructor(options= {}) {

    let defaultOptions = {
      buttonText: "Adresser på husnummeret",
      targets: [{source: "Dawa", typeId: "adresse"}]
    }
    super(Object.assign(defaultOptions, options))

    this.mode = "resultlist"
    if (options.mode)
      this.mode = options.mode
    this.handlerFunction = this.myHandler
  }
  
  async myHandler(result) {
    let items = []
    let dawaSearcher = result.searcher
    let enhedsAdresser = await dawaSearcher.getAdresserForHusnummer(result.id)
    if (this.mode == "count") {
      items.push({
        type: "labelvalue",
        label: "Adresser på husnummeret",
        value: enhedsAdresser.features.length + (enhedsAdresser.features.length == 1 ? " adresse" : " adresser")  + " på husnummeret"
      })
    } else if (this.mode == "list") {
      for (let enhedsAdresse of enhedsAdresser.features)
        items.push({
          type: "labelvalue",
          value: enhedsAdresse.properties.adressebetegnelse
        })
    } else {
      //resultlist
      let ids = []
      for (let enhedsAdresse of enhedsAdresser.features)
        ids.push(enhedsAdresse.properties.id)
      let results = await dawaSearcher.gets(ids, "enhedsadresse")
      for (let result of results)
        items.push({
          type: "result",
          result: result
        })
    }
    if (this.more && (this.mode !== "count")) {
      let detailItemsList = new DetailItemsList({
        header: this.buttonText,
        name: "adresser"
      })
      for (let item of items)
        detailItemsList.append(item)
      return [detailItemsList.asItem()]
    } else {
      return items
    }

  }
  
}