import {fetch2} from "../utils.js"

export class VejAPI {
  /*
   * @param {Object} options VejAPI expects these options:
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {string} options.serviceUrl
  */
  constructor(options = {}) {
    this.kommunekode  = "*"
    if (typeof options.kommunekode !== 'undefined')
      this.kommunekode = options.kommunekode.toString()
    this.serviceUrl = options.serviceUrl
  }

  /*
   * @param {Object} query 
   * @param {string} query.query
   * @param {string} oquery.limit
  */
  query(query) {
    let callParameters = {q: "",
      side: 1,
      per_side: query.limit + 1,
      noformat: 1}
      
    if (query.query !== "")
      callParameters.q = query.query + "*"

    if ( this.kommunekode !== "*")
      callParameters.kommunekode = this.kommunekode

    return new ApiResult(this.serviceUrl, callParameters)
  }
  
  async getVejStykker(params = {}) {
    params.srid = 25832
    params.format = "geojson"
    params.struktur="nestet"
    if (this.kommunekode !== "*")
      params.kommunekode = this.kommunekode
    let data = await fetch2(this.serviceUrl + "/vejstykker", {data: params})
    
    let hits = []
    if (data.features) {
      for (let vejstykke of data.features) {
        for (let postnummer of vejstykke.properties.postnumre) {
          hits.push({type: "vejstykke", vejnavn: vejstykke.properties.navn, postnummer: postnummer.nr, postdistrikt: postnummer.navn})
        }
      }
    }
    return hits
  }

  async getVejForNavnPostnummer(navn, postnummer) {
    let query = {
      postnr: postnummer,
      navn: navn
    }
    query.srid = 25832
    query.format = "geojson"
    query.struktur="nestet"
    let featureCollection = await fetch2(this.serviceUrl + "/vejstykker", {data: query})
    if (featureCollection.features.length > 0) {
      let feature = featureCollection.features[0]
      let vejstykke = featureCollection.features[0].properties
      let postnummer = vejstykke.postnumre[0]
      return {type: "vej", vejnavn: vejstykke.adresseringsnavn, postnummer: postnummer.nr, postdistrikt: postnummer.navn, geometry: feature.geometry, id: vejstykke.id, feature: feature}
    }
  }


  async get(id) {
    let query = {
      id: id
    }
    query.srid = 25832
    query.format = "geojson"
    query.struktur = "nestet"
    let featureCollection = await fetch2(this.serviceUrl + "/vejstykker", {data: query})
    if (featureCollection.features.length > 0) {
      let feature = featureCollection.features[0]
      let vejstykke = featureCollection.features[0].properties
      let postnummer = vejstykke.postnumre[0]
      return {
        type: "vej",
        vejnavn: vejstykke.adresseringsnavn,
        postnummer: postnummer.nr,
        postdistrikt: postnummer.navn,
        geometry: feature.geometry,
        id: vejstykke.id,
        feature: feature
      }
    }
  }
}

class ApiResult {


  constructor(serviceUrl, callParameters) {
    this.serviceUrl = serviceUrl
    this.callParameters = callParameters
    this.vejnavnPostnummerCount = null
    this.vejnavne = null
    this.vejstykker = null
  }
  
  async vejNavneReady() {
    if (!this.vejnavne) {
      this.vejnavne = await fetch2(this.serviceUrl + "/vejnavne", {data: this.callParameters})
    }
  }

  async vejStykkerReady() {
    if (!this.vejstykker) {
      this.vejstykker = await fetch2(this.serviceUrl + "/vejstykker", {data: this.callParameters})
    }
  }
  
  async getVejnavnCount() {
    await this.vejNavneReady()
    return this.vejnavne.length
  }

  async getVejnavneHits(limit) {
    await this.vejNavneReady()
    let hits = []
    for (let i = 0; (i < this.vejnavne.length) && (hits.length < limit); i++) {
      let vejNavn = this.vejnavne[i]
      if (vejNavn.navn.toLowerCase() + "*" == this.callParameters.q) {
        for (let postNummer of vejNavn.postnumre)
          hits.push({type: "vejnavnpostnummer", vejnavn: vejNavn.navn, postnummer: postNummer.nr, postdistrikt: postNummer.navn})
      }else{
        if (vejNavn.postnumre.length === 1) {
          let postNummer = vejNavn.postnumre[0]
          hits.push({type: "vejnavnpostnummer", vejnavn: vejNavn.navn, postnummer: postNummer.nr, postdistrikt: postNummer.navn})
        }else{
          hits.push({type: "vejnavn", vejnavn: vejNavn.navn})
        }
      }
    }
    return hits
  }

  async getVejnavneHits_org(limit) {
    await this.vejNavneReady()
    let hits = []
    for (let i = 0; (i < this.vejnavne.length) && (hits.length < limit); i++) {
      let vejNavn = this.vejnavne[i]
      let postNummer
      if (vejNavn.postnumre.length === 1) {
        postNummer = vejNavn.postnumre[0]
        hits.push({type: "vejnavnpostnummer", vejnavn: vejNavn.navn, postnummer: postNummer.nr, postdistrikt: postNummer.navn})
      }else{
        hits.push({type: "vejnavn", vejnavn: vejNavn.navn})
      }
    }
    return hits
  }
  
  async buildVejHit() {
    let id = this.vejstykker[0].id
    let query = {
      id: id
    }
    query.srid = 25832
    query.format = "geojson"
    query.struktur="nestet"
    let featureCollection = await fetch2(this.serviceUrl + "/vejstykker", {data: query})
    if (featureCollection.features.length > 0) {
      let feature = featureCollection.features[0]
      let vejstykke = featureCollection.features[0].properties
      let postnummer = vejstykke.postnumre[0]
      return {type: "vej", vejnavn: vejstykke.adresseringsnavn, postnummer: postnummer.nr, postdistrikt: postnummer.navn, geometry: feature.geometry, id: vejstykke.id, feature: feature}
    }
  }
  
  // eslint-disable-next-line no-unused-vars
  async getVejHit(kommunekode, vejkode) {
    let query = {
      kommunekode: kommunekode,
      kode: vejkode
    }
    query.srid = 25832
    query.format = "geojson"
    query.struktur="nestet"
    let featureCollection = await fetch2(this.serviceUrl + "/vejstykker", {data: query})
    if (featureCollection.features.length > 0) {
      let feature = featureCollection.features[0]
      let vejstykke = featureCollection.features[0].properties
      let postnummer = vejstykke.postnumre[0]
      return {type: "vej", vejnavn: vejstykke.adresseringsnavn, postnummer: postnummer.nr, postdistrikt: postnummer.navn, geometry: feature.geometry, id: vejstykke.id, feature: feature}
    }
  }
  
  async getVejnavnPostnummerCount() {
    if (this.vejnavnPostnummerCount === null) {
      await this.vejStykkerReady()
      let count = 0
      for (let vejstykke of this.vejstykker)
        count += vejstykke.postnumre.length
      this.vejnavnPostnummerCount = count
    }
    return this.vejnavnPostnummerCount
  }
  
  async getVejnavnPostnummerHits(limit = 100) {
    await this.vejStykkerReady()
    let hits = []
    for (let i = 0; (i < this.vejstykker.length) && (hits.length < limit); i++) {
      let vejstykke = this.vejstykker[i]
      for (let j = 0; (j < vejstykke.postnumre.length) && (hits.length < limit); j++) {
        let postnummer = vejstykke.postnumre[j]
        hits.push({type: "vejnavnpostnummer", vejnavn: vejstykke.navn, postnummer: postnummer.nr, postdistrikt: postnummer.navn, kommunekode: vejstykke.kommune.kode, vejkode: vejstykke.kode})
      }
    }
    return hits
  }

  getVejstykkeCount_slet() {
    return this.vejstykker.length
  }
  
  getVejstykkeHits_slet(limit = 100) {
    const hits = []
    for (let i = 0; (i < this.vejstykker.length) && (hits.length < limit); i++) {
      let vejstykke = this.vejstykker[i]
      let postnumre = []
      for (let j = 0; (j < vejstykke.postnumre.length); j++) {
        let postnummer = vejstykke.postnumre[j]
        postnumre.push(postnummer.nr + " "+ postnummer.navn)
      }
      hits.push({type: "vejstykke", vejnavn: vejstykke.navn, postnumre: postnumre.join(", ")})
    }
    return hits
  }
  
  async getVejstykkeId() {
    await this.vejStykkerReady()
    return this.vejstykker[0].id
  }

}